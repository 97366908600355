
import GoBackComponent from './utils/GoBackComponent';
import CurrencyInput from '../../components/Layout/CurrencyInput';
import USIcon from '../../images/USD.svg'
import { useEffect, useState } from 'react';
import CardModal from './CardModal';
import GreenCheckMark from  '../../asset/cards/green-checkmark.svg';
import { useNavigate } from 'react-router-dom';

const LowBalance = () => {
const navigate = useNavigate()
    const [sendAmount, setAmountInput] = useState('')
    const [formValid, setFormValid] = useState<boolean>(true);
    const [formData, setFormData] = useState({
        sendAmount: ""
      });
      const [showModal, setShowModal] = useState(false)
    
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
          setShowModal(true);
          setFormData({
            sendAmount: sendAmount,
          
          });
        } catch (error: any) {
       
        }
      };
    

    const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/,/g, '')
        setAmountInput(value)

    }

    const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/,/g, "");
        const parsedValue = parseFloat(value);
        const newValue = isNaN(parsedValue) ? 0 : parsedValue;
    }


  const handleSetAlert = () => {
    setShowModal(false)
    navigate(-1)
  }

    useEffect(() => {
        if (sendAmount) {
          setFormValid(false);
        } else {
          setFormValid(true);
        }
      }, [sendAmount]);

    return (


        <>
            <div className="relative flex-1">
                <div className="pt-16 md:w-[38rem] mx-auto ">
                    <GoBackComponent text="Low balance alert" />

                    <div className="bg-[#FFF] shadow-md rounded-2xl py-[2.28125rem] px-4 md:px-[3.8125rem]">
                        <h1 className='hidden text-[#515151] text-sm font-normal '>Remind me when my balance is less than</h1>
                       <form onSubmit={handleSubmit}  action="">
                        <div className="pb-[2.75rem]">
                        <CurrencyInput
                            title="Remind me when my balance is less than"
                            value={sendAmount.toLocaleString()}
                            fn={handleAmountChange}
                            onBlur={handleBlur}
                            type="text"
                            err=""
                            placeholder="0.00"
                            code="USD"
                            flag={USIcon}
                        />
                        </div>
                        <button   type="submit"
                      disabled={formValid}
                      className={`bg-cleva-gold opacity-${formValid ? "60" : "100"
                        } flex items-center justify-center mb-3 md:mb-0 font-medium rounded-lg md:py-2.5 py-3 px-4 md:px-8 shadow-sm w-[100%]`}
                    >Create Alert</button>
                       </form>
                    </div>
                </div>
            </div>
            {showModal &&
            <CardModal  width="md:w-[33rem]">
                    <div>
                <div className='flex flex-col py-8 px-2 items-center justify-center text-center w-[80%] md:w-[60%] mx-auto'>
                <img src={GreenCheckMark} alt="" />
                <h1 className="text-black font-semibold text-base pt-[2.31rem] pb-3">Balance alert set up successfully! </h1>
                <p className="text-black text-xs font-normal pb-[1.5rem]">You will receive an email when your USD Virtual Card balance is below the amount you specified.</p>
                <button onClick={handleSetAlert} className="bg-cleva-gold flex items-center justify-center mb-3 md:mb-0 font-medium rounded-lg md:py-2.5 py-3 px-4 md:px-8 shadow-sm  w-[80%] md:w-full`}
                    ">Done</button>
                    </div>
                </div>
            </CardModal>
            }
        </>
    )
}

export default LowBalance