import { DiCssTricks } from "react-icons/di"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { useEffect, useRef, useState } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import ErrorBoundary from "../../../ErrorBoundary";
import VerifiedModal from "../modals/verifiedModal";
import { setAdditionalDetails, setBeneficialOwner, setKYCState, setSubKYCState } from "../../../features/Kyc/kycSlice";
import { useNavigate } from "react-router-dom";
import { createOwner, getOwner } from "../../../api";
import { toast } from "react-toastify";
import Loader from "../modals/loader";
import BVNInUse from "../modals/BvnInUse";
import OwnerMismatch from "../modals/OwnerMismatch";
import OwnerInvalid from "../modals/OwnerInvalid";
import FailedBVN from "./KYC/Modals/FailedBVN";
import Spinner from "../../../components/PopUps/Spinner";
import backIcon from "../../../asset/svg/backIcon.svg";
import { isValidEmail } from "../../../components/Auth/Register";
import { AWS_CREDS } from "../../../features/services/AmazonService";

const BeneficiaryOwnerForm = (props: any) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { KYCState, BeneficialOwner, AdditionalDetails, SubKYCState } = useAppSelector((state: any) => state.kycInfo)
    const dateInputRef = useRef<HTMLInputElement | null>(null);
    const [bvn, setBvn] = useState("");
    const [bvnValid, setBvnValid] = useState(true)
    const [loading, setLoading] = useState(false)
    const [loader, setLoader] = useState(true)
    const [modal, setModal] = useState(false)
    const [bvnModal, setBVNModal] = useState(false)
    const [percentError, setPercentError] = useState(false)
    const [showEmailError, setShowEmailError] = useState(false)
    const [email, setEmail] = useState("")

    const isButtonDisabled = BeneficialOwner?.FirstName === "" || BeneficialOwner?.LastName === ""
        || BeneficialOwner?.DateOfBirth === "" || BeneficialOwner?.PercentageOwnership === "" || !bvnValid
        || BeneficialOwner?.Address?.Country === "" || BeneficialOwner?.Address?.City === ""
        || BeneficialOwner?.Address?.StreetAddress === "" || BeneficialOwner?.Address?.StateOrTerritory === ""
        || BeneficialOwner?.CountryOfCitizenship === "" || BeneficialOwner?.Email === "" || showEmailError || percentError


    const goHome = () => {
        navigate('/')
    }

    const proceed = () => {
        const initialBeneficialOwnerState = {
            FirstName: "",
            MiddleName: "",
            LastName: "",
            DateOfBirth: "",
            KYCIdentifier: "",
            Email: "",
            IsOwnerAndController: false,
            PercentageOwnership: "",
            DocumentVerificationPending: false,
            Address: {
                StreetAddress: "",
                City: "",
                Country: "",
                StateOrTerritory: "",
                SecondStreetAddress: "",
                Zipcode: ""
            },
            CountryOfCitizenship: "",
            NationalIdentifier: "",
            IdentificationDocument: {
                DocumentNumber: "",
                DocumentType: "",
                IssuingCountry: ""
            },
            ImageUploadItem: {
                DocumentType: "",
                contentType: "",
                filename: "",
                size: 50
            }
        };
        dispatch(setBeneficialOwner(initialBeneficialOwnerState));

        props.closeDetail()
        localStorage.removeItem("currentOwner");
        localStorage.removeItem("editOwner");
    };

    const handleChange = (event: any) => {
        dispatch(
            setBeneficialOwner({
                ...BeneficialOwner,
                [event.target.name]: event.target.value.trim(),
            })
        );

    };

    const handlePercentChange = (event: any) => {
        const percent = event.target.value.trim();
        if (isNaN(Number(percent)) || percent > 100 || percent < 1) {
            setPercentError(true);
        } else {
            setPercentError(false);
        }

        dispatch(
            setBeneficialOwner({
                ...BeneficialOwner,
                PercentageOwnership: percent,
            })
        );
    };

    const handleBVNchange = (event: any) => {
        const newBvn = event.target.value.trim();
        setBvn(newBvn);

        if (newBvn.length === 11) {
            setBvnValid(true);
        } else {
            setBvnValid(false);
        }

        dispatch(
            setBeneficialOwner({
                ...BeneficialOwner,
                NationalIdentifier: newBvn,
            })
        );
    };

    const handleEmailchange = (event: any) => {
        setShowEmailError(!isValidEmail(event.target.value))
        setEmail(event.target.value)

        dispatch(
            setBeneficialOwner({
                ...BeneficialOwner,
                [event.target.name]: event.target.value,
            })
        );
    };

    const handleAddressChange = (event: any) => {
        const Address: any = {
            ...BeneficialOwner?.Address,
            [event.target.name]: event.target.value,
        };

        dispatch(
            setBeneficialOwner({
                ...BeneficialOwner,
                Address,
            })
        );
    };

    const handleSaveForLater = () => {
        localStorage.setItem("saveForLater", "true")
        navigate('/')
    }

    const handleFocus = (e: any) => {
        if (dateInputRef.current) {
            dateInputRef.current.showPicker();
        }
    };

    useEffect(() => {
        const fetchOwner = async () => {
          try {
            const identifier = localStorage.getItem("editOwner");
            if (identifier) {
              const response = await getOwner(identifier);
              dispatch(setBeneficialOwner(response?.data?.BeneficialOwner));
              setLoader(false);
            } else {
              setLoader(false);
            }
          } catch (error) {
            if(AWS_CREDS.STAGE === 'qa'){
                console.error("Error fetching owner:", error);
              }
          }
        };
      
        fetchOwner();
      }, [dispatch]);


    useEffect(() => {
        const identifier = localStorage.getItem("editOwner")
        if (identifier) {
            dispatch(
                setBeneficialOwner({
                    ...BeneficialOwner,
                    KYCIdentifier: identifier,
                })
            );
        }

    }, [dispatch])


    const validate = async () => {

        setLoading(true)
        try {
            const response = await createOwner({ BeneficialOwner });
   
            setLoading(false);
            setModal(true);

            if (response?.data) {
              
                dispatch(setKYCState(response?.data?.BusinessKycState));
                dispatch(setAdditionalDetails(response?.data?.BeneficialOwner.AdditionalDetails));
                dispatch(setSubKYCState(response?.data?.KYCState));

                const updatedBeneficialOwner = {
                    ...BeneficialOwner,
                    KYCIdentifier: response?.data?.KYCIdentifier
                };

                dispatch(setBeneficialOwner(updatedBeneficialOwner));
                localStorage.setItem("currentOwner", response.data.KYCIdentifier);

            }
        } catch (error: any) {
            if(AWS_CREDS.STAGE === 'qa'){
                console.log(error);
              }
            setLoading(false);
            if (error?.response?.data?.Code === "NATIONAL_ID_DUPLICATE") {
                const updatedBeneficialOwner = {
                    ...BeneficialOwner,
                    KYCIdentifier: error?.response?.data?.KYCIdentifier
                };
                dispatch(setBeneficialOwner(updatedBeneficialOwner));
                localStorage.setItem("currentOwner", error?.response?.data?.KYCIdentifier);
                setBVNModal(true);
            } else if (error?.response?.status === 504 || error?.response?.data?.Code === "PROVIDER_DOWN") {
                toast.error("We're sorry, our service provider is currently down. Please try again in a few hours time.");
            } else {
                toast.error("We're sorry, we encountered some unexpected error. Please try again later.");
            }
        }

    }

    const MismatchBVN: boolean =
        SubKYCState === "MISMATCH" && AdditionalDetails.NoOfRetriesLeft > 0;
    const InvalidBVN = SubKYCState === "INVALID" || SubKYCState === "PENDING"
    const ErrorBVN: boolean = (SubKYCState === "MISMATCH" && AdditionalDetails.NoOfRetriesLeft === 0) || SubKYCState === "BVN_FAILED";




    return (
        <>

            {loader

                ?
                <Spinner />

                :

                <ErrorBoundary>
                    <div className=" flex items-center mb-6">
                        <button className='bg-white' onClick={proceed}>
                            <img src={backIcon} alt="" srcSet="" />
                        </button>
                        <p className="font-medium text-base ml-2">Go Back</p>
                    </div>
                    <form className="">

                        <div className="xl:w-[63%] pb-7 pt-8 sm:pt-0">
                            <h3 className="font-semibold text-[18px] -mt-1 ">
                                Beneficial Owner
                            </h3>
                            <p className="text-[13px] mb-7 text-[#747A80]">
                                Make sure you enter the information exactly as it appears on the government-issued ID.
                            </p>
                            <p className="text-[13px] mb-7 text-[#747A80]">
                                Please add any individual who owns 25% or more of <b className="pl-[2px]"> {props.businessName}</b>
                            </p>

                            <div className="grid gap-4" >

                                <>
                                    {/* Field 1 - legal name*/}
                                    <section>
                                        <div className="flex mt-5">
                                            <p className="text-[13px] pb-1 text-black font-normal">
                                                Legal Name
                                            </p>
                                            <p className="text-[6.5px] text-[#D31D1D]">
                                                <DiCssTricks />
                                            </p>
                                        </div>
                                        {/* first name */}
                                        <input
                                            type="text"
                                            name="FirstName"
                                            id=""
                                            value={BeneficialOwner?.FirstName}
                                            onChange={handleChange}
                                            className={`text-[13px] border mb-1 w-full py-2 pl-2 outline-none rounded-[10px] ${BeneficialOwner?.FirstName === "" ? "bg-white" : "bg-[#FFF5D9]"
                                                }`}
                                            placeholder="First Name"
                                        />
                                        {/* middle name: optional */}
                                        <input
                                            type="text"
                                            name="MiddleName"
                                            id=""
                                            value={BeneficialOwner?.MiddleName}
                                            onChange={handleChange}
                                            className={`text-[13px] border mb-1 w-full py-2 mt-2 pl-2 outline-none rounded-[10px] ${BeneficialOwner?.MiddleName === "" ? "bg-white" : "bg-[#FFF5D9]"
                                                }`}
                                            placeholder="Middle Name (optional)"
                                        />
                                        {/* last name */}
                                        <input
                                            type="text"
                                            name="LastName"
                                            id=""
                                            value={BeneficialOwner?.LastName}
                                            onChange={handleChange}
                                            className={`text-[13px] border mb-1 w-full py-2 mt-2 pl-2 outline-none rounded-[10px] ${BeneficialOwner?.LastName === "" ? "bg-white" : "bg-[#FFF5D9]"
                                                }`}
                                            placeholder="Last Name"
                                        />
                                    </section>
                                </>



                                {/* Field 2 - ownership percentage */}
                                <section>
                                    <div className="flex">
                                        <p className="text-[13px] pb-1 text-black font-normal">
                                            Ownership percentage
                                        </p>
                                        <p className="text-[6.5px] text-[#D31D1D]">
                                            <DiCssTricks />
                                        </p>
                                    </div>
                                    <input
                                        type="text"
                                        name="PercentageOwnership"
                                        id="PercentageOwnership"
                                        value={BeneficialOwner?.PercentageOwnership}
                                        onChange={handlePercentChange}
                                        className={`text-[13px] border mb-2 w-full py-2 pl-2 outline-none rounded-[10px] ${BeneficialOwner?.PercentageOwnership === '' ? "bg-white" : "bg-[#FFF5D9]"
                                            }`}
                                        placeholder="%"
                                        maxLength={3}
                                    />
                                    {percentError && (
                                        <p className="text-[11px] mb-4 text-[#F15757]">
                                            Invalid percentage
                                        </p>
                                    )}
                                </section>

                                {/* field 3 - DOB */}
                                <section>
                                    <div className="flex">
                                        <p className="text-[13px] font-normal pb-1 ">Date of birth</p>
                                        <p className="text-[6.5px] text-[#D31D1D]">
                                            <DiCssTricks />
                                        </p>
                                    </div>
                                    <div className="date-input-container">
                                        <input
                                            type="date"
                                            name="DateOfBirth"
                                            id="dateOfBirthInput"
                                            ref={dateInputRef}
                                            value={BeneficialOwner?.DateOfBirth}
                                            onChange={handleChange}
                                            onFocus={handleFocus}
                                            className={`text-[13px] border mb-2 w-full py-2 outline-none rounded-[10px] ${BeneficialOwner?.DateOfBirth === "" ? "bg-white text-[#747A80] " : "bg-[#FFF5D9]"
                                                }`}
                                            placeholder="MM-DD-YYYY"
                                        />
                                    </div>
                                </section>

                                {/* Field 4 - Country of citizenship */}
                                <div>
                                    <div className="flex">
                                        <p className="text-[13px] md:text-[12px] pb-1 text-black font-normal ">
                                            Country of citizenship
                                        </p>
                                        <p className="text-[6.5px] text-[#D31D1D]">
                                            <DiCssTricks />
                                        </p>
                                    </div>
                                    <select
                                        name="CountryOfCitizenship"
                                        id=""
                                        value={BeneficialOwner?.CountryOfCitizenship}
                                        onChange={handleChange}
                                        className={`text-[13px] text-[#747A80] border mb-1 w-full py-2 pl-2 outline-none rounded-[10px] ${BeneficialOwner?.CountryOfCitizenship === ""
                                            ? "bg-white"
                                            : "bg-[#FFF5D9] text-black"
                                            }`}
                                    >
                                        <option value="Business Address" className="hidden">
                                            Select country
                                        </option>
                                        <option value="USA" className=" ">
                                            United States
                                        </option>
                                        <option value="Nigeria" className=" ">
                                            Nigeria
                                        </option>
                                        <option value="UK" className=" ">
                                            UK
                                        </option>
                                    </select>
                                </div>

                                {/* Field 5 - BVN */}
                                <div>
                                    <div className="flex">
                                        <p className="text-[13px] md:text-[12px] pb-1 text-black font-normal ">
                                            Bank Verification Number (BVN)
                                        </p>
                                        <p className="text-[6.5px] text-[#D31D1D]">
                                            <DiCssTricks />
                                        </p>
                                    </div>
                                    <input
                                        type="text"
                                        name="NationalIdentifier"
                                        id="bvn"
                                        value={bvn}
                                        onChange={handleBVNchange}
                                        className={`text-[13px]  border mb-2 w-full py-2 outline-none rounded-[10px] ${BeneficialOwner?.NationalIdentifier === "" ? "bg-white" : "bg-[#FFF5D9]"
                                            }`}
                                        placeholder="Enter valid BVN"
                                        maxLength={11}
                                    />

                                    {!bvnValid && (
                                        <p className="text-[11px] mb-4 text-[#F15757]">
                                            BVN should be exactly 11 characters long.
                                        </p>
                                    )}
                                    {KYCState === "INVALID" && (
                                        <p className="text-[11px] mb-4 text-[#F15757]">
                                            BVN does not exist. Please
                                            enter a valid BVN
                                        </p>
                                    )}
                                    {KYCState === "MISMATCH" && (
                                        <p className="text-[11px] mb-4 text-[#F15757]">
                                            The details you entered don't match the information on your
                                            BVN
                                        </p>
                                    )}
                                </div>

                                {/* Field 5 - email address */}
                                <div>
                                    <div className="flex">
                                        <p className="text-[13px] md:text-[12px] pb-1 text-black font-normal ">
                                            Email Address
                                        </p>
                                        <p className="text-[6.5px] text-[#D31D1D]">
                                            <DiCssTricks />
                                        </p>
                                    </div>
                                    <input
                                        type="email"
                                        name="Email"
                                        id="Email"
                                        value={BeneficialOwner?.Email}
                                        onChange={handleEmailchange}
                                        className={`text-[13px] border mb-2 w-full py-2 outline-none rounded-[10px] ${BeneficialOwner?.Email === "" ? "bg-white" : "bg-[#FFF5D9]"
                                            }`}
                                        placeholder="Email Address"
                                    />
                                    {showEmailError &&
                                        <span className="inline-flex text-[11px] text-[#F15757]">
                                            {/* <img src={errorImg} className="pr-2" alt="error" /> */}
                                            Please enter a valid email address</span>
                                    }
                                </div>

                                {/* Field 6 -  residential addreess */}
                                <div>
                                    <div className="flex">
                                        <p className="text-[13px] md:text-[12px] pb-1 text-black font-normal ">
                                            Residential address
                                        </p>
                                        <p className="text-[6.5px] text-[#D31D1D]">
                                            <DiCssTricks />
                                        </p>
                                    </div>
                                    <select
                                        name="Country"
                                        id=""
                                        value={BeneficialOwner?.Address?.Country}
                                        onChange={handleAddressChange}
                                        className={`text-[13px] text-[#747A80] border mb-1 w-full py-2 pl-2 outline-none rounded-[10px] ${BeneficialOwner?.Address?.Country === ""
                                            ? "bg-white"
                                            : "bg-[#FFF5D9] text-black"
                                            }`}
                                    >
                                        <option value="Business Address" className=" ">
                                            Select country
                                        </option>
                                        <option value="USA" className=" ">
                                            United States
                                        </option>
                                        <option value="Nigeria" className=" ">
                                            Nigeria
                                        </option>
                                        <option value="UK" className=" ">
                                            UK
                                        </option>
                                    </select>


                                    {/* address line 1 */}
                                    <input
                                        type="text"
                                        name="StreetAddress"
                                        id=""
                                        value={BeneficialOwner?.Address?.StreetAddress}
                                        onChange={handleAddressChange}
                                        className={`text-[13px] border mb-1 w-full py-2 pl-2 outline-none rounded-[10px] ${BeneficialOwner?.Address?.StreetAddress === ""
                                            ? "bg-white"
                                            : "bg-[#FFF5D9]"
                                            }`}
                                        placeholder="Address Line 1"
                                    />
                                    {/* adress line 2 */}
                                    <input
                                        type="text"
                                        name="SecondStreetAddress"
                                        id=""
                                        value={BeneficialOwner?.Address?.SecondStreetAddress}
                                        onChange={handleAddressChange}
                                        className={`text-[13px] border mb-2 w-full py-2 pl-2 outline-none rounded-[10px] ${BeneficialOwner?.Address?.SecondStreetAddress === ""
                                            ? "bg-white"
                                            : "bg-[#FFF5D9]"
                                            }`}
                                        placeholder="Address Line 2 (optional)"
                                    />

                                    {/* city */}
                                    <input
                                        type="text"
                                        name="City"
                                        id=""
                                        value={BeneficialOwner?.Address?.City}
                                        onChange={handleAddressChange}
                                        className={`text-[13px] border mb-1 w-full py-2 pl-2 outline-none rounded-[10px] ${BeneficialOwner?.Address?.City === ""
                                            ? "bg-white"
                                            : "bg-[#FFF5D9]"
                                            }`}
                                        placeholder="City"
                                    />
                                    {/* state */}
                                    <input
                                        type="text"
                                        name="StateOrTerritory"
                                        id=""
                                        value={BeneficialOwner?.Address?.StateOrTerritory}
                                        onChange={handleAddressChange}
                                        className={`text-[13px] border mb-1 w-full py-2 pl-2 outline-none rounded-[10px] ${BeneficialOwner?.Address?.StateOrTerritory === ""
                                            ? "bg-white"
                                            : "bg-[#FFF5D9]"
                                            }`}
                                        placeholder="State"
                                    />
                                    {/* zip
                                    <input
                                        type="text"
                                        name="Zipcode"
                                        id=""
                                        value={BeneficialOwner?.Address?.Zipcode}
                                        onChange={handleAddressChange}
                                        className={`text-[13px] border mb-1 w-full py-2 pl-2 outline-none rounded-[10px] ${BeneficialOwner?.Address?.Zipcode === ""
                                            ? "bg-white"
                                            : "bg-[#FFF5D9]"
                                            }`}
                                        placeholder="Zip"
                                    /> */}
                                </div>

                                <div className="flex w-full items-stretch flex-col gap-3 justify-between mt-5">
                                    <button
                                        type="button"
                                        disabled={isButtonDisabled}
                                        onClick={validate}
                                        className={`relative text-[13px] flex items-center justify-center  py-3 px-6 rounded-[10px] mt-2 font-medium border-2 ${isButtonDisabled ?
                                            "bg-[#FFF5D9] text-[#5F5D5D]"
                                            : "bg-[#FFBD59]"
                                            }`}>
                                        Save & Continue  <span className="ml-[0.5rem]"> <MdKeyboardArrowRight /></span>
                                    </button>

                                    <button onClick={handleSaveForLater} className="border border-[#0B69AD] text-[#0B69AD] py-3 px-6 rounded-[10px] mt-2" >Save for later</button>
                                </div>
                            </div>

                        </div>
                        <div />


                    </form>
                    {(loading) && <Loader />}
                    {bvnModal && <BVNInUse open={bvnModal} setOpen={setBVNModal} />}
                    {MismatchBVN && (
                        <OwnerMismatch
                            open={modal}
                            setOpen={setModal}
                            additionalDetails={AdditionalDetails}
                        />
                    )}
                    {InvalidBVN && (
                        <OwnerInvalid
                            open={modal}
                            setOpen={setModal}
                        />
                    )}
                    {(SubKYCState === "DOCUMENT_UPLOAD" || SubKYCState === "VERIFIED") && (
                        <VerifiedModal open={modal} setOpen={setModal} action={proceed} />
                    )}
                    {ErrorBVN && (
                        <FailedBVN open={modal} setOpen={setModal} goHome={goHome} />
                    )}

                    {/* {invalidBvn && (<InvalidModal open={invalidBvn} setOpen={setInvalidBvn} />)}
      
            {bvnSuccess && (<VerifiedModal open={bvnSuccess} setOpen={setBvnSuccess} action={proceed} />)} */}
                </ErrorBoundary>
            }
        </>
    )
}

export default BeneficiaryOwnerForm

