import React from "react";
import { Link } from "react-router-dom";
import successIcon from "../../../../images/success.svg";
const SuccessPin = () => {
  return (
    <div className="relative flex-1">
          <div className="flex justify-center pt-8 min-h-[100vh] items-center md:pt-0">
            <div>
              <div className="login-card-md w-full md:w-[32rem] xl:w-[36rem] px-8 py-8 md:px-20 md:py-16">
                <div className="">
                  
                  <div className="flex items-center justify-center">
                    <img
                      loading="lazy"
                      className="pb-4 w-[8rem]"
                      src={successIcon}
                      alt="success"
                    />
                  </div>
                  <div className="mt-8 text-center">
                    <p className="text-2xl font-semibold">
                    Transaction PIN Updated!
                    </p>
                    <p className="text-sm text-[#484848] py-8">
                    Your transaction PIN was successfully updated. You can now use your PIN to authorize all transactions.
                    </p>
                  </div>
                  <div className="mt-8 w-[80%] mx-auto">
                    <Link
                      to="/"
                      type="submit"
                      className="login-active"
                    >
                      Okay
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  )
}

export default SuccessPin