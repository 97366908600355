import { useNavigate } from "react-router";
import { useAppSelector } from "../../../app/hooks";
import { MdAdd } from "react-icons/md";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/store";
import { fetchRecipients } from "../../../features/Recipients/RecipientsApi";
import Spinner from "../../../components/PopUps/smallSpinner";

export default function RecipientDropdown({ onTagClick, closeModal }: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { recipients, loading } = useAppSelector((state) => state.recipient);
  const reverseRecipients = recipients ?? [];
  const getRecipients = [...reverseRecipients].reverse();

  function handleClose() {
    closeModal();
  }

  function goToAddRecipients() {
    navigate("/tag-recipient");
    handleClose();
  }

  const select = (info: any) => {
    onTagClick({
      Tag: info.NickName,
      FullName: info.FullName,
      RecipientIdentifier: info.RecipientIdentifier,
    });
    handleClose();
  };

  useEffect(() => {
    dispatch(fetchRecipients());
  }, [dispatch]);

  return (
    <div className="sticky z-20 top-50 md:w-[512px] w-[100%]">
      <div className="absolute border w-full shadow-lg rounded-md bg-white">
        <header className="flex px-5 py-3 justify-between items-center w-full bg-[#F5F5F5]">
          <p className="text-base font-small text-[#747A80] text-left">
            Saved Recipients
          </p>
        </header>
        <ul className="w-full">
          {loading ? (
            <Spinner /> //
          ) : (
            getRecipients
              .filter((info: any) => info.RecipientType === "TAG")
              .map((info, index) => (
                <li
                  key={index}
                  className="w-full z-30 text-[15px] cursor-pointer font-medium text-left border border-[#DADADA] border-t-0 border-r-0 border-b-1 border-l-0 px-4 py-3 gap-8 text-gray-600 bottom-1"
                >
                  <div onClick={() => select(info)}>
                    <span className="font-semibold">
                      {info.FullName.FirstName} {info.FullName.MiddleName}{" "}
                      {info.FullName.LastName}
                    </span>{" "}
                    - @{info.NickName}
                  </div>
                </li>
              ))
          )}
          {!loading &&
            (!getRecipients ||
              getRecipients.filter((info: any) => info.RecipientType === "TAG")
                .length === 0) && (
              <li className="w-full z-30 text-[15px] text-center font-medium border border-[#DADADA] border-t-0 border-r-0 border-b-1 border-l-0 px-1 py-3 gap-8 text-gray-600 bottom-1">
                No recipients found
              </li>
            )}
        </ul>
        {/* <button
          onClick={goToAddRecipients}
          className="flex justify-center w-full h-50 border-0 p-auto text-center items-center border-t-0 border-r-0 border-b-1 border-l-0 gap-4 text-[#0B69AD] font-small bg-[#F5F5F5] px-5 py-3 bottom-1"
        >
          <MdAdd size={16} /> Add New
        </button> */}
      </div>
    </div>
  );
}
