import { useState, useEffect } from 'react';
import { useAppSelector } from './app/hooks';

export const useIsBusinessUser = () => {
  const user = useAppSelector((state) => state.user.userData);
  const [isBusinessUser, setIsBusinessUser] = useState<boolean | null>(null);

  useEffect(() => {
    if (user?.BusinessIdentifier) {
      setIsBusinessUser(true);
    } else {
        setIsBusinessUser(false)
    }
  }, [user]);

  return isBusinessUser;
};

export const KYCCheckboxData = [
  { value: "Deel, Rippling, or Remote.com", label: "Deel, Rippling, or Remote.com" },
  { value: "Payoneer", label: "Payoneer" },
  { value: "Fiverr or Upwork", label: "Fiverr or Upwork" },
  { value: 'PayPal', label: 'PayPal' },
  { value: 'Telus International AI', label: 'Telus International AI' },
  { value: 'Survey Platforms (e.g.Verily, Cliqly, e.t.c)', label: 'Survey Platforms (e.g.Verily, Cliqly, e.t.c)' },
  { value: 'Other platforms (e.g Airbnb, Amazon, e.t.c)', label: 'Other platforms (e.g Airbnb, Amazon, e.t.c)' },
  { value: 'Mercury or Brex', label: 'Mercury or Brex' },
  { value: 'Other US banks (e.g Bank of America, Chase e.t.c)', label: 'Other US banks (e.g Bank of America, Chase e.t.c)' },
  { value: 'Non-US countries', label: 'Non-US countries' },
];

export const getBusinessNameInitials = (value:string) => {
  let word = value.split(" ")
  const firstLetters = word.map(name => name?.charAt(0)?.toUpperCase());

    const firstItems = firstLetters.splice(0,2)
    return firstItems
  }