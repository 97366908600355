import { useState, useEffect } from "react"
import CardModal from "./CardModal";
import { IoCloseOutline } from "react-icons/io5";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setTitle } from "../../features/Nav/NavSlice";
import { kycError } from "../../Image";
import BVNInvalidModal from "../Kyc/modals/BVNInvalidModal";
import { verifiedIcon } from "../../Image";
import InvalidModal from "../Kyc/modals/invalidModal";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchCard, verifyCardBVN } from "../../features/ClevaCards/ClevaCardsApi";
import SpinnerTwo from "../../components/PopUps/SpinnerTwo";
import { AWS_CREDS } from "../../features/services/AmazonService";



const VerifyCardBVN = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const location = useLocation()
    const loading = useAppSelector((state) => state.clevaCards.loading);
    const [disableForm, setdisableForm] = useState(true)
    const [BVNvalue, setBVNvalue] = useState<string>('')
    const [BVNvalid, setBVNvalid] = useState(true)
    const [modalOpen, setModalOpen] = useState(false)
    const [invalidBvn, setinvalidBvn] = useState(false)
    const [mismatchBvn, setmismatchBvn] = useState(false)
    const [duplicateBvn, setduplicateBvn] = useState(false)
    const [verifiedBvn, setverifiedBvn] = useState(false)
    const [isLoading, setisLoading] = useState(false)
    const [multiNames, setMultiNames] = useState(false)
    const cards = useAppSelector((state) => state.clevaCards.cards);
    const userNames = useAppSelector((state) => state.clevaCards.userNames)

    useEffect(() => {
        dispatch(setTitle("Card"));
        if (userNames.length > 2) {
            setMultiNames(true)
        }
    }, [dispatch, userNames]);

    const handleInputChange = (e: any) => {
        const value = e.target.value.trim()
        setBVNvalue(value)

        if (value.length === 11) {
            setdisableForm(false)
            setBVNvalid(true)
        } else {
            setdisableForm(true)
            setBVNvalid(false)
        }
    }

    const handleSubmit = (e: any) => {
        setisLoading(true);
        e.preventDefault()
       // Dispatch the verifyCardBVN action
        dispatch(verifyCardBVN(BVNvalue))
            .unwrap()
            .then((result) => {
                if (result.Match === true) {
                    setTimeout(() => {
                        setisLoading(false);
                        setverifiedBvn(true)
                    }, 1000);

                } else {
                    setTimeout(() => {
                        setisLoading(false);
                        setmismatchBvn(true)
                    }, 1000);
                }

            })
            .catch((error) => {
                if(AWS_CREDS.STAGE === 'qa'){
              
                    console.error('Verification error:', error);
                  }
            });

    }

    useEffect(() => {
        if (cards && (cards as any)?.length > 0){
            const activeCard = (cards as any).find((card:any) => ["ACTIVE", "PENDING", "FROZEN"].includes(card.Status));
            if (activeCard) {
                navigate('/cards')
            }
          }
    }, [cards, location]);

    useEffect(() => {
        dispatch(fetchCard())
    }, [dispatch]);

    return (
        <div className="flex flex-col justify-center items-center py-20 xl:ml-20">
            <section className="text-center">
                <h1 className="font-medium text-xl text-[#172323]">Confirm your BVN</h1>
                <p className="text-[#6F6F6F] text-[0.875rem] leading-5 font-normal py-4">Please enter your <span className="text-[#1B1B1B]">Bank Verification Number (BVN)</span> to confirm your identity.</p>
                <button onClick={() => setModalOpen(true)} className="text-[#458DD0] underline text-[0.875rem] leading-5 ">Why we need your BVN</button>
            </section>


            <section className="rounded-[1.25rem] border border-[#EFEFEF] bg-[#FFF] shadow-lg mt-[4.28rem] p-10 w-full md:w-[35rem]">
                <p className="text-[#515151] pb-2 font-normal text-[0.875rem] leading-4 ">Bank verification number (BVN)</p>
                <form onSubmit={handleSubmit} action="">
                    <div>
                        <input data-test="bvn-input" maxLength={11} onChange={handleInputChange} value={BVNvalue} className="w-full rounded-lg border border-[#555] placeholder:text-[#ACACAC] p-[.4rem]" placeholder="Enter 11 digits number" />
                        {!BVNvalid && (
                            <div className="flex items-center pt-1">
                                <p className=" text-[11px]   text-[#F15757]">
                                    BVN should be exactly 11 characters long.
                                </p>
                            </div>
                        )}
                    </div>
                    {/* disable button until BVN is properly  formatted - check KYC */}
                    <button type="submit" disabled={disableForm} className={
                        `bg-[#FFBD59] rounded-xl py-4 px-[0.75rem] w-full mt-[2.12rem] 
                        ${disableForm ? 'opacity-60' : ''}
                    `}>Submit</button>
                </form>
            </section>

            {modalOpen &&
                <CardModal width="md:w-[33rem]" isOpen={modalOpen} bg="bg-white" topS="xl:top-[15rem]" closeModal={() => setModalOpen(false)}>
                    <div className="px-8 py-4">
                        <div onClick={() => setModalOpen(false)} className='flex justify-end cursor-pointer'>
                            <IoCloseOutline className='text-2xl text-[#747474]' />
                        </div>
                        <h1 className="text-[#111] font-medium text-lg pb-[1.75rem]">Why We Need Your BVN</h1>
                        <p className="text-[#444444] font-medium text-sm">
                            We are required by regulation to verify BVNs before creating a USD card for a customer.
                        </p>
                    </div>
                </CardModal>
            }

            {/* invalid Bvn */}
            {invalidBvn &&
                <InvalidModal open={invalidBvn} setOpen={setinvalidBvn} />

            }

            {/* mimatch   BVN */}
            {mismatchBvn && (
                <CardModal width="md:w-[33rem]" isOpen={mismatchBvn} bg="bg-white" topS="xl:top-[15rem]" closeModal={() => setmismatchBvn(false)}>
                    <div className="pt-4 pb-8 px-8">
                        <div onClick={() => setmismatchBvn(false)} className='flex justify-end pb-4 cursor-pointer'>
                            <IoCloseOutline className='text-2xl text-[#747474]' />
                        </div>
                        <div className=" flex flex-col items-center justify-center">
                            <img src={kycError} alt="" />
                            <h1 className="text-[#111] font-semibold text-xl pt-4"> BVN does not match</h1>
                            <p className="text-[#444] text-sm font-normal py-4 text-center">
                                The details you entered don't match the information on your BVN

                            </p>
                            <button className="bg-[#FFBD59] text-[#111] text-sm font-bold py-[0.75rem] px-[3rem] rounded-lg" onClick={() => setinvalidBvn(false)} >Enter Valid BVN</button>
                        </div>
                    </div>
                </CardModal>
            )}

            {/* BVN  already in use */}
            {duplicateBvn && <BVNInvalidModal open={duplicateBvn} setOpen={setduplicateBvn} />}


            {/* successful BVN */}
            {verifiedBvn &&
                <CardModal width="md:w-[33rem]"  isOpen={verifiedBvn} bg="bg-white" topS="xl:top-[15rem]" closeModal={() => setverifiedBvn(false)}>
                    <div data-cy="bvn-success" className="pt-4 pb-8 px-8">
                        <div onClick={() => setverifiedBvn(false)} className='flex justify-end pb-4 cursor-pointer'>
                            <IoCloseOutline className='text-2xl text-[#747474]' />
                        </div>
                        <div className=" flex flex-col items-center justify-center">
                            <img src={verifiedIcon} alt="" />
                            <h1 className="text-[#111] font-normal text-base py-8"> BVN confirmed successfully!</h1>
                            {multiNames ?
                                <button className="bg-[#FFBD59] text-[#111] text-sm font-bold py-[0.75rem] px-[3rem] rounded-lg" onClick={() => navigate('/cards/card-name')} >Continue</button>
                                :
                                <button className="bg-[#FFBD59] text-[#111] text-sm font-bold py-[0.75rem] px-[3rem] rounded-lg" onClick={() => navigate('/cards/fund')} >Continue</button>
                            }
                        </div>
                    </div>
                </CardModal>}

            {isLoading && (
                <CardModal width="md:w-[27rem]">
                    <div className="flex flex-col items-center justify-center p-16 gap-10 lg:gap-20">
                        <SpinnerTwo />
                        <h1 className="text-[#111] text-center font-semibold text-xl md:text-2xl">
                            Loading...
                        </h1>
                    </div>
                </CardModal>
            )}
        </div>
    )
}

export default VerifyCardBVN