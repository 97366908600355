import { Suspense, useEffect } from "react";
import "./App.css";
import { RouterProvider } from "react-router-dom";
import routes from "./routes";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { Amplify, Auth } from "aws-amplify";

import awsConfig from "./features/services/AwsConfigs";
import Spinner from "./components/PopUps/Spinner";
import { setUser } from "./features/User/UserSlice";
import ErrorBoundary from "./ErrorBoundary";
import ReactGA from "react-ga4";
import {GOOGLE_TRACKER_ID} from "./GoogleAnalyticsTracker";


ReactGA.initialize(GOOGLE_TRACKER_ID);

const emitAnalyticsToGoogle = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}

function App() {
  const AppDispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.userData);

  //registerAuth

  useEffect(() => {
    Amplify.configure(awsConfig);
    Auth.configure(awsConfig);
    Amplify.Logger.LOG_LEVEL = "INFO";
    emitAnalyticsToGoogle()

    if (user) {
      AppDispatch(setUser(user));
    } else {
      AppDispatch(setUser(null));
    }
  },[]);

  return (
    <>
      <Suspense
        fallback={
          <div>
            <Spinner />
          </div>
        }
      >
        <ErrorBoundary>
          <RouterProvider router={routes(user)} />
        </ErrorBoundary>
      </Suspense>
    </>
  );
}

export default App;
