import {  Link, useLocation } from 'react-router-dom';
import { ReactNode, useState, useEffect } from 'react';
import WebLayout from '../landing_page/components/WebLayout';
interface ILegalParent {
    active?: string,
    children?: ReactNode,
    page?: string,
    title: string,
    date: string,

}

const LegalParent = (props: ILegalParent) => {
    const [activeLink, setActiveLink] = useState('service')
    const { children, page, title, date } = props;
    const location = useLocation().pathname

    const changeRoute = () => {
      
        if (location.includes('service')) {
            setActiveLink('service')
        }
        else if (location.includes('app')) {
            setActiveLink('app')
        }
        else if (location.includes('referral')) {
            setActiveLink('referral')
        } else{
            setActiveLink('privacy') 
        }
    }

    useEffect(() => {
        if (location.includes('service')) {
            setActiveLink('service')
        }
        else if (location.includes('app')) {
            setActiveLink('app')
        }
        else if (location.includes('referral')) {
            setActiveLink('referral')
        } else{
            setActiveLink('privacy')
        }
    }, [location]);


   const activeStyle = 'border-l-4 border-cleva-gold pl-[1rem] ml-[-1.2rem]'
   //const activeStyle = ''



    return (
        <WebLayout>
            <div>
            <div className='bg-[#000000] relative mt-[7rem] py-[3rem] text-[#fff] text-center'>
                    <h1 className='text-2xl lg:text-4xl font-bold '>{title}</h1>
                    <p className='text-base  pt-[.5rem]'>{date}</p>
                </div>
            <section className='h-full pb-[5rem] w-[90%] xl:w-[80%] mx-auto 2xl:w-[1280px]'>
                <div className="grid gap-[4rem] lg:gap-[6.61rem] lg:grid-cols-[10rem_1fr] pt-[2rem]  md:pt-[5.17rem]">
                    <div>
                        <p className='text-[#1D1D1D] text-[1.5rem] font-bold pb-[2rem] lg:pb-[4.31rem]'>Quick links</p>
                        {/*  */}
                        <div className='flex gap-[1rem] border-l-2 border-[#000] pl-[1rem] md:w-[11rem]'>

                            <ul className='grid grid-rows-2 '>
                                <Link className={`h-12 flex items-center ${activeLink === 'service'  ? activeStyle : ''}`} onClick={changeRoute} to="/terms-of-service">Terms of Service</Link>
                                <Link className={`h-14 flex items-center ${activeLink === 'privacy'  ? activeStyle : ''}`} onClick={changeRoute} to="/privacy-policy">Privacy Policy</Link>
                                <Link className={`h-16 flex items-center ${activeLink === 'app'  ? activeStyle : ''}`} onClick={changeRoute} to="/privacy-policy-app">Privacy Policy - App</Link>
                                <Link className={`h-12 flex items-end ${activeLink === 'referral'  ? activeStyle : ''}`} onClick={changeRoute} to="/referral-terms">Referral Policy</Link>
                            </ul>
                        </div>
                    </div>
                    <div className='grid justify-center'>
                        <h1 className='hidden text-[1rem] md:text-[2rem] font-bold pb-[1rem] text-center'>{page}</h1>
                        <div className='lg:w-[90%]'>{children}</div>
                    </div>
                </div>
            </section>
            <div className="absolute bottom-0 w-full h-[2rem] cursor-pointer">
            </div>
            </div>
        </WebLayout>
        
   
    )
}

export default LegalParent