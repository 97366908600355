import { IoEllipsisHorizontal } from "react-icons/io5";
import EventPop from "../modals/EventPop";
import { useState } from "react";
import { Popover } from "@headlessui/react";

interface IOptionButton {
  RecipientIdentifier: string;
  RecipientName: string;
  RecipientTag: string;
}

export default function OptionButton({
  RecipientName,
  RecipientIdentifier,
  RecipientTag,
}: IOptionButton) {
  const [delDropdown, setDelDropdown] = useState(false);
  return (
    <div
      onClick={() => {
        setDelDropdown(!delDropdown);
      }}
      className="py-3 max-[600px]:pr-4 pr-12 cursor-pointer float-right"
    >
      <IoEllipsisHorizontal />
      {delDropdown && (
        <EventPop
          key={RecipientIdentifier}
          closeDropdown={() => setDelDropdown(false)}
          RecipientIdentifier={RecipientIdentifier}
          RecipientName={RecipientName}
          RecipientTag={RecipientTag}
        />
      )}
    </div>
  );
}
