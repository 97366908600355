import React from "react";
import { Link } from "react-router-dom";

interface CardProps {
  active?: boolean;
  title?: string;
  amount?: string;
  text?: string;
  icon?: string;
  link?: string;
  account?: boolean;
  linkText?: string;
}
const DashboardAccountCard: React.FC<CardProps> = ({
  active,
  title,
  amount,
  text,
  icon,
  link,
  account,
  linkText,
}) => {
  return (
    <div className={`card`}>
      {account ? (
        <Link to={`${link}`}>
          <div className="flex rounded-md items-center bg-white border shadow-xl hover:shadow-xl  h-full py-6 px-4">
            <div className="w-[20%]">
              <img src={icon} className="w-[2.5rem]" alt="logo" />
            </div>
            <div className="w-[80%] ml-[6px]">
              <div className="mb-0">
                <p className="text-sm text-[#4B4B4B]">{title}</p>
                <p className="text-lg text-[#1A1A1A] font-bold">{amount}</p>
                <p className="text-sm text-[#656565]}">{text}</p>
                <p className="text-[#114E79] font-medium underline">
                  {linkText}
                </p>
              </div>
            </div>
          </div>
        </Link>
      ) : (
        <div className="flex rounded-md items-center bg-white border shadow-xl hover:shadow-xl  h-full py-6 px-4">
          <div className="w-[20%]">
            <img src={icon} className="w-[2.5rem]" alt="logo" />
          </div>
          <div className="w-[80%] ml-[6px]">
            <div className="mb-0">
              <p className="text-sm text-[#4B4B4B]">{title}</p>
              <p className="text-lg text-[#1A1A1A] font-bold">{amount}</p>
              <p className="text-sm text-[#656565]">{text}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardAccountCard;
