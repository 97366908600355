import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { ReusableIDDocument } from '../../components/ReusableIDDocument';
import { fetchController, fetchKYC, fetchOwners } from '../../../../features/Kyc/KycApi';
import Spinner from '../../../../components/PopUps/Spinner';
import BusinessDetails from '../businessDetails';
import ControllerInformation from './ControllerInformation';
import { Timeline } from '../../components/Features';
import { useNavigate } from 'react-router-dom';
import backIcon from "../../../../asset/svg/backIcon.svg";

const UploadOwnerDocumentForm = ({ identifier }: any) => {
    const dispatch = useAppDispatch()
    const documentIdentifier = localStorage.getItem("uploadDoc")
    //const controllerIdentifier = localStorage.getItem("uploadDocController")
    const businessName = localStorage.getItem("businessName")
    const incomingPersonType = localStorage.getItem("incomingPersonType")
    const {  BeneficialOwners, loading, Controllers } = useAppSelector((state) => state.kycInfo);
    const [people, setPeople] = useState<any>(null)
    const [personType, setPersonType] = useState<any>('')
    const step = Number(4)
    const completedSteps = [1, 2, 3]

    const changeStep = (next: number) => {
        if (next > step) {

        } else {

        }
        window.scrollTo(0, 0);
    };


    useEffect(() => {
    
        let owner;
        if (incomingPersonType === 'OWNER') {
            owner = BeneficialOwners.find(item => item.KYCIdentifier === documentIdentifier) as any;
            setPersonType('OWNER')
        } else if (incomingPersonType === 'CONTROLLER') {
            owner = Controllers.find(item => item.KYCIdentifier === documentIdentifier) as any
            setPersonType('CONTROLLER')
        }

        setPeople(owner)
    }, [BeneficialOwners, documentIdentifier, Controllers])

    useEffect(() => {
        const businessIdentifier = localStorage.getItem("KYCIdentifier")
        dispatch(fetchOwners())
        dispatch(fetchController())
        dispatch(fetchKYC(businessIdentifier))

    }, [])

    if (loading || people === null) {
        return <Spinner />
    }

    return (
        <>
            <div className="sm:flex py-5 md:py-20">
                <div className="md:w-[35%] sm:w-[50%] pb-10">
                    {<Timeline step={step} completed={completedSteps} />}
                </div>

                {step === 1 && <BusinessDetails currentStep={step} nextStep={changeStep} />}
                {step === 2 && <ControllerInformation currentStep={step} nextStep={changeStep} />}
                {step === 3 && <ControllerInformation currentStep={step} nextStep={changeStep} />}
                {step === 4 && <PeopleDoc data={people} personType={personType} businessName={businessName} />}
            </div>


        </>
    )
}

export default UploadOwnerDocumentForm

export const PeopleDoc = ({ data, personType, businessName }: any) => {
    const navigate = useNavigate()

    const moveToTheNext = () => {
        navigate('/kyb-info')
    }

   

    if (!data) {
        return <Spinner />
    }
    return (
            <div className='grid gap-4 xl:w-[45%] sm:ml-12'>
            <div className=" flex items-center mb-3">
                <button className='bg-white' onClick={moveToTheNext}>
                    <img src={backIcon} alt="" srcSet="" />
                </button>
                <p className="font-medium text-base ml-2">Go Back</p>
            </div>
                <section className='bg-white py-4 px-2'>
                    <div className='flex gap-4'>
                        {personType === "OWNER" ?
                            <div className="w-[44px] h-[44px] rounded-full bg-[#EDE5DB] font-medium text-[#703308] text-sm flex items-center justify-center">
                                {`${data?.BeneficialOwner?.FirstName[0]?.toUpperCase()}${data.BeneficialOwner?.LastName[0]?.toUpperCase()}`}
                            </div>
                            :
                            <div className="w-[44px] h-[44px] rounded-full bg-[#EDE5DB] font-medium text-[#703308] text-sm flex items-center justify-center">
                                {`${data?.Controller?.FirstName[0]?.toUpperCase()}${data?.Controller?.LastName[0]?.toUpperCase()}`}
                            </div>
                        }
                        <div>
                            {personType === "OWNER"
                                ?
                                <h2 className="text-[#454545] font-medium text-lg">{data?.BeneficialOwner?.FirstName}  {data.BeneficialOwner?.LastName}</h2>
                                :
                                <h2 className="text-[#454545] font-medium text-lg">{data?.Controller?.FirstName}  {data.Controller?.LastName}</h2>
                            }
                            <div className="grid xl:flex">
                                {personType === "OWNER"
                                    ?
                                    <p className="text-[#454545] font-base text-sm"> Owner at {businessName}</p>
                                    :
                                    <p className="text-[#454545] font-base text-sm">{data?.Controller?.IsOwnerAndController ? 'Controller & Owner' : 'Controller'} at {businessName}</p>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                <section className='bg-white p-2'>
                    <ReusableIDDocument identifier={data?.KYCIdentifier} personType={data?.PersonType} moveToTheNext={moveToTheNext} />
                </section>
            </div>
    )
}