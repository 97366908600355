import './banner.css';
import gsap from 'gsap';
import { useEffect } from "react";

interface IBanner { }

const Banner = (props: IBanner) => {
    useEffect(() => {
        const wrap = gsap.utils.wrap(-100, 100);
        const boxes: HTMLElement[] = gsap.utils.toArray('.my-list-item');
        const duration = 10
        const gap = 0.5
        const step = duration / boxes.length - gap
        const gapPosition = '+=' + gap

        gsap.set(".my-list-item", {
            y: (i) => i * 50
        });

        gsap.to(".my-list-item", {
            y: "-=200",
            duration: 10,
            ease: "none",
            modifiers: {
                y: gsap.utils.unitize(wrap)
            },
            repeat: -1,

        });

        const tl = gsap.timeline({
            repeat: -1,
            defaults: {
                duration: step,
                ease: 'none'
            }
        });

        boxes.forEach((box, i) => {
            const currentBox = box as HTMLElement;
            //const nextBox = boxes[i + 1] || boxes[0];

            if (boxes[i + 1]) {
                tl.to(currentBox, { color: '#19191B' }, gapPosition).to(
                    boxes[i + 1],
                    { color: '#FBBD61' },
                    '<'
                );
            } else {
                tl.to(box, { color: '#19191B' }, gapPosition).to(
                    boxes[0],
                    { color: '#FBBD61' },
                    '<'
                );
            }
        });

        // Clean up GSAP when the component unmounts
        return () => {
            tl.kill(); // Kill the animation
        };

    }, [])
    const listStyle = "text-[#19191B] text-[1.75rem] lg:text-[2.25rem] font-bold leading-normal";
    //const listStyleActive = "text-[#FBBD61] text-[1.75rem] lg:text-[2.25rem] font-bold leading-normal";
    return (
        // absolute w-[90%] xl:w-[80%]
        <div className=" bottom-[-10rem] md:bottom-[-8rem] mx-auto 2xl:w-[1280px]">
            <div className="bg-[#000000] items-center block md:flex md:flex-row justify-between px-[3.62rem] py-[2.19rem] xl:pr-[13.0625rem] xl:pl-[8.125rem] lg:py-[0.09375rem] rounded-[1.25rem] shadow-[0px_-15px_20px_0px_rgba(13, 10, 25, 0.06)]">
                {/* min-[1440px]:w-[22.6875rem] */}
                <p className="text-white text-[1.25rem] lg:text-[2rem] font-light md:block">For anyone with global
                    financial needs</p>
                <div className="wrapper">
                    <ul className="pt-[2.44rem] md:pt-[0px] text-white my-list">
                        <li className={`${listStyle} my-list-item`}>Startups</li>
                        <li className={`${listStyle} my-list-item`}>Creators</li>
                        <li className={`${listStyle} my-list-item box`}>Freelancers</li>
                        <li className={`${listStyle} my-list-item`}>Contractors</li>
                    </ul>
                </div>
            </div>


        </div>
    )
}

export default Banner;