

const Questions = () => {
    return (
        <section className=" pb-[6.75rem] w-[80%] mx-auto lg:w-[52.1875rem]">
            {/* #999ba82e */}
            <div className="text-center px-[1rem] py-[2.81rem] md:px-[5.38rem] rounded-[1.25rem] bg-[#F6F9FF] shadow-xl shadow-[rgba(153, 155, 168, 0.18)]">
                <h1 className="text-[#282F41] text-[1.5rem] font-semibold pb-[.5rem]">Still have some questions?</h1>
                <p className="text-[#4A4A4A] text-[1.125rem] font-[500]">
                    <span className="" >Can’t find the answer you’re looking for? Please get in touch with us </span>
                    <a className="font-bold cursor-pointer text-[#126DC0]" href="mailto:contact@getcleva.com">contact@getcleva.com</a>
                </p>
            </div>
        </section>
    )
}

export default Questions