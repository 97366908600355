import React from "react";
import { Link } from "react-router-dom";
import deleteIcon from "../../../asset/svg/deleteIcon.svg";

const DeleteUserAccount = () => {
  return (
    <div className="py-10 xl:w-[85%] mx-auto">
      <p className="text-xl font-bold text-center">Please read and confirm</p>

      <div className="shadow bg-white mt-8 xl:p-16 p-5">
        <div className="flex justify-center mb-8">
          <img src={deleteIcon} alt="" />
        </div>
        <p className="text-lg font-bold mb-5">
          Please note that once you delete your account, the following applies:
        </p>
        <ol className="list-decimal ml-8 text-lg">
          <li className="py-2">
            You will not be able to create another Cleva account using your same
            BVN, following our compliance policy
          </li>
          <li className="py-2">
            This account will be immediately de-activated and you will no longer
            have access to it
          </li>
          <li className="py-2">
            Your data will be removed after 90 days, following our data
            retention policy
          </li>
        </ol>

        <div className="flex items-center justify-between gap-4 mt-8">
          <div>
            <Link
              to="/profile"
              className="bg-cancel text-sm font-bold py-3 md:px-10 px-6 rounded-lg"
            >
              Cancel
            </Link>
          </div>
          <div>
            <Link
              to="/profile/confirm-delete-account"
              className={`bg-cleva-gold text-sm font-bold py-3 md:px-10 px-6 rounded-lg`}
            >
              Delete
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteUserAccount;
