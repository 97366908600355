import React from 'react'
import { TableColumn } from 'react-data-table-component'

type DataItem = {
  CreatedAt: string
  MerchantName: string
  Description: string
  Entry: string
  Type: string
  FromAmount: string
  Amount: string
  Status: string
  id: string
}

const formatAmount = (amount: any) => {
  const parsedAmount = parseFloat(amount) // Parse the amount as a floating-point number
  const formattedAmount = parsedAmount.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }) // Format the amount with two decimal places and comma as a thousands separator

  return formattedAmount
}

export const CardColumn: TableColumn<DataItem>[] = [
  {
    name: 'Date',
    selector: (row) => row.CreatedAt,
    cell: (row) => <span data-tag="allowRowEvents" className="">{`${row.CreatedAt.slice(0, 10)}`}</span>,
    sortable: true,
    reorder: true,
    omit: false,
  },
  {
    name: 'Merchant',
    selector: (row) => row.MerchantName,
    cell: (row) => <span data-tag="allowRowEvents" className="font-medium">{`${row.MerchantName || "Card debit"}`}</span>,
    sortable: true,
    reorder: true,
    omit: false,
    //width:"180px",
  },
  {
    name: 'Description',
    selector: (row) => row.Description,
    cell: (row) =>
      row.Status === "COMPLETED" ?
        <span data-tag="allowRowEvents" className="">Approved Successfully</span>
        :
        <span data-tag="allowRowEvents" className="">{row.Description}</span>,
    sortable: true,
    reorder: true,
    width: "200px",
    omit: false,
  },
  {
    name: 'Type',
    selector: (row) => row.Type,
    cell: (row) =>
      row.Type === 'WITHDRAWAL' ? (
        <div data-tag="allowRowEvents" className="">
          <span data-tag="allowRowEvents" className="">Withdrawal</span>
        </div>
      ) : row.Type === 'AUTHORIZATION' ? (
        <div data-tag="allowRowEvents" className="">
          <span data-tag="allowRowEvents" className="">Card debit</span>
        </div>
      ) : (
        <div data-tag="allowRowEvents" className="">
          <span data-tag="allowRowEvents" className="">Card debit</span>
        </div>
      ),
    sortable: true,
    center: false,
    reorder: true,
    omit: false,
    // width: '200px',
  },
  {
    name: 'Amount',
    selector: (row) => row.Amount,
    cell: (row) => (
      <div data-tag="allowRowEvents" className="">
        <span data-tag="allowRowEvents" className="font-medium text-sm">{`$${formatAmount(row.Amount)}`}</span>
      </div>
    ),
    sortable: true,
    center: false,
    reorder: true,
    width: '140px',
    omit: false,
  },
  {
    name: 'Status',
    selector: (row) => row.Status,
    cell: (row) =>
      row.Status === 'COMPLETED' ? (
        <div data-tag="allowRowEvents" className="py-1">
          <span data-tag="allowRowEvents" className="px-3 py-1 my-2 rounded-lg capitalize bg-[#DEF7EC] text-[#03543F] font-medium flex items-center text-[12px]">
            <span data-tag="allowRowEvents">Completed</span>
          </span>
        </div>
      ) : row.Status === 'PENDING' ? (
        <div data-tag="allowRowEvents" className="py-1">
          <span data-tag="allowRowEvents" className="px-3 py-1 my-2 rounded-lg capitalize bg-[#EBFBFE] text-[#1892D7] font-medium flex items-center text-[12px]">
            <span data-tag="allowRowEvents">In Progress</span>
          </span>
        </div>
      )
        :
        row.Status === 'FAILED' && row.Type === "DECLINE" ? (
          <div data-tag="allowRowEvents" className="py-1">
            <span data-tag="allowRowEvents" className="px-3 py-1 my-2 rounded-lg capitalize bg-[#FDE8E8] text-[#D31D1D] font-medium flex items-center text-[12px]">
              <span data-tag="allowRowEvents">Declined</span>
            </span>
          </div>
        )
          : row.Status === 'DELAYED' ? (
            <div data-tag="allowRowEvents" className="py-1">
              <span data-tag="allowRowEvents" className="px-3 py-1 my-2 rounded-lg capitalize bg-[#FDF0E7] text-[#FF6600] font-medium flex items-center text-[12px]">
                <span data-tag="allowRowEvents">Delayed</span>
              </span>
            </div>
          ) : row.Status === 'CANCELLED' ? (
            <div data-tag="allowRowEvents" className="py-1">
              <span data-tag="allowRowEvents" className="px-3 py-1 my-2 rounded-lg capitalize bg-[#FDF0E7] text-[#FF6600] font-medium flex items-center text-[12px]">
                <span data-tag="allowRowEvents">{row.Status}</span>
              </span>
            </div>
          ) : (
            <div data-tag="allowRowEvents" className="py-1">
              <span data-tag="allowRowEvents" className="flex items-center px-3 py-1 my-2 font-medium capitalize bg-[#FDE8E8] rounded-lg text-[#9B1C1C] text-[12px]">
                <span data-tag="allowRowEvents">Failed</span>
              </span>
            </div>
          ),
    sortable: true,
    reorder: true,
    width: '130px',
    omit: false,
  },
]
