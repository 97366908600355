import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import OptionButton from '../components/OptionButton'


export default function AllRecipients() {
  const [delDropdown, setDelDropdown] = useState(false)
  const { recipients } = useAppSelector((state) => state.recipient)


  const reverseRecipients = recipients ?? [];
  const getRecipients = [...reverseRecipients].reverse();

  return (
    <div className="pr-4 py-10">

      <div className="overflow-x-auto">
        <table className="w-full mt-6">
          <thead className="text-left text-[14px] bg-gray-100 mb-2">
            <tr>
              <th className="py-3 pl-2">Recipient</th>
              <th className="py-3 pl-4"> Country</th>
              <th className="py-3 pl-4"> Bank Name</th>
              <th className="py-3 pl-4"> Account</th>
              <th className="py-3"> </th>
            </tr>
          </thead>
          {getRecipients.filter((info: any) => info.RecipientType !== 'TAG')
            .map((info: any) => (
            <tbody
              className="bg-white text-left
      text-[13px] border
      odd:bg-gray-100"
              key={info.RecipientIdentifier}
            >
              <tr>
                <td
                  // onClick={() => navigate("/recipient_details")}
                  className="py-3 pl-4 border-b"
                >
                  <p className="flex space-x-1">
                    <span>{info.FullName.FirstName}</span>
                    <span>{info.FullName.LastName}</span>
                  </p>
                </td>
                <td className="py-3 pl-4 border-b"> {info.Country}</td>
                <td className="py-3 pl-4 border-b">{info.BankName}</td>
                <td className="py-3 pl-4 border-b">**** {info.LastFourDigits}</td>
                <td className="py-3 max-[600px]:pr-4 pl-4 border-b cursor-pointer">
                 <OptionButton key={info.RecipientIdentifier}
                              RecipientIdentifier={info.RecipientIdentifier} 
                              RecipientName={`${info.FullName.FirstName} ${info.FullName.LastName}`} 
                              RecipientTag={info.NickName} />
                </td>
              </tr>
            </tbody>
          ))}
        </table>
      </div>
    </div>
  );
}
