import React from "react";
import { RootState } from "../../app/store";
import { useSelector} from "react-redux";
import nairaIcon from "../../images/naira.svg"

interface DetailProps {
  title: string;
}
const RecipientCard: React.FC<DetailProps> = ({
  title,
  
}) => {

  const { singleTransfer } = useSelector(
    (state: RootState) => state.transaction
  );
  

  const receiveAmount = singleTransfer
  ? (singleTransfer as any).TransactionDetail.ToAmount
  : " ";;

  const AcctName = singleTransfer
  ? (singleTransfer as any).TransactionDetail.Recipient.FullName.FirstName +
    " " +
    (singleTransfer as any).TransactionDetail.Recipient.FullName.LastName
  : " ";
const bankName = singleTransfer
  ? (singleTransfer as any).TransactionDetail.Recipient.BankName
  : "";
const AcctNumber = singleTransfer
  ? (singleTransfer as any).TransactionDetail.Recipient.AccountNumber
  : "";

  return (
    <>
      <div className="bg-[#FCFCFC] border border-[#F1F1F1] md:px-4 py-3 px-2 rounded-xl mb-3">
        <p className="font-medium">{title}</p>
        <div className="md:flex justify-between my-3">
          <p className="text-sm text-[#747A80]">Account name</p>
          <p className="text-sm font-medium">{AcctName}</p>
        </div>
        <div className="md:flex justify-between my-3">
          <p className="text-sm text-[#747A80]">Bank name</p>
          <p className="text-sm font-medium">{bankName}</p>
        </div>
        <div className="md:flex justify-between my-3">
          <p className="text-sm text-[#747A80]">Account number</p>
          <p className="text-sm font-medium">{AcctNumber}</p>
        </div>
        <div className="md:flex justify-between mt-3">
          <p className="text-sm text-[#747A80]">Recipient receives</p>
          <div className="text-sm font-medium flex">
            <img src={nairaIcon} alt="" srcSet="" className="mr-[1px]" />
            {receiveAmount.toLocaleString(undefined, {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})}</div>
        </div>
      </div>
    </>
  );
};

export default RecipientCard;
