import { Link, Text, View, StyleSheet } from '@react-pdf/renderer'
import formatDateString from '../../../../utils/FormatDateString'
import { convertMoneyToLocale } from './TransactionList'
import { IVirtualAccount } from '../../../../features/VirtualAccount/VirtualAccountSlice'

export interface statementAccountDetails extends IVirtualAccount {
  month: string
  year: string
  moneyIn: number
  moneyOut: number
}

const styles = StyleSheet.create({
  VirtualAccountSection: {
    flexDirection: 'row',
  },
  leftColumn: {
    flexGrow: 0,
    width: '50%',
    gap: '25px',
  },
  rightColumn: {
    flexGrow: 1,
    width: '50%',
    paddingLeft: '25%',
    gap: '25px',
  },
  nameDetails: {
    gap: 10,
    height: 40,
    justifyContent: 'center',
  },
  bankDetails: {
    fontSize: 8,
    backgroundColor: '#FFFDF6',
    padding: 5,
    borderRadius: 4,
    borderColor: '#EBEBEB',
    border: '1px solid',
  },
  bankDetailsChildren: {
    flexDirection: 'row',
    marginBottom: 7,
    alignItems: 'center',
  },
  bankDetailsKey: {
    fontWeight: 400,
  },
  bankDetailsValue: {
    fontWeight: 500,
    fontSize: 8,
  },
  fontRegularBold: {
    fontWeight: 500,
    fontSize: 8,
  },
  dateDetails: {
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    fontSize: 8,
    gap: 6,
  },
  contactContainer: {
    fontSize: 8,
    height: 40,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    textAlign: 'justify',
  },
  accountValue: {
    fontSize: 8,
    width: '100%',
  },
  accountValueChildren: {
    flexDirection: 'row',
    fontSize: 7,
    justifyContent: 'space-between',
  },
  accountValueTopContainer: {
    paddingBottom: 15,
    borderBottomWidth: 0.5,
    borderBottomColor: '#112131',
    borderBottomStyle: 'dashed',
    gap: 10,
  },
  accountValueBottomContainer: {
    paddingTop: 15,
    gap: 10,
  },
})

const DetailsLeftColumn = ({ accountDetails }: { accountDetails: statementAccountDetails }) => (
  <View style={styles.leftColumn}>
    <View style={styles.nameDetails}>
      <Text style={{ textTransform: 'uppercase', fontWeight: 700, fontSize: 12 }}>{accountDetails?.AccountName}</Text>
    </View>
    <View style={styles.bankDetails}>
      <View style={styles.bankDetailsChildren}>
        <Text style={{ width: '100px' }}>Bank name:</Text>
        <Text style={styles.fontRegularBold}>{accountDetails?.BankName}</Text>
      </View>
      <View style={styles.bankDetailsChildren}>
        <Text style={{ marginRight: 2, width: '100px' }}>Account number:</Text>
        <Text style={styles.fontRegularBold}>{accountDetails?.AccountNumber}</Text>
      </View>
      <View style={styles.bankDetailsChildren}>
        <Text style={{ marginRight: 2, width: '100px' }}>Routing number:</Text>
        <Text style={styles.fontRegularBold}>{accountDetails?.RoutingNumber}</Text>
      </View>
      <View style={styles.bankDetailsChildren}>
        <Text style={{ marginRight: 2, width: '100px' }}>Account address:</Text>
        <Text style={{ width: '130px', fontWeight: 500 }}>{accountDetails?.Address}</Text>
      </View>
    </View>
    <View style={styles.dateDetails}></View>
  </View>
)
const DetailsRightColumn = ({ accountDetails }: { accountDetails: statementAccountDetails }) => (
  <View style={styles.rightColumn}>
    <View style={styles.contactContainer}>
      <Text style={styles.fontRegularBold}>Need any help? </Text>
      <Text style={{ fontSize: 7 }}>
        {`We’re here to help, so if something isn't right contact us: `}
        <Link src="" style={{ color: '#024678' }}>
          contact@getcleva.com
        </Link>
      </Text>
    </View>
    <View style={styles.accountValue}>
      <View style={styles.accountValueTopContainer}>
        <View style={styles.accountValueChildren}>
          <Text style={{ marginRight: 2 }}>Statement for:</Text>
          <Text style={[styles.fontRegularBold, { fontSize: 7 }]}>{`${accountDetails.month}, ${accountDetails.year}`}</Text>
        </View>
        <View style={styles.accountValueChildren}>
          <Text style={{ marginRight: 2 }}>Generated on:</Text> <Text style={{}}>{formatDateString(new Date().toString())}</Text>
        </View>
      </View>
      <View style={styles.accountValueBottomContainer}>
        <View style={styles.accountValueChildren}>
          <Text style={{ marginRight: 2 }}>Money In:</Text>
          <Text style={{ fontWeight: 600 }}>${convertMoneyToLocale(accountDetails.moneyIn)}</Text>
        </View>
        <View style={styles.accountValueChildren}>
          <Text style={{ marginRight: 2 }}>Money Out:</Text>
          <Text style={{ fontWeight: 600 }}>${convertMoneyToLocale(accountDetails.moneyOut)}</Text>
        </View>
      </View>
    </View>
  </View>
)

const VirtualAccountDetails = ({ userAccountDetails }: { userAccountDetails: statementAccountDetails }) => (
  <View style={styles.VirtualAccountSection}>
    <DetailsLeftColumn accountDetails={userAccountDetails} />
    <DetailsRightColumn accountDetails={userAccountDetails} />
  </View>
)
export default VirtualAccountDetails
