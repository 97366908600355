import Product1 from "../../asset/landing_photos/product-1.svg";
import Product2 from "../../asset/landing_photos/product-2.svg";
import Product3 from "../../asset/landing_photos/product-3.svg";

import One from "../../asset/landing_photos/one-yellow.svg";
import Two from "../../asset/landing_photos/two-yellow.svg";
import Three from "../../asset/landing_photos/three-yellow.svg";

import Safety from "../../asset/landing_photos/safety.svg";
import Security from "../../asset/landing_photos/security.svg";
import SecurityChecks from "../../asset/landing_photos/security-checks.svg";

import {clock, bell, door} from "../../asset/landing_photos"

const freeAccountFeatures = [
  " Open a USD account in seconds",
  " Start receiving payments immediately",
  " Save in USD and protect against inflation",
  " No monthly or hidden fees",
];
const currencyConverterFeatures = [
  " Instant currency conversion",
  " Get bank-beating exchange rates",
  " Transfer to your local bank account",
  " Track the status of your transfer at all times",
];

const productFeatures = [
  {
    img: Product1,
    headerBg: "[#000]",
    bottom: "[-.5rem]",
    title: "Create professional invoices",
    content:
      "Send your clients customized invoices with your brand name or logo, and receive payment directly into your Cleva USD account. Track paid and unpaid invoices and even send out automated reminders.",
  },
  {
    img: Product2,
    headerBg: "[#8fa7c4]",
    bottom: "0",
    title: "Send USD across the world",
    content:
      "Send ACH or domestic wires to bank accounts in the US, and send SWIFT internationally at a much better price than the typical bank rate.",
  },
  {
    img: Product3,
    headerBg: "[#000]",
    bottom: "[-9px]",
    title: "Create a USD card",
    content:
      "Instantly create a virtual USD card that you can rely on for online payments anytime and any day.",
  },
];

const getStarted = [
  {
    img: One,
    title: "Create your Cleva Account",
    content: "Sign up with just an email and get a free Cleva account",
  },
  {
    img: Two,
    title: "Complete KYC/KYB",
    content: "Verify your Identity using your government-issued documents",
  },
  {
    img: Three,
    title: "Get your Cleva USD account",
    content: "Start receiving USD and paying out in your local currency",
  },
];

const getStartedCard = [
  {
    img: One,
    title: "Create your Cleva Account",
    content: "Sign up with just an email and get a free Cleva account",
  },
  {
    img: Two,
    title: "Create Cleva USD Account",
    content: "Verify your identity and open a Cleva USD account",
  },
  {
    img: Three,
    title: "Create USD card",
    content: "Get your USD card, fund it and start shopping without hassle.",
  },
];

//  (FinCEN - 31000239140406)
const secureFunds = [
  {
    img: Safety,
    title: "Safety of funds",
    content: "Your funds are securely processed by our licensed U.S-based financial partners. Cleva is also a registered as a Money Service Business (MSB) with the United States’ Financial Crimes Enforcement Network (FinCEN)",
  },
  {
    img: Security,
    title: "Account security",
    content: "Your account is secured with 2FA. You can also set up a Transaction PIN to authorize all of your transactions",
  },
  {
    img: SecurityChecks,
    title: "Security checks",
    content: "We conduct ongoing security audits of our systems and processes to protect your funds, information, and prevent any security breach",
  },
];
const faq = [
  {
    question: "Why is Cleva different?",
    answer: `We are:
    • Easy_to_use: You can open a Cleva USD account quickly without any hassle. 

    • Experts: We have 10+ years of experience working within the US banking system and know how to build a great product you’ll enjoy.
    
    • Affordable: We cap how much we charge for deposits received. You’ll never get charged more than $20 for any deposit you receive.

    • Customer-obsessed: We obsess over your experience and aim to provide the best USD banking experience possible. You can keep us accountable on this promise!
    `,
  },
  {
    question: "Where can I receive money from?",
    answer: `You can receive ACH, domestic wire from US banks, or international wires from non-US banks. You can also receive payments from platforms such as Payoneer, Upwork, Deel, Fiverr, Amazon, e.t.c.
    `,
  },
  {
    question: "Is there a fee to open a Cleva USD account?",
    answer: `No. Opening a Cleva USD account is free and there are no monthly fees.
    `,
  },
  {
    question: "What are the fees for USD deposits?",
    answer: `• ACH_deposit_fee: Only 0.9% of the deposit. Our minimum fee is $1.5 and maximum is $20 per deposit.
    
    • Wire_deposit_fee: Flat fee of $10. `,
  },
  {
    question: "Is there a minimum deposit I can receive?",
    answer: `Yes, a minimum of $1.5.`,
  },
  {
    question: "How long do funds take to land in my Cleva account?",
    answer: `• ACH: 1 to  3 business days 
    
    • Wire_(from_a_US_Bank): Hours to 1 business day

    • International_wire (from a non-US Bank): 1 to 5 business days.`,
  },
  {
    question: "Which countries are supported?",
    answer: `We currently onboard Nigerians with a BVN or NIN. We plan to onboard additional countries soon. Email us at expansion@getcleva.com with a vote for the country you’d like to see us onboard.`,
  },
  {
    question: "What currencies can I convert my USD into?",
    answer: `You can convert your USD to NGN (Nigerian Naira). We plan to add additional currencies soon. Email us at expansion@getcleva.com with a vote for the currencies you’d like to see.`,
  },
  {
    question: "What are the fees to convert from USD and payout?",
    answer: `No fees! Converting USD to NGN and transferring to your Nigerian bank account is free!`,
  },
  {
    question: "Can I receive funds from outside the US?",
    answer: `Yes, you can receive international deposits from non-US countries using your account number and SWIFT code located on the Account details page of your Cleva account.`
  },
  {
    question: "Can I transfer USD from my Cleva account to another US account?",
    answer: `Not at this time. You can only transfer NGN out of your account, but you will soon be able to transfer USD via ACH and wires.`,
  },
  {
    question: "What are the fees for the Cleva USD Card?",
    answer: ` • Card_creation_fee: $3, where $1 will be added to the card and available for use
    
    • Card_funding_fee: 1% with a minimum of $1 and maximum of $5

    • Card_decline_fee: $0.30

    • Dispute_fee: $50

    • International_transaction_fee: 3%
    `,
  },
];

const cardBenefits = [
  {
    img: clock,
    title: '24/7 Accessibility',
    text: 'Access and manage your Cleva USD account anytime, anywhere, with our user-friendly mobile app and web interface'
  },
  {
    img: bell,
    title: 'Instant Notifications',
    text: 'Stay in the loop with real-time notifications for account activities, ensuring you\'re always aware of your financial status.'
  },
  {
    img: door,
    title: 'Account Management',
    text: 'Streamline your financial management with easy-to-use tools for tracking transactions, checking balances, and more.'
  },
]

export {
  freeAccountFeatures,
  currencyConverterFeatures,
  productFeatures,
  getStarted,
  secureFunds,
  faq,
  cardBenefits,
  getStartedCard
};
