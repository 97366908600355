import { useState, useEffect } from "react";
import BackButton from "../../../components/Buttons/BackButton";
import TabButtons from "../../../components/Tabs/TabButton";
import TabContent from "../../../components/Tabs/TabContent";
import { useSelector, useDispatch } from "react-redux";
import { fetchTransactions } from "../../../features/Transanctions/transactionApi";
import { fetchAccount } from "../../../features/VirtualAccount/VirtualAccountApi";
import { AppDispatch } from "../../../app/store";
import { ToastContainer } from "react-toastify";
import Spinner from "../../../components/PopUps/Spinner";
import { setTitle } from "../../../features/Nav/NavSlice";
import CreateLocalTransfer from "./CreateLocalTransfer";
import CreateTagTransfers from "../../Tags/CreateTagTransfer";
import {
  setDescription,
  setReceiveAmount,
  setRecipientFirstName,
  setRecipientLastName,
  setRecipientTag,
  setSendAmount,
} from "../../../features/Transanctions/TransanctionSlice";

export default function Transfers() {
  const [activeTab, setActiveTab] = useState<string>("all");
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(setTitle("Transfers"));
    dispatch(fetchTransactions());
    dispatch(fetchAccount());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchTransactions());
    dispatch(setDescription(""));
    dispatch(setSendAmount(""));
    dispatch(setRecipientFirstName(""));
    dispatch(setRecipientLastName(""));
    dispatch(setRecipientTag(""));
    dispatch(setReceiveAmount(0));
  }, [dispatch]);

  const handleTabChange = () => {
    dispatch(fetchTransactions());
    dispatch(setDescription(""));
    dispatch(setSendAmount(""));
    dispatch(setRecipientFirstName(""));
    dispatch(setRecipientLastName(""));
    dispatch(setReceiveAmount(0));
  };

  return (
    <>
      <div className="flex items-center pt-6">
        <BackButton />
        <p className="text-lg font-bold ml-3">Make Transfer</p>
      </div>

      <div className="pt-10 mb-10 Tables">
        <div className=" w-full md:mb-8 md:w-1/2 order-0 md:order-0 pt-5 mb-5 mx-auto">
          <ul className="grid grid-cols-2 gap-4 bg-[#EBF0F0] rounded-[10px] px-2 py-1.5">
            <TabButtons
              name="To bank"
              id="all"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              onClick={() => handleTabChange()}
            />
            <TabButtons
              name="To @Cleva tag"
              id="successfulTab"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              onClick={() => handleTabChange()}
            />
          </ul>
        </div>
      </div>
      <div className="mt-4">
        <TabContent id="all" activeTab={activeTab}>
          <CreateLocalTransfer />
        </TabContent>
        <TabContent id="successfulTab" activeTab={activeTab}>
          <CreateTagTransfers />
        </TabContent>
      </div>

      <ToastContainer />
    </>
  );
}
