//const businessType = ['Business Name', 'Limited Liability Company (LTD/Limited)', 'Company Limited by Guarantee (LtGe)', 'Incorporated Trustee (e.g. NGO)', 'Limited Partnership', 'Public Limited Company (PLC)']
const businessType = [
  { value: "BUSINESS NAME", label: "Business Name" },
  { value: "COMPANY", label: "Limited Liability Company (LTD/Limited)" },
  { value: "INCORPORATED TRUSTEES", label: "Incorporated Trustees (e.g NGO)" },
  { value: 'LIMITED PARTNERSHIP', label: 'Limited Partnership' },
  { value: 'LIMITED LIABILITY PARTNERSHIP', label: 'Limited Liability Partnership' },  
]
  const industryType = [
    'Agriculture',
    'Armaments and Defense',
    'Art/Antique Dealers and Galleries',
    'Automotive',
    'Banking & Investment Services',
    'Business Services',
    'Cash-Intensive Businesses',
    'Civic and Social Orgs',
    'Construction',
    'Consulting Services',
    'Consumer Lending',
    'Courier and Express Delivery Services',
    'Credit Card Issuing',
    'Credit Intermediation',
    'Cryptocurrency',
    'Data Processing Hosting and Related',
    'Dealers in Precious Metals/Stones',
    'E-Commerce',
    'Education',
    'Employment Agency',
    'Energy Utilities & Telecom',
    'Entertainment Services - Non Adult',
    'Environment Conservation and Wildlife',
    'Financial Transactions Processing, Reserve, and Clearing House Activities',
    'Furniture Merchant Wholesalers',
    'Grant Marketing and Giving Services',
    'Healthcare',
    'High-End Auction Houses',
    'Hospitality',
    'Import/Export',
    'Industrial Goods or Services',
    'Information Technology',
    'Insurance - Other',
    'Insurance Direct - Except Life Health and Med',
    'Lawful Gaming/Gambling',
    'Legal Services - Non prohibited',
    'Lending - Non-Pay Day',
    'Logistics and Transportation',
    'Luxury Goods',
    'Manufacturing',
    'Marketplaces',
    'Marketing Research',
    'Marketing Services- Non-Mail Order/Telemarketing',
    'Media and Entertainment',
    'Mobile Wallets',
    'Money Services Businesses (MSBs) - Registered',
    'Multi-Level Marketing (MLM)',
    'Non-Profit Organizations',
    'Oil and Gas',
    'Online Auctions',
    'Other',
    'Outdoor Advertising',
    'Payroll Services',
    'Personal & Household Products & Services',
    'Pharmaceuticals',
    'Precious Metals and Stones',
    'Professional Services',
    'Public Administration',
    'Public Service',
    'Real Estate',
    'Real Estate Development',
    'Residential Property Management',
    'Retailers',
    'Sales Financing',
    'Software & IT Services',
    'Technology Equipment',
    'Telecommunications',
    'Telemarketing Bureaus',
    'Third-Party Payment Processors',
    'Tour Operators',
    'Transportation',
    'Travel Agencies and Tour Operators',
    'Travel Services Non-prepaid',
    'Trust Fiduciary Custody',
    'Utilities',
    'Wholesale and Distribution',
    'Wholesale Trade Agent and Brokers'
  ];
  
  

  export {businessType, industryType}