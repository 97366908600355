import React, { useState } from "react";
import copyIcon from "../../../images/ph_copy.svg";
import Clipboard from "react-clipboard.js";
import { ToastContainer, toast } from "react-toastify";
// import { PDFDownloadLink } from "@react-pdf/renderer";
//import AccountDetailsPDF from "./AccountDetailsPDF"; // Import the PDF component
import { useAppSelector } from "../../../app/hooks";
import { Link } from "react-router-dom";
import { AWS_CREDS } from "../../../features/services/AmazonService";
interface AccountDetailsProps {
  AcctName: string;
  Bank: string;
  AcctNumber: string;
  routNum: string;
  swiftCode: string;
  accType: string;
  address: string;
}

interface ModalProps {
  onClose?: () => void;
}

const AccountDetails: React.FC<ModalProps> = ({ onClose }) => {
  const [copiedText, setCopiedText] = useState<string | number>("");
  const allAccount = useAppSelector((state) => state.virtualAccount.allAccount);

  
  const handleCopy = (text: string | number) => {
    setCopiedText(text);
    toast.success("Copied");
  };

  const accountDetails: AccountDetailsProps = {
    AcctName: allAccount ? (allAccount as any).AccountName : " ",
    Bank: allAccount ? (allAccount as any).BankName : " ",
    AcctNumber: allAccount ? (allAccount as any).AccountNumber : " ",
    routNum: allAccount ? (allAccount as any).RoutingNumber : " ",
    swiftCode: allAccount ? (allAccount as any).SwiftCode : `${AWS_CREDS.SWIFT_CODE}`,
    accType: allAccount ? (allAccount as any).AccountType : " ",
    address: allAccount ? (allAccount as any).Address : " ",
  };

  const allDetails = `
      Account name: ${accountDetails.AcctName}
      Bank: ${accountDetails.Bank}
      Account number: ${accountDetails.AcctNumber}
      Routing number: ${accountDetails.routNum}
     Swift Code: ${accountDetails.swiftCode}
      Account Type: ${
        accountDetails.accType === "ach_credit_transfer"
          ? "Business Checking"
          : accountDetails.accType
      }
      Address: ${accountDetails.address}
    `;

  const handleCopyAllDetails = (allDetails: any) => {
    setCopiedText(allDetails);
    toast.success("Account details copied successfully");
  };

  return (
    <>
      <div className="mt-3 flex justify-end">
        <Clipboard
          data-clipboard-text={allDetails}
          onClick={handleCopyAllDetails}
        >
          <button className="text-[#1480E3] underline underline-offset-2 text-sm">
            Copy all account details
          </button>
        </Clipboard>
      </div>

      <div className="bg-[#FBFBFB] border border-[#F0F0F0] mt-2 md:px-8 md:py-6 p-2 rounded-xl mb-3">
        <div className="md:flex justify-between py-4 border-b">
          <p className="text-sm md:w-1/3">Account name</p>
          <div className="flex items-center">
            <p className="text-sm font-bold select-text">
              {accountDetails.AcctName}
            </p>
            <Clipboard
              data-clipboard-text={accountDetails.AcctName}
              onClick={() => handleCopy(accountDetails.AcctName)}
            >
              <button className="copy-button">
                <img src={copyIcon} alt="" className="ml-1 w-4" srcSet="" />
              </button>
            </Clipboard>
          </div>
        </div>
        <div className="md:flex justify-between py-4 border-b">
          <p className="text-sm md:w-1/3">Bank</p>
          <div className="flex items-center">
            <p className="text-sm font-bold select-text">
              {accountDetails.Bank}
            </p>
            <Clipboard
              data-clipboard-text={accountDetails.Bank}
              onClick={() => handleCopy(accountDetails.Bank)}
            >
              <button className="copy-button">
                <img src={copyIcon} alt="" className="ml-1 w-4" srcSet="" />
              </button>
            </Clipboard>
          </div>
        </div>
        <div className="md:flex justify-between py-4 border-b">
          <p className="text-sm md:w-1/3">Account number</p>
          <div className="flex items-center">
            <p className="text-sm font-bold select-text">
              {accountDetails.AcctNumber}
            </p>
            <Clipboard
              data-clipboard-text={accountDetails.AcctNumber}
              onClick={() => handleCopy(accountDetails.AcctNumber)}
            >
              <button className="copy-button">
                <img src={copyIcon} alt="" className="ml-1 w-4" srcSet="" />
              </button>
            </Clipboard>
          </div>
        </div>
        <div className="md:flex justify-between py-4 border-b">
          <p className="text-sm md:w-1/3">Routing number</p>
          <div className="flex items-center">
            <p className="text-sm font-bold select-text">
              {accountDetails.routNum}
            </p>
            <Clipboard
              data-clipboard-text={accountDetails.routNum}
              onClick={() => handleCopy(accountDetails.routNum)}
            >
              <button className="copy-button">
                <img src={copyIcon} alt="" className="ml-1 w-4" srcSet="" />
              </button>
            </Clipboard>
          </div>
        </div>
        <div className="md:flex justify-between py-4 border-b">
          <div className="md:w-2/3">
            <p className="text-sm">SWIFT code</p>
            <p className="text-xs text-[#737373]">
              For payments from non-US countries
            </p>
          </div>
          <div className="flex items-center">
            <p className="text-sm font-bold select-text">
              {accountDetails.swiftCode}
            </p>
            <Clipboard
              data-clipboard-text={accountDetails.swiftCode}
              onClick={() => handleCopy(accountDetails.swiftCode)}
            >
              <button className="copy-button">
                <img src={copyIcon} alt="" className="ml-1 w-4" srcSet="" />
              </button>
            </Clipboard>
          </div>
        </div>
        <div className="md:flex justify-between py-4 border-b">
          <p className="text-sm md:w-1/3">Account Type</p>
          <div className="flex items-center">
            <p className="text-sm font-bold select-text">
              {accountDetails.accType === "ach_credit_transfer"
                ? "Business Checking"
                : accountDetails.accType}
            </p>
            <Clipboard
              data-clipboard-text={
                accountDetails.accType === "ach_credit_transfer"
                  ? "Business Checking"
                  : accountDetails.accType
              }
              onClick={() =>
                handleCopy(
                  accountDetails.accType === "ach_credit_transfer"
                    ? "Business Checking"
                    : accountDetails.accType
                )
              }
            >
              <button className="copy-button">
                <img src={copyIcon} alt="" className="ml-1 w-4" srcSet="" />
              </button>
            </Clipboard>
          </div>
        </div>
        <div className="md:flex justify-between py-4">
          <p className="text-sm md:w-1/3">Address</p>
          <div className="flex items-center">
            <p className="text-sm font-bold select-text">
              {accountDetails.address}
            </p>
            <Clipboard
              data-clipboard-text={accountDetails.address}
              onClick={() => handleCopy(accountDetails.address)}
            >
              <button className="copy-button">
                <img src={copyIcon} alt="" className="ml-1 w-4" srcSet="" />
              </button>
            </Clipboard>
          </div>
        </div>
      </div>
      <div className="mt-4">
        {/* desktop button  */}
        <button
          onClick={onClose}
          className="text-sm hidden md:block
            font-bold py-4 md:px-10 px-6
            w-full
            rounded-md mt-4
            border border-[#747A80]"
        >
          Done
        </button>

        {/* mobile button  */}
        <Link
          to="/accounts"
          className="text-sm block
            font-bold py-4 md:px-10 px-6
            w-full
            rounded-md mt-6 text-center
            border border-[#747A80] md:hidden"
        >
          Done
        </Link>
      </div>

      <ToastContainer />
    </>
  );
};

export default AccountDetails;
