import LegalParent from "./LegalParent";

interface TOS { }


const TermsOfService = (props: TOS) => {

    return (
        <div>
            <LegalParent title="Terms of service" date="Last Updated: July 27, 2023" page="Cleva Technologies Inc.">
                <section className="text-[12px] leading-8 ">
                    <div className="hidden">
                        <p className="text-[1rem] font-bold py-[1rem] text-center">
                            Terms of Service  </p>
                        <p className="text-[1rem] font-bold py-[1rem] text-center">
                            Last Updated: July 27, 2023
                        </p>
                    </div>
                    <p>
                        This Terms of Use <b>(“Agreement”)</b> constitutes a legally binding agreement between you and Cleva Technologies Inc. <b>(“Cleva”, “we”, “us”, “our”)</b> concerning your access to and the use of our products and services made available to you on our website, mobile application or other digital platforms <b>(“Platform”)</b> whether as visitors, registered users or account holders. By accessing our services and onboarding on our Platform, you accept and agree to be bound by the terms set out under this Agreement. If you do not wish to be bound by this Agreement, please do not use or access our services.
                    </p>
                    <br />
                    <p>We encourage you to read the terms of this Agreement as it sets out your rights and obligations. This Agreement incorporates our Privacy Notice which is available here. <b>You can find the referral terms of service <a className="text-sky-400 cursor-pointer" target="_blank" href="/referral-terms">here.</a></b></p>

                    {/* 1 - version control */}

                    <div className="flex gap-8">
                        <b>1.</b>
                        <div>
                            <b>Version control</b>
                            <p>We may make changes to this Agreement from time to time and when we do, we will update the “Last Updated” date given above. We may notify you through your registered email address on the Platform of the revisions made to this Agreement. However, you understand that this may not always be practicable and when that occurs, you agree to waive your right to notification.
                                <br />
                                It is your responsibility to review this Agreement frequently and to stay informed of all changes which may be made to it. The current version of this Agreement will supersede all earlier versions from time to time. By using our services after a revision has been made to this Agreement, you consent to be bound by the changes we made.
                            </p>
                        </div>
                    </div>

                    {/* 2 - definition & interpretation */}
                    <div className="flex gap-8">
                        <b>2.</b>
                        <div>
                            <b>Definition and Interpretation</b>
                            <p>Under this Agreement, the following words and meanings will have the meanings ascribed to them
                            </p>

                            <ul>
                                <div className="flex gap-8">
                                    <b>2.1</b>
                                    <p><b>“Applicable Law”</b> means every law applicable to the services under this Agreement for the time being in force in our  jurisdiction of operation;</p>
                                </div>
                                <div className="flex gap-8">
                                    <b>2.2</b>
                                    <p><b>“Application/App”</b>  means the mobile application operated by Cleva that facilitates transactions to our customers on our mobile application through aggregated accounts provided by Cleva;</p>
                                </div>
                                <div className="flex gap-8">
                                    <b>2.3</b>
                                    <p><b>“Accepted Payment Method”</b>   means the specific form of payment such as a payment by Card, Bank Transfer, or any other payment method that is accepted by Cleva in exchange for transferring funds into your virtual account;</p>
                                </div>
                                <div className="flex gap-8">
                                    <b>2.4</b>
                                    <p><b>“Business Days”</b>  means a day other than a Saturday, Sunday or a public holiday in Nigeria and the United States of America when banking institutions in both countries are not open for business;</p>
                                </div>
                                <div className="flex gap-8">
                                    <b>2.5</b>
                                    <p><b>“Confidential Information”</b>  means all and any confidential and proper information shared between the parties, whether or not marked as ‘confidential’ or if by its nature intended to be for the knowledge of the receiving Party alone, shall be kept confidential by the receiving Party unless or until the receiving Party can reasonably demonstrate that such information and material, is, or part of it is, in the public domain through no fault of its own;</p>
                                </div>
                                <div className="flex gap-8">
                                    <b>2.6</b>
                                    <p><b>“Deposit”</b> means the transfer of US Dollars into your Cleva Account as referenced in Clause 6.2;</p>
                                </div>
                                <div className="flex gap-8">
                                    <b>2.7</b>
                                    <p><b>“Pay Out”</b> means the disbursement of Naira to the beneficiaries resident in Nigeria;</p>
                                </div>
                                <div className="flex gap-8">
                                    <b>2.8</b>
                                    <p><b>“Transaction”</b> means the conversion of US Dollars to Naira and transfer of the Naira to beneficiaries resident in Nigeria;</p>
                                </div>
                                <div className="flex gap-8">
                                    <b>2.9</b>
                                    <p><b>“Fees”</b> means the fees that are imposed on each Transaction that is made using your Cleva Account;</p>
                                </div>
                                <div className="flex gap-8">
                                    <b>2.10</b>
                                    <p><b>“USD”</b> means United States Dollars;</p>
                                </div>
                                <div className="flex gap-8">
                                    <b>2.11</b>
                                    <p><b>“Website”</b> means <a href="https://www.getcleva.com" target="_blank" rel="noreferrer" className="text-sky-500">https://www.getcleva.com</a> as updated from time to time.</p>
                                </div>
                            </ul>
                        </div>
                    </div>

                    {/* 3 - nature of services */}

                    <div className="flex gap-8">
                        <b>3.</b>
                        <div>
                            <b>Nature of our Services</b>
                            <p>We may make changes to this Agreement from time to time and when we do, we will update the “Last Updated” date given above. We may notify you through your registered email address on the Platform of the revisions made to this Agreement. However, you understand that this may not always be practicable and when that occurs, you agree to waive your right to notification.

                                Cleva is a technological platform that enables Nigerians to receive payment from US-based businesses and individuals for their goods and services. As a user of our platform, you will be able to open a USD account, receive payments into that account, save in USD, and payout in local currency to Nigerian bank accounts. We facilitate all of these through our partnerships with licensed financial institutions in the U.S and in Nigeria.
                            </p>
                        </div>
                    </div>
                    {/* 4 - onboarding requirements */}
                    <div className="flex gap-8">
                        <b>4.</b>
                        <div>
                            <b>Onboarding Requirements</b>
                            <ul>
                                <div className="flex gap-8">
                                    <b>4.1</b>
                                    <p>For us to offer our services, you are required to provide us with the following:</p>
                                </div>
                                <ul className="ml-[3rem]">
                                    <div className="flex gap-8">
                                        <p>4.1.1</p>
                                        <p>First Name</p>
                                    </div>
                                    <div className="flex gap-8">
                                        <p>4.1.2</p>
                                        <p>Last Name</p>
                                    </div>
                                    <div className="flex gap-8">
                                        <p>4.1.3</p>
                                        <p>Business Name</p>
                                    </div>
                                    <div className="flex gap-8">
                                        <p>4.1.4</p>
                                        <p>Phone Number</p>
                                    </div>
                                    <div className="flex gap-8">
                                        <p>4.1.5</p>
                                        <p>Date of Birth</p>
                                    </div>
                                    <div className="flex gap-8">
                                        <p>4.1.6</p>
                                        <p>Bank Verification Number</p>
                                    </div>
                                    <div className="flex gap-8">
                                        <p>4.1.7</p>
                                        <p>National Identification Number</p>
                                    </div>
                                </ul>
                                <div className="flex gap-8">
                                    <b>4.2</b>
                                    <p>As a proof of your address, a copy of any of the following documents would suffice: </p>
                                </div>
                                <ul className="ml-[3rem]">
                                    <div className="flex gap-8">
                                        <p>4.2.1</p>
                                        <p>Bank statement</p>
                                    </div>
                                    <div className="flex gap-8">
                                        <p>4.2.2</p>
                                        <p>Government issued tax statement;</p>
                                    </div>
                                    <div className="flex gap-8">
                                        <p>4.2.3</p>
                                        <p>Utility bill</p>
                                    </div>
                                </ul>
                                <div className="flex gap-8">
                                    <b>4.3</b>
                                    <p>We may request, in our sole discretion, any additional information or documents we deem necessary and appropriate in line with our assessment of risks relevant to you as our client, both in the course of our  review of your application and throughout your use of our services;</p>
                                </div>
                                <div className="flex gap-8">
                                    <b>4.4</b>
                                    <p>You undertake that all information and documents which you present us are true, accurate and up to date. If, at any point during the term of this Agreement, any of the information and documents presented to us should change or you identify that they were false, inaccurate and / or incorrect in any form, then you shall promptly present us with the respective updated information and documents within seven (7) days of notifying us;</p>
                                </div>
                                <div className="flex gap-8">
                                    <b>4.5</b>
                                    <p>You understand and accept that we have the right to suspend and terminate your access to our platform and the use of our services, if we find that any of the information provided to us, are false, inaccurate or incomplete.</p>
                                </div>
                            </ul>
                        </div>
                    </div>
                    {/* 5 - profile */}
                    <div className="flex gap-8">
                        <b>5.</b>
                        <div>
                            <b>Profile</b>
                            <ul>
                                <div className="flex gap-8">
                                    <b>5.1</b>
                                    <p>Once your Profile is created, you will be able to access your transaction  history and all other transactions relevant to your Cleva Account.</p>
                                </div>
                                <div className="flex gap-8">
                                    <b>5.2</b>
                                    <p>We will set the minimum and maximum balance to be retained in the Cleva Account, and the total daily transaction limit.</p>
                                </div>
                            </ul>
                        </div>
                    </div>
                    {/* 6 - Transactions */}
                    <div className="flex gap-8">
                        <b>6.</b>
                        <div>
                            <b>Transactions</b>
                            <ul>
                                <div className="flex gap-8">
                                    <b>6.1</b>
                                    <p>Deposits are carried out by way of bank transfer through ACH or domestic wire transfer; </p>
                                </div>
                                <div className="flex gap-8">
                                    <b>6.2</b>
                                    <p>We will provide you with a designated account number specifically set up for you to deposit your funds into. You understand that your Deposits are to be made in USD in the manner set out in Clause 6.1;</p>
                                </div>
                                <div className="flex gap-8">
                                    <b>6.3</b>
                                    <p>Once we have received the Deposit, we will credit your Account (“Cleva Account”) with the exact funds you provided net of any associated fee. We are only responsible for the funds deposited once we have received it.</p>
                                </div>
                                <div className="flex gap-8">
                                    <b>6.4</b>
                                    <p>When you wish to perform a Transaction, we will request that you provide us the following:</p>
                                </div>
                                <ul className="ml-[3rem]">
                                    <div className="flex gap-8">
                                        <p>6.4.1</p>
                                        <p>Name of the beneficiary;</p>
                                    </div>
                                    <div className="flex gap-8">
                                        <p>6.4.2</p>
                                        <p>Address of the recipient;</p>
                                    </div>
                                    <div className="flex gap-8">
                                        <p>6.4.3</p>
                                        <p>Beneficiary's bank / payment institution;</p>
                                    </div>
                                    <div className="flex gap-8">
                                        <p>6.4.4</p>
                                        <p>Beneficiary's bank account number;</p>
                                    </div>
                                    <div className="flex gap-8">
                                        <p>6.4.5</p>
                                        <p>Purpose of the payment;</p>
                                    </div>
                                    <div className="flex gap-8">
                                        <p>6.4.6</p>
                                        <p>Any other information requested by us; and follow the on-screen instructions to confirm the Transaction.</p>
                                    </div>

                                </ul>
                                <div className="flex gap-8">
                                    <b>6.5</b>
                                    <p>We shall only make transfers to beneficiaries that have a Nigerian Naira bank account;</p>
                                </div>
                                <div className="flex gap-8">
                                    <b>6.6</b>
                                    <p>After you initiate the transfer, we will convert your USD to Naira using the exchange rate we have shown earlier shown you, and Pay Out the naira to the beneficiary, using the account information you provide to us;
                                    </p>
                                </div>
                                <div className="flex gap-8">
                                    <b>6.7</b>
                                    <p>You understand that each Transaction, once processed, shall be completed within one (1) business day;</p>
                                </div>
                                <div className="flex gap-8">
                                    <b>6.8</b>
                                    <p>You understand that you are solely responsible for ensuring the accuracy of the beneficiary’s bank account information and we will not be liable to pursue recovery from any account we make a Pay Out to, when the account payment is made to correlate with the information you provided to us. </p></div>
                            </ul>
                            <div className="flex gap-8">
                                <b>6.9</b>
                                <p>Where we are unable to process a Transaction due to a system error on our end, we will notify you of this after 24 hours and continue to attempt the processing of the Transaction;
                                </p></div>
                            <div className="flex gap-8">
                                <b>6.10</b>
                                <p>You have the right after the notice has been provided to you, to request for a reversal of the payment made. When this happens we will transfer the USD received from you back to you net of the fees.
                                </p></div>
                            <div className="flex gap-8">
                                <b>6.11</b>
                                <p>We review Transaction activity at various times for suspicious or illegal activity. If and where appropriate, we have the right to refuse, return or delay and investigate the execution of the Transaction where:
                                </p></div>
                            <ul className="ml-[3rem]">
                                <div className="flex gap-8">
                                    <p>6.11.1</p>
                                    <p>you have not provided the information or any additional information we have requested to execute the Transaction;</p>
                                </div>
                                <div className="flex gap-8">
                                    <p>6.11.2</p>
                                    <p>you fail to authorize the Transaction in accordance with any request we make;</p>
                                </div>
                                <div className="flex gap-8">
                                    <p>6.11.3</p>
                                    <p>The Transaction differs from your usual pattern of transacting;</p>
                                </div>
                                <div className="flex gap-8">
                                    <p>6.11.4</p>
                                    <p>if Applicable Law prohibits the Transaction from being executed and/or we are required to carry out further financial crime checks;</p>
                                </div>
                                <div className="flex gap-8">
                                    <p>6.11.5</p>
                                    <p>if we believe or suspect that a Transaction may be fraudulent or relate to any other criminal activity;</p>
                                </div>
                                <div className="flex gap-8">
                                    <p>6.11.6</p>
                                    <p>you have breached the Agreement in any manner, or  carrying out the Transaction would result in such a breach;</p>
                                </div>
                                <div className="flex gap-8">
                                    <p>6.11.7</p>
                                    <p>If we have any reason to  delay your transaction we will notify you of such delay and will only provide you with the reason for the delay and the next course of action if we are permitted to do so under an  Applicable Law.</p>
                                </div>

                            </ul>
                        </div>
                    </div>
                    {/* 7 -  Fees and Deductions */}
                    <div className="flex gap-8">
                        <b>7.</b>
                        <div>
                            <b>Fees and Deductions</b>
                            <ul>
                                <div className="flex gap-8">
                                    <b>7.1</b>
                                    <p>You acknowledge and agree that we will collect a Fee for each Deposit or Transaction conducted by you. The Fee shall be automatically deducted from your Cleva Account.</p>
                                </div>
                                <div className="flex gap-8">
                                    <b>7.2</b>
                                    <p>You agree that the Fee will be added to the total payment processed for an individual transaction;</p>
                                </div>
                                <div className="flex gap-8">
                                    <b>7.3</b>
                                    <p>The fee shall be calculated based on a predetermined figure shown to you before the Transaction is processed;</p>
                                </div>
                                <div className="flex gap-8">
                                    <b>7.4</b>
                                    <p>We reserve the right to alter the Fees as it deems fit and we will provide notice to you in the event of such alteration. The continued use of our App after any such change in the service fee calculation shall be seen as your consent to such change.</p>
                                </div>
                            </ul>
                        </div>
                    </div>
                    {/* 8 - Reversals and Refunds */}
                    <div className="flex gap-8">
                        <b>8.</b>
                        <div>
                            <b>Reversals and Refunds</b>
                            <ul>
                                <div className="flex gap-8">
                                    <b>8.1</b>
                                    <p>We do not  provide refunds or reversal of funds once a Transaction is processed except where and to the extent required by Applicable Laws.</p>
                                </div>
                                <div className="flex gap-8">
                                    <b>8.2</b>
                                    <p>In the event of Clause 6.8, we will:</p>
                                </div>
                                <ul className="ml-[3rem]">
                                    <div className="flex gap-8">
                                        <p>8.2.1</p>
                                        <p>Notify you through your registered email address that the payment was reversed;</p>
                                    </div>
                                    <div className="flex gap-8">
                                        <p>8.2.2</p>
                                        <p>Once you receive the email notification we expect you to either elect that we credit you back with the USD in your Cleva Account;</p>
                                    </div>
                                    <div className="flex gap-8">
                                        <p>8.2.3</p>
                                        <p>You understand that the exchange rate of converting the reversed naira back to USD will be the exchange rate applicable at the time of the reversal.</p>
                                    </div>
                                    <div className="flex gap-8">
                                        <p>8.2.4</p>
                                        <p>You understand that we shall not provide you with a refund on the Fees.</p>
                                    </div>
                                </ul>
                                <div className="flex gap-8">
                                    <b>8.3</b>
                                    <p>You understand that when you are the beneficiary of a payment to your Cleva Account and you receive a payment which is subsequently refunded or invalidated for any reason, you are fully liable for the complete payment amount initially received, along with any associated fees;</p>
                                </div>
                                <div className="flex gap-8">
                                    <b>8.4</b>
                                    <p>We will promptly and automatically refund or reverse the transaction from your Cleva Account, using the same currency as the original transaction;</p>
                                </div>
                                <div className="flex gap-8">
                                    <b>8.5</b>
                                    <p>If, however, your Cleva Account balance is insufficient to cover the refund or reversal amount, we will notify you of the refund or reversal, and you will be required to fund your Cleva Account with the required funds.</p>
                                </div>
                            </ul>
                        </div>
                    </div>
                    {/* 9 - Account Security */}
                    <div className="flex gap-8">
                        <b>9.</b>
                        <div>
                            <b>Account Security</b>
                            <ul>
                                <div className="flex gap-8">
                                    <b>9.1</b>
                                    <p>You understand that you are fully liable for ensuring that your email address, password and any other confidential information about your Cleva Account is secure and protected;</p>
                                </div>
                                <div className="flex gap-8">
                                    <b>9.2</b>
                                    <p>In the event that your Cleva Account is compromised due to a failure to ensure adequate security of your confidential information, we shall not be liable for any losses, damages or costs incurred by you.</p>
                                </div>
                            </ul>
                        </div>
                    </div>
                    {/* 10 - Acceptable Use Policy */}
                    <div className="flex gap-8">
                        <b>10.</b>
                        <div>
                            <b>Acceptable Use Policy</b>
                            <ul>
                                <div className="flex gap-8">
                                    <b>10.1</b>
                                    <p>Your onboarding and continued use of our Platform is subject to your compliance with our acceptable use requirements. You agree not to use our Platform or services for any of the following;</p>
                                </div>
                                <ul className="ml-[3rem]">
                                    <div className="flex gap-8">
                                        <p>10.1.1</p>
                                        <p>the benefit of a country, organization, entity, or person sanctioned, embargoed, or blocked by any government, including those on applicable national and international sanctions lists identified by the United States Office of Foreign Asset Control <b>(OFAC)</b>, Financial Actions Task Force <b>(FATF)</b>, etc.</p>
                                    </div>
                                    <div className="flex gap-8">
                                        <p>10.1.2</p>
                                        <p>Facilitation of illegal transactions </p>
                                    </div>
                                    <div className="flex gap-8">
                                        <p>10.1.3</p>
                                        <p>Breach of the provision of any Applicable Law or regulations;</p>
                                    </div>
                                    <div className="flex gap-8">
                                        <p>10.1.4</p>
                                        <p>Circumvent, disable or otherwise interfere with the normal operation of our services or affect the use of our  services by other customers.</p>
                                    </div>
                                    <div className="flex gap-8">
                                        <p>10.1.5</p>
                                        <p>Violate the rights of a third party;</p>
                                    </div>
                                    <div className="flex gap-8">
                                        <p>10.1.6</p>
                                        <p>Modify, adopt, decipher, decompile, disassemble or reverse engineer any software composed in or in any way making up a part of our Platform.</p>
                                    </div>
                                </ul>
                            </ul>
                        </div>
                    </div>
                    {/* 11 - Disclaimers and Limitation of Liability */}
                    <div className="flex gap-8">
                        <b>11.</b>
                        <div>
                            <b>Disclaimers and Limitation of Liability</b>
                            <ul>
                                <div className="flex gap-8">
                                    <b>11.1</b>
                                    <p>You understand and agree that we provide our services to you on an "as is" and "as available" basis. We do not guarantee that our services will meet your expectations or will be secured. </p>
                                </div>
                                <div className="flex gap-8">
                                    <b>11.2</b>
                                    <p>We specifically disclaim that our Platform are free of viruses or errors, or that the content is accurate and will be uninterrupted, or that defects will be corrected or that the service will be available at all times or access to our Platform or any part of the services, our terms and other information provided by us or any part of our services will be continuous, uninterrupted, timely, or error-free.</p>
                                </div>
                                <div className="flex gap-8">
                                    <b>11.3</b>
                                    <p>We expressly disclaim all warranties of any kind, whether express or implied including without limitation implied warranty of merchantability, fitness for a particular purpose, title, non-infringement, security or accuracy.</p>
                                </div>
                                <div className="flex gap-8">
                                    <b>11.4</b>
                                    <p>We shall not be liable for any direct or indirect losses or damages arising out of or in connection with the use of our services except that we may be liable for losses due only to our  willful misconduct or gross negligence.</p>
                                </div>
                                <div className="flex gap-8">
                                    <b>11.5</b>
                                    <p>No advice or information obtained from us will create any warranty not expressly stated in this Agreement. We do not warrant the accuracy or completeness, or reliability of any information you access through the use of our services</p>
                                </div>
                            </ul>
                        </div>
                    </div>
                    {/* 12 - Indemnity  */}
                    <div className="flex gap-8">
                        <b>12.</b>
                        <div>
                            <b>Indemnity </b>
                            <ul>
                                <div className="flex gap-8">
                                    <b>12.1</b>
                                    <p>You will indemnify us fully for any loss or damage we may sustain when you fail to perform your obligations under this Agreement, an Applicable law or violate our acceptable use policy.</p>
                                </div>
                                <div className="flex gap-8">
                                    <b>12.2</b>
                                    <p>Whenever your indemnification responsibility is triggered, it will also include interests that may be added to the amount payable.</p>
                                </div>
                            </ul>
                        </div>
                    </div>
                    {/* 13 - Confidentiality  */}
                    <div className="flex gap-8">
                        <b>13.</b>
                        <div>
                            <b>Confidentiality </b>
                            <ul>
                                <div className="flex gap-8">
                                    <b>13.1</b>
                                    <p>Any information we receive from you or which you provide us with, and which by its nature ought to be confidential or which is communicated to be confidential is to be treated with the utmost confidentiality. This implies that Cleva and you will not communicate them to third parties with</p>
                                </div>
                                <div className="flex gap-8">
                                    <b>13.2</b>
                                    <p>You understand that this clause does not restrict us from making disclosures to:</p>
                                </div>
                                <ul className="ml-[3rem]">
                                    <div className="flex gap-8">
                                        <p>13.2.1</p>
                                        <p>Our affiliates or partners for the provision of the services;</p>
                                    </div>
                                    <div className="flex gap-8">
                                        <p>13.2.2</p>
                                        <p>Law enforcement and regulatory authorities that require this information in the performance of their duties.</p>
                                    </div>
                                </ul>
                            </ul>
                        </div>
                    </div>
                    {/* 14 - Third-Party Contracts  */}
                    <div className="flex gap-8">
                        <b>14.</b>
                        <div>
                            <b>Third-Party Contracts </b>
                            <p>Your use of our services may be subject to additional terms that may apply between us and our partners for the purpose of providing our services to you, and by using our services you agree to be subject to such additional terms.</p>

                        </div>
                    </div>
                    {/* 15 - Governing Law  */}
                    <div className="flex gap-8">
                        <b>15.</b>
                        <div>
                            <b>Governing Law </b>
                            <p>This Agreement and all disputes that may arise under it are subject to the laws of the state of Delaware, United States without regard for conflict of law rules. All disputes which may arise from this Agreement shall first be resolved by negotiation. However, where negotiation subsists for 14 days without resulting in the termination of the Agreement, the dispute shall be resolved by mediation.</p>
                        </div>
                    </div>
                    {/* 16 - Updates    */}
                    <div className="flex gap-8">
                        <b>16.</b>
                        <div>
                            <b>Updates   </b>
                            <ul>
                                <div className="flex gap-8">
                                    <b>16.1</b>
                                    <p>To improve our user’s experience, and to broaden the reach of our services we will make updates to our Platform from time to time;</p>
                                </div>
                                <div className="flex gap-8">
                                    <b>16.2</b>
                                    <p>This will include introducing new features to our Platform and introducing additional services;</p>
                                </div>
                                <div className="flex gap-8">
                                    <b>16.3</b>
                                    <p>By consenting to this Agreement you agree to be migrated to our improved Platform as they are introduced;</p>
                                </div>
                                <div className="flex gap-8">
                                    <b>16.4</b>
                                    <p>We will provide you with notice before migration and you have the right to discontinue the use of our services where these new features and additional services do not meet your needs.</p>
                                </div>

                            </ul>
                        </div>
                    </div>
                    {/* 17 - Electronic Communication and Signature    */}
                    <div className="flex gap-8">
                        <b>17.</b>
                        <div>
                            <b>Electronic Communication and Signature   </b>
                            <ul>
                                <div className="flex gap-8">
                                    <b>17.1</b>
                                    <p>You consent to receive all notices, disclosures, and other communications required to be provided under this Agreement, electronically, through email and other commercially acceptable, electronic means of communication, and such electronic means of communication satisfies any legal or contractual requirements for written communications.</p>
                                </div>
                                <div className="flex gap-8">
                                    <b>17.2</b>
                                    <p>This agreement may be executed through digital signatures, and you may not at any time in the future repudiate the legality of such signature.</p>
                                </div>


                            </ul>
                        </div>
                    </div>
                    {/* 18 - Contact us    */}
                    <div className="flex gap-8">
                        <b>18.</b>
                        <div>
                            <b>Contact us   </b>
                            <p>If you have any questions or inquiries concerning our service or Platform, please contact us at <a className="text-sky-600" href="mailto:legal@getcleva.com">legal@getcleva.com</a>.</p>

                        </div>
                    </div>
                </section>
            </LegalParent>
        </div>
    )
}

export default TermsOfService