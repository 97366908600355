import useGoBack from './handleGoBack';
import backIcon from "../../../asset/svg/backIcon.svg";

interface IGoBack{
  text: string
}

const GoBackComponent = (props:IGoBack) => {
  const goBack = useGoBack()

  // use component name to determine where it should go back to

  return (
    <div className=" flex items-center mb-6">
      <button className='bg-white' onClick={goBack}>
        <img src={backIcon} alt="" srcSet="" />
      </button>
      <p className="font-medium text-base ml-2">{props.text}</p>
    </div>
  )
}

export default GoBackComponent