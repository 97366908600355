import { freeAccountFeatures, currencyConverterFeatures } from "./data"
import ProductInfo from "./ProductInfo"
import { Link, useNavigate } from "react-router-dom";
import {CardImg, FreeAcc,  Woman, Nigeria, America} from "../../asset/landing_photos"
import { useEffect } from "react";

interface IProducts {
    innerRef?: React.RefObject<HTMLDivElement>;
}

  

const Products = (props: IProducts) => {
    const { innerRef } = props;
    const navigate = useNavigate()

    const headingStyle = "text-[#060606]  font-bold";
    const contentStyle = "text-[#060606] text-base xl:text-[1.125rem] font-normal leading-[1.75rem] pt-[1rem] py-[1.5rem] xl:pt-[1.12rem] xl:pb-[2.5rem]";
    const card = "lg:w-[26.135rem] rounded-[1.25rem] shadow-2xl shadow-[#999ba8] opacity-100 border-[0.5px] border-solid border-[#E8E8E8] bg-[#FCFCFC] p-[1.5rem] grid gap-y-[1.5rem]"

    const scrollToTop = () => {
        navigate('/auth/register')

      }

      useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);
  

    return (
        <section ref={innerRef} className="">
            <div className="w-[90%] xl:w-[70%] mx-auto 2xl:w-[1280px] py-[2.88rem]">
                {/* open an account */}
                <div className="flex flex-col md:flex-row justify-between items-center mb-[4.79rem] lg:gap-y-[0] gap-y-[2.5rem]">
                    <div className="">
                        <p className={`${headingStyle} text-[1.375rem] xl:text-[1.75rem] lg:w-[28rem]`}>Open a Cleva USD account</p>
                        <p className={`${contentStyle} lg:w-[24rem]`}>Receive USD payments via ACH or Wires from your clients, investors, employers, or any platform such as Upwork, Deel, Amazon, e.t.c</p>
                        <Link to="/usd-account"  className=" border border-[#0772D5] bg-[#F8F8F8] text-[#0772D5] cursor-pointer font-medium  px-[.75rem] py-[.5rem] rounded-lg">Learn more</Link>
                        <div className={`${card} hidden`}>
                            {freeAccountFeatures.map((str, index) => (
                                <ProductInfo key={index} text={str} />
                            ))}
                        </div>
                    </div>
                    <div className=" min-[1440px]:flex-shrink-0">
                        <img src={FreeAcc} alt={FreeAcc} />
                    </div>
                </div>
                {/* convert currency */}
                <div className="flex justify-between flex-col md:flex-row items-center xl:gap-x-[8rem] gap-x-[4rem]">
                    <div className="xl:flex-shrink-0 order-2 md:order-1 relative pt-[3rem]">
                        <img className="absolute z-10 right-[3rem] top-[1.5rem] animate-bounce" src={America} alt={America} />
                        <img className="relative left-[0.5rem]" src={Woman} alt={Woman} />
                        <img className="absolute bottom-[-2.5rem] left-[1rem] animate-bounce" src={Nigeria} alt={Nigeria} />
                    </div>
                    <div className="order-1 md:order-2">
                        <p className={`${headingStyle} lg:w-[28.5rem] text-[1.375rem] xl:text-[2.25rem]`}>Instantly convert currencies</p>
                        <p className={`${contentStyle} lg:w-[25rem]`}>Convert your USD to local currency whenever you want and payout to your local bank account</p>
                        <button onClick={scrollToTop} onTouchStart={scrollToTop} className="border  border-[#0772D5] bg-[#F8F8F8] text-[#0772D5] cursor-pointer font-medium  px-[.75rem] py-[.5rem] rounded-lg">Get started</button>

                        <div className={`${card} hidden`}>
                            {currencyConverterFeatures.map((str, index) => (
                                <ProductInfo key={index} text={str} />
                            ))}
                        </div>
                    </div>
                </div>
                {/* USD Card - flex*/}
                <div className=" flex justify-between flex-col md:flex-row items-center mt-[4.79rem] lg:gap-y-[0] gap-y-[2.5rem]">
                    <div className="">
                        <p className={`${headingStyle} lg:w-[26.63875rem] xl:w-[33rem] text-[1.375rem] xl:text-[1.75rem]`}>Create a Cleva USD Card</p>
                        <p className={`${contentStyle} lg:w-[28rem]`}>Unlock global transactions with a trustworthy and reliable virtual USD card that’s got your back</p>
                        <Link to="/usd-card" className="border border-[#0772D5] bg-[#F8F8F8] text-[#0772D5] cursor-pointer font-medium  px-[.75rem] py-[.5rem] rounded-lg">Learn More</Link>
                    </div>
                    <div className=" min-[1440px]:flex-shrink-0">
                        <img src={CardImg} alt="Unlock Global Transactions with Our Virtual USD Card" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Products