import React, { useEffect, useState } from 'react'
import logo from '../../asset/images/logo.svg'
import authImg from '../../asset/images/login-img.svg'
import emailIcon from '../../asset/images/email.svg'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Link, useParams } from 'react-router-dom'
import AuthServices from '../../features/services/AuthServices'
import AlertModal from '../PopUps/AlertModal'
import { AiFillCheckCircle } from "react-icons/ai";
import { FcCancel } from "react-icons/fc";
import { AWS_CREDS } from '../../features/services/AmazonService'

const RenderHTMLTemplate = ({ htmlTemplate }: any) => {
  return (
    <div>
      {/* Render the HTML template */}
      {React.createElement('div', { dangerouslySetInnerHTML: { __html: htmlTemplate } })}
    </div>
  )
}

export function formatTime(time: any) {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
  return `${formattedMinutes}:${formattedSeconds}`;
}

const PendingEmailVerification = () => {
  const [email, setEmail] = useState<string>('')
  const [hasVerifiedEmail, setHasVerifiedEmail] = useState<boolean>(false)
  const { pending } = useParams()
  const [countdown, setCountdown] = useState(300); // Initial countdown time in seconds
  const [formattedTime, setFormattedTime] = useState(formatTime(countdown));
  const [resendEmail, setResendEmail] = useState<boolean>(false)
  const [displayModal, setDisplayModal] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string>('Error. Try again later')
  const [isError, setIsError] = useState<boolean>(false)

 

  const cannotFindEmail = `<span className="mt-6 text-red-500 text-sm">Error. Cannot find Email.</span>`


  const handleResendEmail = async () => {
    try {
      if (!email) { // check if  there is no email and if email is already verified
        setIsError(true)
        setErrorMsg(cannotFindEmail)
      }
      setResendEmail(false)
      setCountdown(300)
      //setDisplayModal(true)
      // setTimeout(() => {
      //   setDisplayModal(false);
      // }, 5000); // 5000 milliseconds = 5 seconds
      await AuthServices.resendOTP(email!)
      toast.success('Email sent successfully')
    } catch (error: any) {
      if(AWS_CREDS.STAGE === 'qa'){
        console.log(error.message)
      }
      setErrorMsg(error.message)
    }
  }

  const CloseModal = () => setDisplayModal(false)

  useEffect(() => {
    if (pending === 'pending') {
      setHasVerifiedEmail(false)
    } else {
      setHasVerifiedEmail(true)
    }
    const storedEmail = localStorage.getItem('registeredEmail')
    if (storedEmail) {
      setEmail(storedEmail)
    }
  }, [hasVerifiedEmail, pending])

  useEffect(() => {
    const interval = setInterval(() => {
      if (countdown <= 0) {
        clearInterval(interval);
        setResendEmail(true)
        return;
      }
      setCountdown(countdown - 1);
      setFormattedTime(formatTime(countdown - 1));
    }, 1000);

    return () => {
      clearInterval(interval); // Cleanup the interval on unmount
    };
  }, [countdown]);

  // Initially set displayModal to true onLoad
  // useEffect(() => {
  //   setDisplayModal(true);
  //   const timer = setTimeout(() => {
  //     setDisplayModal(false);
  //   }, 5000); // 5000 milliseconds = 5 seconds
  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, []);

  return (
    <>
      {displayModal && <AlertModal onClose={CloseModal} isOpen={displayModal} shadow="shadow-2xl" bg='bg-green-600' leftS='lg:left-[550px]'>
        <div className="flex items-center text-white gap-1">
          <AiFillCheckCircle />
          <h1 className=''>Email sent Successfully</h1>
        </div>
      </AlertModal>}
      {isError && <AlertModal onClose={() => setIsError(false)} isOpen={isError} shadow="shadow-2xl" bg='bg-red-600' leftS='lg:left-[550px]'>
        <div className="flex items-center text-white gap-1">
          <FcCancel />
          <h1 className=''>
            <RenderHTMLTemplate htmlTemplate={errorMsg} />
          </h1>
        </div>
      </AlertModal>}
      <div className="md:flex min-h-full">
        <div className="hidden lg:flex md:flex-col justify-center flex-1 px-4 sm:px-6 lg:flex-none lg:px-12 xl:px-20 bg-black text-white ">
          <div className="mx-auto w-full max-w-[24rem] 2xl:max-w-[28rem] lg:w-[30rem] xl:w-[28rem]">
            <div className="flex justify-center pt-20 min-h-[100vh] md:items-center md:pt-0">
              <div className="w-full">
                <div>
                  <Link to="/">
                    <img className="w-auto mt-2" src={logo} alt="logo" />
                  </Link>
                  <h1 className="mt-12 text-4xl font-medium text-white">Verify Your Email Address</h1>
                </div>

                <img src={authImg} className="mt-20" alt="login" />
              </div>
            </div>
          </div>
        </div>
        <div className="relative flex-1 bg-[#FBFBFB]">
          <div className="flex justify-center  min-h-[100vh] md:items-center md:pt-0">
            <div className="login-card w-full md:w-[32rem] xl:w-[36rem] py-8 px-8 md:py-16 md:px-20">
              <div className="text-center py-6 ">
                <div className="flex justify-center">
                  <img src={emailIcon} alt="email" />
                </div>

                <h2 className="text-2xl text-medium text-black-soft mt-6">Verify your email address</h2>
                {!hasVerifiedEmail && (
                  <p className="text-[#5F5D5D] md:w-[25rem] mx-auto text-sm mt-4">
                    We have sent an email to &nbsp;
                    <span className="text-[#935B06]">{email},&nbsp;</span>
                    Please click on the button in that email to verify your email address.
                  </p>
                )}
                {hasVerifiedEmail && (
                  <p className="text-[#5F5D5D] md:w-[25rem] mx-auto text-base mt-4">
                    We noticed you haven't verified your email address. <br />
                    We have sent an email to,&nbsp;
                    <span className="text-[#935B06]">{email},&nbsp;</span>
                    Please click on the button in that email to verify your email address.
                  </p>
                )}
              </div>
              {resendEmail ?
                <p className="text-center text-[#404040d9] text-base">Didn’t receive an email? <a className='underline cursor-pointer hover:text-black' onClick={handleResendEmail}>Resend</a></p>
                :
                <p className="text-center text-[#404040d9] text-base">Didn’t receive an email? <span className="text-black">Resend in {formattedTime}</span> secs</p>
              }
              {/* <button type="button" onClick={handleResendEmail} className={'login-active'}>
                {false ? 'Loading ...' : 'Resend Email'}
              </button> */}
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  )
}

export default PendingEmailVerification
