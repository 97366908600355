import React, { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import  EditTag from "./EditTag";
import TagComplete from "./TagComplete";

interface IEditTagModal {
  open: boolean;
  closeModal: () => void;
  tag: string | null;
}

const TagModal = ({
  open, 
  closeModal,
  tag
}: IEditTagModal) => {
    const mobileStyle =
    "max-[768px]:top-[50%] max-[768px]:left-1/2  max-[768px]:w-full max-[768px]:h-[450px] max-[768px]:transform max-[768px]:-translate-x-1/2 max-[768px]:-translate-y-1/2";
  const cancelButtonRef = useRef(null);
  const [success, setSuccess] = useState(false);

  const openSucess = () => {
    setSuccess(true);
  }

  useEffect(() => {
    setSuccess(false);
  }
  , [open]);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => closeModal()}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className={`fixed inset-0 z-10 overflow-y-auto ${mobileStyle}`}>
            <div className="flex md:min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white pb-8 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-lg  w-[30rem]">
                {!success && <EditTag onSuccess={openSucess} tag={tag!} closeModal={closeModal} />}
                {success && <TagComplete closeModal={closeModal} tag={tag!} />}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default TagModal;