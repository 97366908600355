/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { setStep, setCompletedSteps } from "../../features/Kyc/kycSlice";
import {  Timeline2, UploadGuide } from "./components/Features";
import IndividualDetails from "./pages/individualDetails";
import { ToastContainer } from "react-toastify";
import { setTitle } from "../../features/Nav/NavSlice";
import { useNavigate } from "react-router-dom";
import { IDDocument } from "./components/IDDocument"; 

function IndividualKYC() {
  const { step, completedSteps, KYCState, AdditionalDetails, index } = useAppSelector((state) => state.kycInfo);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
 

  const changeStep = (next: number) => {
    if (next > step) {
      dispatch(setCompletedSteps(step));
      dispatch(setStep(next));
    } else {
      setCompletedSteps(completedSteps.filter((completedStep) => completedStep !== step));
      dispatch(setStep(next));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    dispatch(setTitle("KYC Verification - Individual"));
  if (KYCState==="VERIFIED"){
    navigate("/");
  } else if (KYCState==="BIOMETRICS"){
      if (AdditionalDetails?.VerificationPending){
        navigate("/");
      } else {
        dispatch(setStep(2));
      }
  } else if (KYCState==="DOCUMENT_RETRY"){
      dispatch(setStep(2));
    }
    else if (KYCState==="RETRY"){
      dispatch(setStep(1));
      dispatch(setCompletedSteps([]));
    }
  else if (KYCState==="VERIFIED"){
    navigate("/");
  } else if (KYCState==="DENIED"){
    navigate("/");
  } else if (KYCState==="SUSPENDED"){
    navigate("/");
  }
  }, [])


  return (
    <div className="sm:flex py-20">
      <div className=" xl:w-[35%] sm:w-[50%] pb-2">
      <Timeline2 step={step} completed={completedSteps} />
      {step === 2 && <UploadGuide />}

      </div>

      {step === 1 &&
        <IndividualDetails
          currentStep={step}
          nextStep={changeStep}
        />}

      {step === 2 && <IDDocument index={index} key={1}

      />}

      <ToastContainer /> 
    </div>
  );
}

export default IndividualKYC;
