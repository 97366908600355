import GetStartedBullets from "./List";
import { secureFunds } from "./data"

const Secure = () => {
    return (
        <section className="bg-[#F5F8FF]">
            <div className="pt-[4.25rem] w-[90%] xl:w-[80%] mx-auto 2xl:w-[1280px]">
                <h1 className="lg:w-[33rem] xl:w-[55rem] mx-auto text-center text-black text-[1.75rem] lg:text-[2rem] xl:text-[3rem] font-bold"><span className="text-[#129B62]">Your funds are secure! </span><br/>Cleva Banking is safe banking</h1>
                <div>
                    <GetStartedBullets textColor="[#000]" customStyle="bg-white rounded-[1.25rem] border border-[#E8E8E8] shadow-md px-5 py-12" data={secureFunds} />
                </div>
            </div>
        </section>
    )
}

export default Secure;