
import Button from '../Button'
import { useNavigate } from 'react-router-dom'
import { global, exchange, secure } from "../../../asset/landing_photos";

const Benefits = () => {
    const navigate = useNavigate()

    return (
        <div>
            <div className="w-[90%] xl:w-[75%] mx-auto 2xl:w-[1280px] py-8 md:py-16">
                <section className='pb-8 md:pb-16 flex items-center justify-center'>
                    <h2 className='md:w-[26rem] lg:w-[41rem] text-center text-2xl text-black lg:text-[2.5rem] lg:leading-[3.125rem] md:text-3xl font-bold'>Unlock the Power of a Cleva <span className='text-[#FF7A05] pl-1'>USD Account</span></h2>
                </section>

                <section className='grid gap-3 lg:gap-[2rem] xl:gap-[3.75rem]'>
                    <article className='gradient-bg pb-2 p-[1.5rem] rounded-xl md:rounded-3xl border border-[#053073]'>
                        <div className="flex flex-col md:flex-row items-center justify-center gap-6 md:pb-8 lg:pb-0 min-[1140px]:gap-[19rem]">
                            <div className='text-white'>
                                <h3 className='md:w-[20rem] font-bold text-2xl lg:text-[1.75rem] lg:leading-10'>Global Payments, Local Convenience</h3>
                                <p className='md:w-[24rem] text-sm md:text-lg pt-2 pb-[2.44rem] leading-6'>Receive USD payments from anywhere in the world, without any hassle</p>
                                <Button
                                    text="Get Started"
                                    fn={() => navigate("/auth/register")}
                                    style={`px-[2rem] rounded-[0.75rem]`}
                                />
                            </div>
                            <div>
                                <img src={global} alt="Global Transactions" />
                            </div>
                        </div>
                    </article>
                    <div className='grid md:grid-cols-2 lg:gap-[2rem] xl:gap-[3.75rem] gap-3'>
                        <article className='gradient-bg-2 pb-2 p-[1.5rem] lg:p-[2.5rem] rounded-xl md:rounded-3xl border border-[#053073]'>
                            <div className='lg:h-[4rem]'>
                                <img className='w-[3rem] lg:w-[5rem] m:w-auto' src={exchange} alt="Competitive Exchange Rates" />
                            </div>
                            <div className="flex flex-col gap-6 pt-8">
                                <div className='text-white'>
                                    <h3 className='font-bold text-2xl lg:text-[1.75rem] lg:leading-10 py-3'>Competitive Exchange Rates</h3>
                                    <p className='text-[#eee] text-sm md:text-lg pt-2 pb-[2.44rem] leading-6'>Enjoy favorable exchange rates for your currency conversions, ensuring you get the most value.</p>
                                </div>

                            </div>
                        </article>
                        <article className='gradient-bg-2 pb-2 p-[1.5rem] lg:p-[2.5rem] rounded-xl md:rounded-3xl border border-[#053073]'>
                            <div className='lg:h-[4rem]'>
                                <img className='w-[3rem] lg:w-[5rem] m:w-auto' src={secure} alt="Secure and Transparent" />
                            </div>
                            <div className="flex flex-col gap-6 pt-8">
                                <div className='text-white'>
                                    <h3 className='font-bold text-2xl lg:text-[1.75rem] lg:leading-10 py-3'>Secure and Transparent</h3>
                                    <p className='text-[#eee] text-sm md:text-lg pt-2 pb-[2.44rem] xl:w-[24rem] leading-6'>Security and transparency are top priorities for us. Trust that your funds are always safe with us.</p>
                                </div>

                            </div>
                        </article>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Benefits
