import React, { useState, useEffect } from 'react';
import {GetAnnouncementsRequest} from "../../api";
import { AWS_CREDS } from '../../features/services/AmazonService';

const Announcement = ({ Service }) => {
    const [matchingAnnouncement, setMatchingAnnouncement] = useState(null);

    const fetchAnnouncement = async () => {
        try {
            // Replace this with your actual API call to fetch announcements
            await GetAnnouncementsRequest().then((response) => {
                if(AWS_CREDS.STAGE === 'qa'){
                    console.log(response.data?.AnnouncementList)
                  }
                // Find the announcement that matches the provided Service
                const announcement = response.data?.AnnouncementList.find(
                    (item) => item.Service === Service
                );

                //console.log(`Announcement: `, data)
                if(AWS_CREDS.STAGE === 'qa'){
    
                    console.log(`Announcement: `, announcement)
                  }
                //console.log(JSON.parse(response.AnnouncementList))
                // Update state with the matching announcement
                setMatchingAnnouncement(announcement);
            });

        } catch (error) {
            if(AWS_CREDS.STAGE === 'qa'){
                console.error('Error fetching announcement:', error);
              }
        }
    };

    useEffect(() => {
        // Call fetchAnnouncement function
        fetchAnnouncement();
    }, [Service]);

    let title = null;
    let message = null;

    // Check if matchingAnnouncement exists
    if (matchingAnnouncement) {
        title = matchingAnnouncement.Title;
        message = matchingAnnouncement.Message;
    }

    return (
        <div>
            {title && <h2 className="text-xs">{title}</h2>}
            {message && (
                <span className="text-xs block text-[#000] p-[0.5rem] my-[0.5rem] rounded-[0.25rem] bg-[#FFE0A4] font-medium">
          {message}
        </span>
            )}
        </div>
    );
};

export default Announcement;
