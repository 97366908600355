import ProfileModal from "../components/profileModal";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { LuDot } from "react-icons/lu";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import Spinner from "../../../components/PopUps/Spinner";
import { fetchController, fetchOwners } from "../../../features/Kyc/KycApi";
import { getKyc } from "../../../api";
import { getBusinessNameInitials, useIsBusinessUser } from "../../../utils";
import TagModal from "../../Tags/Modals/index";
import { useNavigate } from "react-router-dom";

interface IProfile {
  user: any;
}
export default function Profile(props: IProfile) {
  const isBusinessUser = useIsBusinessUser();
  let [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const [individualUser, setIndividualUser] = useState<boolean | null>(null);
  const [activeTab, setActiveTab] = useState("1");
  const [viewBeneficial, setViewBeneficial] = useState("");
  const { KYCState, Controllers, BeneficialOwners } = useAppSelector(
    (state) => state.kycInfo
  );
  const identifier = localStorage.getItem("KYCIdentifier");
  const [businessInfo, setBusinessInfo] = useState<any>(null);
  let [openTag, setOpenTag] = useState(false);
  const userTag = useAppSelector((state) => state.tags.userTag);

  const navigate = useNavigate();

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const handleActiveTab = (target: any) => {
    setActiveTab(target.id);
  };

  const handleBeneficial = (id: any, e: any) => {
    let text = e.target.innerText;
    text = text.toLowerCase();
    if (text === "view") {
      setViewBeneficial(id);
    }

    if (text === "hide") {
      setViewBeneficial("");
    }
  };

  useEffect(() => {
    if (props) {
      if (props.user?.BusinessIdentifier) {
        setIndividualUser(false);
      } else {
        setIndividualUser(true);
      }
    }
  }, [props]);

  useEffect(() => {
    if (identifier !== "placeholder") {
      // dispatch(fetchKYC(identifier))
      dispatch(fetchController());
      dispatch(fetchOwners());
    }
  }, [dispatch]);

  useEffect(() => {
    const fetchKyc = async () => {
      const resp = await getKyc(identifier);
      setBusinessInfo(resp?.data);
    };
    if (identifier !== "placeholder") {
      fetchKyc();
    }
  }, []);

  if (individualUser === null) {
    return <Spinner />;
  }

  if (KYCState === "BUSINESS_VERIFIED" && !businessInfo) {
    return <Spinner />;
  }

  const openTagModal = () => {
    setOpenTag(true);
  };

  const closeTagModal = () => {
    setOpenTag(false);
  };

  return (
    <>
      <section>
        <div className="pt-[1em] flex items-center gap-6">
          <span className="rounded-full h-[60px] w-[60px] bg-[#F2F2F2] text-[20px] border-[3px] border-[#cccccc] text-center flex items-center justify-center">
            {(isBusinessUser && (props?.user?.BusinessName)) ? (
              <b style={{ lineHeight: "70px", width: "100%" }}>
                {getBusinessNameInitials(props?.user?.BusinessName)}
              </b>
            ) : 
            (isBusinessUser && (props?.user?.DisplayName)) ? (
              <b style={{ lineHeight: "70px", width: "100%" }}>
                {getBusinessNameInitials(props?.user?.DisplayName)}
              </b>
            ) :
            (
              <b style={{ lineHeight: "70px", width: "100%" }}>
                {`${props.user?.FullName?.FirstName[0]}${props.user?.FullName?.LastName[0]}`}
              </b>
            )}
          </span>
        </div>
      </section>

      <br />
      {KYCState === "VERIFIED" ? (
        <section className="bg-white border border-[#aaa9a9] mt-4 md:px-12 px-4 py-8 text-[14px] rounded-xl mb-6">
          <div>
            <header className="text-[#4B4B4B] font-[500] text-base">
              Cleva Tag
            </header>

            <div className="md:flex items-start justify-between mt-3">
              <div className="md:w-[80%]">
                <span className="text-[#747A80] text-[0.875rem] italic font-[400]">
                  Receive money from other Cleva users using your unique tag.
                </span>
              </div>
              {!userTag ? (
                <button
                  onClick={() => {
                    navigate("/create-tag");
                  }}
                  className="border border-[#0E7BE0] float-right mt-3 md:mt-0 py-3 px-8 text-[#0E7BE0]  rounded-[8px] "
                >
                  Add
                </button>
              ) : (
                <button
                  onClick={openTagModal}
                  className="border border-[#0E7BE0] float-right mt-3 md:mt-0 py-3 px-8 text-[#0E7BE0]  rounded-[8px] "
                >
                  Edit
                </button>
              )}
            </div>

            <TagModal open={openTag} closeModal={closeTagModal} tag={userTag} />
          </div>
          <div>
            <p
              className={
                (userTag ? "text-[#2B2B2B]" : "text-[#D0D0D0]") +
                " text-[1.25rem] pt-5 font-[700]"
              }
            >
              @{userTag || "yourTag"}
            </p>
          </div>
        </section>
      ) : (
        //unverified user
        <section className="bg-white border border-[#aaa9a9] mt-4 md:px-12 px-4 py-8 text-[14px] rounded-xl mb-6">
          <div>
            <header className="text-[#4B4B4B] font-[500] text-base">
              Cleva Tag
            </header>

            <div className="md:flex items-start justify-between mt-3">
              <div className="md:w-[80%]">
                <span className="text-red-500 text-[0.875rem] italic font-[400]">
                  Your identity needs to be verified before you can create your
                  tag
                </span>
              </div>
              <button
                className="border border-[#0E7BE0] float-right mt-3 md:mt-0 py-3 px-8 text-[#0E7BE0] rounded-[8px] opacity-50 cursor-not-allowed"
                disabled
              >
                Add
              </button>
            </div>
          </div>
          <div>
            <p className="text-[#D0D0D0] text-[1.25rem] pt-5 font-[700]">
              @{"yourTag"}
            </p>
          </div>
        </section>
      )}

      {/* <section className="bg-white border border-[#aaa9a9] mt-4 md:px-12 px-4 py-8 text-[14px] rounded-xl ">
        <div>
          <header className="text-[#4B4B4B] font-[500] text-base">
            Display Name
          </header>

          <div className="md:flex items-start justify-between mt-3">
            <div className="md:w-[80%]">
              <span className="text-[#747A80] text-[0.875rem] italic font-[400]">
                You can edit this name to your business name if you use Cleva
                for your business. This name will be shown on your home page, in
                emails, and transfer notifications your recipients receive.
              </span>
            </div>
            <button
              onClick={openModal}
              className="border border-[#0E7BE0] float-right mt-3 md:mt-0 py-3 px-8 text-[#0E7BE0]  rounded-[8px] "
            >
              Edit
            </button>
          </div>
        </div>

        <ProfileModal
          userData={props.user}
          myopen={open}
          profileName={props.user?.DisplayName}
          closeModal={closeModal}
        />
        {/* <div>
          <p className="text-[#2B2B2B] text-[1.25rem] font-[700]">
            {props.user?.DisplayName}
          </p>
        </div> */}

      {individualUser ? (
        <section className="bg-white border border-[#aaa9a9] px-4 py-8 text-[14px] rounded-xl ">
          <div>
            <header className="text-[#4B4B4B] font-[500] text-base">
              Personal Information
            </header>
            <p className="text-[#747A80] text-[0.875rem] italic font-[400] mt-3">
              This information has been verified. Any updates will require
              re-verification of your identity.
              <br />
              Email us at{" "}
              <span className="text-[#052B7B] font-[400px]">
                contact@getcleva.com
              </span>{" "}
              to update your identity information.
            </p>
            <div className=" grid md:grid-cols-2 w-[70%] gap-8 mt-8">
              <div className="">
                <p className="text-black text-opacity-90 text-sm font-normal leading-[18px]">
                  First Name
                </p>
                <b>{props.user?.FullName?.FirstName}</b>
              </div>

              <div className="">
                <p className="text-black text-opacity-90 text-sm font-normal leading-[18px]">
                  Last Name
                </p>
                <b className="break-words">{props.user?.FullName?.LastName}</b>
              </div>

              <div className="">
                <p className="text-black text-opacity-90 text-sm font-normal leading-[18px]">
                  Email Address
                </p>
                <b className="break-words">
                  {props.user?.StandardAttributes?.Email}
                </b>
              </div>

              <div className="">
                <p className="text-black text-opacity-90 text-sm font-normal leading-[18px]">
                  Phone Number
                </p>
                <b className="break-words">
                  {props.user?.StandardAttributes?.PhoneNumber}
                </b>
              </div>

              <div className="">
                <p className="text-black text-opacity-90 text-sm font-normal leading-[18px]">
                  Government-issued ID
                </p>
                <b>{props.user?.StandardAttributes?.Address?.Country}</b>
              </div>
            </div>
          </div>
        </section>
      ) : !individualUser && KYCState === "BUSINESS_VERIFIED" ? (
        <section className="bg-white border border-[#aaa9a9] px-4 py-8 text-[14px] rounded-xl ">
          <div>
            <header className="text-[#4B4B4B] font-[500] text-base">
              Business Information
            </header>
            <p className="text-[#747A80] text-[0.875rem] italic font-[400] mt-3">
              This information has been verified. Any updates will require
              re-verification of your identity. Email us at
              <span className="mx-1 text-[#052B7B] font-[400px]">
                contact@getcleva.com
              </span>
              to update your identity information.
            </p>
            <div className=" grid md:grid-cols-2 gap-8 mt-8">
              <div className="">
                <p className="text-black text-opacity-90 text-sm font-normal leading-[18px]">
                  Registered Business Name
                </p>
                <b>{businessInfo?.BusinessKyc?.BusinessName}</b>
              </div>

              <div className="">
                <p className="text-black text-opacity-90 text-sm font-normal leading-[18px]">
                  Industry
                </p>
                <b className="break-words">
                  {businessInfo?.BusinessKyc?.Industry}
                </b>
              </div>

              <div className="">
                <p className="text-black text-opacity-90 text-sm font-normal leading-[18px]">
                  Country of Business Registration
                </p>
                <b className="break-words">
                  {businessInfo?.BusinessKyc?.CountryOfIncorporation}
                </b>
              </div>

              <div className="">
                <p className="text-black text-opacity-90 text-sm font-normal leading-[18px]">
                 Business Email Address
                </p>
                <b className="break-words">
                {businessInfo?.BusinessKyc?.ContactDetails?.Email}
                </b>
              </div>

              <div className="">
                <p className="text-black text-opacity-90 text-sm font-normal leading-[18px]">
                  Phone Number
                </p>
                <b className="break-words">
                  {businessInfo?.BusinessKyc?.ContactDetails.PhoneNumber}
                </b>
              </div>

              <div className="">
                <p className="text-black text-opacity-90 text-sm font-normal leading-[18px]">
                  Website
                </p>
                <b className="break-words">
                  {businessInfo?.BusinessKyc?.Website}
                </b>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="bg-white border border-[#aaa9a9] px-4 py-8 text-[14px] rounded-xl ">
          <div>
            <header className="text-[#4B4B4B] font-[500] text-base">
              Business Information
            </header>
            <p className="text-[#747A80] text-[0.875rem] italic font-[400] mt-3">
              This information has been verified. Any updates will require
              re-verification of your identity. Email us at{" "}
              <span className="mx-1 text-[#052B7B] font-[400px]">
                {" "}
                contact@getcleva.com
              </span>
              to update your identity information. to update your identity
              information.
            </p>
            <div className=" grid md:grid-cols-2 gap-8 mt-8">
              <div className="">
                <p className="text-black text-opacity-90 text-sm font-normal leading-[18px]">
                  Business Name
                </p>
                <b>{props?.user?.BusinessName}</b>
              </div>

              <div className="">
                <p className="text-black text-opacity-90 text-sm font-normal leading-[18px]">
                  Business Email Address
                </p>
                <b className="break-words">
                  {props?.user?.StandardAttributes.Email}
                </b>
              </div>

              <div className="">
                <p className="text-black text-opacity-90 text-sm font-normal leading-[18px]">
                  Phone Number
                </p>
                <b className="break-words">
                  {props?.user?.StandardAttributes.PhoneNumber}
                </b>
              </div>

              <div className="">
                <p className="text-black text-opacity-90 text-sm font-normal leading-[18px]">
                  Government-issued ID
                </p>
                <b className="break-words">
                  {props?.user?.StandardAttributes.Address.Country}
                </b>
              </div>
            </div>
          </div>
        </section>
      )}

      {!individualUser && KYCState === "BUSINESS_VERIFIED" &&
        <section className="mt-5 bg-white border border-[#aaa9a9] px-4 py-8 text-[14px] rounded-xl ">
          <div>
            <ul className="flex border-b px-4  gap-4 sm:gap-8 border-[#eee] xl:w-[80%]">
              <button
                id="1"
                onClick={(e) => handleActiveTab(e.target)}
                className={`text-[#6B7280] text-sm font-medium  pb-4 ${activeTab === "1" &&
                  "border-b-2 border-black font-bold text-[#111111]"
                  }`}
              >
                Controller’s Information
              </button>
              <button
                id="2"
                onClick={(e) => handleActiveTab(e.target)}
                className={`text-[#6B7280] text-sm font-medium  pb-4 ${activeTab === "2" &&
                  "border-b-2 border-black font-bold text-[#111111]"
                  }`}
              >
                Beneficial Owners
              </button>
            </ul>

            <p className="text-[#747A80] text-[0.875rem] italic font-[400] mt-3">
              This information has been verified. Any updates will require
              re-verification of your identity. Email us at
              <span className="mx-1 text-[#052B7B] font-[400px]">
                contact@getcleva.com
              </span>
              to update your identity information.
            </p>

            {activeTab === "1" ? (
              // controller
              Controllers?.map((person: any) => (
                <div
                  key={person?.Controller?.KYCIdentifier}
                  className=" grid md:grid-cols-2 xl:w-[70%] gap-8 mt-8"
                >
                  <div className="">
                    <p className="text-black text-opacity-90 text-sm font-normal leading-[18px]">
                      Name
                    </p>
                    <b>
                      {person?.Controller?.FirstName}{" "}
                      {person?.Controller?.LastName}{" "}
                      {person?.Controller?.MiddleName}
                    </b>
                  </div>

                  <div className="">
                    <p className="text-black text-opacity-90 text-sm font-normal leading-[18px]">
                      Email Address
                    </p>
                    <b className="break-words">{person?.Controller?.Email}</b>
                  </div>

                  <div className="">
                    <p className="text-black text-opacity-90 text-sm font-normal leading-[18px]">
                      Job Title
                    </p>
                    <b className="break-words">{person?.Controller?.JobTitle}</b>
                  </div>

                  <div className="">
                    <p className="text-black text-opacity-90 text-sm font-normal leading-[18px]">
                      Country of Citizenship
                    </p>
                    <b className="break-words">
                      {person?.Controller?.CountryOfCitizenship}
                    </b>
                  </div>
                  <div className="">
                    <p className="text-black text-opacity-90 text-sm font-normal leading-[18px]">
                      Country of Residence
                    </p>
                    <b className="break-words">
                      {person?.Controller?.Address.Country}
                    </b>
                  </div>
                </div>
              ))
            ) : (
              // Owners
              <section className="mt-8">
                {BeneficialOwners.map((owner: any) => (
                  <div
                    key={owner?.KYCIdentifier}
                    className="border border-[#ECECEC] mb-3"
                  >
                    <div
                      className={`flex justify-between items-center  p-4 ${viewBeneficial === owner?.KYCIdentifier && "bg-[#f1f0f0]"
                        }`}
                    >
                      <div>
                        <h1 className="text-lg font-semibold">
                          {owner?.BeneficialOwner?.FirstName}{" "}
                          {owner?.BeneficialOwner?.LastName}{" "}
                          {owner?.BeneficialOwner?.MiddleName}
                        </h1>
                        <p className="flex flex-col sm:flex-row sm:gap-2 sm:items-center">
                          <span className="text-[#404040] text-sm ">
                            {owner?.BeneficialOwner?.IsOwnerAndController
                              ? "Controller & Owner"
                              : "Owner"}{" "}
                          </span>
                          <span>
                            <LuDot />
                          </span>
                          <span className="text-[#A16207] text-sm ">
                            {owner?.BeneficialOwner?.PercentageOwnership}%
                            Ownership
                          </span>
                        </p>
                      </div>
                      <button
                        id={owner?.KYCIdentifier}
                        onClick={(e) => handleBeneficial(owner?.KYCIdentifier, e)}
                        className="bg-[#F9F9F9] border border-[#F1F1F1] text-[#0B69AD] flex items-center h-[40px] rounded-lg py-[8px] px-[12px]"
                      >
                        {viewBeneficial === owner?.KYCIdentifier
                          ? "Hide"
                          : "View"}{" "}
                        {viewBeneficial === owner?.KYCIdentifier ? (
                          <RiArrowDropUpLine />
                        ) : (
                          <RiArrowDropDownLine />
                        )}
                      </button>
                    </div>
                    {viewBeneficial === owner?.KYCIdentifier && (
                      <article className="px-4 pb-4 grid md:grid-cols-2 gap-8 mt-4">
                        <div className="">
                          <p className="text-black text-opacity-90 text-sm font-normal leading-[18px]">
                            Country of Citizenship
                          </p>
                          <b className="break-words">
                            {owner?.BeneficialOwner?.CountryOfCitizenship}
                          </b>
                        </div>

                        <div className="">
                          <p className="text-black text-opacity-90 text-sm font-normal leading-[18px]">
                            Email Address
                          </p>
                          <b className="break-words">
                            {" "}
                            {owner?.BeneficialOwner?.Email}
                          </b>
                        </div>

                        <div className="">
                          <p className="text-black text-opacity-90 text-sm font-normal leading-[18px]">
                            Country of Residence
                          </p>
                          <b className="break-words">
                            {owner?.BeneficialOwner?.Address?.Country}
                          </b>
                        </div>
                      </article>
                    )}
                  </div>
                ))}
              </section>
            )}
          </div>
        </section>
      }
    </>
  );
}
