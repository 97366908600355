import { useRef, useState, useEffect } from "react";
import { fetchReferral } from "../../features/Referrals/ReferralAPI";
import Img from "../../asset/svg/referral.svg";
import Spinner from "../../components/PopUps/Spinner";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setTitle } from "../../features/Nav/NavSlice";
import { howReferralsWork } from "./data";
import CopyToClipboardAlert from "../../components/PopUps/CopyToClipboard";
import ShareReferrals from "./ShareReferrals";
import { AiOutlineRight } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';
import { AWS_CREDS } from "../../features/services/AmazonService";
interface IReferralData {
  id: number;
  img?: string;
  title: string;
  content: string;
}

export default function Referrals() {
  const maxReferralBonus = AWS_CREDS.MAX_REFERRAL_BONUS || 300;
  const bonusPerReferredUser = AWS_CREDS.BONUS_PER_REFERRED_USER || 3;
  const bonusGivenAtDeposit = AWS_CREDS.BONUS_GIVEN_AT_TOTAL_DEPOSIT_AMOUNT || 300;

  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.userData);
  const { loading, data } = useAppSelector((state: any) => state.referral)
  const copyCode = useRef<HTMLParagraphElement>(null);
  const copyLink = useRef<HTMLParagraphElement>(null);
  const [successModal, setSuccessModal] = useState(false);
  const [failureModal, setFailureModal] = useState(false);
  const referralCode = user?.ReferralCode;
  const navigate = useNavigate()


  useEffect(() => {
    dispatch(setTitle("Referrals"));
    dispatch(fetchReferral())
  }, [dispatch]);

  const handleCopy = async (text: React.RefObject<HTMLElement>) => {
    if (text.current) {
      try {
        await navigator.clipboard.writeText(text.current.innerText);
        setSuccessModal(true);
      } catch (error) {
        setFailureModal(false);
        if(AWS_CREDS.STAGE === 'qa'){
          console.error("Copy failed:", error);
        }
      }
    }
  };

  const navigateToStats = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    navigate('/referrals/statistics')
  }

  const handleCopyLink = () => handleCopy(copyLink);
  const handleCopyCode = () => handleCopy(copyCode);
  
  const domainUrl = window.location.origin;
  const referralLink = `${domainUrl}/refer/${referralCode}`;

  // const referralLink = `http://localhost:3000/refer/${referralCode}`


  return (
    <>
      {loading ? <Spinner /> :
        <div onClick={() => setSuccessModal(false)}>
          <main className="pt-10 max-[12800px]:mb-8">
            <div>
              <img
                className="rounded-xl xl:w-[1440px]"
                src={Img}
                alt="Referral Page"
              />
            </div>
            <div className="mt-8 md:mt-16 grid md:grid-cols-2 gap-8 md:gap-16 xl:gap-[9.31rem]">
              <div className="xl:w-[25rem]">
                <h1 className="text-2xl font-semibold text-[#0C0C0C] xl:w-[22.71681rem]">
                  Tell your friends about Cleva and earn up to ${maxReferralBonus}!
                </h1>
                <p className="text-base text-black pt-4">
                  <b>You get ${bonusPerReferredUser}</b> for each referral when they create a Cleva
                  account and receive up to ${bonusGivenAtDeposit} in deposits. Your cash bonus will be deposited into your balance and available for payout anytime.
                </p>
                <section className="mt-[3.49rem]">
                  <div className="">
                    <p className="font-medium text-[#202020]  text-base pb-2">Your Statistics</p>
                    <div className="rounded-lg shadow bg-white border border-[#E9E9E9] text-center flex items-center flex-col py-8 ">
                      <p className="text-[#1D1D1D] text-4xl font-bold">${data?.TotalAmountEarned || 0}</p>
                      <p className="text-[#FD810E] py-2 text-lg font-medium">Total cash bonus earned</p>
                      <button onClick={navigateToStats} className="flex gap-1 items-center border border-[#4F4F4F] bg-[#F8F8F8] rounded-lg font-normal text-[#4F4F4F] px-4 py-2 text-base">See all <AiOutlineRight /> </button>
                    </div>
                  </div>


                </section>
              </div>
              <div className="shadow bg-[#F9F9F9] rounded-xl px-4 relative">
                <article className="bg-white rounded-lg border-[0.5px] border-solid border-[#DADADA] p-5 my-5 md:my-9">
                  <p className="text-[#505050] text-sm">Your referral code</p>
                  <div className="flex  justify-between items-center">
                    <p
                      ref={copyCode}
                      className="text-[#E65808] uppercase text-lg font-semibold referral-code  w-28 xl:w-[18rem] break-words sm:overflow-visible"
                    >
                      {referralCode}
                    </p>
                    <button
                      onClick={handleCopyCode}
                      className="rounded-[0.19463rem] border-[1px] border-solid border-[#505050] hover:bg-[#505050] hover:text-white py-2 w-[5.625rem] text-[#505050] text-sm font-medium justify-between items-center"
                    >
                      Copy code
                    </button>
                  </div>
                </article>
                <article className="bg-white rounded-lg border-[0.5px] border-solid border-[#DADADA] p-[1rem] xl:p-5">
                  <p className="text-[#505050] text-sm">Your referral link</p>
                  <div className="flex justify-between items-center pt-[.5rem]">
                    <p
                      className="text-[.8rem] xl:text-lg text-[#0773BC] font-medium w-28 xl:w-[18rem] break-words sm:overflow-visible"
                      ref={copyLink}
                    >
                      {referralLink}
                    </p>
                    <button
                      onClick={handleCopyLink}
                      className="rounded-[0.19463rem] border-[1px] border-solid border-[#505050] hover:bg-[#505050] hover:text-white py-2 px-3 w-[5.625rem] text-[#505050] text-sm font-medium justify-between items-center"
                    >
                      Copy link
                    </button>
                  </div>
                </article>
                {/* share to social media */}
                <div className="md:absolute md:bottom-2 xl:bottom-6 md:w-[90%] max-[768px]:pb-5">
                  <ShareReferrals code={referralCode} />
                </div>
              </div>
            </div>

            <div className="grid">

              {/* max-[768px]:order-2 */}
              <section className="mt-16 border-t border-solid ">
                <h1 className="py-8 text-center text-lg font-bold text-[#202020]">
                  Here's how it works:
                </h1>
                <div className="grid md:grid-cols-3 gap-4">
                  {howReferralsWork.map((item: IReferralData) => (
                    <div
                      className="text-center px-2 xl:px-8 md:border-r last:border-none border-solid border-[#CBCBCB]"
                      key={item.id}
                    >
                      <div className="flex items-center justify-center md:hidden">
                        <img src={item.img} alt={item.title} />
                      </div>
                      <h1 className="text-[#090909] font-semibold text-lg ">
                        {item.title}
                      </h1>
                      <p className="text-[#2F2F2F] text-sm p-2">
                        {item.content.split(" ").map((word, index) => {
                          if (word.startsWith("<") && word.endsWith(">")) {
                            const formattedWord = word.slice(1, -1); // Remove <>
                            return (
                              <span key={index} className="text-green-600 font-bold">
                                {formattedWord}{" "}
                              </span>
                            );
                          }
                          return <span key={index}>{word} </span>;
                        })}
                      </p>
                    </div>
                  ))}
                </div>
              </section>

              {/* max-[768px]:order-1 */}

            </div>
          </main>

          <CopyToClipboardAlert
            isOpen={successModal}
            onClose={() => setSuccessModal(false)}
            bg="bg-black"
          >
            <div className="flex flex-col items-center justify-center gap-2">
              <p className="text-sm font-light text-white">Copied to clipboard</p>
            </div>
          </CopyToClipboardAlert>

          <CopyToClipboardAlert
            isOpen={failureModal}
            onClose={() => setSuccessModal(false)}
            bg="bg-[#E65808]"
          >
            <div className="flex flex-col items-center justify-center gap-4">
              <p className="text-sm font-light text-white">Error. Try again.</p>
            </div>
          </CopyToClipboardAlert>
        </div>
      }
    </>
  );
}
