import { useNavigate } from "react-router-dom"
import { happylady } from "../../../asset/landing_photos"
import Button from "../Button"

const Filler = () => {
    const navigate = useNavigate()
    return (
        <div className="bg-[#F8FCFF]">
            <div className="w-[90%] xl:w-[80%] mx-auto min-[1440px]:w-[70%] 2xl:w-[1280px] py-8 md:py-16">
                <section className="flex flex-col md:flex-row-reverse items-center justify-center gap-8 lg:gap-[9rem]">
                    <div>
                        <h3 className="text-xl lg:text-3xl xl:w-[32rem] font-semibold">Personal and Business Solutions</h3>
                        <p className="pt-2 pb-4 xl:pb-[2.44rem] xl:w-[28rem] leading-6 text-sm md:text-lg">Whether you're a sole proprietor or business, your Cleva  USD account caters to your specific financial needs.</p>
                    <Button
                        text="Get started"
                        fn={() => navigate("/auth/register")}
                        style={`px-[2rem] py-[0.5rem] rounded-[0.5rem]`}
                    />
                    </div>
                    <img src={happylady} alt="" />
                </section>
            </div>
        </div>
    )
}

export default Filler