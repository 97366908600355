import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ModalProps } from "./TopupModal";
import {
    XMarkIcon,
  } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

const ConfirmNameModal = ({ formData, closeModal }:ModalProps) => {
  const navigate = useNavigate()
    const [open, setOpen] = useState(true);
    const [isLoading, setisLoading] = useState(false)
    const cancelButtonRef = useRef(null);

    
    const handleSubmit = () => {
  
      localStorage.setItem("cardNames", JSON.stringify(formData))
      navigate('/cards/fund')
    }
  return (
    <>
       <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-[87%] md:min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-xl bg-white pb-8 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-[25rem]  w-[24rem]">
                  <header
                    className={`w-full text-left bg-[#EFEFEF] py-4 px-8  flex justify-between`}
                  >
                    <Dialog.Title
                      as="h3"
                      className="text-base font-bold leading-6 text-gray-900"
                    >
                      Review Details 
                    </Dialog.Title>
                    <button
                      type="button"
                      className="rounded-md text-black hover:text-gray-500 focus:outline-none focus:ring-2 font-bold"
                      onClick={closeModal}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                    </button>
                  </header>

                  <div className="">
                    <div className="mt-3">
                      <div className="px-6 md:px-8">
                        <div className="flex justify-center items-center py-6">
                          <div>
                          </div>
                          <p className="text-black text-center text-sm font-medium">Please note that, this will be your permanent cardholder name and it cannot be changed later.</p>
                        </div>

                        <div className="mb-4">
                          <div className="flex justify-between my-4">
                            <p className="text-sm text-[#747A80]">
                             First Name
                            </p>
                            <p className="text-base font-semibold">
                              {formData.firstName}
                            </p>
                          </div>
                          <div className="flex justify-between my-4">
                            <p className="text-sm text-[#747A80]">Last Name</p>
                            <p className="text-base font-semibold">
                            {formData.lastName}
                            </p>
                          </div>
                         
                         
                        </div>

                        <footer>
                          <div className=" pt-4">
                            <button
                              type="submit"
                              className="text-sm 
                            font-bold py-3 md:px-10 px-6
                            w-[100%]
                            rounded-md mt-4 
                            bg-[#FFBD59]"
                              onClick={handleSubmit}
                              // ref={}
                            >
                              {isLoading ? "Loading ..." : "Confirm"}
                            </button>
                          </div>
                        </footer>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default ConfirmNameModal