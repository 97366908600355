import React from "react";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../asset/images/logo.svg";
import authImg from "../../asset/images/login-img.svg";
import { Link } from "react-router-dom";
import Logo from "../../asset/logo.svg";
import successIcon from "../../images/success.svg";
const ConfirmPin = () => {
  return (
    <>
      <div className="md:flex min-h-full">
        <div className="hidden lg:flex md:flex-col justify-center flex-1 px-4 sm:px-6 lg:flex-none lg:px-12 xl:px-20 bg-black text-white ">
          <div className="mx-auto w-full max-w-[24rem] 2xl:max-w-[28rem] lg:w-[30rem] xl:w-[28rem]">
            <div className="flex justify-center pt-20 min-h-[100vh] md:items-center md:pt-0">
              <div className="w-full">
                <div>
                  <Link to="/">
                    <img className="w-auto mt-2" src={logo} alt="logo" />
                  </Link>
                  <h1 className="mt-16 text-4xl font-medium text-white">
                    Set Transaction PIN
                  </h1>
                </div>

                <img src={authImg} className="mt-10" alt="login" />
              </div>
            </div>
          </div>
        </div>
        <div className="relative flex-1 bg-[#FBFBFB]">
          <div className="flex justify-center pt-8 min-h-[100vh] items-center md:pt-0">
            <div>
              <div className="login-card-md w-full md:w-[32rem] xl:w-[36rem] px-8 py-8 md:px-20 md:py-16">
                <div className="">
                  <div className="flex items-center justify-center lg:hidden">
                    <img
                      loading="lazy"
                      className="pb-4 w-[10rem]"
                      src={Logo}
                      alt="Cleva logo"
                    />
                  </div>
                  <div className="flex items-center justify-center">
                    <img
                      loading="lazy"
                      className="pb-4 w-[8rem]"
                      src={successIcon}
                      alt="success"
                    />
                  </div>
                  <div className="mt-8 text-center">
                    <p className="text-2xl font-semibold">
                      Transaction PIN Created!
                    </p>
                    <p className="text-sm text-[#484848] py-8">
                      Your transaction PIN was successfully created. You can now
                      use your PIN to authorize all transactions.
                    </p>
                  </div>
                  <div className="mt-8 w-[80%] mx-auto">
                    <Link
                      to="/"
                      type="submit"
                      className="login-active"
                    >
                      Continue
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmPin;
