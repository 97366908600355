
import Button from '../Button'
import { useNavigate } from 'react-router-dom'
import {  exchange, secure } from "../../../asset/landing_photos";

interface IBenefits{
title:string
colored:string
benefit1:string
benefit1text:string
benefit1img:string
b2:string
b2text:string
b3:string
b3text:string
}

const Benefits = (props:IBenefits) => {
    const {title, colored, benefit1, benefit1text, benefit1img, b2, b2text, b3, b3text} = props

    const navigate = useNavigate()
    return (
        <div>
            <div className="w-[90%] xl:w-[75%] mx-auto 2xl:w-[1280px] py-8 md:py-16">
                <section className='pb-8 md:pb-16 flex items-center justify-center'>
                    <h2 className='md:w-[26rem] text-center text-2xl text-black lg:text-[2.5rem] lg:leading-[3.125rem] md:text-3xl  font-bold'>{title}<br/><span className='text-[#FF7A05] pl-1'>{colored}</span></h2>
                </section>

                <section className='grid gap-3 lg:gap-[2rem] xl:gap-[3.75rem]'>
                    <article className='gradient-bg pb-2 p-[1.5rem] rounded-xl md:rounded-3xl border border-[#053073]'>
                        <div className="flex flex-col md:flex-row items-center justify-center gap-6 md:pb-8 lg:pb-0 min-[1140px]:gap-[19rem]">                    
                        <div className='text-white'>
                            <h3 className=' font-bold text-2xl lg:text-[1.75rem] lg:leading-10'>{benefit1}</h3>
                            <p className='md:w-[24rem] xl:w-[26rem] text-lg pt-2 pb-[2.44rem] leading-7'>{benefit1text}</p>
                            <Button
                                text="Get Started"
                                fn={() => navigate("/auth/register")}
                                style={`rounded-[0.75rem] px-[2rem]`}
                            />
                        </div>
                        <div>
                        <img src={benefit1img} alt={benefit1} />
                            </div>
                        </div>
                    </article>
                    <div className='grid lg:grid-cols-2 lg:gap-[2rem] xl:gap-[3.75rem] gap-3'>
                    <article className='gradient-bg-2 pb-2 p-[1.5rem] lg:p-[2.5rem] rounded-xl md:rounded-3xl border border-[#053073]'>
                    <div className='lg:h-[4rem]'>
                    <img className='w-[3rem] lg:w-[5rem]' src={exchange} alt="Competitive Exchange Rates" />
                    </div>
                        <div className="flex flex-col gap-6 pt-8">                    
                        <div className='text-white'>
                            <h3 className='font-bold text-2xl lg:text-[1.75rem] lg:leading-10 py-3'>{b2}</h3>
                            <p className='text-[#eee] text-lg pt-2 pb-[2.44rem] leading-7'>{b2text}</p>
                        </div>
                       
                        </div>
                    </article>
                    <article className='gradient-bg-2 pb-2 p-[1.5rem] lg:p-[2.5rem] rounded-xl md:rounded-3xl border border-[#053073]'>
                    <div className='lg:h-[4rem]'>
                    <img className='w-[3rem] lg:w-[5rem]' src={secure} alt="Secure and Transparent" />
                    </div>
                        <div className="flex flex-col gap-6 pt-8">                    
                        <div className='text-white'>
                            <h3 className='font-bold  text-2xl lg:text-[1.75rem] lg:leading-10 py-3'>{b3}</h3>
                            <p className='text-[#eee] text-lg pt-2 pb-[2.44rem] xl:w-[24rem] leading-7'>{b3text}</p>
                        </div>
                       
                        </div>
                    </article>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Benefits
