import { useEffect } from "react";
import ProductCard from "./ProductCard";
import {c2c, Product1, Product2 } from "../../asset/landing_photos"
import AOS from 'aos';


const NewProducts = () => {
    useEffect(() => {
        AOS.init();
    }, [])

    return (
        <section className="bg-[#F5F8FF]">
            <div data-aos="fade-in-up" className="py-[3rem] w-[90%]  xl:w-[80%] mx-auto 2xl:w-[1280px] ">
                <header className="flex flex-col items-center justify-center text-center">
                    <h1 className="text-black text-[1.75rem] lg:text-[2rem] font-bold">Exciting New Products <span className="text-[#129B62]">Coming Soon!</span></h1>
                    <p className="text-black text-base lg:text-lg font-normal pt-[1.12rem]">Our upcoming products are designed with your needs in mind</p>
                </header>
                <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-[1rem] pt-[3.62rem]">
                    <ProductCard img={c2c} title="C2C transfer" content="Instantly send USD to other Cleva users through their Cleva tag." headerBg="bg-[#145566]" bottom="bottom-[-16px]" />
                    <ProductCard img={Product2} title="Send USD across the world" content="Send payments to bank accounts across the world at a much lower cost than the typical bank rate." headerBg="bg-[#8fa7c4]" bottom="bottom-0" />
                    {/* <ProductCard img={Product3} title="Create a USD card" content="Instantly create a virtual USD card that you can rely on for online payments anytime and any day." headerBg="bg-[#000]" bottom="bottom-[-24px] xl:bottom-[-9px] lg:bottom-0" /> */}
                    <ProductCard img={Product1} title="Create professional invoices" content="Send customized invoices with your brand name and logo. Track paid and unpaid invoices, and even send out automated reminders." headerBg="bg-[#000]" bottom="bottom-[-.5rem]" />
                </div>

            </div>
        </section>
    )
}

export default NewProducts;