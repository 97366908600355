// IDDocument.js
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
// import {
//   setIndividualkyc,
//   setStep,
//   updateIDAndSelfieDocuments,
// } from "../../../features/Kyc/kycSlice";
import { NewImageUploadInput } from "./NewImageUploadInput";
import { getKyc, updateKyc } from "../../../api";
import "react-dropzone-uploader/dist/styles.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/PopUps/MainLoader";
import SuccessModal from "../../../components/PopUps/SuccessModal";
import { DiCssTricks } from "react-icons/di";
import { AWS_CREDS } from "../../../features/services/AmazonService";
import { SampleImg, FullSample } from '../../../asset'

interface IDoc {
  key: any;
  index?: any;
  currentStep?: number;
  nextStep?: any;
  saveForLater?: any;
}

export function IDDocument(props: IDoc) {
  const { KYCState, AdditionalDetails } = useAppSelector(
    (state) => state.kycInfo
  );
  const [documentUpload, setDocumentUpload] = useState<any>({});
  const [selfieUpload, setSelfieUpload] = useState<any>({});
  const [isDocumentUploadButtonDisabled, setDocumentUploadButtonDisabled] =
    useState(true);
  const [isSelfieUploadButtonDisabled, setSelfieUploadButtonDisabled] =
    useState(true);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [showSelfieUpload, setShowSelfieUpload] = useState(false);

  const handleDocumentInputChange = (field: string, value: any) => {
    const updatedData = { ...documentUpload, [field]: value };
    const filename = updatedData["uploaded image"]?.file?.name;

    setDocumentUpload(updatedData);

    const allDocumentFieldsUploaded = updatedData.DocumentType && filename;
    setDocumentUploadButtonDisabled(!allDocumentFieldsUploaded || fileError);

    // Show the selfieUpload field when an ID type is selected
    setShowSelfieUpload(!!value);
  };

  const handleSelfieInputChange = (field: string, value: any) => {
    const updatedData = { ...selfieUpload, [field]: value };
    setSelfieUpload(updatedData);
    const filename = updatedData["uploaded image"]?.file?.name;

    const allSelfieFieldsUploaded = filename;
    setSelfieUploadButtonDisabled(!allSelfieFieldsUploaded || fileError);
  };

  const hasFileErrors = () => {
    return fileError;
  };

  const getContentTypeConfig = (contentType: any) => {
    return {
      headers: {
        "Content-Type": contentType,
      },
    };
  };

  const DocContentType = documentUpload["uploaded image"]?.file?.type;
  const SelfieContentType = selfieUpload["uploaded image"]?.file?.type;

  const handleUploadButton = async () => {
    if (fileError) {
      return;
    }

    const KYCIdentifier: any = localStorage.getItem("KYCIdentifier");
    setLoading(true);

    try {
      const response = await getKyc(KYCIdentifier);
      const responseData = response?.data?.IndividualKyc;

      const DocConfig = getContentTypeConfig(DocContentType);
      const SelfieConfig = getContentTypeConfig(SelfieContentType);

      const DocUpload = `${responseData.AdditionalDetails.IDUploadUrl.url}`;
      const url = DocUpload;

      const file = documentUpload["uploaded image"].file;

      await axios.put(url, file, DocConfig);

      const selfieUrl = `${responseData.AdditionalDetails.SelfieUploadUrl.url}`;
      const selfieFile = selfieUpload["uploaded image"].file;

      await axios.put(selfieUrl, selfieFile, SelfieConfig);
      if (responseData) {
        const IDDocument = {
          DocumentType: documentUpload.DocumentType,
          DocumentNumber: "",
          IssuingCountry: "",
          IssueDate: "",
          ExpirationDate: "",
          ContentType: DocConfig.headers["Content-Type"],
          Filename: documentUpload["uploaded image"]?.file?.name,
        };
        const SelfieDocument = {
          ContentType: SelfieConfig.headers["Content-Type"],
          Filename: selfieUpload["uploaded image"]?.file?.name,
          Path: "",
          Size: selfieUpload["uploaded image"]?.file?.size,
        };

        if (responseData) {
          responseData.IdentificationDocument = IDDocument;
          responseData.ImageUploadItem = SelfieDocument;
          responseData.DocumentVerificationPending = true;
        }
        await updateKyc(KYCIdentifier, { IndividualKyc: responseData });
        setLoading(false);
        setShowSuccessModal(true);
      } else {
        if (AWS_CREDS.STAGE === 'qa') {
          console.error("No KYC data found.");
        }
      }
    } catch (error) {
      if (AWS_CREDS.STAGE === 'qa') {
        console.error("Error updating KYC:", error);
      }
    }

    setLoading(false);

    if (props.index !== null) {
      // Dispatch your action here if needed
    }
  };

  const handleSaveForLater = () => {
    const dataToSave = {
      document,
      selfieUpload,
    };

    // Convert the data to a JSON string and save it to local storage
    localStorage.setItem("kycData", JSON.stringify(dataToSave));

    setShowSaveModal(true);
  };

  const closeSuccessModal = () => {
    navigate("/kyc-pending");
    setShowSuccessModal(false);
  };

  const closeSaveModal = () => {
    navigate("/");
    setShowSuccessModal(false);
  };

  const documentType = documentUpload.DocumentType;

  const docUploaded =
    documentType === "OTHER"
      ? "Voter's Card"
      : documentType === "PASSPORT"
        ? "International Passport"
        : documentType === "DRIVERS_LICENSE"
          ? "Driver's License"
          : documentType === "NATIONAL_ID"
            ? "National ID Card (NIN)"
            : documentType;

  useEffect(() => {
    setIsButtonDisabled(hasFileErrors());
  }, [documentUpload, selfieUpload]);

  const BVNKYC: boolean =
    KYCState === "MISMATCH" && AdditionalDetails.NoOfRetriesLeft === 0;

  return (
    <>
      {loading ? (
        <Loader text="Uploading documents" />
      ) : (
        <div className="md:w-[55%] sm:w-[50%] mb-2">
          <section className="bg-white px-2 md:px-8 py-4 rounded-lg">
            <p className="text-lg font-semibold mb-4">
              Upload your ID <span className="text-[#D31D1D]">*</span>
            </p>
            <p className="text-sm text-[#172323] pb-1">Here’s a sample document for your reference:</p>
            <img alt="sample ID" src={SampleImg} />

            <div className="">
              <div className="flex mt-5">
                <label htmlFor="id" className="text-sm">
                  Select ID type
                </label>
                <p className="hidden text-[6.5px] text-[#D31D1D] ml-[0.5] mt-1">
                  <DiCssTricks />
                </p>
              </div>

              <select
                name="DocumentType"
                value={documentUpload.DocumentType || ""}
                onChange={(e) =>
                  handleDocumentInputChange("DocumentType", e.target.value)
                }
                className="w-full text-sm rounded-lg outline-none inline-block border mt-1 focus:ring-2 focus:ring-cleva-gold focus:border-transparent"
              >
                <option value="" className="hidden text-gray-600">
                  Select Means of Identification
                </option>
                <option value="PASSPORT">International Passport</option>
                <option value="OTHER">Voter’s Card</option>
                <option value="DRIVERS_LICENSE">Driver’s License</option>
                {!BVNKYC ? (
                  <option value="NATIONAL_ID">National ID Card (NIN)</option>
                ) : (
                  ""
                )}
              </select>
            </div>
            {documentUpload.DocumentType && (
              <p className="mt-7 text-sm">Upload {docUploaded}</p>
            )}
            <NewImageUploadInput
              index={props.index}
              name="ID Documents"
              show={documentUpload.DocumentType ? true : false}
              document={documentUpload}
              fileError={fileError} // Pass the fileError state
              onChange={(field, value, fileError) => {
                handleDocumentInputChange(field, value);
                // Handle the fileError value here or use it as needed in your parent component
                setFileError(fileError);
              }}
            />
          </section>

          <section className="bg-white px-2 md:px-8 py-4 mt-4 rounded-lg">
          <p className="text-lg font-semibold mb-4">
            Hold your ID next to your full face and take a picture <span className="text-[#D31D1D]">*</span>
          </p>
          <p className="text-sm text-[#172323]">Here’s a sample document for your reference:</p>

          <img className="py-2" alt="sample ID" src={FullSample} />

          <NewImageUploadInput
            index={props.index}
            name="Selfie ID"
            show={true}
            document={selfieUpload}
            fileError={fileError}
            onChange={(field, value, fileError) => {
              handleSelfieInputChange(field, value);
              setFileError(fileError);
            }}
          />
          </section>

          <div className="md:mt-16 mt-12">
            <button
              onClick={handleUploadButton}
              className={`${isSelfieUploadButtonDisabled ||
                  isDocumentUploadButtonDisabled ||
                  isButtonDisabled
                  ? "bg-cleva-gold opacity-50 cursor-not-allowed"
                  : "bg-cleva-gold"
                } mb-2 block w-[100%] py-3 rounded-lg text-sm font-bold text-black`}
              disabled={
                isSelfieUploadButtonDisabled ||
                isDocumentUploadButtonDisabled ||
                isButtonDisabled
              }
            >
              {loading ? "Uploading..." : "Upload Document"}
            </button>

            <button
              className="border border-[#ADADADB2] rounded-lg block w-[100%] py-3 text-sm"
              onClick={handleSaveForLater}
            >
              Save for later
            </button>
          </div>
        </div>
      )}

      {showSuccessModal && (
        <SuccessModal
          btnText="Continue"
          text="Documents uploaded successfully!"
          onClose={closeSuccessModal}
        />
      )}

      {showSaveModal && (
        <SuccessModal
          btnText="Continue"
          text="KYC saved for Later"
          onClose={closeSaveModal}
        />
      )}
    </>
  );
}
