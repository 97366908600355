
import { YCombinator } from '../../asset/landing_photos'


const Investor = () => {
    return (
        <section className='bg-[#FCF8E3]  mb-[3.61rem] lg:w-[90%] xl:w-[80%] mx-auto 2xl:w-[1280px]'>
            <div className=''>
    
               <div className='flex items-center justify-center'>
                <p className='text-[#5A5A5A] flex items-center justify-center '><span className='text-lg  '>Backed by</span>  <span className=''><img className='w-[8rem] md:w-[12rem]' src={YCombinator} alt="Y Combinator"/></span></p>
               </div>
            </div>
    
        </section>
    )
}

export default Investor