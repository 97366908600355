import ClevaUSDAccounts from './ClevaUSDAccounts'
import Benefits from './Benefits'
import Filler from "./Filler"
import WebLayout from '../components/WebLayout'
import MoreBenefits from './MoreBenefits'
import GetStartedTwo from './GetStartedTwo'
import { useEffect } from 'react'


const Index = () => {
useEffect(() => {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
}, []);
  return (
   <WebLayout>
   <ClevaUSDAccounts/>
   <Benefits/>
   <Filler/>
   <MoreBenefits/>
   <GetStartedTwo/>
   </WebLayout>
  )
}

export default Index