import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setTitle } from "../../features/Nav/NavSlice";
import Card from "../../asset/cards/card.png"
import { clevaData } from "./data";
import { useNavigate } from "react-router-dom";
import { KycStatus } from "../Kyc/components/KycStatus";
import { getKyc } from "../../api";
import { setAdditionalDetails, setKYCState } from "../../features/Kyc/kycSlice";

export default function NewCard() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch();
  const { KYCState } = useAppSelector((state) => state.kycInfo);
  const KYCIdentifier = localStorage.getItem("KYCIdentifier");
  const [bvnStage, setbvnStage] = useState(true)
  const [multiNames, setMultiNames] = useState(false)
  const { bvnVerified } = useAppSelector((state) => state.clevaCards);
  const userNames = useAppSelector((state) => state.clevaCards.userNames)

  useEffect(() => {
    dispatch(setTitle("Card"));

    if (KYCIdentifier) {
      getKyc(KYCIdentifier).then((res) => {
        if (res?.data?.IndividualKyc) {
          dispatch(setKYCState(res?.data?.IndividualKyc?.KYCState!));
          dispatch(setAdditionalDetails(res?.data?.IndividualKyc?.AdditionalDetails));
        } else
          if (res?.data?.BusinessKyc) {
            dispatch(setKYCState(res?.data?.BusinessKyc?.KYCState!));
            dispatch(setAdditionalDetails(res?.data?.BusinessKyc?.AdditionalDetails));
          } else {
            dispatch(setKYCState(""));
          }
      }).catch((error) => {
        dispatch(setKYCState(""));
      });
    }
  }, [dispatch]);

  useEffect(() => {
    if (userNames.length > 2) {
      setMultiNames(true)
    }
  }, [userNames])

  useEffect(() => {
    if (bvnVerified?.IsBVNRetrievable) {
      setbvnStage(false);
    }
  }, [bvnVerified]);

  return (
    <section className="pt-10">
      <>
        <KycStatus />
      </>
      <div className="md:w-[80%] xl:w-[40rem] mx-auto pb-10">
        <h1 className="text-[#141414] text-xl font-semibold pb-[1.86rem]">Cleva Virtual Card</h1>
        <img className="xl:w-[90%] rounded-t-[1.25rem]" src={Card} alt="Cleva virtual  card" />
        <div className="xl:w-[90%]   bg-white border border-[#EFEFEF] rounded-b-[1.25rem] shadow-sm p-[1.5rem] md:p-10">
          <div className="xl:w-[25rem] mx-auto">


            {clevaData.map((item, index) => (
              <div key={index} className="flex flex-col  md:flex-row items-start md:items-center gap-5 pb-9">

                <img src={item.img} alt={item.header} />
                <div>
                  <h1 className="text-[#000000] text-lg font-semibold">{item.header}</h1>
                  <p className="text-[#5E5E5E] text-[0.875rem] font-normal">{item.content}</p>
                </div>
              </div>
            ))}


            {bvnStage ?
              <button disabled={(KYCState !== "VERIFIED" && KYCState !== "BUSINESS_VERIFIED")} onClick={() => navigate('/cards/verify')} className={`rounded-xl bg-[#FFBD59] py-4 px-[0.75rem] text-xl font-semibold w-full ${(KYCState !== "VERIFIED" && KYCState !== "BUSINESS_VERIFIED") ? "cursor-not-allowed opacity-50" : ""}`}>Create card</button>
              :
             // multiNames && !bvnStage ?
                //<button disabled={(KYCState !== "VERIFIED" && KYCState !== "BUSINESS_VERIFIED")} onClick={() => navigate('/cards/card-name')} className={`rounded-xl bg-[#FFBD59] py-4 px-[0.75rem] text-xl font-semibold w-full ${(KYCState !== "VERIFIED" && KYCState !== "BUSINESS_VERIFIED") ? "cursor-not-allowed opacity-50" : ""}`}>Create card</button>
               // :
                <button disabled={(KYCState !== "VERIFIED" && KYCState !== "BUSINESS_VERIFIED")} onClick={() => navigate('/cards/fund')} className={`rounded-xl bg-[#FFBD59] py-4 px-[0.75rem] text-xl font-semibold w-full ${(KYCState !== "VERIFIED" && KYCState !== "BUSINESS_VERIFIED") ? "cursor-not-allowed opacity-50" : ""}`}>Create card</button>
            }
          </div>
        </div>
      </div>


    </section>
  );
}
