import React from 'react'
import { Navigate, createBrowserRouter,  } from 'react-router-dom'
import { SuccessMsg } from './components/Message/SuccessMsg'
import { FailureMsg } from './components/Message/FailureMsg'
import { RetryMsg } from './components/Message/RetryMail'
import { Error404 } from './components/error/Error404'
import FAQ from './pages/FAQs'
import TermsOfService from './pages/Legal/TermsOfService'
import PrivacyPolicy from './pages/Legal/PrivacyPolicy'
import ReferralTerms from './pages/Legal/ReferralTerms'
import MainLayout from './components/Layout/MainLayout'
import Login from './components/Auth/Login'
import Accounts from './pages/Accounts'
import ViewAccount from './pages/Accounts/ViewAccount'
import Transactions from './pages/Transactions'
import Invoice from './pages/Invoice'
import ClevaCards from './pages/ClevaCards'
import VerifyCardBVN from './pages/ClevaCards/VerifyCardBVN'

import PendingEmailVerification from './components/Auth/AwaitingEmailVerification'
import ForgotPassword from './components/Auth/ForgotPassword'
import ResetPassword from './components/Auth/ResetPassword'
import Register from './components/Auth/Register'
import VerifyEmail from './components/Auth/VerifyEmail'
import UnauthenticatedLayout from './components/Auth/UnauthenticatedLayout'
import EditProfile from './pages/Profile/EditProfile'
// import ChangePassword from './pages/Profile/ChangePassword';
import Transfers from './pages/Transfers/M2'
import CreateTransfers from './pages/Transfers/M2/CreateTransfer'
import ConfirmTransfers from './pages/Transfers/ConfirmTransfer'
import Recipients from './pages/Recipients'
import ConfirmRecipient from './pages/Recipients/modals/ConfirmRecipient'
import AllRecipients from './pages/Recipients/pages/AllRecipients'
import ViewTransfer from './pages/Transfers/M2/ViewTransfer'
import LandingPage from './pages/landing_page'
import USDAccount from './pages/landing_page/USDAccount'
import ProfilePage from './pages/Profile'
import USDCard from './pages/landing_page/USDCard/Entry';
import RecipientHistory from './pages/Recipients/pages/RecipientsHistory';
import ConfirmSelfie from "./pages/Kyc/ConfirmSelfie";
import FailureSelfie from "./pages/Kyc/FailureSelfie";
import Home from './pages/Home/index'

import Referrals from './pages/Referrals/Index'
import Statistics from './pages/Referrals/Statistics'
import ReferralsWeb from './pages/Referrals/ReferralsWeb'

// new kyc flow
import KYC from './pages/Kyc'
import KycInfo from './pages/Kyc/KycInfoPage'
import {KycPending, KybPending} from './pages/Kyc/pages/KycPending'
import KycEntry from './pages/Kyc/pages/KYC/KycEntry'

import ReferralsRedirect from './pages/Referrals/RedirectReferrals'

//Transaction PIN
import CreatePin from './pages/Security/CreatePin'
import ConfirmPin from './pages/Security/ConfirmPin'

//profile flow
import ChangePin from './pages/Profile/pages/Security/Index'
import ProfileLayout from './components/Layout/ProfileLayout'

import SuccessPin from './pages/Profile/pages/Security/SuccessPin'
import UpdatePin from './pages/Profile/pages/Security/UpdatePIN'
import ChangePassword from './pages/Profile/pages/Security/ChangePassword'
import VerifyUserOTP from './pages/Profile/pages/Security/VerifyUserOTP'
import FundCard from './pages/ClevaCards/FundCard'
import CardDeactivation from './pages/ClevaCards/CardDeactivation'

//cards
import TopUp from './pages/ClevaCards/Topup'
import Withdraw from './pages/ClevaCards/Withdraw'
import CardTransactions from './pages/ClevaCards/CardTransactions'
import CardSetting from './pages/ClevaCards/CardSetting'
import LowBalance from './pages/ClevaCards/LowBalance'
import ExistingCard from './pages/ClevaCards/ExistingCard'
//import AccountStatement from "./pages/Accounts/AccountStatement";
import AccountDetails from './pages/Accounts/MoblieAccountDetails'
import PendingCard from './pages/ClevaCards/PendingCard'
import CardName from './pages/ClevaCards/CardName'
import AccountStatement from './pages/Accounts/AccountStatement';
//tag related pages
import Tags from "./pages/Tags";
import TagComplete from "./pages/Tags/TagComplete";
import AddRecipient from "./pages/Tags/AddRecipient";
import ViewC2CTransfer from './pages/Tags/ViewC2CTransfer'

//mobile review transfer
import MobileReviewTransfer from './pages/Transfers/M2/MobileReviewTransfer'
import RegisterEntry from './components/Auth/CreateAccount/RegisterEntry'
import KycBusiness from './pages/Kyc/pages/KYC/KycBusiness'
import BusinessKYC from './pages/Kyc/pages/KYC/BusinessKyc'
import PrivacyPolicyApp from './pages/Legal/PrivacyPolicyApp'
import UploadOwnerDocumentForm from './pages/Kyc/pages/KYC/UploadOwnerDocumentForm'
import ConfirmKybDetails from './pages/Kyc/pages/ConfirmKybDetails'
import DeleteUserAccount from './pages/Profile/pages/DeleteAccount'
import ConfirmDeleteAccount from './pages/Profile/pages/ConfirmDeleteAccount'
import CreateTransfer from './pages/Tags/CreateTagTransfer'
import CompleteTransfer from './pages/Tags/CompleteTransfer'
import TransferOption from './pages/Transfers/M2/TransferOption'
import TagMobileReviewTransfer from './pages/Tags/MobileReviewTransfer'
import UpdatePinEntry from './pages/Profile/pages/Security/UpdatePinEntry'
import AccountDeletionOTP from './pages/Profile/pages/Security/AccountDeletionOTP'

const routes = (user: any) => {
  return createBrowserRouter([
    {
      path: '/',
      element: user ? <MainLayout /> : <LandingPage />,
      children: [
        {
          path: '',
          element: <Home />,
        },
        {
          path: '/accounts',
          element: <Accounts />,
        },
        { 
          path: '/accounts/view',
          element: <ViewAccount />,
        },
        {
          path: '/accounts/account-details',
          element: <AccountDetails />,
        },
        {
          path: '/accounts/statement',
          element: <AccountStatement />,
        },

        {
          path: '/transanctions',
          element: <Transactions />,
        },

        // transfer pages
        {
          path: '/transfers',
          element: <Transfers />,
        },


        {
          path: '/transfers/create',
          element: <CreateTransfers />,
        },
        {
          path: '/transfers/confirm',
          element: <ConfirmTransfers />,
        },
        {
          path: '/transfers/view',
          element: <ViewTransfer />,
        },

        {
          path: '/transfers/review-transfer/tag',
          element: <TagMobileReviewTransfer />,
        },

        {
          path: '/transfers/review-transfer',
          element: <MobileReviewTransfer />,
        },

        {
          path: '/referrals',
          element: <Referrals />,
        },
        {
          path: '/referrals/statistics',
          element: <Statistics />,
        },
        {
          path: '/confirm_selfie',
          element: <ConfirmSelfie />,
        },
        //tags related
        {
          path: '/create-tag',
          element: <Tags />
        },
        {
          path: '/tag-complete',
          element: <TagComplete />
        },
        {
          path: '/tag-recipient',
          element: <AddRecipient />
        },
        {
          path: '/tags/transfers/create',
          element: <CreateTransfer />,
        },
        {
          path: '/transfers/view/c2c',
          element: <ViewC2CTransfer />,
        },
        {
          path: '/transfers/options',
          element: <TransferOption/>,
        },
        {
          path: '/tags/transfers/complete',
          element: <CompleteTransfer />
        },
        //end of tags related
        {
          path: '/create-tag',
          element: <Tags />
        },
        {
          path: '/tag-complete',
          element: <TagComplete />
        },
        {
          path: '/tag-recipient',
          element: <AddRecipient />
        },
        {
          path: '/tags/transfers/create',
          element: <CreateTransfer />,
        },
        {
          path: '/transfers/options',
          element: <TransferOption />,
        },
        {
          path: '/tags/transfers/complete',
          element: <CompleteTransfer />
        },
        //end of tags related
        {
          path: '/failure_selfie',
          element: <FailureSelfie />,
        },
        {
          path: '/password',
          element: <ChangePassword />,
        },

        {
          path: '/confirm_recipient',
          element: <ConfirmRecipient />,
        },
        {
          path: "/recipient_details",
          element: <RecipientHistory />,
        },
        {
          path: '/successMsg',
          element: <SuccessMsg />,
        },
        {
          path: '/failureMsg',
          element: <FailureMsg />,
        },
        {
          path: '/retryMsg',
          element: <RetryMsg />,
        },
        {
          path: '/kyc',
         element: <KycEntry/>
        },
        {
          path: '/kyc/individual',
         element: <KYC />
        },
        {
          path: '/kyb',
         element: <KycBusiness />
        },
        {
          path: '/kyb-info',
         element: <BusinessKYC/>
        },
        {
          path: '/kyb-info/upload/:param',
         element: <UploadOwnerDocumentForm/>
        },
        {
          path: '/kyb-confirm',
         element: <ConfirmKybDetails/>
        },
        {
          path: '/kyc-info',
          element: <KycInfo />,
        },
        {
          path: '/kyc-pending',
          element: <KycPending />,
        },
        {
          path: '/kyb-pending',
          element: <KybPending />,
        },
        {
          path: '/recipients',
          element: <Recipients />,
        },
        {
          path: '/invoices',
          element: <Invoice />,
        },
        {
          path: '/cards',
          element:  <ClevaCards />,
        },
        {
          path: '/cards/verify',
          element: <VerifyCardBVN />,
        },
        {
          path: '/cards/card-name',
          element: <CardName />,
        },
        {
          path: '/cards/fund',
          element: <FundCard />,
        },
        {
          path: '/cards/deactivation',
          element: <CardDeactivation />,
        },
        {
          path: '/cards/topup',
          element: <TopUp />,
        },
        {
          path: '/cards/withdraw',
          element: <Withdraw />,
        },
        {
          path: '/cards/transactions',
          element: <CardTransactions />,
        },
        {
          path: '/cards/settings',
          element: <CardSetting />,
        },
        {
          path: '/cards/low-balance',
          element: <LowBalance />,
        },
        {
          path: '/cards/pending',
          element: <PendingCard />,
        },

        //profile
        {
          path: '/profile',
          element: user ? <ProfileLayout /> : '',
          children: [
            {
              path: '/profile',
              element: <ProfilePage />,
            },
            {
              path: '/profile/view',
              element: <EditProfile />,
            },
            {
              path: '/profile/Card',
              element: <CardSetting />,
            },
            {
              path: '/profile/security/confirm-change',
              element: <UpdatePinEntry/>,
            },
            {
              path: '/profile/security/change-pin',
           element: !user?.PinRetries && !user?.PinResetDisabled ? <Navigate to="/create-pin" /> : <ChangePin />,
            },
            {
              path: '/profile/security/success-pin',
              element: <SuccessPin />,
            },
            {
              path: '/profile/security/verify-otp',
              element: <VerifyUserOTP />,
            },
            {
              path: '/profile/security/update-pin',
              element: !user?.PinResetDisabled ? <UpdatePin /> : <Navigate to="/profile/security/change-pin" />,
            },
            {
              path: '/profile/security/password',
              element: <ChangePassword />,
            },
            {
              path: '/profile/confirm-delete',
              element: <AccountDeletionOTP />,
            },
            {
              path: '/profile/delete-account',
              element: <DeleteUserAccount/>,
            },
            {
              path: '/profile/confirm-delete-account',
              element: <ConfirmDeleteAccount/>,
            },
          ],
        },
      ],
    },
    {
      path: '/landingpage',
      element: <LandingPage />,
    },
    {
      path: '*',
      element: <Error404 />,
    },
    {
      path: 'faq',
      element: <FAQ />,
    },
    {
      path: 'terms-of-service',
      element: <TermsOfService />,
    },
    {
      path: 'privacy-policy',
      element: <PrivacyPolicy />,
    },
    {
      path: 'privacy-policy-app',
      element: <PrivacyPolicyApp />,
    },
    {
      path: 'referral-terms',
      element: <ReferralTerms />,
    },
    {
      path: 'refer-and-earn',
      element: <ReferralsWeb />,
    }, //security pin
    {
      path: '/refer/:referral',
      element: <ReferralsRedirect />,
    },
    {
      path: '/create-pin',
      element: user?.PinResetDisabled ? <Navigate to="/" /> : <CreatePin />,
    },
    {
      path: '/success-pin',
      element: <ConfirmPin />,
    },
    {
      path: '/auth',
      element: !user ? <UnauthenticatedLayout /> : <Navigate to="/create-pin" />,
      children: [
        { path: '', element: <Navigate to="/" /> },
        { path: 'login', element: <Login /> },
        { path: 'register/:referral?', element: <RegisterEntry /> },
        { path: 'register/details', element: <Register /> },
        { path: 'forgot-password', element: <ForgotPassword /> },
        { path: 'reset-password/:contact/:otp', element: <ResetPassword /> },
        { path: 'verify-token/:contact/:otp', element: <VerifyEmail /> },
        { path: 'verify-email', element: <PendingEmailVerification /> },
        { path: 'verify-email/:pending', element: <PendingEmailVerification /> },
      ],
    },
    { path: "usd-account", element: <USDAccount/> },
    { path: "usd-card", element: <USDCard/> },
   
  ])
}

export default routes
