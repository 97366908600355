import React, { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FullName } from "../../../features/Recipients/RecipientSlice";
import successIcon from "../../../images/checkmark.svg";
import { fetchRecipients } from "../../../features/Recipients/RecipientsApi";
import { useAppDispatch } from "../../../app/hooks";

interface IRecipientModal {
  closeModal: () => void;
  fullName: FullName | null;
  tag: string | null;
  open: boolean;
}

const RecipientAdded = ({
  closeModal,
  tag,
  fullName,
  open
}: IRecipientModal) => {

  const dispatch = useAppDispatch();

  const mobileStyle =
  "max-[768px]:top-[50%] max-[768px]:left-1/2  max-[768px]:w-full max-[768px]:h-[450px] max-[768px]:transform max-[768px]:-translate-x-1/2 max-[768px]:-translate-y-1/2";
  const cancelButtonRef = useRef(null);

  return (
    <>
       <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => closeModal()}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className={`fixed inset-0 z-10 overflow-y-auto ${mobileStyle}`}>
            <div className="flex md:min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white pb-8 text-left shadow-xl p-6 md:mx-w-md  w-[28rem]">
               
       <div className="flex items-center justify-center">
            <img
            loading="lazy"
            className="m-4 mt-8 w-[4rem]"
            src={successIcon}
            alt="success"
            />
        </div>
        <div className="mt-1 text-center items-center">
            <p className="text-5md font-semibold text-[#158025]">
            Recipient Added Successfully
            </p>
            <p className="text-md m-auto text-[#484848] py-2 md:w-[60%]">
            You have added <b>{fullName?.FirstName} {fullName?.MiddleName} {fullName?.LastName}</b> (@{tag}) as your recipient.
            </p>
        </div>
        <div className="mt-8 items-center">
            <button
                onClick={()=>{closeModal()
                  dispatch(fetchRecipients())}}
                className="m-auto login-active w-full md:w-auto md:px-8 py-4 text-sm font-medium text-white bg-[#0772D5] rounded-lg">
                Done
            </button>
        </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default RecipientAdded;