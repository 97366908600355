import FAQ from "../../asset/landing_photos/faq.svg";

const Hero = () => {
    return (
        <section className="bg-[#000] relative mt-[7rem] md:pt-[2rem] ">
            <div className="w-[90%] xl:w-[80%] mx-auto 2xl:w-[1280px]">
                <div className="text-white grid place-items-center text-center pb-[4.5rem]">
                    <img className="hidden md:block animate-bounce" src={FAQ} alt={FAQ} />
                    <h1 className="text-[1.375rem] lg:text-[2rem] font-[700] leading-normal">Frequently Asked Questions</h1>
                    <p className="text-[0.875rem] lg:text-[1.25rem] pt-[.5rem] font-normal leading-normal">Get answers to your questions about Cleva</p>
                </div>
            </div>
        </section>
    )
}

export default Hero