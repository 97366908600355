import React, { useEffect, useState } from "react";
import Atm_icon from "../../images/v-card.svg";
import ReferralHome from "../../asset/referral-homepage.png";
import World_cur from "../../asset/kyc/worldCurrency.svg";
import waveIcon from "../../images/wave-hand.svg";
import DashboardCard from "../../components/Layout/DashboardCard";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { KycStatus } from "../Kyc/components/KycStatus";
// import ToDoContents from "./components/toDoContents";
import { setTitle } from "../../features/Nav/NavSlice";
import InfoModal from "../../components/PopUps/InfoModal";
import Win from "../../asset/referral/win.svg";
// import DecemberRefer from "../../asset/referral/december-referral.png";
import { Link } from "react-router-dom";
import USIcon from "../../images/US-icon.svg";
import DashboardAccountCard from "../../components/Layout/DashboardAccountCard";
import RateCard from "../../components/Layout/RateCard";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import { IoCloseOutline } from "react-icons/io5";
import Yellow from "../../asset/referral/yellowimg.png";
import { AWS_CREDS } from "../../features/services/AmazonService";
import { changeBalanceDisplay } from "../../features/User/UserSlice";
import { useIsBusinessUser } from "../../utils";
import { updateTagModalStatus } from "../../features/Tags/TagsSlice";

const Dashboard = (props: any) => {
  const { userHasCard, hasAccounts, amount } = props

  const maxDepositWaiver = AWS_CREDS.MAX_DEPOSIT_WAIVER || 3;
  const maxReferralBonus = AWS_CREDS.MAX_REFERRAL_BONUS || 200;
  const referralBonusEarned = AWS_CREDS.BONUS_PER_REFERRED_USER || 3;

  const [userHasKycBonus, setUserHasKycBonus] = useState(false);
  const [preReferralUser, setPreReferralUser] = useState(false);
  const [decemberReferral, setDecemberReferral] = useState(false);
  const { KYCState } = useAppSelector((state: any) => state.kycInfo);
  const user = useAppSelector((state) => state.user.userData);
  const isBusinessUser = useIsBusinessUser();
  const userTag = useAppSelector((state) => state.tags.userTag);
  const showAmount = useAppSelector((state) => state.user.displayBalance);
  const showCreateTagModal = useAppSelector((state) => state.tags.displayCreateTagModal);

  const toggleAmount = () => {
    dispatch(changeBalanceDisplay(!showAmount));
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setTitle("Home"));
  }, [dispatch]);

  useEffect(() => {
    if (user?.ReferredByReferralCode === "") {
      setPreReferralUser(true);
    } else {
      setUserHasKycBonus(true);
    }
    if(userTag){
      dispatch(updateTagModalStatus(false)) 
    }
  }, [user]); 

  const showModal = user?.DisplayReferralModal === true;

  const navigateToReferrals = () => {
    localStorage.setItem("modalState", JSON.stringify(false));
  };


  return (
    <div className="w-full m-auto pt-10">
      <InfoModal isOpen={decemberReferral} bg="bg-white">
        <div data-test="promo-modal" className="">
          <div
            className="relative top-[-1rem] rounded-t-2xl bg-no-repeat bg-cover bg-center h-56"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "/jan-bonanza.png"
              })`,
            }}
          >
            <div className="flex justify-end pr-2 pt-2 cursor-pointer">
              <IoCloseOutline className="text-4xl text-[#fff]" />
            </div>
          </div>
          <div className="text-center flex flex-col items-center px-2 md:px-8 pb-4">
            {/* //<img src={DecemberRefer} alt="Deposit Bonus" /> */}
            <h1 className="text-[#111] text-xl font-semibold pt-[.5rem]">
              Cleva New Year <span className="text-[#158025]">Bonanza!</span>
            </h1>
            <p className="py-[0.88rem] md:py-[1.12rem] text-[#373737] text-[0.9375rem] text-center font-normal">
              Earn{" "}
              <span className="text-[#278033] text-lg font-bold">
                ${referralBonusEarned}
              </span>{" "}
              for every referral to Cleva. All you have to do is share your
              referral code. Keep referring and earn up to{" "}
              <span className="text-[#278033] text-lg font-bold">
                ${maxReferralBonus}
              </span>
              .
            </p>

            <p className="pb-[1.19rem] italic text-[#373737] text-xs text-center font-normal">
              Limited time offer for January only, so get on it now!
            </p>
            <Link
              onClick={navigateToReferrals}
              className="mb-2 rounded-lg border border-[#0772D5] bg-[#F8F8F8] py-4 px-8 text-[#0772D5] font-normal text-base"
              to="/referrals"
            >
              Learn More
            </Link>
          </div>
        </div>
      </InfoModal>

     { KYCState === "VERIFIED" ?
      <InfoModal isOpen={showCreateTagModal} bg="bg-white">
        <div data-test="promo-modal" className="">
          <div className="relative top-[-1rem] rounded-t-2xl bg-no-repeat bg-cover bg-center h-56"

            style={{
              backgroundImage: `url('/tags.png')`
            }}
          >

            <div className='flex justify-end pr-2 pt-2 cursor-pointer'>
              <IoCloseOutline className='text-4xl text-[#fff]' />
            </div>
          </div>
          <div className="text-center flex flex-col items-center px-2 md:px-8 pb-4">
            <h1 className="text-[#111] text-lg font-semibold pt-[.5rem]">Introducing the Cleva tag for C2C transfers </h1>
            <p className="py-[0.88rem] text-[#373737] text-[0.8rem] text-center font-normal">
            Create your Cleva tag to receive instant USD transfers from other Cleva users. You can also send to other people’s Cleva tag.
           </p>
            <Link to="/create-tag"
                  type="submit"
                  className="m-2 py-3 px-10 font-normal text-[0.8rem] bg-[#FFBD59] rounded-lg">
                  Create Tag
            </Link>
          </div>
        </div>
      </InfoModal>
      : null}

      {showModal ? (
        <div>
          {user?.DisplayReferralModal && userHasKycBonus && (
            <InfoModal isOpen={userHasKycBonus} bg="bg-white">
              <div className="">
                <div className="flex justify-end pr-8 pt-4 cursor-pointer">
                  <IoCloseOutline className="text-4xl text-[#747474]" />
                </div>
                <div className="text-center flex flex-col items-center px-8 py-4">
                  <img src={Win} alt="Deposit Bonus" />
                  <h1 className="text-[#158025] text-xl font-medium pt-[2.25rem]">
                    You'll receive a waiver on your first deposit fee
                  </h1>
                  <p className="py-[1.12rem] text-[#686868] text-sm font-normal">
                    When you receive your first USD deposit, we'll waive up to $
                    {maxDepositWaiver} from your deposit fee. This means that in
                    most cases,{" "}
                    <b>
                      you won't pay a fee. You can also refer friends to Cleva
                      and earn up to ${maxReferralBonus}!{" "}
                    </b>
                  </p>
                  <Link
                    onClick={navigateToReferrals}
                    className="rounded-lg border border-[#0772D5] bg-[#F8F8F8] py-4 px-8 text-[#0772D5] font-normal text-base"
                    to="/referrals"
                  >
                    Learn More
                  </Link>
                </div>
              </div>
            </InfoModal>
          )}
          {user?.DisplayReferralModal && preReferralUser && (
            <InfoModal isOpen={preReferralUser} bg="bg-white">
              <div className="text-center flex flex-col items-center">
                <img
                  className="cursor-pointer"
                  src={Yellow}
                  alt="Deposit Bonus"
                />
                <div className="px-8 mb-10">
                  <h1 className="text-[#158025] text-xl font-medium pt-[2.25rem]">
                    Introducing Our New Referral Program!
                  </h1>
                  <p className="pt-[1.12rem] pb-[2.12rem] text-[#686868] text-sm font-normal">
                    You can now earn a cash bonus when you refer friends to
                    Cleva. Plus, your friends also get a waiver on their first
                    deposit fee. It's a win-win! Start referring today.
                  </p>
                  <Link
                    onClick={navigateToReferrals}
                    className="rounded-lg border border-[#0772D5] bg-[#F8F8F8] py-4 px-8 text-[#0772D5] font-normal text-base"
                    to="/referrals"
                  >
                    Learn More
                  </Link>
                </div>
              </div>
            </InfoModal>
          )}
        </div>
      ) : null}
      <header>
        <KycStatus />

        <section className="mt-6">
          <div className="flex items-center">
            <p className="text-black text-[16px] font-normal leading-normal ">
              Welcome,{" "}
              {isBusinessUser ? (
                <span className="text-black text-[16px] font-bold leading-normal ">
                  {KYCState === "BUSINESS_VERIFIED"
                    ? props.businessName
                    : user?.DisplayName}
                </span>
              ) : (
                <span className="text-black text-[16px] font-bold leading-normal ">
                  {user?.DisplayName}
                </span>
              )}
            </p>
            <p className=" ml-3">
              <span className="">
                <img src={waveIcon} alt="wave" />
              </span>
            </p>
          </div>
          <p className=" text-[14px] text-[#696969] pt-2 ">
            What would you like to do today?
          </p>
        </section>
      </header>

      {/* {allTransfer.length > 0 && <ToDoContents kycState={KYCState} />} */}

      <>
        <section className="pt-6 md:pt-12">
          <div className="flex items-center">
            <p className="font-semibold text-base pb-3">Account overview</p>
            <div className="ml-3">
              <button type="button" onClick={toggleAmount} className="">
                {showAmount ? (
                  <EyeSlashIcon className="h-5 w-5 text-gray-700" />
                ) : (
                  <EyeIcon className="h-5 w-5 text-gray-700" />
                )}
              </button>
            </div>
          </div>
          <div className="grid md:grid-cols-3 xl:grid-cols-4 gap-4">
            {hasAccounts ? (
              <DashboardAccountCard
                title="USD Account"
                icon={USIcon}
                account={true}
                link="accounts"
                amount={
                  showAmount
                    ? `$
              ${amount.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
                    : "****"
                }
              />
            ) : KYCState === "VERIFIED" ? (
              <DashboardAccountCard
                link="accounts"
                linkText="Tap to open a Cleva USD account"
                icon={USIcon}
                account={true}
              />
            ) : (
              <DashboardAccountCard
                text="Your balance will appear here when you open a USD account"
                icon={USIcon}
                account={false}
              />
            )}

            <RateCard />
          </div>
        </section>

        <section className="pt-6 md:pt-12">
          <h1 className="font-semibold text-base pb-3">
            Try out these features
          </h1>
          <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-4">
            <div className="">
              {hasAccounts ? (
                <DashboardCard
                  disabled={
                    KYCState !== "VERIFIED" && KYCState !== "BUSINESS_VERIFIED"
                  }
                  active={
                    KYCState === "VERIFIED" || KYCState === "BUSINESS_VERIFIED"
                  }
                  link="transfers/create"
                  title="Payout from your Cleva USD account to an NGN account"
                  icon={World_cur}
                  additionalStyle="text-[22px]"
                />
              ) : (
                <DashboardCard
                  disabled={
                    KYCState !== "VERIFIED" && KYCState !== "BUSINESS_VERIFIED"
                  }
                  active={
                    KYCState === "VERIFIED" || KYCState === "BUSINESS_VERIFIED"
                  }
                  link="/accounts"
                  title="Open a USD bank account"
                  icon={World_cur}
                  additionalStyle="text-[22px]"
                />
              )}
            </div>

            <div className="">
              {userHasCard ? (
                <DashboardCard
                  data-test="cards-box"
                  disabled={
                    KYCState !== "VERIFIED" && KYCState !== "BUSINESS_VERIFIED"
                  }
                  active={
                    KYCState === "VERIFIED" || KYCState === "BUSINESS_VERIFIED"
                  }
                  link="cards"
                  title="USD virtual card"
                  text="NEW"
                  icon={Atm_icon}
                  // width="9rem"
                  additionalStyle="text-[22px]"
                />
              ) : (
                <DashboardCard
                  data-test="cards-box"
                  disabled={
                    KYCState !== "VERIFIED" && KYCState !== "BUSINESS_VERIFIED"
                  }
                  active={
                    KYCState === "VERIFIED" || KYCState === "BUSINESS_VERIFIED"
                  }
                  link="cards"
                  title="Create a USD virtual card"
                  text="NEW"
                  icon={Atm_icon}
                  // width="9rem"
                  additionalStyle="text-[22px]"
                />
              )}
            </div>

            <div className=" order-first lg:order-none">
              <DashboardCard
                disabled={
                  KYCState !== "VERIFIED" && KYCState !== "BUSINESS_VERIFIED"
                }
                active={
                  KYCState === "VERIFIED" || KYCState === "BUSINESS_VERIFIED"
                }
                link="referrals"
                cardClass={true}
                title={`Earn up to $${maxReferralBonus} 🎉`}
                body={`Earn up to  $${maxReferralBonus}  when you refer your friends to Cleva`}
                icon={ReferralHome}
                additionalStyle="text-[24px] md:w-[14rem]"
              />
            </div>
          </div>
        </section>
      </>
    </div>
  );
};

export default Dashboard;
