// IDDocument.js
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import { NewImageUploadInput } from "./NewImageUploadInput";
import { createController, createOwner, getController, getOwner } from "../../../api";
import "react-dropzone-uploader/dist/styles.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/PopUps/MainLoader";
import SuccessModal from "../../../components/PopUps/SuccessModal";
import { DiCssTricks } from "react-icons/di";
import { AWS_CREDS } from "../../../features/services/AmazonService";

interface IDoc {
  key?: any;
  index?: any;
  currentStep?: number;
  nextStep?: any;
  saveForLater?: any;
  moveToTheNext: any;
  personType: any;
  identifier: any;
}

export function ReusableIDDocument(props: IDoc) {
  const { KYCState, AdditionalDetails } = useAppSelector(
    (state) => state.kycInfo
  );
  const [documentUpload, setDocumentUpload] = useState<any>({});
  const [selfieUpload, setSelfieUpload] = useState<any>({});
  const [isDocumentUploadButtonDisabled, setDocumentUploadButtonDisabled] =
    useState(true);
  const [isSelfieUploadButtonDisabled, setSelfieUploadButtonDisabled] =
    useState(true);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  //const [showSaveModal, setShowSaveModal] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [error, setError] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [showSelfieUpload, setShowSelfieUpload] = useState(false);

  // updateIDAndSelfieDocuments({
  //   IDDocument: props.name === "ID Documents" ? data : props.document,
  //   selfieDoc: props.name === "Selfie ID" ? data : props.document,
  // )}
  const handleDocumentInputChange = (field: string, value: any) => {
    setError(false)
    const updatedData = { ...documentUpload, [field]: value };
    const filename = updatedData["uploaded image"]?.file?.name;

    setDocumentUpload(updatedData);

    const allDocumentFieldsUploaded = updatedData.DocumentType && filename;
    setDocumentUploadButtonDisabled(!allDocumentFieldsUploaded || fileError);

    // Show the selfieUpload field when an ID type is selected
    setShowSelfieUpload(!!value);
  };

  const handleSelfieInputChange = (field: string, value: any) => {
    setError(false)
    const updatedData = { ...selfieUpload, [field]: value };
    setSelfieUpload(updatedData);
    const filename = updatedData["uploaded image"]?.file?.name;

    const allSelfieFieldsUploaded = filename;
    setSelfieUploadButtonDisabled(!allSelfieFieldsUploaded || fileError);
  };

  const hasFileErrors = () => {
    return fileError;
  };

  const getContentTypeConfig = (contentType: any) => {
    return {
      headers: {
        "Content-Type": contentType,
      },
    };
  };

  // useEffect(() => {
  //   localStorage.removeItem('businessName')
  //   localStorage.removeItem('incomingPersonType' )
  //   localStorage.removeItem("uploadDoc")
  // }, [])

  const DocContentType = documentUpload["uploaded image"]?.file?.type;
  const SelfieContentType = selfieUpload["uploaded image"]?.file?.type;

  const handleUploadButton = async () => {
    setError(false)
    if (fileError) {
      return;
    }

    const KYCIdentifier: any = props.identifier;
   // const BusinesssIdentifier = localStorage.getItem("KYCIdentifier")
    setLoading(true);

    const DocConfig = getContentTypeConfig(DocContentType);
    const SelfieConfig = getContentTypeConfig(SelfieContentType);

    try {

      if (props.personType) {
        if (props.personType === "OWNER") {
          const response = await getOwner(KYCIdentifier);
          const responseData = response?.data?.BeneficialOwner
          //responseData.KYCIdentifier = KYCIdentifier
          const DocUpload = `${responseData?.AdditionalDetails?.IDUploadUrl.url}`;
          const url = DocUpload;

          const file = documentUpload["uploaded image"].file;
          await axios.put(url, file, DocConfig);

          const selfieUrl = `${responseData?.AdditionalDetails?.SelfieUploadUrl.url}`;
          const selfieFile = selfieUpload["uploaded image"].file;
      
           await axios.put(selfieUrl, selfieFile, SelfieConfig);

           if (responseData) {
            const IDDocument = {
              DocumentType: documentUpload.DocumentType,
              DocumentNumber: "",
              IssuingCountry: "",
              IssueDate: "",
              ExpirationDate: "",
              ContentType: DocConfig.headers["Content-Type"],
              Filename: documentUpload["uploaded image"]?.file?.name,
            };
            const SelfieDocument = {
              ContentType: SelfieConfig.headers["Content-Type"],
              Filename:selfieUpload["uploaded image"]?.file?.name,
              Path: "",
              Size:selfieUpload["uploaded image"]?.file?.size,
            };
    
              responseData.IdentificationDocument = IDDocument;
              responseData.ImageUploadItem =SelfieDocument;
              responseData.DocumentVerificationPending = true;
              responseData.KYCIdentifier = KYCIdentifier
              responseData.DocumentVerificationPending = true
      
            const serverResp = await createOwner({ BeneficialOwner: responseData });
    
            if(serverResp?.status === 200){
              setLoading(false);
              setShowSuccessModal(true);
            }
          } else {
            if(AWS_CREDS.STAGE === 'qa'){
              console.error("No KYC data found.");
            }
          }

        } else if (props.personType === "CONTROLLER") {
          const response = await getController(KYCIdentifier);
  
          const responseData = response?.data?.Controller

          const DocUpload = `${responseData?.AdditionalDetails?.IDUploadUrl.url}`;
          const url = DocUpload;

          const file = documentUpload["uploaded image"].file;
          await axios.put(url, file, DocConfig);

          const selfieUrl = `${responseData?.AdditionalDetails?.SelfieUploadUrl.url}`;
          const selfieFile = selfieUpload["uploaded image"].file;
      
           await axios.put(selfieUrl, selfieFile, SelfieConfig);

           if (responseData) {
            const IDDocument = {
              DocumentType: documentUpload.DocumentType,
              DocumentNumber: "",
              IssuingCountry: "",
              IssueDate: "",
              ExpirationDate: "",
              ContentType: DocConfig.headers["Content-Type"],
              Filename: documentUpload["uploaded image"]?.file?.name,
            };
            const SelfieDocument = {
              ContentType: SelfieConfig.headers["Content-Type"],
              Filename:selfieUpload["uploaded image"]?.file?.name,
              Path: "",
              Size:selfieUpload["uploaded image"]?.file?.size,
            };
    
              responseData.IdentificationDocument = IDDocument;
              responseData.ImageUploadItem =SelfieDocument;
              responseData.DocumentVerificationPending = true;
              responseData.KYCIdentifier = KYCIdentifier
              responseData.DocumentVerificationPending = true
    
            const serverResp = await createController({ Controller: responseData });
  
            if(serverResp?.status === 200){
              setLoading(false);
              setShowSuccessModal(true);
            }
          } else {
            if(AWS_CREDS.STAGE === 'qa'){
              console.error("No KYC data found.");
            }
          }
        } else {
          if(AWS_CREDS.STAGE === 'qa'){
            console.log("No KYC data found.")
          }
        }
      } else {
        if(AWS_CREDS.STAGE === 'qa'){
          console.log("User Type not found.")
        }
      }

    } catch (error) {
      if(AWS_CREDS.STAGE === 'qa'){
        console.log(error);
      }
      setError(true)
      setLoading(false);
    }

  };

  const handleSaveForLater = () => {
    localStorage.setItem("saveForLater", "true")
    navigate('/kyb-info')
    // const dataToSave = {
    //   document,
    //   selfieUpload,
    // };

    // // Convert the data to a JSON string and save it to local storage
    // localStorage.setItem("kycData", JSON.stringify(dataToSave));

    // setShowSaveModal(true);
  };

  const closeSuccessModal = () => {
    //navigate(-1);
    navigate('/kyb-info');
    setShowSuccessModal(false);
  };

  const closeSaveModal = () => {
    navigate("/");
    setShowSuccessModal(false);
  };

  const documentType = documentUpload.DocumentType;

  const docUploaded =
    documentType === "OTHER"
      ? "Voter's Card"
      : documentType === "PASSPORT"
        ? "International Passport"
        : documentType === "DRIVERS_LICENSE"
          ? "Driver's License"
          : documentType === "NATIONAL_ID"
            ? "National ID Card (NIN)"
            : documentType;

  useEffect(() => {
    setIsButtonDisabled(hasFileErrors());
  }, [fileError]);

  const BVNKYC: boolean =
    KYCState === "MISMATCH" && AdditionalDetails.NoOfRetriesLeft === 0;

  return (
    <>
      {loading ? (
        <Loader text="Uploading documents" />
      ) : (
        <div className="w-[90%] mx-auto mb-2">
          {/* {error && <p className="text-xs text-[#D31D1D]">An error occurred. Try again.</p>} */}
          <p className="text-lg font-semibold mb-4 mt-10 md:mt-0">
            Upload ID
          </p>

          <div className="">
            <div className="flex mt-5">
              <label htmlFor="id" className="text-sm">
                Select ID type
              </label>
              <p className="text-[6.5px] text-[#D31D1D] ml-[0.5] mt-1">
                <DiCssTricks />
              </p>
            </div>

            <select
              name="DocumentType"
              value={documentUpload.DocumentType || ""}
              onChange={(e) =>
                handleDocumentInputChange("DocumentType", e.target.value)
              }
              className="w-full text-sm rounded-lg outline-none inline-block border mt-1 focus:ring-2 focus:ring-cleva-gold focus:border-transparent"
            >
              <option value="" className="hidden text-gray-600">
                Select Means of Identification
              </option>
              <option value="PASSPORT">International Passport</option>
              <option value="OTHER">Voter’s Card</option>
              <option value="DRIVERS_LICENSE">Driver’s License</option>
              {!BVNKYC ? (
                <option value="NATIONAL_ID">National ID Card (NIN)</option>
              ) : (
                ""
              )}
            </select>
          </div>
          {documentUpload.DocumentType && (
            <p className="mt-7 text-sm">Upload {docUploaded}</p>
          )}
          <NewImageUploadInput
            index="1"
            name="IDDocuments"
            show={documentUpload.DocumentType ? true : false}
            document={documentUpload}
            fileError={fileError} // Pass the fileError state
            onChange={(field, value, fileError) => {
              handleDocumentInputChange(field, value);
              // Handle the fileError value here or use it as needed in your parent component
              setFileError(fileError);
            }}
          />

          <p className="mt-12 text-lg font-semibold mb-4">
            Upload your selfie while holding your ID
          </p>
          {/* <p className="text-sm text-[#747A80] mb-4">
            Take a selfie of your full face while holding your ID
          </p> */}
          <NewImageUploadInput
            index="2"
            name="SelfieID"
            show={true}
            document={selfieUpload}
            fileError={fileError}
            onChange={(field, value, fileError) => {
              handleSelfieInputChange(field, value);
              setFileError(fileError);
            }}
          />

          <div className="mt-12">
            <button
              onClick={handleUploadButton}
              className={`${isSelfieUploadButtonDisabled ||
                isDocumentUploadButtonDisabled ||
                isButtonDisabled
                ? "bg-cleva-gold opacity-50 cursor-not-allowed"
                : "bg-cleva-gold"
                } mb-2 block w-[100%] py-3 rounded-lg text-sm font-bold text-black`}
              disabled={
                isSelfieUploadButtonDisabled ||
                isDocumentUploadButtonDisabled ||
                isButtonDisabled
              }
            >
              {loading ? "Uploading..." : "Upload Document"}
            </button>

            <button
              className="border border-[#ADADADB2] rounded-lg block w-[100%] py-3 text-sm"
              onClick={handleSaveForLater}
            >
              Save for later
            </button>
          </div>
        </div>
      )}

      {showSuccessModal && (
        <SuccessModal
          btnText="Continue"
          text="Documents uploaded successfully!"
          onClose={closeSuccessModal}
        />
      )}

      {/* {showSaveModal && (
        <SuccessModal
          btnText="Continue"
          text="KYB saved for Later"
          onClose={closeSaveModal}
        />
      )} */}
    </>
  );
}
