import React from "react";
import { AWS_CREDS } from "../../features/services/AmazonService";
import AccountDetails from "./components/AccountDetails";
import BackBtn from "../../asset/referral/backBtn.svg"
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";

const MobileAccountDetails = () => {
  const navigate = useNavigate()
  const minimumDeposit = AWS_CREDS.MINIMUM_DEPOSIT;
  const allAccount = useAppSelector((state) => state.virtualAccount.allAccount);
  const dailyLimit = allAccount ? (allAccount as any).Limit?.Total : " ";

 // const dailyLimit = AWS_CREDS.DAILY_LIMIT;

  const backToAccount = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    navigate('/accounts')
  }
  return (
    <>
    <section className=" py-8">
    <div className="flex gap-2 items-center cursor-pointer pb-1 font-medium text-[#0D0D0D]" onClick={backToAccount}>
          <img src={BackBtn} alt="account details"  className="mr-2"/>
          <button>Account Details</button>
        </div>
      <div className="text-left">
        <div className="md:pt-2 pt-2">
          <div className="px-4 py-2 bg-[#F3F3F3] rounded mt-4">
            <p className="text-[#303030] text-base font-bold">
              The minimum deposit you can receive is{" "}
              <span className="text-[#278033]">${minimumDeposit}</span>
            </p>
            <p className="text-sm pt-2 text-[#555656]">
              Micro deposits (amounts less than $1) used for account
              confirmation can still be received but they are later returned to
              the sender.
            </p>
          </div>

          <div className="px-4 py-2 bg-[#F3F3F3] rounded mt-4">
            <p className="text-[#303030] text-base font-bold">
              The maximum daily deposit is{" "}
              <span className="text-[#278033]">${dailyLimit}</span>
            </p>
            <p className="text-sm pt-2 text-[#555656]">
              Amounts above this require additional verification before they can
              be processed.
            </p>
          </div>
          <AccountDetails />
        </div>
      </div>
    </section>

    </>
  );
};

export default MobileAccountDetails;
