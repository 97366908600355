import React from "react";
import { IoEarth } from "react-icons/io5";
import { FaRegMoon } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className=" bg-white fixed bottom-0 w-full  m-auto border-t-2 h-[6rem] md:h-16 flex justify-center items-center">
      <section className="flex flex-col md:flex-row md:items-center justify-between min-[1140px]:w-[92%] lg:pr-80 m-auto text-[8px] md:text-[13px] text-black font-black">
        <ul className="flex gap-[.5rem] md:gap-6 ">
          <li>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </li>
          <li>
            <Link to="#">License</Link>
          </li>
          <li>
            <Link to="#">API</Link>
          </li>
          <li>
            <Link to="#">Help Center</Link>
          </li>
          <li><span className="text-[#787979]">© 2022 All rights reserved</span></li>
        </ul>
        <div className="pt-[.5rem] md:pt-0 flex md:items-center w-[20%] space-x-2 md:space-x-8 md:justify-evenly">
          <span className="flex items-center space-x-2 md:space-x-8">
            <b>English</b>
            <b>
              <IoEarth />
            </b>
          </span>
          <span className="border-2 p-[4px] md:p-[9px]  mrounded-md">
            <FaRegMoon />
          </span>
        </div>
      </section>
    </div>
  );
}
