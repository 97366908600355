import LegalParent from "./LegalParent";


const ReferralTerms = () => {
    return (
        <div>
            <LegalParent title="Referral Terms of Use" date="Last Updated: August 31, 2023" page="">
                <section className="text-[12px] leading-8 ">
                    <div className="hidden">
                        <p className="text-[1rem] font-bold py-[1rem] text-center">Privacy Notice </p>
                        <p className="text-[1rem] font-bold py-[1rem] text-center">Last Updated: July 27, 2023</p>
                    </div>
                    {/* introduction */}
                    <div>
                        <b className="text-[1rem] text-left">Introduction</b>
                        <p className="">
                            Cleva Technologies, Inc. <b>(“Cleva”, “we”, “us”, “our”)</b> is a technology platform that enables our users to carry out payout and remittance services <b>(“Services”)</b>.
                            We intend to create a referral program that will allow users of our Platform (“Client”) to refer new customers (“Referral Customers”) to our platform for the purpose of utilising our Services. (“Referral Program”). These Referral Terms of Use (“Terms”) shall govern our Referral Program, providing you with information about our Referral Program such as referral eligibility, referral link, referral reward, referral obligations, and all other terms and conditions.
                            <br />
                            <br />
                            By participating in this Referral Program, you agree to be bound by these Terms. To this end, we recommend that you read through carefully to understand the terms, conditions, rights and obligations that are incidental to these Terms.
                            <br />
                            <br />
                            By accessing and clicking on “I Agree”,  you acknowledge that you have reviewed these Terms and agree to its terms and conditions stated herein.
                        </p>
                    </div>

                    {/* 1 - Version Control  */}
                    <div className="flex gap-8 pt-[1rem]">
                        <b>1. </b>
                        <div>
                            <b>Version Control </b>
                            <p className="">
                                We may make changes to these Terms from time to time and when we do, we will update the “Last Updated” date given above. We may notify you through your registered email address on the Platform of the revisions made to this policy. However, you understand that this may not always be practicable and when that occurs, you agree to waive your right to notification.
                                <br />
                                <br />
                                It is your responsibility to review this policy  frequently and to stay informed of all changes which may be made to it. The current version of this policy will supersede all earlier versions from time to time. By using our services after a revision has been made to this policy, you consent to be bound by the changes we made.
                            </p>
                        </div>
                    </div>
                    {/* 2 - Information we collect from you */}
                    <div className="flex gap-8">
                        <b>2. </b>
                        <div>
                            <b>Definitions</b>
                            <ul>
                                <div className="flex gap-8">
                                    <b>2.1.</b>
                                    <p> The following words shall have the meaning assigned to them in this Policy:  </p>
                                </div>
                            </ul>
                            <ul className="ml-[3rem]">
                                <div className="flex gap-8">
                                    <b>2.1.1.</b>
                                    <div>
                                        <p className=""><b>Client means</b> all existing users of our Services who meet the eligibility requirements for referring new customers to us</p>
                                    </div>

                                </div>
                                <div className="flex gap-8">
                                    <b>2.1.2.</b>
                                    <div>
                                        <p className=""><b>Services means</b> functionality  we provide to our customers (e.g. opening a USD account and local currency payouts)</p>
                                    </div>
                                </div>
                                <div className="flex gap-8">
                                    <b>2.1.2.</b>
                                    <div>
                                        <p className=""><b>Services means</b> functionality  we provide to our customers (e.g. opening a USD account and local currency payouts)</p>
                                    </div>
                                </div>
                                <div className="flex gap-8">
                                    <b>2.1.3.</b>
                                    <div>
                                        <p className=""><b>Referral Rewards means</b> the reward that  Client receives when they successfully refer new customers in accordance with the requirements provided under this Policy,</p>
                                    </div>
                                </div>
                                <div className="flex gap-8">
                                    <b>2.1.4.</b>
                                    <div>
                                        <p className=""><b>Referral Program means</b> this program which is the subject of this policy that we have put in place to enable our Clients refer new customers to our platform</p>
                                    </div>
                                </div>
                                <div className="flex gap-8">
                                    <b>2.1.5.</b>
                                    <div>
                                        <p className=""><b>Referral Customer means</b> a person who successfully signs up and utilises any of our Services by using a referral link assigned to any of our Clients </p>
                                    </div>
                                </div>
                                <div className="flex gap-8">
                                    <b>2.1.6.</b>
                                    <div>
                                        <p className=""><b>Terms means</b> this Referral Terms of Use </p>
                                    </div>
                                </div>
                                <div className="flex gap-8">
                                    <b>2.1.7.</b>
                                    <div>
                                        <p className=""><b>Potential Clients means</b> customers  within the relevant geographical areas who are interested in participating in the Referral Program.</p>
                                    </div>
                                </div>
                                <ul className="hidden md:ml-[6rem]">
                                    <div className="flex gap-8">
                                        <b>2.2.1.1</b>
                                        <p className="">send money to the US</p>
                                    </div>
                                    <div className="flex gap-8">
                                        <b>2.2.1.2</b>
                                        <p className="">send money to Nigeria</p>
                                    </div>
                                    <div className="flex gap-8">
                                        <b>2.2.1.3</b>
                                        <p className="">open a United States Dollars (USD) account </p>
                                    </div>
                                    <div className="flex gap-8">
                                        <b>2.2.1.4</b>
                                        <p className="">create USD cards for yourself and your employees  </p>
                                    </div>
                                </ul>



                            </ul>
                        </div>
                    </div>
                    <div>
                    </div>
                    {/*  3 - Referral Eligibility  */}
                    <div className="flex gap-8">
                        <b>3.</b>
                        <div>
                            <b className="">Referral Eligibility </b>
                            <ul>
                                <div className="flex gap-8">
                                    <b>3.1</b>
                                    <p>
                                        In order to be eligible for the Referral Program, you must fulfil the following criteria:
                                    </p>
                                </div>
                                <ul className="ml-[3rem]">
                                    <div className="flex gap-8">
                                        <b>3.1.1.</b>
                                        <div>
                                            <p className="">must be a registered user of our platform </p>
                                        </div>

                                    </div>
                                    <div className="flex gap-8">
                                        <b>3.1.2.</b>
                                        <div>
                                            <p className="">must be an active and verified user of our platform Suspended or terminated accounts shall not be eligible for Referral Rewards.</p>
                                        </div>
                                    </div>

                                </ul>

                            </ul>
                        </div>
                    </div>

                    {/* 4 - Referral Link  */}
                    <div className="flex  gap-8">
                        <b>4.</b>
                        <div>
                            <b>Referral Link </b>
                            <ul>
                                <div className="flex gap-8">
                                    <b>4.1</b>
                                    <div>

                                        <p className="">
                                            We will provide you with a referral link when you sign up for Cleva. </p>
                                    </div>
                                </div>
                                <div className="flex gap-8">
                                    <b>4.2</b>
                                    <div>

                                        <p className="">
                                            You can share your referral link with Potential Clients to refer them to Cleva. </p>
                                    </div>
                                </div>
                                <div className="flex gap-8">
                                    <b>4.3</b>
                                    <div>

                                        <p className="">
                                            Upon clicking the referral link, we collect basic customer identification information from the Potential Client.</p>
                                    </div>
                                </div>

                            </ul>
                        </div>
                    </div>
                    {/* 5 - Referral Reward  */}
                    <div className="flex gap-8">
                        <b>5.</b>
                        <div>
                            <b> Referral Reward</b>
                            <ul>
                                <div className="flex gap-8">
                                    <b>5.1</b>
                                    <p>When you refer a customer who proceeds to sign up on Cleva using your referral link, provides all the customer due diligence information, as well as completes the required actions, both you and the referred customer may be eligible for a Referral Reward,</p>
                                </div>

                                <div className="flex gap-8">
                                    <b>5.2</b>
                                    <p className="">We shall go through every referral to ensure that it meets the requirements that we have stipulated in these Terms.</p>
                                </div>
                                <div className="flex gap-8">
                                    <b>5.3</b>
                                    <p className="">The specific Referral Reward will be determined by us and may be subject to change at our sole discretion. </p>
                                </div>
                                <div className="flex gap-8">
                                    <b>5.4</b>
                                    <p className=""> You must provide valid contact information, including a verified email address and phone number, to receive referral rewards. We reserve the right to disqualify referral with incomplete or inaccurate contact details. </p>
                                </div>
                                <div className="flex gap-8">
                                    <b>5.5</b>
                                    <p className=""> You agree that the Referral Rewards shall only be sent to your Cleva account and the same account shall serve as the designated account for the receipt of all other incentives that we may award you from time to time. </p>
                                </div>

                            </ul>
                        </div>
                    </div>
                    {/* 6 - Referral Actions */}
                    <div className="flex gap-8">
                        <b>6.</b>
                        <div>
                            <b>Referral Actions</b>

                            <ul>
                                <div className="flex gap-8">
                                    <b>6.1</b>
                                    <p>
                                        In order for you to be eligible for the Referral Reward, the referred customer must satisfy the specified referral actions which include but are not limited to:
                                    </p>
                                </div>

                                <ul className="ml-[3rem]">
                                    <div className="flex gap-8">
                                        <b>6.1.1</b>
                                        <p className=""> completing the registration process;</p>
                                    </div>
                                    <div className="flex gap-8">
                                        <b>6.1.2</b>
                                        <p className=""> Receive a deposit of at least $300 (or any qualifying amount set by Cleva from time to time);</p>
                                    </div>
                                </ul>



                            </ul>
                        </div>
                    </div>

                    {/* 7 - Referral Tracking */}
                    <div className="flex gap-8">
                        <b>7.</b>
                        <div>
                            <b>Referral Tracking </b>
                            <ul>
                                <div className="flex gap-8">
                                    <b>7.1</b>
                                    <p className="">We shall maintain an effective and comprehensive referral tracking system to record and monitor all referrals made to us. </p>
                                </div>
                                <div className="flex gap-8">
                                    <b>7.2</b>
                                    <p className="">The Tracking system shall include details such as the date of referral, the referred customers’ names, their contact information and such other requirements as we may require from time to time. </p>
                                </div>

                            </ul>
                        </div>
                    </div>
                    {/* 8 - Referral Reward fulfilment  */}
                    <div className="flex gap-8">
                        <b>8.</b>
                        <div className="">
                            <b>Referral Reward fulfilment </b>
                            <ul>
                                <div className="flex gap-8">
                                    <b>8.1</b>
                                    <p className="">The Referral Reward will be credited to your Cleva account and the referred entity’s account after the referred entity completes the required Referral actions.</p>
                                </div>
                                <div className="flex gap-8">
                                    <b>8.2</b>
                                    <p className="">We reserve the right to withhold or revoke the Referral Rewards if we suspect any fraudulent, suspicious or abusive behaviour.  </p>
                                </div>

                            </ul>
                        </div>
                    </div>
                    {/* 9 - Maximum Referral */}
                    <div className="flex gap-8">
                        <b>9.</b>
                        <div>
                            <b>Maximum Referral </b>
                            <ul>
                                <div className="flex gap-8">
                                    <b>9.1</b>
                                    <p>The maximum referral award you can earn will be determined by Cleva at its discretion. Any additional referrals made above this limit shall not be eligible for Referral Rewards. </p>
                                </div>
                                <div className="flex gap-8">
                                    <b>9.2</b>
                                    <p>We encourage Clients with a substantial social media presence or following to participate in the Referral Program as we may consider them for special incentives and programs. </p>
                                </div>
                            </ul>
                        </div>
                    </div>
                    {/* 10 - Promotion and Marketing   */}
                    <div className="flex gap-8">
                        <b>10.</b>
                        <div>
                            <b>Children’s Privacy </b>
                            <ul>
                                <div className="flex gap-8">
                                    <b>10.1</b>
                                    <p>By participating in this Referral Program, you consent to receive marketing communications from us, including newsletters and updates related to the program and its services. </p>
                                </div>
                                <div className="flex gap-8">
                                    <b>10.2</b>
                                    <p>You also grant us the permission to use your name, username, and profile picture for promotional and marketing purposes related to the Referral Program.  </p>
                                </div>
                            </ul>
                        </div>
                    </div>
                    {/* 11 - Changes and Termination  */}
                    <div className="flex  gap-8">
                        <b>11.</b>
                        <div>
                            <b>Changes and Termination  </b>
                            <ul className="">
                                <div className="flex gap-8">
                                    <b className="">11.1</b>
                                    <p>We reserve the right to modify or terminate the Referral Program or these Terms at any time without prior notice. </p></div>
                                <div className="flex gap-8">
                                    <b className="">11.2</b>
                                    <p>Any changes to the Referral Program or Terms will be effective upon posting on our website.</p></div>

                            </ul>
                        </div>
                    </div>

                    {/* 12 -  Compliance with Applicable Laws  */}
                    <div className="flex gap-8">
                        <b>12.</b>
                        <div>
                            <b>Compliance with Applicable Laws </b>
                            <ul className="">
                                <div className="flex gap-8">
                                    <b className="">12.1</b>
                                    <p>Participants in the Referral Program must comply with all applicable laws and regulations and must not engage in any fraudulent, deceptive or abusive practices. </p></div>
                                <div className="flex gap-8">
                                    <b className="">12.2</b>
                                    <p>As participants in the financial services industry, we uphold stringent measures to prevent the misuse of our services for fraudulent purposes such as money laundering, terrorism financial, proliferation financing and other unlawful activities. For this purpose, we shall carry out comprehensive CDD procedures, ensuring that we screen Potential Clients against various public records and sanctions lists. </p></div>
                                <div className="flex gap-8">
                                    <b className="">12.3</b>
                                    <p>We shall also continuously monitor the activities of Referral Customers to ensure that they are compliant with Anti-Money Laundering/Countering Financing Terrorism (AML/CFT) Laws and Regulations that are applicable to their various geographical locations.
                                    </p></div>
                                <div className="flex gap-8">
                                    <b className="">12.4</b>
                                    <p>Additionally, we retain transaction records as required by law to serve as vital support for law enforcement agencies during investigations.
                                    </p></div>

                            </ul>
                        </div>
                    </div>
                    {/* 13 -  Limitation of Liability   */}
                    <div className="flex gap-8">
                        <b>13.</b>
                        <div>
                            <b>Limitation of Liability </b>
                            <ul className="">
                                <div className="flex gap-8">
                                    <b className="">13.1</b>
                                    <p>We shall not be liable for any damages,, losses, or expenses arising out of or related  to the Referral Program, including but not limited to Referral Rewards or any technical issues related to the Referral Link. </p></div>
                                <div className="flex gap-8">
                                    <b className="">13.2</b>
                                    <p>You agree to indemnify and to hold us harmless from and against any misuse or illegal use of the Referral link by Employees, Contractors, Beneficial Owners of your company or any third parties who gain access to the link through any carelessness, negligence or inadvertence of yours.  </p></div>


                            </ul>
                        </div>
                    </div>
                    {/* 14 - Governing Law and Dispute Resolution  */}
                    <div className="flex gap-8">
                        <b>14.</b>
                        <div>
                            <b>Governing Law and Dispute Resolution </b>
                            <ul className="">
                                <div className="flex gap-8">
                                    <b className="">14.1</b>
                                    <p>These Terms shall be governed and construed in accordance with the Laws of the Federal Republic of Nigeria, without regard to conflict of laws principles.
                                    </p></div>
                                <div className="flex gap-8">
                                    <b className="">14.2</b>
                                    <p>In the event of any dispute between you and Cleva, we shall resort  to negotiation. Where negotiation lasts for a period of 14 days without successfully resulting in the termination of the dispute, arbitration shall be our next point of call;
                                    </p></div>
                                <div className="flex gap-8">
                                    <b className="">14.3</b>
                                    <p>Arbitration shall be before a single arbitrator jointly appointed by the parties, and the seat of arbitration shall be Lagos state, Nigeria;
                                    </p></div>
                                <div className="flex gap-8">
                                    <b className="">14.4</b>
                                    <p>Where we fail to reach an agreement on the appointment of the arbitrator within a period of 30 days from the issuance of the notice of arbitration, the arbitrators shall at the request of any of the parties be appointed by the chairman of the Chartered Institute of Arbitrators, UK, Nigerian Branch.
                                    </p></div>
                                <div className="flex gap-8">
                                    <b className="">14.5</b>
                                    <p>Any part of these Terms found to be inconsistent with the provisions of the Governing law shall be deemed severed. However, such severance shall not affect that legality or enforceability of the other parts of this Notice.
                                    </p></div>


                            </ul>
                        </div>
                    </div>
                    {/* 15 - Contact Us  */}
                    <div className="flex gap-8">
                        <b>15.</b>
                        <div>
                            <b>Contact Us </b>
                            <p>
                                If you have any questions, requests, or concerns regarding these Terms or any other information regarding our Referral Program(s), please contact us at legal@getcleva.com.
                            </p>
                        </div>
                    </div>
                </section>

            </LegalParent>
        </div>
    )

}

export default ReferralTerms;