import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUser } from '../../types'

interface UserState {
  userData: IUser | null,
  displayReferModal: boolean,
  displayBalance: boolean,
  userTag: string,
  displayCreateTagModal: boolean
}

const initialState: UserState = {
  displayReferModal: false,
  userData: localStorage.getItem('storageUser') ? JSON.parse(localStorage.getItem('storageUser')!) : null,
  displayBalance: localStorage.getItem('displayBalance')
    ? JSON.parse(localStorage.getItem('displayBalance')!)
    : true,
  userTag: localStorage.getItem('userTag') ? JSON.parse(localStorage.getItem('userTag')!) : null,
  displayCreateTagModal: false
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {

    updateReferralModalStatus: (state, action) => {
      state.displayReferModal = action.payload
    },
    changeBalanceDisplay: (state, action) => {
      state.displayBalance = action.payload;
      localStorage.setItem("displayBalance",action.payload);
    },
    setUser: (state, action: PayloadAction<UserState['userData']>) => {
      state.userData = action.payload
    },
    clearUser: (state) => {
      state.userData = null
    },
  },
})

export const { setUser, clearUser, updateReferralModalStatus, changeBalanceDisplay } = userSlice.actions

export default userSlice.reducer
