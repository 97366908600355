import React, { useEffect, useState } from "react";
import { downloadIcon } from "../../../Image";
import { ImFileText2, ImNotification } from "react-icons/im";
import { HiOutlineX } from "react-icons/hi";
import { useAppDispatch } from "../../../app/hooks";
import { updateIDAndSelfieDocuments } from "../../../features/Kyc/kycSlice";

interface IUploadInput {
  uploadedDocument?: {
    id?: number;
    filename?: string;
    contentType?: string;
    size?: number;
    data?: string | null;
    status?: string;
    message?: string;
    previewUrl: string;
  };
  show: boolean;
  index: any;
  name: string;
  document: any;
  fileError: boolean;
  onChange: (field: string, value: any, fileError: boolean) => void;
}

export function NewImageUploadInput(props: IUploadInput) {
  const [uploadedDocument, setUploadedDocument] = useState<any>(
    props.uploadedDocument || {}
  );
  const [fileSizeError, setFileSizeError] = useState<boolean>(false);
  const [fileTypeError, setFileTypeError] = useState<boolean>(false);

  const [fileError, setFileError] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const handleRemoveDocument = () => {
    setUploadedDocument({});
    setFileSizeError(false);
  };

  const formatSizeInKB = (size: number) => {
    return (size / 1024).toFixed(2); // Convert size to KB and round to 2 decimal places
  };
  const allowedFileTypes = ["image/jpeg", "image/png", "application/pdf"];
  
const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const file = event.target.files?.[0];

  if (file) {
    if (!allowedFileTypes.includes(file.type)) {
      // Check if the selected file type is not in the allowed list
      setFileTypeError(true);
      props.onChange("uploaded image", { file }, true);
      return;
    } else {
      setFileTypeError(false);
      props.onChange("uploaded image", { file }, false);
    }

    if (file.size > 5 * 1024 * 1024) {
      // Check if the file size exceeds 5MB
      setFileSizeError(true);
      setFileError(false);
      props.onChange("uploaded image", { file }, true);
    } else {
      setFileSizeError(false);
      setFileError(false);
      props.onChange("uploaded image", { file }, false);

      const reader = new FileReader();
      reader.onload = () => {
        const image: string | ArrayBuffer | null = reader.result;
        if (typeof image === "string") {
          const data = {
            file,
            // Other properties about the uploaded file
          };
          setUploadedDocument(data);

          // Dispatch the updateIDAndSelfieDocuments action with the uploaded document
          dispatch(
            updateIDAndSelfieDocuments({
              IDDocument: props.name === "ID Documents" ? data : props.document,
              selfieDoc: props.name === "Selfie ID" ? data : props.document,
            })
          );
        }
      };
      reader.readAsDataURL(file);
    }
  }
};

  useEffect(() => {
    const hasFileError = fileSizeError || !uploadedDocument?.file;
    setFileError(hasFileError);
    props.onChange("uploaded image", uploadedDocument, hasFileError);
  }, [uploadedDocument, fileSizeError]);

  return (
    <>
      {props.show && (
        <>
          <div className="mt-1 items-center">
            {!uploadedDocument?.file && (
              <label
                htmlFor={props.name} // Use props.name as the unique ID
                className={`${
                  fileSizeError || fileTypeError
                    ? "border-[2.5px] border-solid border-red-500"
                    : "border-[2.5px] border-dotted  border-[#747A80]"
                } text-sm py-10 bg-neutral-50 rounded-[13px] flex pl-5 cursor-pointer`}
              >
                <div>
                  <img className="w-[30px] md:mt-1" src={downloadIcon} alt="" />
                </div>
                <div className="ml-2">
                  <input
                    type="file"
                    name={props.name} // Use props.name as the unique ID
                    id={props.name} // Use props.name as the unique ID
                    hidden
                    accept="image/x-png,image/jpeg,application/pdf"
                    onChange={handleFileInputChange}
                    placeholder={` types: JPEG, PNG, PDF. Max file size 5mb for ${props.name}`}
                  />
                  <div className="">
                    <div className="flex -mt-2 mb-2">
                      <p className="text-base font-medium">
                        Drag and drop documents here or
                        <span className="ml-2 text-[#FFBD59] text-base font-normal">
                          Browse
                        </span>
                      </p>
                    </div>
                    <div className="flex -mt-2 mb-2">
                    <p className="text-sm text-[#747A80] font-normal">
                      Supported file types: JPEG, PNG, PDF. Max file size 5mb
                    </p>
                    </div>
                    {/* <div className="flex -mt-2 mb-2">
                      <p className="text-xs">
                        {props.name === "Selfie ID"
                          ? "Take a selfie of your full face while holding your ID"
                          : `Upload ${props.name}`}
                      </p>
                    </div> */}
                  </div>
                </div>
              </label>
            )}
            {uploadedDocument?.file && (
              <>
                <label
                  className={`${
                    uploadedDocument.status === "CORRUPT" ||
                    uploadedDocument.status === "FAILED" ||
                    fileSizeError || fileTypeError
                      ? "border-[#D31D1D]"
                      : "border-[#747A80] bg-[#E8F4FF]"
                  } text-sm  border-[2.5px] border-dotted rounded-[13px] flex m-auto justify-between py-5`}
                >
                  <div className=" w-[90%]">
                    <div className="flex w-[85%] m-auto items-center">
                      <p className="text-[25px] mt-1">
                        {uploadedDocument.status === "CORRUPT" ||
                        uploadedDocument.status === "FAILED" || fileTypeError ||
                        fileSizeError ? (
                          <ImNotification color="#D31D1D" />
                        ) : (
                          <ImFileText2 />
                        )}
                      </p>
                      <p>{fileSizeError || fileTypeError}</p>
                      <div className="  text-[12px]">
                        <div>
                          {uploadedDocument.status === "CORRUPT" ||
                            uploadedDocument.status === "FAILED" ||
                            (fileSizeError && (
                              <p className={`text-sm ml-5`}>Upload failed</p>
                            ))||
                            (fileTypeError && (
                              <p className={`text-sm ml-5`}>File type not supported</p>
                            ))}
                            
                            
                          <p className="text-sm ml-5 ">
                            {uploadedDocument.file.name}
                          </p>
                          <p
                            className={`text-[13px] text-[#747A80] font-semibold ml-5 pt-2`}
                          >
                            {formatSizeInKB(uploadedDocument.file.size)} KB
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-[10%]  ml-11 cursor-pointer"
                    onClick={handleRemoveDocument}
                  >
                    <p className="text-[22px] text-[#747A80]">
                      <HiOutlineX />
                    </p>
                  </div>
                </label>
                {uploadedDocument?.message && (
                  <p className="mt-3 text-[13px] text-[#D31D1D]">
                    {uploadedDocument?.message}
                  </p>
                )}
              </>
            )}
          </div>
          <p className="text-sm text-[#D31D1D] font-semibold mt-3">
            {fileSizeError &&
              "File size exceeds 5MB. Please select another file to proceed"}
          </p>

          <p className="text-sm text-[#D31D1D] font-semibold mt-3">
            {fileTypeError &&
              "File type not supported . Supported file types are JPEG, PNG, PDF "}
          </p>
        </>
      )}
    </>
  );
}
