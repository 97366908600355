import DashboardServices from '../services/DashboardServices'
import { AppDispatch } from '../../app/store'
import {  setRecipients } from './RecipientSlice'

export const fetchRecipients = () => async (dispatch: AppDispatch) => {
  try {

    dispatch(setRecipients({ loading: true, recipients: null, error: null }))
    const response = await DashboardServices.fetchRecipients()
    const res = response.data

    dispatch(setRecipients({ loading: false, recipients: res.RecipientSummaryList, error: null }))

    return res.RecipientSummaryList
  } catch (error: any) {
    dispatch(setRecipients({ loading: false, recipients: null, error }))
  }
}
