import Button from '../Button'

interface IHeader{
    title:string
    subtitle:string
    btnCta:string
    img:string
    imgsub:string
    btnAction: () => void
}

const ProductHeader = (props:IHeader) => {
const {title, subtitle, btnCta, btnAction, img} = props

  return (

    <div className='mt-28'>
      <section className='bg-black'>
        <div className="w-[90%] xl:w-[80%] mx-auto 2xl:w-[1280px] pt-8 pb-16">
          <div className="flex space-between lg:gap-16 items-center justify-center">
            <div className='hero-section'>
              <h1 className=' text-white text-[2rem] lg:text-4xl font-bold md:w-[20.51931rem] lg:w-[28.51931rem]'>{title}</h1>
              <p className=' text-[#F1F1F1] text-lg lg:text-xl py-4 lg:pt-[1.64rem] lg:pb-[2.5rem] font-normal md:w-[27.51931rem]'>{subtitle}</p>
              <div className=''>
                <Button
                  text={btnCta}
                  fn={btnAction}
                  style={`px-[4rem] rounded-[0.75rem] md:px-[2rem]`}
                />
              </div>
            </div>
            <div className='hidden md:block'>
              <img loading="lazy" width="100%" height="100%"  src={img} alt={title}/>

            </div>
          </div>
        </div>
      </section>
    </div>

  )
}

export default ProductHeader