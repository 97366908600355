const handlePublicProfileChange = (
    event: any,
    dispatch: any,
    setKYCState: any,
    setIsProfileLinkValid: any,
    setIndividualkyc: any,
    IndividualKyc: any
  ) => {

    dispatch(setKYCState(""));
    const profileLink = event.target.value.trim();
    const urlRegex = /^(ftp|http|https):\/\/(www\.)?[^ "]+$/;
    const isValidUrl = urlRegex.test(profileLink);
  
    if (!isValidUrl) {
      setIsProfileLinkValid(false);
    } else {
      setIsProfileLinkValid(true);
    }
  
    dispatch(
      setIndividualkyc({
        ...IndividualKyc,
        [event.target.name]: profileLink,
      })
    );
  };

  const handleDropdownChange = (event: any, dispatch:any, setKYCState: any, setIndividualkyc: any, IndividualKyc:any) => {
    dispatch(setKYCState(""));

    dispatch(
      setIndividualkyc({
        ...IndividualKyc,
        [event.target.name]: event.target.value,
      })
    );
  
  }
  
  export const KYCCheckboxData = [
    { value: "Deel, Rippling, or Remote.com", label: "Deel, Rippling, or Remote.com" },
    { value: "Payoneer", label: "Payoneer" },
    { value: "Fiverr or Upwork", label: "Fiverr or Upwork" },
    { value: 'PayPal', label: 'PayPal' },
    { value: 'Telus International AI', label: 'Telus International AI' },
    { value: 'Survey Platforms (e.g.Verily, Cliqly, e.t.c)', label: 'Survey Platforms (e.g.Verily, Cliqly, e.t.c)' },
    { value: 'Other platforms (e.g Airbnb, Amazon, e.t.c)', label: 'Other platforms (e.g Airbnb, Amazon, e.t.c)' },
    { value: 'Bank of America', label: 'Bank of America' },
    { value: 'Other US banks (e.g Chase, Citi, e.t.c)', label: 'Other US banks (e.g Chase, Citi, e.t.c)' },
    { value: 'Non-US countries', label: 'Non-US countries' },
  ];


  export { handlePublicProfileChange, handleDropdownChange }
  