
import FAQs from "../landing_page/FAQs";

import { useRef } from 'react';
import Questions from './Questions';
import Hero from './Hero';
import WebLayout from '../landing_page/components/WebLayout';

const FAQ = () => {
    const faqRef = useRef<HTMLDivElement>(null);
    // const scrollToProducts = () => {
    //     navigate("/")
    //     window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    // };

    // const scrollToFAQ = () => {
    //     if (faqRef.current) {
    //         window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    //     }
    // };

    // const handleMobileNav = () => setIsMobile(!isMobile)

    return (
        <WebLayout >
            <div className="">
                <Hero />
                <div className="h-full pb-[5rem]">
                    <FAQs innerRef={faqRef} />
                    <Questions />
                </div>
            </div>
        </WebLayout>
    )
}

export default FAQ;