import LegalParent from "./LegalParent";

interface IPP {

}


const PrivacyPolicy = (props: IPP) => {
    return (
        <div>
            <LegalParent title="Privacy Policy" date="Last Updated: July 27, 2023" page="">
                <section className="text-[12px] leading-8 ">
                    <div className="hidden">
                        <p className="text-[1rem] font-bold py-[1rem] text-center">Privacy Notice </p>
                        <p className="text-[1rem] font-bold py-[1rem] text-center">Last Updated: July 27, 2023</p>
                    </div>
                    {/* introduction */}
                    <div>
                        <b className="text-[1rem] text-left">Introduction</b>
                        <p className=""> Cleva Technologies Inc, <b>(“Cleva”, “we”, “us”, “our”)</b> is a technology platform that enables our users to carry out payout and remittance services <b>(“Services”)</b>. We are committed to protecting the privacy and security of the  personal information of our users <b>(“you”, “your”, “user”)</b> when  using any of  our Services.
                            <br />
                            <br />
                            As part of our commitment, we have designed this Privacy Notice <b>(“Notice”)</b> to inform you about the kinds of information we collect, how we collect them, the purpose of collection, how we store and use your information as well as your rights as it relates to handling your Personal data. We recommend that you read this Notice carefully to understand your rights and our duties towards the management of your information.
                            By accessing and using our services, you acknowledge that you have reviewed this  Notice and agree to its terms. This also means that you agree to the collection and processing of your data  and have accepted the applicable disclosures.
                            <br />
                            <br />
                            This Notice incorporates our Terms of use which are available <a className="text-sky-600"  href="/terms-of-service" target="_blank">here.</a>
                            <br />
                            This Notice incorporates the privacy policy of the App available <a className="text-sky-600" href="/privacy-policy-app" target="_blank">here.</a>
                        </p>
                    </div>

                    {/* 1 - Version Control  */}
                    <div className="flex gap-8 pt-[1rem]">
                        <b>1. </b>
                        <div>
                            <b>Version Control </b>
                            <p className="">

                                We may make changes to this Notice from time to time and when we do, we will update the “Last Updated” date given above. We may notify you through your registered email address on the Platform of the revisions made to this Notice. However, you understand that this may not always be practicable and when that occurs, you agree to waive your right to notification.
                                <br />
                                It is your responsibility to review this Notice frequently and to stay informed of all changes which may be made to it. The current version of this Notice will supersede all earlier versions from time to time. By using our services after a revision has been made to this Notice, you consent to be bound by the changes we made.
                            </p>
                        </div>
                    </div>
                    {/* 2 - Information we collect from you */}
                    <div className="flex gap-8">
                        <b>2. </b>
                        <div>
                            <b>Information we collect from you</b>
                            <ul>
                                <div className="flex gap-8">
                                    <b>2.1</b>
                                    <p>
                                        We collect your information to provide you with our suite of services and to enhance your experience on our platform. </p>
                                </div>
                                <div className="flex gap-8">
                                    <b>2.2</b>
                                    <p>   We collect the following categories of information: </p>
                                </div>
                            </ul>
                            <ul className="ml-[3rem]">
                                <div className="flex gap-8">
                                    <b>2.2.1</b>
                                    <div>
                                        <b>Onboarding Information</b>
                                        <p className="">To enable us to fulfil Customer Due Diligence (“CDD”) requirements while onboarding you on our platform, we will request for certain information from you which is necessary to assess your eligibility and to fulfil the requisite CDD requirements. This information encompasses details such as your company name, business address, employer identification number and the intended utilisation of our services, which may include:
                                        </p>
                                    </div>

                                </div>
                                <ul className="md:ml-[6rem]">
                                    <div className="flex gap-8">
                                        <b>2.2.1.1</b>
                                        <p className="">send money to the US</p>
                                    </div>
                                    <div className="flex gap-8">
                                        <b>2.2.1.2</b>
                                        <p className="">send money to Nigeria</p>
                                    </div>
                                    <div className="flex gap-8">
                                        <b>2.2.1.3</b>
                                        <p className="">open a United States Dollars (USD) account </p>
                                    </div>
                                    <div className="flex gap-8">
                                        <b>2.2.1.4</b>
                                        <p className="">create USD cards for yourself and your employees  </p>
                                    </div>
                                </ul>
                                <div className="flex gap-8">
                                    <b>2.2.2</b>
                                    <div>
                                        <b>Personal information</b>
                                        <p className="">
                                            We collect information regarding the name, email address, and residential address of individuals who own up to 25% equity in your business. This information is relevant for the purpose of ensuring that we comply with our obligations for the prevention of financial crimes.
                                            <br />
                                            We also request information concerning the beneficiary of each transaction initiated by you at the time of the transaction. This includes their name and bank account information. You understand that it is your responsibility to obtain the consent of these third parties before disclosure.
                                        </p>
                                    </div>

                                </div>
                                <div className="flex gap-8">
                                    <b>2.2.3</b>
                                    <div>
                                        <b>Transaction records</b>
                                        <p className="">
                                            In compliance with anti-money laundering requirements and for our business purposes, we collect information as to the records of transactions carried out by you on our platform. These data include records of the transfers made to our account for payout to your beneficiaries and the time the transfer was made.
                                        </p>
                                    </div>

                                </div>
                                <div className="flex gap-8">
                                    <b>2.2.4</b>
                                    <div>
                                        <b>Usage Information</b>
                                        <p className="">
                                            We may collect information about how you interact with our Services including your IP address, your devices information, browser type, and activity logs.
                                            <br />
                                            We also keep records of your communications to us through our customer support channel and the information you submit to us through user surveys we may request you to complete from time to time.
                                        </p>
                                    </div>

                                </div>
                            </ul>
                        </div>
                    </div>
                    <div>
                    </div>
                    {/*  3 - Consent and Authorisation  */}
                    <div className="flex gap-8">
                        <b>3.</b>
                        <div>
                            <b className="">Consent and Authorisation</b>
                            <ul>
                                <div className="flex gap-8">
                                    <b>3.1</b>
                                    <p>
                                        We shall require your consent to enable us to collect and process  and we will only collect and process after you have clicked on the “I Agree” option on this Notice. You agree to have consented to have consented to the collection, processing, storage, use and disclosures of each information which we have indicated that we collect under this Privacy Notice;
                                    </p>
                                </div>
                                <div className="flex gap-8">
                                    <b>3.2</b>
                                    <p>
                                        For other information we may require which are not expressly stated under this notice, we will request for your consent and authorisation before collection, storage and use; </p>

                                </div>
                                <div className="flex gap-8">
                                    <b>3.3</b>
                                    <p>
                                        You have the right to withdraw consent which has been given and you shall communicate the withdrawal of your consent to us clearly in writing. However, withdrawing consent previously given does not affect the legality of data processing that took place prior to the withdrawal and information collected prior to your withdrawal will be processed in accordance with the version of this Notice that was in place when you used our services </p>

                                </div>
                                <div className="flex gap-8">
                                    <b>3.4</b>
                                    <p>
                                        All data collected by us in your use of our services, will only be used for the purposes disclosed to you at the time of collection. Your information will not be used for any undisclosed or misrepresented purpose.  </p>

                                </div>
                            </ul>
                        </div>
                    </div>

                    {/* 4 - Lawful Basis: why do we collect your information? */}
                    <div className="flex  gap-8">
                        <b>4.</b>
                        <div>
                            <b>Lawful Basis: why do we collect your information? </b>
                            <ul>
                                <div className="flex gap-8">
                                    <b>4.1</b>
                                    <div>
                                        <b>Eligibility check for onboarding</b>
                                        <p className="">
                                            In order to utilise our services in Nigeria, we mandate our users to be corporate entities incorporated in the Federal Republic of Nigeria. As a result of this requirement, we collect the corporate information of our users to enable us verify that they are legally existing and in operation as proof that they meet our eligibility requirements. Additionally, such information helps us to ensure that we are not providing our services to non-Nigerian entities.
                                        </p>
                                    </div>
                                </div>
                                <div className="flex gap-8">
                                    <b>4.2</b>
                                    <div>
                                        <b>Regulatory compliance obligations</b>
                                        <p className="">
                                            As participants in the financial services industry, we uphold stringent measures to prevent the misuse of our services for fraudulent purposes such as money laundering, terrorism financial, proliferation financing and other unlawful activities. For this purpose, we collect information relating to your business, personal information of your Beneficial Owners (BOs) and beneficiaries for screening against various public records and sanctions lists. Additionally, we retain transaction records as required by law to serve as vital support for law enforcement agencies during investigations.
                                        </p>
                                    </div>
                                </div>
                                <div className="flex gap-8">
                                    <b>4.3</b>
                                    <div>
                                        <b>Legitimate business interests</b>
                                        <p className="">
                                            To the extent permitted under the Nigeria Data Protection Regulation and the Data Protection Act, we rely on our legitimate business interests to process your data for the following reasons:
                                        </p>
                                    </div>
                                </div>
                                <ul className="ml-[3rem]">
                                    <div className="flex gap-8">
                                        <b>4.3.1</b>
                                        <p>To provide our services to you;</p>
                                    </div>
                                    <div className="flex gap-8">
                                        <b>4.3.2</b>
                                        <p>To process and fulfil your requests for sending money to the beneficiaries;</p>
                                    </div>
                                    <div className="flex gap-8">
                                        <b>4.3.3</b>
                                        <p>To communicate with you, respond to your inquiries and provide customer support;
                                        </p>
                                    </div>
                                    <div className="flex gap-8">
                                        <b>4.3.4</b>
                                        <p>Detect, monitor, and prevent fraud;
                                        </p>
                                    </div>
                                    <div className="flex gap-8">
                                        <b>4.3.5</b>
                                        <p>To enhance our user experiences and troubleshoot technical issues
                                        </p>
                                    </div>
                                    <div className="flex gap-8">
                                        <b>4.3.6</b>
                                        <p>Manage, operate, and improve the performance of our services and our platforms;
                                        </p>
                                    </div>
                                    <div className="flex gap-8">
                                        <b>4.3.7</b>
                                        <p>Protect you, our services, and our platform from malicious activity and other privacy and security risks;
                                        </p>
                                    </div>
                                    <div className="flex gap-8">
                                        <b>4.3.8</b>
                                        <p>Enable network and information security throughout our platform and services;
                                        </p>
                                    </div>
                                    <div className="flex gap-8">
                                        <b>4.3.9</b>
                                        <p>Investigate any misuse of our services or our platform in accordance with our terms of use;
                                        </p>
                                    </div>
                                    <div className="flex gap-8">
                                        <b>4.3.10</b>
                                        <p>To conduct record keeping and otherwise manage our business;</p>
                                    </div>
                                    <div className="flex gap-8">
                                        <b>4.3.11</b>
                                        <p>For any other purpose with your consent</p>
                                    </div>
                                </ul>
                            </ul>
                        </div>
                    </div>
                    {/* 5 - How we collect your information  */}
                    <div className="flex gap-8">
                        <b>5.</b>
                        <div>
                            <b> How we collect your information</b>
                            <ul>
                                <div className="flex gap-8">
                                    <b>5.1</b>
                                    <p>We collect information when:</p>
                                </div>
                                <ul className="ml-[3rem]">
                                    <div className="flex gap-8">
                                        <b>5.1.1</b>
                                        <p>You onboard on our platform to use our services;</p>
                                    </div>
                                    <div className="flex gap-8">
                                        <b>5.1.2</b>
                                        <p> You carry out any transaction through our platform;</p>
                                    </div>
                                    <div className="flex gap-8">
                                        <b>5.1.3</b>
                                        <p> Communicate with us through our customer support channels</p>
                                    </div>
                                </ul>
                                <div className="flex gap-8">
                                    <b>5.2</b>
                                    <p className="">We employ cookies to monitor your local computer settings so as to detect unauthorised access. We may also expand our use of cookies to include the collection of additional data such as passwords, preferences, and other details  as improvements are made on our services.</p>
                                </div>
                                <div className="flex gap-8">
                                    <b>5.3</b>
                                    <p className="">We shall grant you an opportunity to either accept or reject cookies used on our browsers. However, if you choose to reject the cookies, you may not be able to access some functionalities and web pages on our  site or mobile applications, </p>
                                </div>
                                <div className="flex gap-8">
                                    <b>5.4</b>
                                    <p className=""> We shall, as much as reasonably within our abilities, not collect information that identifies any of our Customers personally unless the Customer has provided some personal information to us before then. </p>
                                </div>
                                <div className="flex gap-8">
                                    <b>5.5</b>
                                    <p className=""> If you would prefer not to accept cookies, most browsers will allow you to: </p>
                                </div>
                                <ul className="ml-[3rem]">
                                    <div className="flex gap-8">
                                        <b>5.5.1</b>
                                        <p>Disable existing cookies;</p>
                                    </div>
                                    <div className="flex gap-8">
                                        <b>5.5.2</b>
                                        <p>Set your browser's settings to automatically reject cookies</p>
                                    </div>
                                </ul>
                            </ul>
                        </div>
                    </div>
                    {/* 6 - Third Party access to your information */}
                    <div className="flex gap-8">
                        <b>6.</b>
                        <div>
                            <b>Third Party access to your information</b>
                            <p>
                                During the course of our business relationship with you, we may share your personal information with the following persons and for the following reasons:
                            </p>
                            <ul>
                                <div className="flex gap-8">
                                    <b>6.1</b>
                                    <p>
                                        <b>Service providers: </b>
                                        we engage the service of trusted third-party service providers to assist us in delivering our Services, and they include; identity verification service providers, fraud prevention service providers, and web hosting services for the routine maintenance of our software systems. These service providers are bound by contractual obligations to keep your information confidential and secure.

                                    </p>
                                </div>
                                <div className="flex gap-8">
                                    <b>6.2</b>
                                    <p>
                                        <b>Mandatory disclosures to legal or regulatory authorities: </b>
                                        we may disclose your information if required to do so by a legal or regulatory authority, or in response to a valid legal request or court order.
                                    </p>
                                </div>
                                <div className="flex gap-8">
                                    <b>6.3</b>
                                    <p>
                                        <b>Business partners: </b>
                                        we have business partners for the purpose of providing one or more components of our services to you. We may disclose your information to them for the purpose of providing our services to you and to assist them in complying with their obligations under the law.
                                    </p>
                                </div>
                            </ul>
                        </div>
                    </div>

                    {/* 7 - Storage, security and confidentiality of your information*/}
                    <div className="flex gap-8">
                        <b>7.</b>
                        <div>
                            <b>Storage, security and confidentiality of your information</b>
                            <ul>
                                <div className="flex gap-8">
                                    <b>7.1</b>
                                    <p className="">We maintain organisational, technical and administrative measures designed to protect your personal data against unauthorised access, disclosures, alteration, misuse and destruction;</p>
                                </div>
                                <div className="flex gap-8">
                                    <b>7.2</b>
                                    <p className="">We use secured web services that have been configured to run within a virtual private connection and an SSL certificate to make sure that all communications are made over HTTPs SFTP using SSL. We shall also use our best endeavours to safeguard the confidentiality and security of your personal data however, please note that no method of transmission over the internet is completely secure therefore, we cannot assure you of an absolute security of your data;</p>
                                </div>
                                <div className="flex gap-8">
                                    <b>7.3</b>
                                    <p className="">We will retain your data for as long as you use our services and access our platform;</p>
                                </div>
                                <div className="flex gap-8">
                                    <b>7.4</b>
                                    <p className="">All information collected on our platforms using Amazon Web Services (AWS). Access to this information is restricted to employee responsible for screening; </p>
                                </div>
                                <div className="flex gap-8">
                                    <b>7.5</b>
                                    <p className=""> However, for legal and regulatory compliance reasons, we will retain your personal data for an additional period of seven years after the termination of the relationship between us;</p>
                                </div>
                                <div className="flex gap-8">
                                    <b>7.6</b>
                                    <p className=""> During this period, you may exercise your right to be forgotten/erasure/deletion by requesting that we delete your data before and we may do so immediately upon your request:</p>
                                </div>
                                <ul className="ml-[3rem]">
                                    <div className="flex gap-8">
                                        <b>7.6.1</b>
                                        <p className=""> When no statutory provision states otherwise;</p>
                                    </div>
                                    <div className="flex gap-8">
                                        <b>7.6.2</b>
                                        <p className=""> When after inquiry we can ascertain that you are not subject to an investigation or lawsuit that may require the retention of the data sought to be deleted;</p>
                                    </div>
                                </ul>
                                <div className="flex gap-8">
                                    <b>7.7</b>
                                    <p className=""> Also, we may retain your data stored in our database , after you request that we delete it if:</p>
                                </div>
                                <ul className="ml-[3rem]">
                                    <div className="flex gap-8">
                                        <b>7.7.1</b>
                                        <p className="">We have a legal obligation to retain it;</p>                                </div>
                                    <div className="flex gap-8">
                                        <b>7.7.2</b>
                                        <p className="">It is necessary to retain such information for fraud prevention and to enhance the safety of our users.</p>
                                    </div>
                                </ul>
                            </ul>
                        </div>
                    </div>
                    {/* 8 - International data transfer */}
                    <div className="flex gap-8">
                        <b>8.</b>
                        <div className="">
                            <b>International data transfer</b>
                            <p>
                                We may transfer your data to our partners in the US or UK for the purpose of providing the services to you. This transfer will only be made after we have ascertained that our partners have implemented adequate security measures to ensure the protection of your data.
                            </p>
                        </div>
                    </div>
                    {/* 9 - Your rights as it relates to your data */}
                    <div className="flex gap-8">
                        <b>9.</b>
                        <div>
                            <b>Your rights as it relates to your data </b>
                            <ul>
                                <div className="flex gap-8">
                                    <b>9.1</b>
                                    <p>You have the following rights in relation to you data held by us</p>
                                </div>
                                <ul className="ml-[3rem]">
                                    <div className="flex gap-8">
                                        <b>9.1.1</b>
                                        <p>The right to have access to your personal data</p>
                                    </div>
                                    <div className="flex gap-8">
                                        <b>9.1.2</b>
                                        <p>The right to be informed about the processing of your personal data</p>
                                    </div>
                                    <div className="flex gap-8">
                                        <b>9.1.3</b>
                                        <p> The right to rectify any inaccurate personal data or any information about you.</p>
                                    </div>
                                    <div className="flex gap-8">
                                        <b>9.1.4</b>
                                        <p>The right to review, modify or erase any personal data and any information we have about you. This is referred to as your right to be forgotten. </p>
                                    </div>
                                    <div className="flex gap-8">
                                        <b>9.1.5</b>
                                        <p>The right to restrict the processing of your personal data</p>
                                    </div>
                                    <div className="flex gap-8">
                                        <b>9.1.6</b>
                                        <p>The right to block the processing of your personal data that is done in violation of any applicable law.</p>
                                    </div>
                                    <div className="flex gap-8">
                                        <b>9.1.7</b>
                                        <p> The right to be informed about any erasure or rectification of your personal data or restriction of any [processing carried out.</p>
                                    </div>
                                    <div className="flex gap-8">
                                        <b>9.1.8</b>
                                        <p> The right to the portability of your personal data; and</p>
                                    </div>
                                    <div className="flex gap-8">
                                        <b>9.1.9</b>
                                        <p> The right to lodge a complaint to a supervisory authority within Nigeria.</p>
                                    </div>
                                </ul>
                                <div className="flex gap-8">
                                    <b>9.2</b>
                                    <p>At your request, we will provide the personal data held by us in a structured manner, commonly used and machine-readable format;</p>
                                </div>
                                <div className="flex gap-8">
                                    <b>9.3</b>
                                    <p>We will restrict you from transmitting those data in our database to another company where the processing is based on consent, on a contract, and processing is carried out by automated means;</p>
                                </div>
                                <div className="flex gap-8">
                                    <b>9.4</b>
                                    <p>To exercise any of your rights under this Notice, you may contact us through the email address provided under this Notice. However, please note that we may request that you complete a form and provide suitable identifications to verify your identity. Once the form is completed where required, we will provide you with the requested information within a period not exceeding two months. However, we have a right to refuse your request where we notice that the request is repetitive or excessive;
                                    </p>
                                </div>
                                <div className="flex gap-8">
                                    <b>9.5</b>
                                    <p>Please note that your right to the erasure of your data does not apply to legal necessities like invoices, audit logs, subscription information, and the data archived on our backup systems. For such data, we shall securely isolate and protect their users from any further processing; </p>
                                </div>
                                <div className="flex gap-8">
                                    <b>9.6</b>
                                    <p>Where any of the rights as provided for under this Notice is breached, the resolution shall be in accordance with the dispute resolution mechanism in our Terms of use.</p>
                                </div>
                            </ul>
                        </div>
                    </div>
                    {/* 10 - Children’s Privacy  */}
                    <div className="flex gap-8">
                        <b>10.</b>
                        <div>
                            <b>Children’s Privacy </b>
                            <p>
                                Our Services are not intended for children under the age of 18. We do not knowingly collect personal information from children. If we become aware that we have inadvertently collected Personal Data from a child, we will take prompt steps to delete such information from our records.
                            </p>
                        </div>
                    </div>
                    {/* 11 - Governing Law  */}
                    <div className="flex  gap-8">
                        <b>11.</b>
                        <div>
                            <b>Governing Law </b>
                            <ul className="">
                                <div className="flex gap-8">
                                    <b className="">11.1</b>
                                    <p>This Notice is made pursuant to the Nigeria Data Protection Regulations, (NDPR)  and the Nigeria Data Protection Act.
                                    </p></div>
                                <div className="flex gap-8">
                                    <b className="">11.2</b>
                                    <p>Any part of this Notice found to be inconsistent with the provisions of the above mentioned enactments shall be deemed severed. However, such severance shall not affect that legality or enforceability of the other parts of this Notice.        </p></div>

                            </ul>
                        </div>
                    </div>

                    {/* 12 -  Contact Us */}
                    <div className="flex gap-8">
                        <b>12.</b>
                        <div>
                            <b>Contact Us</b>
                            <p>
                                If you have any questions, requests, or concerns regarding this Notice or our data practices, please contact us at <a className="text-sky-400" href="mailto:legal@getcleva.com">legal@getcleva.com</a>.</p>

                        </div>
                    </div>
                </section>

            </LegalParent>
        </div>
    )
}

export default PrivacyPolicy