import { androidIcon, appleIcon } from "../../asset/landing_photos";

const DownloadPrompt = () => {
    return (
        <div className="bg-[#000000] text-white">
            <div className="flex justify-center items-center py-16">
                <div className="md:w-1/2 w-[80%] mx-auto">
                    <h1 className="text-center font-bold text-2xl lg:text-4xl  pb-8">Download the Cleva App & get started now!</h1>
                    <div className="flex gap-[0.75rem] justify-center items-center">
                        <a href="https://play.google.com/store/apps/details?id=com.getcleva.cleva_mobile_app" target="_blank" rel="noreferrer" className="">
                            <img src={androidIcon} alt="Get it on google play" />

                        </a>
                        <a href="https://apps.apple.com/app/cleva-app/id6473619732" target="_blank" rel="noreferrer">
                            <img src={appleIcon} alt="Get it on apple store" />

                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DownloadPrompt