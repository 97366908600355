import React, { useEffect } from "react";
import { useAppDispatch } from "../../app/hooks";
import { setTitle } from "../../features/Nav/NavSlice";

export default function Invoice() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setTitle("Invoice"));
  }, [dispatch]);
  
  return (
    <div>
    </div>
  );
}
