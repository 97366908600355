  const Bank = [
    {
      id: 1,
      value: "",
      label: "Select Bank",
    },
    {
      id: 2,
      value: "ABBEY MORTGAGE BANK",
      label: "Abbey Mortgage Bank",
    },
    {
      id: 3,
      value: "ACCESS BANK PLC",
      label: "Access Bank",
    },
    {
      id: 4,
      value: "AccessMobile",
      label: "Access mobile",
    },
    {
      id: 5,
      value: "ACCION MICROFINANCE BANK",
      label: "Accion Mfb",
    },
    {
      id: 6,
      value: "ADDOSSER MICROFINANCE BANK",
      label: "Addoser Mfb",
    },
    {
      id: 7,
      value: "AG MORTGAGE BANK PLC",
      label: "Ag Morgage Bank ",
    },
    {
      id: 8,
      value: "AL",
      label: "Al ",
    },
    {
      id: 9,
      value: "AMJU UNIQUE MICROFINANCE BANK",
      label: "Amju Unique Mfb ",
    },
    {
      id: 10,
      value: "AMML MFB",
      label: "Amml Mfb ",
    },
    {
      id: 11,
      value: "ASO SAVINGS AND LOANS",
      label: "Aso Savings and Loans",
    },
    {
      id: 12,
      value: "BAINES CREDIT MFB",
      label: "Baines Credit Mfb",
    },
    {
      id: 13,
      value: "Boctrust Microfinance Bank",
      label: "Boctrust Mfb",
    },
    {
      id: 14,
      value: "BOWEN MICROFINANCE BANK",
      label: "Bowen Mfb",
    },
    {
      id: 15,
      value: "BRENT MORTGAGE BANK",
      label: "Brent Mortgage Bank",
    },
    {
      id: 100026,
      value: "Carbon",
      label: "Carbon (One Finance)",
    },
    {
      id: 16,
      value: "CELLULANT",
      label: "Cellulant",
    },
    {
      id: 17,
      value: "CEMCS MICROFINANCE BANK",
      label: "Cemcs Mfb",
    },
    {
      id: 18,
      value: "CENTRAL BANK OF NIGERIA",
      label: "Central Bank of Nigeria",
    },
    {
      id: 19,
      value: "ChamsMobile",
      label: "Chams Mobile",
    },
    {
      id: 20,
      value: "CIT MICROFINANCE BANK",
      label: "CIT Mfb",
    },
    {
      id: 21,
      value: "Citibank Nigeria LTD",
      label: "CitiBank Ng Ltd",
    },
    {
      id: 22,
      value: "CONSUMER MICROFINANCE BANK",
      label: "Consumer Mfb",
    },
    {
      id: 23,
      value: "CONTEC GLOBAL INFOTECH LIMITED (NOWNOW)",
      label: "Contec Global Infotech Limited(NOWNOW)",
    },
    {
      id: 24,
      value: "Coronation Merchant Bank",
      label: "Coronation Merchant Bank",
    },
    {
      id: 25,
      value: "Covenant",
      label: "Covenant",
    },
    {
      id: 26,
      value: "CREDIT AFRIQUE MICROFINANCE BANK",
      label: "Credit Afrique Mfb",
    },
    {
      id: 27,
      value: "DAYLIGHT MICROFINANCE BANK",
      label: "Daylight Mfb",
    },
    {
      id: 28,
      value: "DIAMOND BANK PLC",
      label: "Diamond Bank Plc",
    },
    {
      id: 29,
      value: "Eartholeum",
      label: "Eartholeum",
    },
    {
      id: 30,
      value: "ECOBANK NIGERIA PLC",
      label: "Eco Bank Nig Plc",
    },
    {
      id: 31,
      value: "EcoMobile",
      label: "Eco Mobile",
    },
    {
      id: 32,
      value: "EKONDO MICROFINANCE BANK",
      label: "Ekondo Mfb",
    },
    {
      id: 33,
      value: "EMPIRE TRUST BANK",
      label: "Empire Trust Bank",
    },
    {
      id: 34,
      value: "eTranzact",
      label: "eTranzact",
    },
    {
      id: 90551,
      value: "Fairmoney Microfinance",
      label: "Fairmoney Microfinance",
    },
    {
      id: 35,
      value: "FAST MICROFINANCE BANK",
      label: "Fast Mfb",
    },
    {
      id: 36,
      value: "FBN Mortgages Limited",
      label: "FBN Mortgages Limited",
    },
    {
      id: 37,
      value: "FBNMobile",
      label: "Fbn Mobile",
    },
    {
      id: 38,
      value: "FBNQuest Merchant Bank Limited",
      label: "FBN Quest Merchant Bank Limited",
    },
    {
      id: 39,
      value: "FET",
      label: "Fet",
    },
    {
      id: 40,
      value: "FFS MICROFINANCE BANK",
      label: "FFS Mfb",
    },
    {
      id: 41,
      value: "FIDELITY BANK PLC",
      label: "Fidelity Bank Plc",
    },
    {
      id: 42,
      value: "Fidelity Mobile",
      label: "Fidelity Mobile",
    },
    {
      id: 43,
      value: "FidFund MFB",
      label: "FidFund MFB",
    },
    {
      id: 44,
      value: "FINATRUST MICROFINANCE BANK",
      label: "Finatrust Mfb",
    },
    {
      id: 45,
      value: "FIRST BANK OF NIGERIA PLC",
      label: "First Bank of Nigeria Plc",
    },
    {
      id: 46,
      value: "FIRST CITY MONUMENT BANK PLC",
      label: "First City Monument Bank Plc",
    },
    {
      id: 47,
      value: "FIRST ROYAL MICROFINANCE BANK",
      label: "First Royal Mfb",
    },
    {
      id: 48,
      value: "FLUTTERWAVE TECHNOLOGY SOLUTIONS LIMITED",
      label: "Flutterwave Technology Solutions Limited",
    },
    {
      id: 49,
      value: "Fortis Microfinance Bank",
      label: "Fortis Mfb",
    },
    {
      id: 50,
      value: "FSDH MERCHANT BANK LIMIT",
      label: "Fsdh Merchant Bank ",
    },
    {
      id: 51,
      value: "GATEWAY MORTGAGE BANK",
      label: "Gateway Mortgage Bank",
    },
    {
      id: 52,
      value: "Globus Bank",
      label: "Globus Bank",
    },
    {
      id: 53,
      value: "GOWANS MFB",
      label: "Gowans Mfb",
    },
    {
      id: 54,
      value: "GREENBANK MICROFINANCE BANK",
      label: "GreenBank Mfb",
    },
    {
      id: 55,
      value: "GTMobile",
      label: "Gt Mobile",
    },
    {
      id: 56,
      value: "Guaranty Trust Bank",
      label: "Guaranty Trust Bank",
    },
    {
      id: 57,
      value: "Haggai Mortgage Bank Limited",
      label: "Haggai Mortgage Bank Limited",
    },
    {
      id: 58,
      value: "HASAL MICROFINANCE BANK",
      label: "Hasal Mfb",
    },
    {
      id: 59,
      value: "Hedonmark",
      label: "Hedonmark",
    },
    {
      id: 61,
      value: "HIGHSTREET MICROFINANCE BANK",
      label: "Hightstreet Mfb",
    },
    {
      id: 62,
      value: "IBILE MFB",
      label: "Ibile Mfb",
    },
    {
      id: 63,
      value: "IMPERIAL HOMES MORTGAGE BANK",
      label: "Impmerial Home Mortage Bank",
    },
    {
      id: 64,
      value: "INFINITY MICROFINANCE BANK",
      label: "Infinity Mfb",
    },
    {
      id: 65,
      value: "INFINITY TRUST MORTGAGE BANK",
      label: "Infinity Trust Mortgage Bank",
    },
    {
      id: 66,
      value: "Innovectives Kesh",
      label: "Innovectives Kesh",
    },
    {
      id: 67,
      value: "Intellifin",
      label: "Intellifin",
    },
    {
      id: 68,
      value: "JAIZ BANK PLC",
      label: "Jaiz Bank Plc",
    },
    {
      id: 69,
      value: "JUBILEE LIFE MORTGAGE BANK",
      label: "Jubilee Life Mortgage Bank",
    },
    {
      id: 70,
      value: "KEYSTONE BANK",
      label: "Keystone Bank",
    },
    {
      id: 71,
      value: "KongaPay",
      label: "Konga Pay",
    },
    {
      id: 72,
      value: "Kuda",
      label: "Kuda Mfb",
    },
    {
      id: 73,
      value: "LAGOS BUILDING INVESTMENT COMPANY",
      label: "Lagos Bulding Investment Company",
    },
    {
      id: 74,
      value: "MALACHY MICROFINANCE BANK",
      label: "Malachy Mfb",
    },
    {
      id: 75,
      value: "Microcred Microfinance Bank Nigeria Ltd",
      label: "Microcred MFB Nig Ltd",
    },
    {
      id: 76,
      value: "MICROVIS MICROFINANCE&#160;BANK",
      label: "Microvis Mfb",
    },
    {
      id: 77,
      value: "Mkudi",
      label: "Mkudi",
    },
    {
      id: 78,
      value: "MONEY TRUST MICROFINANCE BANK",
      label: "Money Trust Mfb",
    },
    {
      id: 79,
      value: "MoneyBox",
      label: "Money Box",
    },
    {
      id: 790000,
      value: "Moniepoint Microfinance Bank",
      label: "Moniepoint Microfinance Bank",
    },
    {
      id: 80,
      value: "MUTUAL TRUST MICROFINANCE BANK",
      label: "Mutual Trust Mfb",
    },
    {
      id: 81,
      value: "NDIORAH MICROFINANCE BANK",
      label: "Ndiorah Mfb",
    },
    {
      id: 82,
      value: "New Prudential Bank",
      label: "New Predential Bank",
    },
    {
      id: 83,
      value: "NIGERIA INTERBANK SETTLEMENT SYSTEM",
      label: "Nigeria Interbank Settlement System",
    },
    {
      id: 84,
      value: "NIP Virtual Bank ",
      label: "Nip Virtual Bank ",
    },
    {
      id: 85,
      value: "NOVA MERCHANT BANK LTD ",
      label: "Nova Merchant Bank Ltd ",
    },
    {
      id: 86,
      value: "NPF Microfinance Bank  ",
      label: "NPF Mfb",
    },
    {
      id: 87,
      value: "OHAFIA MFB",
      label: "Ohafia Mfb ",
    },
    {
      id: 88,
      value: "OKPOGA MICROFINANCE BANK",
      label: "Okpoga Mfb ",
    },
    {
      id: 89,
      value: "Omoluabi Savings and Loans Plc",
      label: "Omoluabi Savings and Loans ",
    },
    {
      id: 90,
      value: "OPAY (PAYCOM)",
      label: "OPay (PayCom)",
    },
    {
      id: 91,
      value: "PAGA",
      label: "Paga",
    },
    {
      id: 92,
      value: "Page MFBank",
      label: "Page Mfb",
    },
    {
      id: 93,
      value: "PALMPAY",
      label: "PalmPay",
    },
    {
      id: 94,
      value: "PARALLEX MFB",
      label: "Parallex Mfb",
    },
    {
      id: 95,
      value: "Parkway",
      label: "ParkWay",
    }, 
    {
      id: 97,
      value: "PECANTRUST MICROFINANCE BANK",
      label: "Pecantrust Mfb",
    }, 
    {
      id: 98,
      value: "Personal Trust Microfinance Bank",
      label: "Personal Trust Mfb",
    }, 
    {
      id: 99,
      value: "PETRA MICROFINANCE BANK",
      label: "Petra Mfb",
    }, 
    {
      id: 100,
      value: "PLATINUM MORTGAGE BANK",
      label: "Platinum Mortgage Bank",
    }, 
    {
      id: 101,
      value: "POLARIS BANK LTD (SKYE BANK PLC)",
      label: "Polaris Bank Ltd(Skye Bank Plc)",
    }, 
    {
      id: 102,
      value: "PROVIDUS BANK",
      label: "Providus Bank",
    }, 
    {
      id: 103,
      value: "RAHAMA MICROFINANCE BANK",
      label: "Rahama Mfb",
    }, 
    {
      id: 104,
      value: "RAND MERCHANT BANK",
      label: "Rand Merchant Bank",
    }, 
    {
      id: 105,
      value: "REGENT MFB",
      label: "Regent Mfb",
    }, 
    {
      id: 106,
      value: "RELIANCE MICROFINANCE BANK",
      label: "Reliance Mfb",
    }, 
    {
      id: 107,
      value: "RICHWAY MICROFINANCE BANK",
      label: "Rich Way Mfb",
    }, 
    {
      id: 108,
      value: "ROLEZ MICROFINANCE BANK",
      label: "Rolez Mfb",
    }, 
    {
      id: 109,
      value: "Safetrust Mortgage bank ",
      label: "Safe Trust Mortgage Bank",
    },
    {
      id: 110,
      value: "SAGAMU MICROFINANCE BANK LTD ",
      label: "Sagamu Mfb",
    }, 
    {
      id: 111,
      value: "SEED CAPITAL MICROFINANCE BANK ",
      label: "Seed Capital Mfb",
    }, 
    {
      id: 112,
      value: "Sparkle Microfinance Bank ",
      label: "Sparkle Mfb",
    }, 
    {
      id: 113,
      value: "STANBIC IBTC BANK PLC ",
      label: "Stanbic Ibtc Bank Plc",
    }, 
    {
      id: 114,
      value: "STANBIC MOBILEMONEY ",
      label: "Stanbic Mobile Money",
    }, 
    {
      id: 115,
      value: "STANDARD CHARTERED BANK NIGERIA LTD ",
      label: "Standard Chartered Bank Nig Ltd",
    }, 
    {
      id: 116,
      value: "STERLING BANK PLC ",
      label: "Sterling Bank Plc",
    }, 
    {
      id: 117,
      value: "Sterling Mobile  ",
      label: "Sterling Mobile ",
    }, 
    {
      id: 118,
      value: "SunTrust Bank ",
      label: "Sun Trust Bank",
    }, 
    {
      id: 119,
      value: "TCF ",
      label: "Tcf",
    }, 
    {
      id: 120,
      value: "TeasyMobile ",
      label: "Teasy Mobile",
    }, 
    {
      id: 120,
      value: "TRIDENT MICROFINANCE BANK ",
      label: "Trident Mfb",
    }, 
    {
      id: 121,
      value: "TRUSTBOND MORTGAGE BANK ",
      label: "Trust Mortgage Bank",
    }, 
    {
      id: 122,
      value: "UNION BANK OF NIGERIA PLC ",
      label: "Union Bank of Nig Plc",
    }, 
    {
      id: 123,
      value: "UNITED BANK FOR AFRICA PLC ",
      label: "United Bank For Africa Plc",
    }, 
    {
      id: 124,
      value: "UNITY BANK PLC ",
      label: "Unity Bank Plc",
    }, 
    {
      id: 125,
      value: "Verite Microfinance Bank",
      label: "Versite Mfb",
    },
    {
      id: 126,
      value: "VFD MICROFINANCE BANK",
      label: "Vfd Mfb",
    }, 
    {
      id: 127,
      value: "VISA MICROFINANCE BANK",
      label: "Visa Mfb",
    }, 
    {
      id: 128,
      value: "Visual ICT&#160; ",
      label: "Vissual Ict",
    }, 
    {
      id: 129,
      value: "VTNetworks",
      label: "Vt Networks",
    }, 
    {
      id: 130,
      value: "WEMA BANK PLC",
      label: "Wema Bank Plc",
    }, 
    {
      id: 131,
      value: "WETLAND MFB",
      label: "WetLand Mfb",
    }, 
    {
      id: 132,
      value: "XSLNCE Microfinance Bank",
      label: "Xslnce Mfb",
    }, 
    {
      id: 133,
      value: "YES MICROFINANCE BANK",
      label: "Yes Mfb",
    }, 
    {
      id: 134,
      value: "ZENITH BANK PLC",
      label: "Zenith Bank Plc",
    }, 
    {
      id: 135,
      value: "ZenithMobile",
      label: "Zenith Mobile",
    }, 
  ];
export default Bank;