import React, { useState } from "react";
import moneyIcon from "../../images/currency.png";
import { useNavigate } from "react-router-dom";
import SuccessModal from "../../components/PopUps/SuccessModal";
import { postAccount } from "../../features/VirtualAccount/VirtualAccountApi";
import { RootState, AppDispatch } from "../../app/store";
import { useSelector, useDispatch } from "react-redux";
import { setLoading } from "../../features/VirtualAccount/VirtualAccountSlice";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../components/PopUps/MainLoader";
import { KycStatus } from "../Kyc/components/KycStatus";
import { AWS_CREDS } from "../../features/services/AmazonService";

const CreateAccount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector((state: RootState) => state.virtualAccount.loading);
  const { KYCState } = useSelector((state: RootState) => state.kycInfo);

  let user = useSelector((state: RootState) => state.user.userData)
  if (!user) {
    user = JSON.parse(localStorage.getItem("storageUser")!);
  }
  const FirstName = user?.FullName.FirstName || "";
  const LastName = user?.FullName.LastName || "";
  const email = user?.StandardAttributes.Email || "";
  const myData = {
    FirstName,
    LastName,
    email,
  }

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const handleSubmit = () => {
    if (KYCState === "VERIFIED" || KYCState === "BUSINESS_VERIFIED") {
      // Perform form submission
      setLoading(true);
      // setShowSuccessModal(true);

      const action = postAccount(myData);
      dispatch(action)
        .unwrap()
        .then((response: any) => {
          if (response) {
            setLoading(false);
            setShowSuccessModal(true);
          } else {
            setLoading(false);
            //toast.error("Are you sure you don't have an account?");
            toast.error("Account Creation Failed, Please try again later");

          }
        })
        .catch((error: any) => {
          setLoading(false);
          toast.error("Account Creation Failed, Please contact support");
          if(AWS_CREDS.STAGE === 'qa'){
     
            console.log(error);
          }
          // Prevent navigation if the response returns undefined
          if (error === undefined) {
            return;
          }
          return Promise.reject(error);
        });
    } else {
      setShowTooltip(true);
    }
  };

  const closeSuccessModal = () => {
    navigate("/accounts/view");
    setShowSuccessModal(false);
  };


  return (
    <>
      <div className="pt-12">
        <KycStatus />
      </div>

      {loading ? <Loader text="Your account is being generated" /> :
        <div className="flex justify-center items-center h-[75vh]">
          <div className=" mx-auto text-center md:p-10 p-5 bg-white">
            <div className="flex justify-center">
              <img src={moneyIcon} alt="progress" className="w-16 h-16" />
            </div>
            <div>
              <p className="text-[22px] font-semibold mt-6 mb-2">
                Create a USD account
              </p>
              <p className="text-[#747A80] text-sm mb-12 ">
                Create a USD account to receive USD
              </p>

              <div className="">
                <button
                  onClick={handleSubmit}
                  className={`bg-cleva-gold py-4 px-20 w-[100%] rounded-lg font-semibold shadow-lg ${(KYCState !== "VERIFIED" && KYCState !== "BUSINESS_VERIFIED") ? "cursor-not-allowed opacity-50" : ""
                    }`}
                  disabled={KYCState !== "VERIFIED" && KYCState !== "BUSINESS_VERIFIED"}
                >
                  Create account
                </button>

              </div>
            </div>
          </div>
        </div>
      }
      {/* {loading && <Loader />} */}

      {showSuccessModal && <SuccessModal text="Account created successfully" btnText="View Account" secText="Congratulations! Your USD account has been created." onClose={closeSuccessModal} />}
      <ToastContainer />

    </>
  );
};

export default CreateAccount;
