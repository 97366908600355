import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { setKYCState } from "../../../features/Kyc/kycSlice";
import { useEffect, useState } from "react";
import { getKyc, updateKyc } from "../../../api";
import { fetchController } from "../../../features/Kyc/KycApi";
import Spinner from "../../../components/PopUps/Spinner";
import { AWS_CREDS } from "../../../features/services/AmazonService";

function ConfirmKybDetails() {
  const { Controllers } = useAppSelector((state) => state.kycInfo)
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isChecked, setIsChecked] = useState(false)
  const [loader, setLoader] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')

  const handleDashboard = async () => {
    const KYCIdentifier: any = localStorage.getItem("KYCIdentifier");
    try {
      const response = await getKyc(KYCIdentifier);
      const responseData = response?.data?.BusinessKyc
      setLoader(true);

      if (responseData) {
        responseData.DisclosuresAccepted = true
        const response = await updateKyc(KYCIdentifier, { BusinessKyc: responseData });
        if (response?.data?.KYCState === "BUSINESS_DOCUMENT_IN_REVIEW") {
          dispatch(setKYCState(response?.data?.KYCState))
          navigate("/kyb-pending");
          setLoader(false);
        }
      } else {
        if(AWS_CREDS.STAGE === 'qa'){
          console.log('No KYC data found')
        }
      }

    } catch (error) {
      if(AWS_CREDS.STAGE === 'qa'){
        console.log(error);
      }
   
    }
  };

  const goBack = () => {
    navigate('/')
  }

  const handleCheckbox = () => {
    setIsChecked(!isChecked)
  }

  useEffect(() => {
    dispatch(fetchController())
  }, [])

  useEffect(() => {
    Controllers.map((person: any) => {
      setFirstName(person?.Controller?.FirstName)
      setLastName(person?.Controller?.LastName)
    })
  }, [Controllers])

  if (!Controllers) {
    return <Spinner />
  }
  return (
    <div className="pt-9 xl:p-16">
      <div className="sm:w-[75%] mx-auto xl:w-[60%] p-4 md:p-7 shadow-lg  rounded-lg bg-[#FFFFFF] border-[#D0D0D0] border">
        <div className="">

          <h2 className="text-[black] text-center font-extrabold pt-4 text-2xl">
            Account information disclosure
          </h2>

          <section className="p-4">
            <p className="py-5  text-[#484848] ">
              By clicking “Agree & Submit” I <b>{firstName} {lastName} </b> certify, to the best of my knowledge, that the information provided above is complete and correct.

              <br />

              <br />
              You consent to comply with laws against money laundering and terrorism financing. Additionally, if requested by Cleva, you agree to supply further information or documentation relating to your transactions, account, or both.
            </p>

            <div className="flex items-center gap-1 my-5">
              <input onChange={handleCheckbox} checked={isChecked} type="checkbox" name="" id="" />
              <p className="text-[#404040] text-base">I have read the terms and accept</p>
            </div>

            <div className="w-full grid gap-2 items-center justify-center mt-4">
              <button
                disabled={!isChecked || loader}
                onClick={handleDashboard}
                className={`p-3 font-bold bg-[#FFBD59] w-[200px] sm:w-[250px] lg:w-[350px] rounded-lg ${!isChecked || loader ? 'opacity-50' : 'opacity-100'}`}
              >
                {loader ? "Submitting..." : "Submit KYB"}
              </button>
              <button
                onClick={goBack}
                className={`p-3 border border-[#ADADADB2] font-bold w-[200px] sm:w-[250px] lg:w-[350px] rounded-lg`}
              >
                Back
              </button>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default ConfirmKybDetails;
