import progressIcon from "../../images/checkmark.svg";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import BackButton from "../../components/Buttons/BackButton";
import { useNavigate } from "react-router-dom";
import {
  setReceiveAmount,
} from "../../features/Transanctions/TransanctionSlice";
import { AppDispatch } from "../../app/store";
import { useAppSelector } from "../../app/hooks";
import { setTagTransaction } from "../../features/Tags/TagsSlice";
import { useState } from "react";
import { TagRecipient } from "../../api";

const CompleteTransfer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [recipientAdded, setRecipientAdded] = useState(false);
  const [btnLoading, setButtonLoader] = useState(false);

  const { recipients } = useAppSelector((state) => state.recipient)

  const {tagTransaction} = useAppSelector((state) => state.tags);

  const reverseRecipients = recipients ?? []
  const getRecipients = [...reverseRecipients].reverse()
  const alreadyAdded = getRecipients.filter((info: any) => info?.NickName===tagTransaction?.TagItem?.Tag).length > 0 ? true : false;
  const AcctName = tagTransaction?.TagItem?.FullName?.FirstName + " " + tagTransaction?.TagItem?.FullName?.MiddleName + " " + tagTransaction?.TagItem?.FullName?.LastName;
  
  const goBack = () => {
    dispatch(setTagTransaction(null));
    dispatch(setReceiveAmount(0));
    navigate("/transfers");
  };
  const saveRecipient = async (e: any) => {
    setButtonLoader(true);
    e.preventDefault();
    // console.log("saving...:",tagTransaction?.TagItem)
    try {
        await TagRecipient({ActionType:"ADD_RECIPIENT",...tagTransaction?.TagItem});
        setRecipientAdded(true);
        console.log("saved")
        setButtonLoader(false)
    } catch (error: any) {
      setButtonLoader(false)
      console.log(error)
    }
  }


  return (
    <>
      <div className="flex items-center pt-6">
          <BackButton />
          <p className="text-lg font-bold ml-3">Make Transfer</p>
      </div>

      <div className="flex justify-center items-center h-[75vh]">
        <div className="login-card  w-full md:w-[32rem] xl:w-[32rem] px-6 py-10 md:px-10 md:py-16 text-center">
          <div className="flex justify-center">
            <img src={progressIcon} alt="progress" className="w-16 h-16" />
          </div>
          <div>
            <p className="text-base font-medium mt-8 mb-4 text-[#138D24]">
              Transfer successful
            </p>
            <p className="text-[#535353] mb-8 ">
              Your transferred{" "}
              <span className="text-[#242424] font-medium">
                ${tagTransaction?.Amount?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>{" "}
              to {AcctName} (@{tagTransaction?.TagItem?.Tag})
            </p>

            <div className="mt-12">
            <button
                  onClick={(e)=>{
                   navigate("/transfers")
                  }}
                className="bg-cleva-gold border border-cleva-gold font-small rounded-lg w-full py-2 max-w-[200px]"
              >
                View Transfers
              </button>
            </div>
            {!alreadyAdded &&
            <div className="mt-6">
            <button
                  onClick={(e)=>{
                    !recipientAdded && saveRecipient(e)
                  }}
                  className={`bg-white border font-small rounded-lg w-full py-2  max-w-[200px] 
                  ${btnLoading ? ("border-[#111111] text-[#111111] opacity-[0.6]"):''} 
                  ${!btnLoading ? (recipientAdded ? "border-[#138D24] text-[#138D24]" : "border-[#000000] text-[#000000]"):null} `}>
                  {!btnLoading ? (recipientAdded ? 'Recipient saved':'Save recipient'):null}
                  {btnLoading ? 'saving':null}
            </button>
            </div>}

          </div>
        </div>
      </div>
    </>
  );
};

export default CompleteTransfer;
