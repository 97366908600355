import React from "react";
import { RootState } from "../../../app/store";
import { useSelector } from "react-redux";

interface DetailProps {
  title: string;
}
const RecipientCard: React.FC<DetailProps> = ({ title }) => {
  const RecipientFirstName = useSelector(
    (state: RootState) => state.transaction.RecipientFirstName
  );
  const RecipientLastName = useSelector(
    (state: RootState) => state.transaction.RecipientLastName
  );

  const RecipientTag = useSelector(
    (state: RootState) => state.transaction.RecipientTag
  );
  const AcctName = RecipientFirstName + " " + RecipientLastName;

  return (
    <>
      <div className="bg-[#FCFCFC] border border-[#F1F1F1] md:px-4 py-3 px-2 rounded-xl mb-3">
        <p className="font-medium">{title}</p>
        <div className="flex justify-between my-3">
          <p className="text-sm text-[#747A80]">Account name</p>
          <p className="text-sm font-medium">{AcctName}</p>
        </div>
        <div className="flex justify-between my-3">
          <p className="text-sm text-[#747A80]">Cleva Tag</p>
          <p className="text-sm font-medium">@{RecipientTag}</p>
        </div>
      </div>
    </>
  );
};

export default RecipientCard;
