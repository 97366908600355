import { XMarkIcon } from "@heroicons/react/24/outline";
import { verifiedIcon } from "../../../../../Image";
import { MdKeyboardArrowRight } from "react-icons/md";

const VerifiedBusiness = ({handleClose, proceed}:any) => {

    return (
        <div className="modal-container">
            <div className="modal p-8 w-[90%] m-[0 auto]">
                <button
                    onClick={handleClose}
                    type="button"
                    className="rounded-md hidden text-black float-right justify-end hover:text-gray-500 focus:outline-none focus:ring-2 font-bold"
                >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                </button>
                <div className="flex items-center justify-center">
                    <img src={verifiedIcon} alt="" />

                </div>
                <>
                    <h1 className="font-semibold text-lg md:text-xl text-[#111111] my-4">Business details verified successfully!</h1>
                </>

                <div className="flex justify-center">
                    <button onClick={proceed} className="border border-[#FFBD59] bg-[#FFBD59] rounded-lg py-3 pl-6 pr-4 text-sm text-[#111111] font-bold flex items-center justify-center gap-2">
                        Continue
                        <span className="text-lg">
                        <MdKeyboardArrowRight />
                        </span>
                    </button>
                </div>

            </div>
        </div>
    )
}

export default VerifiedBusiness