import Input from '../../components/Layout/inputs/Input'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/Layout/buttons/Button'
import {  useAppSelector } from '../../app/hooks'
import { AWS_CREDS } from '../../features/services/AmazonService'

export default function EditProfile() {
  const navigate = useNavigate()

  const user = useAppSelector((state) => state.user.userData)

  const handleChange = (event: any) => {
    const update: any = {
      ...user,
      [event.target.name]: event.target.value,
    }
    // dispatch(setUser(update))
  }

  const handleFullNameChange = (event: any) => {
    const update: any = {
      ...user,
      FullName: {
        [event.target.name]: event.target.value,
      },
    }
    // dispatch(setUser(update))
  }

  const handleStandardAttributesChange = (event: any) => {
    const update: any = {
      ...user,
      StandardAttributes: {
        [event.target.name]: event.target.value,
      },
    }
    //dispatch(setUser(update))
  }

  const handleClose = () => {
    if(AWS_CREDS.STAGE === 'qa'){
      console.log("Button was clicked")
    }
    navigate('/profile')
  }
  const handleSubmit = () => {
    if(AWS_CREDS.STAGE === 'qa'){
      console.log("Button was clicked")
    }
    navigate('/profile')
  }

  return (
    <div className="w-full md:w-[50%]">
      <header className="text-[16px] pt-6 pb-4">View Personal Information</header>
      <form>
        <div className=" grid grid-cols-2 gap-4">
          <Input
            title="First Name"
            text={''}
            type="text"
            fn={handleFullNameChange}
            err={``}
            value={user?.FullName?.FirstName}
          />

          <Input
            title="Last Name"
            text={''}
            type="text"
            fn={handleFullNameChange}
            err={``}
            value={user?.FullName?.LastName}
          />
        </div>
        <Input
          title="Email Address"
          text={''}
          type="email"
          fn={handleStandardAttributesChange}
          err={``}
          value={user?.StandardAttributes?.Email}
        />
        <Input title="Business Name" text={''} type="text" fn={handleChange} err={``} value={user?.BusinessName} />
        <Input
          title="Phone Number"
          text={''}
          type="tel"
          fn={handleStandardAttributesChange}
          err={``}
          value={user?.StandardAttributes?.PhoneNumber}
        />
      </form>
      <div className='flex justify-between'>
        <Button
          status={false}
          fn={handleClose}
          text="Cancel"
          styles={`
          text-[11px]
          font-bold
          bg-[#FFBD59]
          w-28 p-3
          
          rounded-md mt-4
        `}
        />
        <Button
          status={false}
          fn={handleSubmit}
          text="Save"
          styles={`
          text-[11px]
          font-bold
          bg-[#FFBD59]
          w-28 p-3
          
          rounded-md mt-4
        `}
        />
      </div>

    </div>
  )
}