import Global  from  "../../asset/cards/global.svg";
import Enhanced from "../../asset/cards/enhanced.svg";
import Quick  from  "../../asset/cards/quick.svg";

const clevaData = [
    {
        img: Global,
        header: 'Global Acceptance',
        content: 'Our card is widely accepted at millions of merchants worldwide, giving you the freedom to shop and transact wherever you go.'
    },
    {
        img: Enhanced,
        header: 'Enhanced Security',
        content: 'We help safeguard your financial information and protect against unauthorized use.'
    },
    {
        img: Quick,
        header: 'Quick and convenient',
        content: 'Our card simplifies your financial transactions, offering a seamless payment experience.'
    }
]



export {clevaData}