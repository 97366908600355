import React, { useEffect } from "react";
import { useAppDispatch } from "../../app/hooks";
import { setTitle } from "../../features/Nav/NavSlice";

export default function Transactions() {

  const dispatch = useAppDispatch();
  
  useEffect(() => {
    dispatch(setTitle("Transactions"));
  }, [dispatch]);
  
  return (
    <div>
    </div>
  );
}
