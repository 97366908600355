import logo from "../../asset/landing_photos/cleva-logo.svg";
import { Link } from "react-router-dom";
import Twitter from "../../asset/landing_photos/twitter.svg";
import Linkedin from "../../asset/landing_photos/linkedin.svg"
import Instagram from "../../asset/landing_photos/instagram.svg";
import Medium from "../../asset/medium.svg";

const Footer = () => {
  const headerStyle = "font-semibold text-[1.25rem] pb-[.5rem]"
  const contentStyle = "text-[1rem] font-normal"
  const hoverStyle = "transition duration-500 hover:text-cleva-gold cursor-pointer"
  const YEAR = new Date().getFullYear();
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }
  return (
    <section className="bg-[#000000] text-white">
      <div className="py-[3.06rem] w-[80%] mx-auto 2xl:w-[1280px]">
        <div className="flex flex-col xl:flex-row gap-[3.3rem] xl:gap-[7.44rem] pb-[4rem]">

          <button onClick={scrollToTop} className="flex ">
            <img className="" src={logo} alt="Cleva Logo" />
          </button>

          <div className="flex flex-col lg:flex-row gap-[3.19rem] lg:gap-x-[4.56rem]">
            <div className="">
              <h1 className={`${headerStyle}`}>Locations:</h1>
              <ul className="grid gap-[0.5rem]">
                <li className={`${contentStyle}`}>651 N Broad Street, Suite 201, Middletown, Delaware, 19709, U.S.A</li>
                <li className={`${contentStyle}`}>9450 Southwest Gemini Drive, Beaverton, OR, 97008, U.S.A</li>
                <li className={`${contentStyle}`}>4, Ayanboye Street, Anthony Village, Lagos, Nigeria</li>
              </ul>
            </div>
            {/* socials */}
            <div className="">
              <h1 className={`${headerStyle}`}>Socials:</h1>
              <ul className="flex items-center flex-row gap-[.5rem]">
                <Link className={`${contentStyle} ${hoverStyle} `} to="https://twitter.com/clevabanking" target="_blank"><img  className="w-6 md:w-12" src={Twitter} alt="Cleva Banking Twitter Page" /></Link>
                <Link className={`${contentStyle} ${hoverStyle} `} to="https://www.linkedin.com/company/cleva-banking/about/" target="_blank"><img  className="w-6 md:w-12" src={Linkedin} alt="Cleva Banking Linkedin Page" /></Link>
                <Link className={`${contentStyle} ${hoverStyle} `} to="https://www.instagram.com/cleva_banking/" target="_blank"><img  className="w-6 md:w-12" src={Instagram} alt="Cleva Banking Instagram Page" /></Link>
                <Link className={`${contentStyle} ${hoverStyle} `} to="https://medium.com/@clevabanking" target="_blank"><img  className="w-6 md:w-12" src={Medium} alt="Cleva Banking Blog " /></Link>
              </ul>
            </div>
            {/* mail */}
            <div className="">
              <h1 className={`${headerStyle}`}>Contact us:</h1>
              <ul>
                <a className={`${contentStyle} ${hoverStyle}`} href="mailto:contact@getcleva.com">contact@getcleva.com</a>
              </ul>
            </div>
            {/* terms of service */}
            <div className="md:w-48">
              <h1 className={`${headerStyle}`}>Legal:</h1>
              <ul className="grid gap-[0.5rem]">
                <Link onClick={scrollToTop} to="/terms-of-service" className={`text-xs font-normal underline uppercase cursor-pointer ${hoverStyle}`}>Terms of Service</Link>
                <Link onClick={scrollToTop} to="/privacy-policy" className={`${hoverStyle} text-xs font-normal underline uppercase cursor-pointer`}>Privacy Policy</Link>
                <Link onClick={scrollToTop} to="/referral-terms" className={`${hoverStyle} text-xs font-normal underline uppercase cursor-pointer`}>Referrals Policy </Link>
              </ul>
            </div>
          </div>
        </div>
        <div className="border-t border-gray-700 pt-[2.37rem] text-center text-[#979797]">
          <p className="font-normal text-[0.875rem] pb-[2.31rem]">© {YEAR} Cleva Technologies, Inc. All rights reserved</p>
          <p className="font-normal text-[0.875rem]">Cleva (a.k.a Cleva Banking) is a financial technology company, not a bank. All banking services are provided by our licensed banking partners.</p>
        </div>
      </div>
    </section>
  );
};

export default Footer;
