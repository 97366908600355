import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { postAccount, fetchAccountById, fetchAccount } from "./VirtualAccountApi";

interface AccountState {
  FirstName : string;
  LastName : string;
  email: string;
  loading: boolean;
  error: string | null;
  singleAccount: IVirtualAccount |null
  allAccount: IVirtualAccount | null

}

export interface IVirtualAccount {
  BankName:                 string;
  AccountName:              string;
  AccountNumber:            string;
  RoutingNumber:            string;
  AccountType:              string;
  VirtualAccountIdentifier: string;
  Description:              string;
  Balance:                  Balance;
  Currency:                 string;
  Country:                  string;
  Address:                  string;
  VirtualAccountState:      string;
  LastUpdatedTime:          number;
  Date:                     number;
  Error:                    string | null;
}

export interface Balance {
  Time:     number;
  Money:    number;
  Currency: string;
}

const initialState: AccountState = {
FirstName: "",
LastName:"",
email: "",
loading: false,
  error: null,
  singleAccount: null,
  allAccount: null
};

const virtualAccountSlice = createSlice({
  name: "Virtual account",
  initialState,
  reducers: {
    setFirstName: (state, action: PayloadAction<string>) => {
      state.FirstName = action.payload
    },
    setLastName: (state, action: PayloadAction<string>) => {
      state.LastName = action.payload
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postAccount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postAccount.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(postAccount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(fetchAccount.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchAccount.fulfilled, (state, action) => {
        state.loading = false
        state.allAccount = action.payload
      })
      .addCase(fetchAccount.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload as string
      })


       // fetch single account
       .addCase(fetchAccountById.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchAccountById.fulfilled, (state, action) => {
        state.loading = false
        state.singleAccount = action.payload
      })
      .addCase(fetchAccountById.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload as string
      })

    }
});

export const {
  setFirstName,
  setLastName,
  setLoading
}= virtualAccountSlice.actions

export default virtualAccountSlice.reducer;
