// useIdleTimer.js
import { useEffect } from 'react';
import { AuthService } from '../services/AuthServices'
import { useNavigate } from "react-router-dom";
import { removeAuthTokens } from "../../login";
import { resetKYBState, setCompletedSteps, setStep } from "../Kyc/kycSlice";
import { setUser } from "../User/UserSlice";
import { useAppDispatch } from "../../app/hooks";
import { resetBvnVerified } from '../ClevaCards/ClevaCardsSlice';


export default function UseIdleTimer(timeoutDuration: number) {
    const navigate = useNavigate()
    const dispatch = useAppDispatch();


    useEffect(() => {
        let idleTime: any;
        const resetTimer = () => {
            clearTimeout(idleTime);
            idleTime = setTimeout(logout, timeoutDuration);
        };

        const logout = async () => {
            dispatch(resetKYBState())
            removeAuthTokens()
            AuthService.signOut()
            dispatch(setCompletedSteps([]))
            dispatch(setStep(1));
            dispatch(setUser(null))
            dispatch(resetBvnVerified())
            navigate('/')
        };

        document.addEventListener('mousemove', resetTimer);
        document.addEventListener('mousedown', resetTimer);
        document.addEventListener('touchstart', resetTimer);
        document.addEventListener('click', resetTimer);
        document.addEventListener('scroll', resetTimer);
        document.addEventListener('keypress', resetTimer);

        return () => {
            // Cleanupz
            document.removeEventListener('mousemove', resetTimer);
            document.removeEventListener('mousedown', resetTimer);
            document.removeEventListener('touchstart', resetTimer);
            document.removeEventListener('click', resetTimer);
            document.removeEventListener('scroll', resetTimer);
            document.removeEventListener('keypress', resetTimer);
        };
    }, [timeoutDuration]);
}
