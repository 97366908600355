// ProfileLayout.js

import React from 'react';
import { Outlet } from 'react-router-dom';
import ProfileNav from './ProfileNav';

const ProfileLayout = () => {
 

  return ( 
    <div className="md:flex">
      {/* Sidebar */}
     <ProfileNav/>
 
      {/* Main content */}
      <main className="md:w-4/5 md:px-4 xl:px-12">
      <Outlet />

        </main>
    </div>
  );
};

export default ProfileLayout;
