import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../../../app/hooks';
import { getUser, getUserIdWithAccessToken } from '../../../../login';
import { Auth } from 'aws-amplify';
import { VerifyOTP } from '../../../../api';
import { setUser } from '../../../../features/User/UserSlice';
import { ToastContainer, toast } from 'react-toastify';
import emailIcon from '../../../../asset/images/email.svg'
import errorImg from "../../../../asset/svg/material-symbols_warning.svg";
import OtpField from 'react-otp-field';
import { AWS_CREDS } from '../../../../features/services/AmazonService';
import { sendOTP } from './Index';
import SuccessfulPin from '../../../Kyc/pages/KYC/Modals/SuccessfulPin';
import AuthServices from '../../../../features/services/AuthServices';
import { formatTime } from '../../../../components/Auth/AwaitingEmailVerification';

const UpdatePinEntry = () => {
  const [otp, setOtp] = useState<string>("");
  const [formValid, setFormValid] = useState<boolean>(false);
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const AppDispatch = useAppDispatch();
  const user = localStorage.getItem("storageUser")
  const userObject = JSON.parse(user!)
  const [resendEmail, setResendEmail] = useState<boolean>(false)
  const [countdown, setCountdown] = useState(300);
  const [error, setError] = useState(false);
  const [serverErr, setServerErr] = useState(false);
  const [formattedTime, setFormattedTime] = useState(formatTime(countdown));

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    const userID = await getUserIdWithAccessToken()
    const session = await Auth.currentSession()
    try {
      const response = await VerifyOTP(userID, otp)
      console.log({response})
      if (response) {
        const userDetails = await getUser(userID, session.getIdToken().getJwtToken());
        AppDispatch(setUser(userDetails))
        setSuccess(true)
        setLoading(false)
      }
    } catch (err: any) {
      setError(true);
      if (AWS_CREDS.STAGE === 'qa') {
        console.log(err)
      }
      setLoading(false);
      //toast.error(err.message);
    }
  };

  useEffect(() => {
    if (otp) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [otp]);

  const proceed = () => {
    navigate('/profile/security/update-pin');
    setSuccess(false)
  }

  const handleResendEmail = async () => {
    try {
      setResendEmail(false)
      setCountdown(300)
      sendOTP()
      toast.success('Email sent successfully')
    } catch (error: any) {
      if (AWS_CREDS.STAGE === 'qa') {
        console.log(error.message)
      }
      toast.error('Error. Try again later')
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (countdown <= 0) {
        clearInterval(interval);
        setResendEmail(true)
        return;
      }
      setCountdown(countdown - 1);
      setFormattedTime(formatTime(countdown - 1));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [countdown]);

  return (
    <>
      <div className="relative flex-1 bg-[#FBFBFB]">
        <div className="flex justify-center pt-8 min-h-[100vh] md:items-center md:pt-0">
          <div className="login-card w-full lg:w-[32rem] xl:w-[36rem] py-8 px-8 md:py-16 md:px-20">
            <div className="text-center ">
              <div className="flex justify-center">
                <img src={emailIcon} alt="email" />
              </div>

              <h2 className="text-2xl font-bold text-[#111111] mt-6">
                Confirm it’s you
              </h2>
              <p className="text-[#5F5D5D] md:w-[20rem] mx-auto text-sm mt-4">
                Please enter the 6 digit code sent to your email address{" "}
                <span className="text-[#935B06]">{userObject?.StandardAttributes?.Email}</span>
              </p>
              {/* form section  */}
              <form onSubmit={handleSubmit} className=" mt-8">
                <div className="md:w-[20rem] mx-auto">
                  <OtpField
                    value={otp}
                    onChange={(e: any) => { setOtp(e); setError(false); setServerErr(false) }}
                    numInputs={6}
                    onChangeRegex={/^([0-9]{0,})$/}
                    autoFocus
                    separator={<span> </span>}
                    isTypeNumber={false}
                    inputProps={{
                      className: `otp-field__input ${error ? 'error-box' : ''}`,
                      disabled: false,
                      pattern: "[0-9]*",
                      inputMode: "numeric"
                    }}
                  />
                </div>

                {error &&
                  <div
                    className="w-full px-5 flex font-[500] mb-8 mt-4
                        rounded-lg
                        justify-center text-sm items-center h-[3.75rem] bg-[#FFEAED] ">
                    <img src={errorImg} className="" alt="error" />
                    <span className="text-[#800205] pl-2  font-normal">
                      OTP code not valid.
                    </span>
                  </div>}

                {serverErr &&
                  <div
                    className="w-full px-5 flex font-[500] mb-8 mt-4
                        rounded-lg
                        justify-center text-sm items-center h-[3.75rem] bg-[#FFEAED] ">
                    <img src={errorImg} className="" alt="error" />
                    <span className="text-[#800205] pl-2  font-normal">
                      Error verifying OTP. Try again later.
                    </span>
                  </div>}
                <div className="mt-7">
                  <button
                    onClick={(e) => handleSubmit(e)}
                    disabled={loading || otp.length < 6 || error}
                    className={
                      (loading || otp.length < 6) ? " login-disabled" : "login-active"
                    }>
                    {loading ? "Loading ..." : "Submit"}
                  </button>
                </div>
                <div className="pt-4">
                  {resendEmail ?
                    <p className="text-center text-[#404040d9] text-base">Didn’t receive an email? <a className='underline cursor-pointer hover:text-black' onClick={handleResendEmail}>Resend</a></p>
                    :
                    <p className="text-center text-[#404040d9] text-base">Didn’t receive an email? <span className="text-black">Resend in {formattedTime}</span> secs</p>
                  }
                </div>


              </form>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>

      {success && <SuccessfulPin proceed={proceed} />}
    </>
  )
}

export default UpdatePinEntry