import {  createSlice } from "@reduxjs/toolkit";
import { fetchReferral } from "./ReferralAPI";

export interface IReferredUsers {
  JoinedDate: string;
  FullName: string;
  BonusCompletionDate: string;
}

interface ReferralData {
  MaximumEarnableAmount: number;
  NumberOfReferredUsers: number;
  NumberOfUsersEarnedFrom: number;
  NumberOfUsersYouCanStillEarnFrom: number;
  ReferredUsers: IReferredUsers[] | null;
  TotalAmountEarned: number;
}

interface ReferralState {
  loading: boolean;
  error: string | null;
  data: ReferralData;
}

const initialState: ReferralState = {

  loading: false,
  error: null,
  data: 
    {
      MaximumEarnableAmount: 300,
      NumberOfReferredUsers: 0,
      NumberOfUsersEarnedFrom: 0,
      NumberOfUsersYouCanStillEarnFrom: 100,
      ReferredUsers: [],
      TotalAmountEarned: 0,    
    },

  
};

const referralSlice = createSlice({
  name: "referral",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReferral.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchReferral.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchReferral.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error as string;
      });
  },
});

//export const { setReferralStat } = referralStatSlice.actions;

export default referralSlice.reducer;
