import React, { useEffect, useState } from "react";
import BackButton from "../../../components/Buttons/BackButton";
import CurrencyInput from "../../../components/Layout/CurrencyInput";
import Input from "../../../components/Layout/inputs/Input";
import TransferFlag from "../../../components/TransferFlag";
import ViewModal from "./modals/ViewModal";
import USIcon from "../../../images/USD.svg";
import NGIcon from "../../../images/ngn.svg";
import FeeIcon from "../../../images/fee-icon.svg";
import { useSelector, useDispatch } from "react-redux";
import { useAppSelector } from "../../../app/hooks";
import errIcon from "../../../images/error-icon.svg";
import Spinner from "../../../components/PopUps/Spinner";
import axios from "../../../features/services/AxiosInstance";

import { toast, ToastContainer } from "react-toastify";
import {
  fetchRecipients,
  fetchRates,
  createTransaction,
} from "../../../features/Transanctions/transactionApi";
import { fetchAccount } from "../../../features/VirtualAccount/VirtualAccountApi";
import { setModalState } from "../../../features/Kyc/kycSlice";

import {
  setSenderFirstName,
  setSenderLastName,
  setRecipientFirstName,
  setRecipientLastName,
  setSendAmount,
  setFee,
  setTotalAmount,
  setReceiveAmount,
  setDescription,
  setLoading,
  setBankName,
  setRecipientIdentifier,
  setExchangeRate,
  setAccountNumber,
  setAccountIdentifier,
} from "../../../features/Transanctions/TransanctionSlice";
import { RootState, AppDispatch } from "../../../app/store";
import { Link, useNavigate } from "react-router-dom";
import AddRecipient from "./modals/AddRecipients";
import ValidateUserPin from "../../Security/modals/ValidateUserPin";
import ForgotPin from "../../Security/modals/ForgotPin";
import NewPin from "../../Security/modals/NewPin";
import UpdatedPin from "../../Security/modals/UpdatedPin";
import VerifyUserOTP from "../../Security/modals/ExceededPin";
import { ResetPin } from "../../../api";
import { getUserIdWithAccessToken } from "../../../login";
import PinLoader from "../../Security/modals/PinLoader";
import { getLimitResetHour } from "../../../utils/FormatDateString";
import { FaCommentsDollar } from "react-icons/fa";
import Announcement from "../../../components/Announcement/Announcement";

const delayedDescription: string =
  "Our banking provider is experiencing a downtime that is impacting transfers and causing them to be delayed. We are actively working with them to fix it. Please kindly postpone any transfers and try again in about 6 hours. Thanks a lot for your patience.";

const CreateLocalTransfer = () => {
  const allAccount = useAppSelector((state) => state.virtualAccount.allAccount);

  const accountNumber = allAccount ? (allAccount as any).AccountNumber : " ";
  const accountIdentifier = allAccount
    ? (allAccount as any).VirtualAccountIdentifier
    : " ";

  const getLastFourDigits = (number: string) => number.slice(-4);
  const getMaskedAccountNumber = (number: string | undefined) =>
    number ? " **** " + getLastFourDigits(number) : "";
  const accBalance = allAccount ? (allAccount as any).Balance?.Money : " ";

  let user = useSelector((state: RootState) => state.user.userData);

  if (!user) {
    user = JSON.parse(localStorage.getItem("storageUser")!);
  }
  let userPayoutAmountUsed = user?.PayoutLimit?.daily.amountUsed ?? 0;
  let userDailyPayoutLimit =
    user?.PayoutLimit?.currentTier.daily_limit_value ?? 0;
  let dailyRemainingPayoutBal = Number(
    (userDailyPayoutLimit - userPayoutAmountUsed).toFixed(2)
  );

  const [modal, setModal] = useState(false);
  // const [sendAmount, setAmountInput] = useState("");
  const [openTransactionPin, setTransactionPin] = useState(false);
  const [openForgotPin, setForgotPin] = useState(false);
  const [openUpdatedPin, setUpdatedPin] = useState(false);
  const [openNewPin, setNewPin] = useState(false);
  const [openOTP, setOTP] = useState(false);
  const navigate = useNavigate();
  const { allRecipients, rates } = useSelector(
    (state: RootState) => state.transaction
  );

  let reversedRecipients = allRecipients.slice().reverse();

  const SenderFirstName = user?.FullName.FirstName || "";
  const SenderLastName = user?.FullName.LastName || "";

  const transactionData = useSelector((state: RootState) => state.transaction);

  const receiveAmount = useSelector(
    (state: RootState) => state.transaction.receiveAmount
  );
  const sendAmount = useSelector(
    (state: RootState) => state.transaction.sendAmount
  );

  const fee = useSelector((state: RootState) => state.transaction.fee);
  const loading = useSelector((state: RootState) => state.transaction.loading);

  const description = useSelector(
    (state: RootState) => state.transaction.description
  );
  const RecipientFirstName = useSelector(
    (state: RootState) => state.transaction.RecipientFirstName
  );
  const RecipientLastName = useSelector(
    (state: RootState) => state.transaction.RecipientLastName
  );
  // const RecipientIdentifier = useSelector(
  //   (state: RootState) => state.transaction.RecipientIdentifier
  // );

  const [recipientName, setRecipientName] = useState(
    `${RecipientFirstName} ${RecipientLastName}`
  );

  const exchangeRate = useSelector(
    (state: RootState) => state.transaction.exchangeRate
  );

  const [amtError, setAmtError] = useState("");
  const [verifying, setVerifying] = useState(false);
  const [recipientError, setRecipientError] = useState("");
  const [receiveAmountError, setReceiveAmountError] = useState("");

  const [BtnError, setBtnError] = useState(false);
  const { modalState } = useAppSelector((state) => state.kycInfo);

  // const loading = useSelector((state: RootState) => state.transaction.loading);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (loading) {
      dispatch(setSendAmount(0));
    }
    if (rates) {
      const priceString = (rates as any)?.Fee;
      if (priceString) {
        const price = parseFloat(priceString.replace("$", ""));
        dispatch(setFee(price));
        dispatch(setExchangeRate((rates as any).ToCurrencyRate));
        setLoading(false);
      }
    }
  }, [rates, dispatch]);

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectValue: string = e.target.value;
    setRecipientName(selectValue);

    if (!selectValue) {
      setRecipientError("Please select a recipient");
      setBtnError(true);
    } else {
      setRecipientError("");
      setBtnError(false);
    }

    const selectedRecipient = allRecipients.find(
      (recipient: any) => recipient.RecipientIdentifier === selectValue
    );

    if (selectedRecipient) {
      dispatch(setBankName((selectedRecipient as any).BankName));
      dispatch(
        setRecipientIdentifier((selectedRecipient as any).RecipientIdentifier)
      );
      dispatch(setAccountNumber((selectedRecipient as any).AccountNumber));
      dispatch(setSenderFirstName(SenderFirstName));
      dispatch(setSenderLastName(SenderLastName));
      dispatch(setAccountIdentifier(accountIdentifier));
    }

    // const [selectedFirstName, selectedLastName] = selectValue.split(" ");
    dispatch(
      setRecipientFirstName((selectedRecipient as any).FullName.FirstName)
    );
    dispatch(
      setRecipientLastName((selectedRecipient as any).FullName.LastName)
    );
  };
  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Number((userDailyPayoutLimit-userPayoutAmountUsed).toFixed(2));
    const value = e.target.value.replace(/,/g, ""); // Remove existing commas

    const amountFloat = parseFloat(value);
    dispatch(setSendAmount(value));

    const parsedValue = parseFloat(value);
    const convertedValue = isNaN(parsedValue) ? 0 : parsedValue * exchangeRate;
    const myRate = parseFloat(convertedValue.toFixed(2));

    dispatch(setTotalAmount());
    dispatch(setReceiveAmount(myRate));

    let inputAmount = Number(value) + Number(fee);

    if (isNaN(inputAmount) || !Number.isFinite(inputAmount)) {
      setAmtError("Please enter a valid amount");
      setBtnError(true);
      return;
    }
    if (myRate < 100) {
      setReceiveAmountError("Minimum payout amount should be 100 NGN");
      setBtnError(true);
    } else {
      setReceiveAmountError("");
      setBtnError(false);
    }

    if (inputAmount > dailyRemainingPayoutBal) {
      setAmtError("The amount you entered is above your daily limit");
      setBtnError(true);
      return;
    } else {
      setAmtError("");
      setBtnError(false);
    }

    if (accBalance < inputAmount) {
      setAmtError("Insufficient account balance");
      setBtnError(true);
      return;
    } else {
      setAmtError("");
      setBtnError(false);
    }
    if (!value) {
      setAmtError("Please enter amount");
    }

    if (amountFloat <= 0) {
      setAmtError("Please enter a valid amount");
      setBtnError(true);
      return;
    }

    if (!accBalance) {
      setAmtError("Please create an account");
      return;
    }

    const decimalRegex = /^\d+(\.\d{0,2})?$/;
    if (!decimalRegex.test(value)) {
      setAmtError("Please enter a valid amount of maximum two decimal places");
      setBtnError(true);
      return;
    } else {
      setAmtError("");
      setBtnError(false);
    }

    setAmtError("");
  };

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/,/g, "");
    const parsedValue = parseFloat(value);
    const newValue = isNaN(parsedValue) ? 0 : parsedValue;
    // const mySendAmount = newValue
    dispatch(setSendAmount(newValue));
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setDescription(e.target.value));
  };

  function toggleModal() {
    const amountFloat = parseFloat(sendAmount);

    if (!sendAmount) {
      setAmtError("Please enter amount");
    }

    if (amountFloat <= 0) {
      setAmtError("Please enter a valid amount");
    }

    if (!recipientName || recipientName.trim() === "") {
      setRecipientError("Please select a recipient");
      setBtnError(true);
    } else {
      setRecipientError("");
    }

    if (!accBalance) {
      setAmtError("Please create an account");
    }
    // Check if there are any errors
    if (
      recipientError ||
      amtError ||
      !recipientName ||
      recipientName.trim() === "" ||
      !sendAmount ||
      !accBalance
    ) {
      return;
    }
    setModal(true);
    setTransactionPin(false);
  }

  const closeModal = () => {
    setModal(false);
    setModalState(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
  };

  const VerifyTransaction = async () => {
    try {
      setLoading(true);
      setVerifying(true);

      const response = await dispatch(createTransaction(transactionData));

      setLoading(false);
      setVerifying(false);

      if (response?.payload) {
        navigate("/transfers/view");
      } else {
        toast.error(
          "We could not process your transfer at this time. Please try again later"
        );
      }
    } catch (error: any) {
      setLoading(false);
      setVerifying(false);
      toast.error(error.message);
      delete axios.defaults.headers.common["Otptoken"];
    }
  };

  const sendOTP = async () => {
    const userID = await getUserIdWithAccessToken();
    try {
      await ResetPin(userID);
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  // This should be useEffect hook watching for change in user Limit for the day

  let progress, rawProgress;
  let [minAmount, maxAmount] = [0.1, userDailyPayoutLimit];
  if (userPayoutAmountUsed === minAmount) {
    progress = 1;
  } else {
    rawProgress = (userPayoutAmountUsed - minAmount) / (maxAmount - minAmount);
    progress = Math.min(Math.max(rawProgress, 0), 1) * 100;
  }

  useEffect(() => {
    dispatch(fetchRecipients());
    dispatch(fetchRates());
    dispatch(fetchAccount());
  }, [dispatch]);

  const navigateMobile = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const amountFloat = parseFloat(sendAmount);

    if (!sendAmount) {
      setAmtError("Please enter amount");
    }

    if (amountFloat <= 0) {
      setAmtError("Please enter a valid amount");
    }
    if (!recipientName || recipientName.trim() === "") {
      setRecipientError("Please select a recipient");
      setBtnError(true);
    } else {
      setRecipientError("");
    }

    if (!accBalance) {
      setAmtError("Please create an account");
    }
    // Check if there are any errors
    if (
      recipientError ||
      amtError ||
      !recipientName ||
      recipientName.trim() === "" ||
      !sendAmount ||
      !accBalance
    ) {
      return;
    }
    navigate("/transfers/review-transfer");
  };

  return (
    <>
      <div className="md:w-[38rem] mx-auto mt-8 md:mb-20 pb-8 mb-12 bg-white p-4 md:p-12">
        <div className="relative rounded-lg border border-[#F4E8BF] bg-[#FFF9E3] p-3  mb-[2.34rem]">
          <div className="xl:w-[80%] mx-auto flex justify-between items-center">
            <p className="text-black font-normal  text-sm">
              Daily transfer limit:
            </p>
            <p className="font-semibold text-sm flex gap-1 text-[#2E2100]">
              {/* user limit */}
              <span className="">${userPayoutAmountUsed.toLocaleString()}</span>
              /
              <span className="text-[#047014]">
                ${userDailyPayoutLimit.toLocaleString()}
              </span>
            </p>
          </div>
          <section className="relative xl:w-[80%] mx-auto ">
            <div className="overflow-hidden h-[0.5rem] mb-3 mt-2 text-xs flex rounded-lg bg-[#D9D9D9]">
              <div
                style={{ width: `${progress}%` }}
                className="shadow-none flex flex-col text-center whitespace-nowrap text-[#3E3E3E]
                   justify-center  bg-[#FFBD59] relative"
              >
                {/* flex */}
                <span className="hidden absolute inset-y-0 m-auto left-[40px] right-0  items-center justify-center">{`$${userPayoutAmountUsed}`}</span>
              </div>
            </div>
            <div className="text-sm">
              <p className="text-[#747A80] font-normal">
                Resets at <span> {getLimitResetHour()}</span> your time
              </p>
            </div>
          </section>
        </div>

        {/*===========================================
         delay transfer flag commented out incase needed another time
         ============================================== */}

        <Announcement Service="Transfer" />

        <div className="flex justify-between mb-6">
          <div>
            <p className="text-lg font-bold">International Transfer</p>
          </div>
          <div>
            <TransferFlag />
          </div>
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <div>
            <label className="text-sm pb-1 text-left">
              Recipient <span className="text-[#D31D1D] font-semibold">*</span>
            </label>
            <select
              onChange={handleSelectChange}
              className={`bg-[#F9F9F9] w-full rounded-md text-[14px] border-1 h-12 outline-none border text-[#424242] focus:outline-none focus:ring-cleva-gold focus:border-cleva-gold`}
              name=""
              id=""
              value={recipientName}
            >
              <option value="">Select Recipient</option>

              {reversedRecipients
                .filter((info: any) => info.RecipientType === "BANK")
                .map((recipient: any) => (
                  <option
                    key={recipient.RecipientIdentifier}
                    value={`${recipient.RecipientIdentifier}`}
                  >
                    {`${recipient.FullName.FirstName} ${recipient.FullName.LastName} - (${recipient.BankName})`}
                  </option>
                ))}
            </select>

            <div className="flex justify-between mt-1">
              {recipientError ? (
                <div className="flex items-center mb-2 mt-1">
                  <img src={errIcon} alt="error" />
                  <p className="text-[#D31D1D] text-sm ml-1">
                    {recipientError}
                  </p>
                </div>
              ) : (
                <div></div>
              )}
              <button
                onClick={() => dispatch(setModalState(!modalState))}
                className="text-[#446DC3D9]  text-sm"
              >
                Add new recipient
              </button>
            </div>

            <div className="mt-4">
              <label className="text-sm pb-1 text-left">
                Pay from <span className="text-[#D31D1D] font-semibold">*</span>
              </label>
              <div className="input-control">
                <div className="flex justify-between">
                  <p className="font-medium text-sm">
                    Cleva USD Account
                    {getMaskedAccountNumber(accountNumber)}
                  </p>
                  <p className="text-[#747A80]">
                    $
                    {accBalance?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </div>
              </div>
            </div>

            <CurrencyInput
              title="You will send"
              value={sendAmount?.toLocaleString()}
              fn={handleAmountChange}
              onBlur={handleBlur}
              type="text"
              err={amtError ? amtError : ""}
              placeholder="0.00"
              code="USD"
              flag={USIcon}
            />
            <p className="font-bold text-base mb-1">
              1 USD = {exchangeRate} NGN
            </p>
            <CurrencyInput
              title="Recipient will get"
              value={receiveAmount?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
              fn={handleChange}
              type="text"
              err=""
              readOnly={true}
              code="NGN"
              flag={NGIcon}
            />
            {receiveAmountError && (
              <div className="flex items-center mb-2 mt-1">
                <img src={errIcon} alt="error" />
                <p className="text-[#D31D1D] text-sm ml-1">
                  {receiveAmountError}
                </p>
              </div>
            )}
            <div className="flex items-center my-1">
              <div className="flex items-center">
                <img src={FeeIcon} alt="fee" />
                <p className="text-xs text-[#747A80] mr-4 ml-2">Transfer fee</p>
              </div>
              <p className="text-xs font-bold">{fee.toFixed(2)} USD </p>
            </div>
            <Input
              title="Description"
              value={description}
              fn={handleDescriptionChange}
              type="text"
              err=""
            />
            <div className="flex items-center justify-between gap-4 my-6">
              <div>
                <Link
                  to="/transfers"
                  className="bg-cancel text-sm font-bold py-3 md:px-10 px-6 rounded-lg"
                >
                  Cancel
                </Link>
              </div>
              <div>
                <button
                  onClick={() => toggleModal()}
                  className={`bg-cleva-gold text-sm font-bold py-3 md:px-10 px-6 rounded-lg hidden md:block`}
                  disabled={
                    !sendAmount ||
                    !recipientName ||
                    !!BtnError ||
                    !accBalance ||
                    !!amtError ||
                    !!receiveAmountError
                  }
                  style={{
                    opacity:
                      !sendAmount ||
                      !recipientName ||
                      BtnError ||
                      !accBalance ||
                      !!amtError ||
                      !!receiveAmountError
                        ? 0.6
                        : 1,
                  }}
                >
                  Continue
                </button>
                {/* ====================
              mobile button for review transfer
              ================== */}
                <button
                  onClick={() => navigateMobile()}
                  className={`bg-cleva-gold text-sm font-bold py-3 md:px-10 px-6 rounded-lg md:hidden`}
                  disabled={
                    !sendAmount ||
                    !recipientName ||
                    !!BtnError ||
                    !accBalance ||
                    !!amtError ||
                    !!receiveAmountError
                  }
                  style={{
                    opacity:
                      !sendAmount ||
                      !recipientName ||
                      BtnError ||
                      !accBalance ||
                      !!amtError ||
                      !!receiveAmountError
                        ? 0.6
                        : 1,
                  }}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        )}

        <ToastContainer />
      </div>

      {modalState && <AddRecipient closeModal={closeModal} />}

      {modal && (
        <ViewModal
          closeModal={closeModal}
          openTransactionPin={() => {
            closeModal();
            setTransactionPin(true);
          }}
        />
      )}

      {!verifying && openTransactionPin && (
        <ValidateUserPin
          VerifyTransaction={VerifyTransaction}
          openForgotPinModal={() => {
            setTransactionPin(false);
            setForgotPin(true);
            sendOTP();
          }}
          sendOTP={() => {
            setTransactionPin(false);
            setOTP(true);
            sendOTP();
          }}
          closeModal={() => setTransactionPin(false)}
        />
      )}

      {openForgotPin && (
        <ForgotPin
          openNewPinModal={() => {
            setForgotPin(false);
            setNewPin(true);
          }}
          closeModal={() => setForgotPin(false)}
        />
      )}

      {openOTP && (
        <VerifyUserOTP
          openNewPinModal={() => {
            setOTP(false);
            setNewPin(true);
          }}
          closeModal={() => setOTP(false)}
        />
      )}

      {openNewPin && (
        <NewPin
          openUpdatedPinModal={() => {
            setNewPin(false);
            setUpdatedPin(true);
          }}
          closeModal={() => setNewPin(false)}
        />
      )}

      {openUpdatedPin && <UpdatedPin closeModal={() => setUpdatedPin(false)} />}

      {verifying && <PinLoader text="Initiating transfer..." />}

      <ToastContainer />
    </>
  );
};

export default CreateLocalTransfer;
