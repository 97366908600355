import React from "react";
import  { TableColumn } from "react-data-table-component";

type DataItem = {
  CreatedAt: string;
  SenderName: string;
  Description: string;
  TransactionType: string;
  FromAmount: string;
  TransactionState: string;
  id: string;
};

const formatAmount = (amount: any) => {
  const parsedAmount = parseFloat(amount); // Parse the amount as a floating-point number
  const formattedAmount = parsedAmount.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }); // Format the amount with two decimal places and comma as a thousands separator

  return formattedAmount;
};

export const IncomingTransferColumn: TableColumn<DataItem>[] = [
  {
    name: "Date",
    selector: (row) => row.CreatedAt,
    cell: (row) => <span className="">{`${row.CreatedAt.slice(0, 10)}`}</span>,
    sortable: true,
    reorder: true,
    omit: false,
  },
  {
    name: "Description",
    selector: (row) => row.Description,
    cell: (row) => (
      <span className="font-medium">
        {(row.TransactionType === "MAKE_PAYMENT_REFUND" ||  row.TransactionType === "TAG_DEBIT_REVERSAL")
          ? "Transfer Refunded"
          : row.TransactionType === "CARD_TERMINATION_REFUND"
          ? "Card Termination Refund"
          : row.TransactionType === "CARD_CREATION_REFUND"
          ? "Card Creation Refund"
          : row.TransactionType === "CARD_FUNDING_REFUND"
          ? "Card Funding Refund"
          : row.TransactionType === "TAG_CREDIT"
          ? `From ${row?.SenderName}`
          : row.TransactionState === "REVERSED"
          ? "Reversal from Sender"
          : row.TransactionState === "REVERSAL_REFUNDED"
          ? "Reversal refund from you"
          : row.TransactionType === 'USD_CREDIT' && row.SenderName !== ""
          ? `From ${row?.SenderName}`
          : `Money Received`}
      </span>
    ),
    sortable: true,
    reorder: true,
    omit: false,
    // width:"220px",
  },
  {
    name: "Amount",
    selector: (row) => row.FromAmount,
    cell: (row) => (
      <div className="">
        <span className="font-medium text-[13px]">
          {row.TransactionState === "REVERSED"
            ? `-$${formatAmount(Math.abs(Number(row.FromAmount)))}`
            : Number(row.FromAmount) < 0
            ? ` -$${formatAmount(Math.abs(Number(row.FromAmount)))}`
            : `$${formatAmount(row.FromAmount)}`}
        </span>
      </div>
    ),
    sortable: true,
    center: false,
    reorder: true,
    // width: '140px',
    omit: false,
  },
  {
    name: "Status",
    selector: (row) => row.TransactionState,
    cell: (row) =>
      row.TransactionState === "COMPLETED" ? (
        <div className="py-1">
          <span className="px-3 py-1 my-2 rounded-lg capitalize bg-[#DEF7EC] text-[#03543F] font-medium flex items-center text-[12px]">
            <span>Completed</span>
          </span>
        </div>
      ) : row.TransactionState === "KYC_PENDING" ? (
        <div className="py-1 flex flex-col md:flex-row items-center">
          <button className="px-2 md:px-3 py-1 my-2 rounded-lg capitalize bg-[#DFDFDF] text-[#2C2C2C] font-medium flex items-center text-[10px] md:text-[12px]">
            Pending
          </button>
          <p className="text-[#FA624D] text-[11px] md:text-[13px] ml-2">
            KYC Required
          </p>
        </div>
      ) : row.TransactionState === "DAILY_LIMIT_EXCEEDED" ? (
        <div className="py-1 flex flex-col md:flex-row items-center">
          <span className="px-2 md:px-3 py-1 my-2 rounded-lg capitalize bg-[#DFDFDF] text-[#2C2C2C] font-medium flex items-center text-[12px]">
            <span>Pending </span>
          </span>
          <span className="text-[#FA624D] text-[11px] md:text-[13px]  ml-2">
            Limit Exceeded
          </span>
        </div>
      ) : row.TransactionState === "RETURNED" ? (
        <div className="py-1 flex items-center">
          <span className="px-3 py-1 my-2 rounded-lg capitalize bg-[#FDE8E8] text-[#9B1C1C] font-medium flex items-center text-[12px]">
            <span>Returned </span>
          </span>
        </div>
      ) : row.TransactionState === "REJECTED" ? (
        <div className="py-1 flex items-center">
          <span className="px-3 py-1 my-2 rounded-lg capitalize bg-[#FDE8E8] text-[#9B1C1C] font-medium flex items-center text-[12px]">
            <span>Rejected </span>
          </span>
        </div>
      ) : row.TransactionState === "REFUNDED" ? (
        <div className="py-1 flex items-center">
          <span className="px-3 py-1 my-2 rounded-lg capitalize bg-[#F0E9DF] text-[#B8730A] font-medium flex items-center text-[12px]">
            <span>Refunded</span>
          </span>
        </div>
      ) : row.TransactionState === "REVERSAL_REFUNDED" ? (
        <div className="py-1 flex items-center">
          <span className="px-3 py-1 my-2 rounded-lg capitalize bg-[#F0E9DF] text-[#B8730A] font-medium flex items-center text-[12px]">
            <span>Reversal Refund</span>
          </span>
        </div>
      ) : row.TransactionState === "REVERSED" ? (
        <div className="py-1 flex items-center">
          <span className="flex items-center px-3 py-1 my-2 font-medium capitalize bg-[#FDE8E8] rounded-lg text-[#9B1C1C] text-[12px]">
            <span>Reversed</span>
          </span>
        </div>
      ) : row.TransactionState === "IN_REVIEW" ? (
        <div className="py-1 flex items-center">
          <span className="flex items-center px-3 py-1 my-2 font-medium capitalize bg-[#E9E9E9] rounded-lg text-[#111111] text-[12px]">
            <span>Processing</span>
          </span>
        </div>
      ) : row.TransactionState === "UNDER_REVIEW" ? (
        <div className="py-1 flex items-center">
          <span className="flex items-center px-3 py-1 my-2 font-medium capitalize bg-[#FDE8E8] rounded-lg text-[#9B1C1C] text-[12px]">
            <span>Under Review</span>
          </span>
        </div>
      ) : (
        <div className="py-1">
          <span className="flex items-center px-3 py-1 my-2 font-medium capitalize bg-[#FDE8E8] rounded-lg text-[#9B1C1C] text-[12px]">
            <span>{row.TransactionState}</span>
          </span>
        </div>
      ),
    sortable: true,
    reorder: true,
    // width: '130px',
    omit: false,
  },
];
