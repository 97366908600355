import { globalcard, cardmain } from '../../../asset/landing_photos';
import WebLayout from '../components/WebLayout'
import ProductHeader from '../components/ProductHeader'
import { useNavigate } from 'react-router-dom'
import ProductBenefit from '../components/ProductBenefit';
import ProductCTA from '../components/ProductCTA';
import { getStartedCard } from '../data';

const Entry = () => {
    const navigate = useNavigate()
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    return (
        <WebLayout>
            <>
                <div>
                    <ProductHeader
                        title="Shop globally, anywhere, anytime"
                        subtitle="The Cleva USD Virtual Card is your bridge to the global economy"
                        btnCta="Get USD card"
                        img={cardmain}
                        imgsub={cardmain}
                        btnAction={() => navigate("/auth/register")}
                    />
                </div>
                <div>
                    <ProductBenefit 
                  title="Meet your favorite"
                  colored="USD card"
                  benefit1="Start spending globally"
                  benefit1text="Shop on your favorite online stores without worrying about currency conversions. The Cleva USD Card lets you pay in USD seamlessly."
                  benefit1img={globalcard}
                  b2="Instant Card Issuance"
                  b2text="Get your virtual USD card instantly upon creation, eliminating the wait time associated with physical card issuance."
                  b3="Enhanced Security"
                  b3text="Benefit from advanced security features such as transaction alerts and secure encryption."
                    />
                </div>
                <div>
                    <ProductCTA
                    dataArr={getStartedCard}
                    cta="Register now and instantly get your USD card."
                    />
                </div>
            </>

        </WebLayout>
    )
}

export default Entry