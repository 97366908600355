export const RegisterCheckboxData = [
    { value: "Friend", label: "A friend told me about Cleva" },
    { value: "Twitter", label: "From Twitter" },
    { value: "Linkedin", label: "From Linkedin" },
    { value: 'Google Search', label: 'From Google Search' },
    { value: 'Youtube', label: 'From Youtube' },
    { value: 'Tiktok', label: ' From TikTok' },
    { value: 'Meta(Instagram or Facebook)', label: 'From Meta (Instagram or Facebook)' },
    { value: 'Event', label: 'From an event I attended' },
  ];
