import CheckList from "../../asset/landing_photos/gg_check.svg"

interface IProductInfo {
    text?: string,
}

const ProductInfo = (props: IProductInfo) => {
    return (
        <section className="">
            <div className="flex gap-[.5rem]">
                <img src={CheckList} alt={CheckList} />
                <p className="text-[#060606] text-base font-normal">{props.text}</p>
            </div>
        </section>
    )
}

export default ProductInfo;