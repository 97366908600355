import { MdRefresh } from "react-icons/md";
import { kycError } from "../../../../../Image"
import { XMarkIcon } from "@heroicons/react/24/outline";

interface IModal {
    text: string;
    title: string;
    handleClose: () => void
    actionBtn: string
    displayRetry: boolean
    retryCount?: string
}

const BusinessModal = (props: IModal) => {
    const { text, title, handleClose, actionBtn, displayRetry, retryCount } = props
    return (
        <div className="modal-container">
            <div className="modal">
                <button
                    onClick={handleClose}
                    type="button"
                    className="rounded-md text-black float-right justify-end hover:text-gray-500 focus:outline-none focus:ring-2 font-bold"
                >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                </button>
                <div className="flex items-center justify-center">
                    <img src={kycError} alt="" />

                </div>
                <>
                    <h1 className="font-semibold text-lg md:text-xl text-[#111111] mt-8">{title}</h1>
                    <p className="text-[#111111] text-sm font-medium py-4">{text}</p>
                </>

                <div className="">
                    <button onClick={handleClose} className="border border-[#FFBD59] bg-[#FFBD59] rounded-lg py-3 px-12 text-sm text-[#111111] font-bold">{actionBtn}</button>
                </div>

                {displayRetry &&
                    <p className="w-full mt-4 text-base text-[#0A7E39] flex   justify-center items-center">
                        <MdRefresh size={18} /> &nbsp; {retryCount || 0} retry left
                    </p>
                }
            </div>
        </div>
    )
}

export default BusinessModal