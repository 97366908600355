import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import USIcon from "../../../images/USD.svg";
import { useAppSelector } from "../../../app/hooks";

import {
  XMarkIcon,
} from "@heroicons/react/24/outline";
import SuccessModal from "../../../components/PopUps/SuccessModal";
interface ModalProps {
  formData: any;
  closeModal: () => void;
  openTransactionPin:  () => void;

}
const TopupModal: React.FC<ModalProps> = ({ formData, closeModal,openTransactionPin  }) => {
  const [open, setOpen] = useState(true);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const cancelButtonRef = useRef(null);

  const allAccount = useAppSelector((state) => state.virtualAccount.allAccount);

  const accountNumber = allAccount ? (allAccount as any).AccountNumber : " ";

  const getLastFourDigits = (number: string) => number.slice(-4);
  const getMaskedAccountNumber = (number: string | undefined) =>
    number ? " **** " + getLastFourDigits(number) : "";

  const totalAmount =
    parseFloat(formData.sendAmount) + parseFloat(formData.fee);

  // const handleSubmit = async () => {
  //   setOpen(false)
  //   setLoading(true);
  //   try {
  //     // await onSubmit(formData);
   
  //     setShowSuccessModal(true);
  //   } catch (error) {
  //     // Handle any errors
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const closeSuccessModal = () => {
    navigate("/cards/card");
    setShowSuccessModal(false);
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-xl bg-white pb-8 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-[25rem]  w-[24rem]">
                  <header
                    className={`w-full text-left bg-[#EFEFEF] py-4 px-8  flex justify-between`}
                  >
                    <Dialog.Title
                      as="h3"
                      className="text-base font-bold leading-6 text-gray-900"
                    >
                      Review Details
                    </Dialog.Title>
                    <button
                      type="button"
                      className="rounded-md text-black hover:text-gray-500 focus:outline-none focus:ring-2 font-bold"
                      onClick={closeModal}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                    </button>
                  </header>

                  <div className="">
                    <div className="mt-3">
                      <div className="px-6 md:px-8">
                        <div className="flex justify-center items-center py-6">
                          <div>
                            <img src={USIcon} alt="dollar" />
                          </div>
                          <p className="text-sm font-bold ml-2">Top up USD card</p>
                        </div>

                        <div className="mb-4">
                          <div className="md:flex justify-between my-4">
                            <p className="text-sm text-[#747A80]">
                              Top up Amount
                            </p>
                            <p className="text-base font-semibold">
                              $ {parseFloat(formData.sendAmount).toFixed(2)}
                            </p>
                          </div>
                          <div className="md:flex justify-between my-4">
                            <p className="text-sm text-[#747A80]">Fee</p>
                            <p className="text-base font-semibold">
                              $ {parseFloat(formData.fee).toFixed(2)}
                            </p>
                          </div>
                          <div className="md:flex justify-between my-4">
                            <p className="text-sm text-[#747A80]">
                              Total Amount to debit
                            </p>
                            <p className="text-base font-semibold">
                              ${" "}
                              {totalAmount.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </p>
                          </div>
                          <div className="md:flex justify-between mt-3">
                            <p className="text-sm text-[#747A80]">Source</p>
                            <div className="text-base font-semibold flex">
                              {/* <img src={nairaIcon} alt="" srcSet="" className="mr-[1px]" /> */}

                              <p className=" text-base font-semibold">
                                Cleva USD Account
                                {getMaskedAccountNumber(accountNumber)}
                              </p>
                            </div>
                          </div>
                        </div>

                        <footer>
                          <div className=" pt-4">
                            <button
                              type="submit"
                              className="text-sm 
                            font-bold py-3 md:px-10 px-6
                            w-[100%]
                            rounded-md mt-4 
                            bg-[#FFBD59]"
                             onClick={(e)=>{
                                e.preventDefault();
                                openTransactionPin();
                            }}
                              // ref={}
                            >
                              {loading ? "Loading ..." : "Confirm"}
                            </button>
                          </div>
                        </footer>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {showSuccessModal && (
        <SuccessModal
          btnText="Done"
          text={`You’ve added $
    ${totalAmount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })} to your Cleva USD Card`}
          onClose={closeSuccessModal}
        />
      )}
    </>
  );
};

export default TopupModal;
