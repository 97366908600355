import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import RecipientCard from "../../../../components/Layout/RecipientCard";
import DetailsCard from "../../../../components/Layout/DetailsCard";
import { RootState } from "../../../../app/store";
import { useSelector } from "react-redux";

import { XMarkIcon } from "@heroicons/react/24/outline";

type ModalAProps = {
  closeModal: () => void;
  openTransactionPin: () => void;
};

const ViewModal: React.FC<ModalAProps> = ({
  closeModal,
  openTransactionPin,
}) => {
  const cancelButtonRef = useRef(null);

  const sendAmount = useSelector(
    (state: RootState) => state.transaction.sendAmount
  );
  const description = useSelector(
    (state: RootState) => state.transaction.description
  );
  const fee = useSelector((state: RootState) => state.transaction.fee);
  
  const RecipientFirstName = useSelector(
    (state: RootState) => state.transaction.RecipientFirstName
  );
  const RecipientLastName = useSelector(
    (state: RootState) => state.transaction.RecipientLastName
  );

  const allAccount = useSelector(
    (state: RootState) => state.virtualAccount.allAccount
  );

  const parseNumber = (value: string): number => {
    const stringValue = String(value);
    const parsedValue = parseFloat(stringValue.replace(/[^0-9.-]+/g, ""));
    return isNaN(parsedValue) ? 0 : parsedValue;
  };

  const [loading, setLoading] = useState(false);

  const parsedAmount = parseNumber(sendAmount);
  const parsedFee = fee;

  const totalAmount = parsedAmount + parsedFee;

  const accountNumber = allAccount ? (allAccount as any).AccountNumber : " ";

  const getLastFourDigits = (number: string) => number.slice(-4);
  const getMaskedAccountNumber = (number: string | undefined) =>
    number ? " **** " + getLastFourDigits(number) : "";

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={closeModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex md:min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white pb-8 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-lg  w-[30rem]">
                <header
                  className={`w-full text-left bg-[#EFEFEF] py-4 px-8  flex justify-between`}
                >
                  <Dialog.Title
                    as="h3"
                    className="text-base font-bold leading-6 text-gray-900"
                  >
                    Review Transfer
                  </Dialog.Title>
                  <button
                    type="button"
                    className="rounded-md text-black hover:text-gray-500 focus:outline-none focus:ring-2 font-bold"
                    onClick={closeModal}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                  </button>
                </header>

                <div className="">
                  <div className="mt-3">
                    <div className="max-[600px]:h-[50vh] overflow-y-scroll px-4">
                      <div className="text-center pt-8 pb-10">
                        <p className="text-lg">
                          Transfer{" "}
                          <span className="font-bold text-xl">
                            $
                            {totalAmount?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </span>{" "}
                          to{" "}
                          <span className="text-cleva-gold">{`${RecipientFirstName} ${RecipientLastName}`}</span>
                        </p>

                        <p className="text-xs italic mt-3">{description}</p>
                      </div>

                      <RecipientCard title="Recipient Details" />
                      <div className="border-dashed border-t border-[#BDBDBD] my-3"></div>
                      <DetailsCard
                        title="Transaction Details"
                        pay={`Cleva USD Account ${getMaskedAccountNumber(
                          accountNumber
                        )}`}
                      />
                    </div>
                  </div>
                </div>
                <footer>
                  <div className="px-4 flex justify-between pt-4">
                    <button
                      type="button"
                      className="text-sm 
                  font-bold py-3 md:px-10 px-6 
                  rounded-md mt-4 
                  bg-[#FFF5D9]"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      className={`text-[12px]
                      font-bold py-[10px] px-[8%]
                      float-right
                      rounded-md mt-4
                      bg-[#FFBD59]
                       ${loading ? "opacity-50 cursor-not-allowed" : ""}`}
                      onClick={(e) => {
                        e.preventDefault();
                        openTransactionPin();
                      }}
                      disabled={loading}

                      // ref={}
                    >
                      {loading ? "Loading ..." : "Confirm Details"}
                    </button>
                  </div>
                </footer>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ViewModal;
