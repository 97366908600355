/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import Nav from './Nav'
import SideBar from './SideBar'
import { Outlet, useNavigate } from 'react-router-dom'
import { getReturningUser, removeAuthTokens } from '../../login'
import { useEffect, useState } from 'react'
import { setUser } from '../../features/User/UserSlice'
import { useAppDispatch } from '../../app/hooks'
import UseIdleTimer from "../../features/SessionExpiry/IdleTimer";
import { setPayoutDailyLimit } from "../../features/Transanctions/transactionApi"
import { getCurrentDate } from "../../utils/getCurrentDate"
import { AWS_CREDS } from "../../features/services/AmazonService"


const FIVE_MINS_IN_MILLISECONDS = 300000

export default function MainLayout() {
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false);

  const navigate = useNavigate()
  const AppDispatch = useAppDispatch()

  UseIdleTimer(FIVE_MINS_IN_MILLISECONDS);


  useEffect(() => {
    getReturningUser()
      .then((user: any) => {
        if (!user?.PayoutLimit || (user.PayoutLimit.daily.currentDay !== getCurrentDate()) ){
         return setPayoutDailyLimit(user).then(user=>AppDispatch(setUser(user)))
         
        }
        AppDispatch(setUser(user))
      })
      .catch((err) => {
        if(AWS_CREDS.STAGE === 'qa'){
     
          console.log(err)
        }
        removeAuthTokens()
        AppDispatch(setUser(null))
        navigate('/auth/login')
      })
  }, [])


  const toggleSidebar = () => { setSidebarIsOpen(!sidebarIsOpen) }

  const closeSidebar = () => {
    if (sidebarIsOpen) setSidebarIsOpen(false)
  }

  const opaqueBg = "max-[768px]:bg-gray-100 max-[768px]:opacity-50 transition-opacity duration-300 ease-in delay-300"


  return (
    <div className="w-12/12 overflow-hidden">
      <section className="flex justify-between m-auto h-screen">
        {/* sideBar */}
        <>
          <SideBar handleClick={toggleSidebar} isOpen={sidebarIsOpen} />
        </>
        {/* Nav With Content */}

        <div id="NavWithContent" onClick={closeSidebar} className={`w-[100%] overflow-y-auto ${sidebarIsOpen ? opaqueBg : ''}`}>
          {/* navBar - w-[90%] min-[2000px]:w-[1440px] m-auto `*/}
          <div id="navwithcontent" className="h-full">
            <div className="bg-[#fff]  py-5 shadow-sm">
            <div  className="responsive-layout " id={`navBar ${isScrolled ? 'bg-blue-500' : 'bg-transparent'}`}>
              <Nav isOpen={sidebarIsOpen} handleClick={toggleSidebar} />
            </div>
            </div>
            {/* main content -  max-h-[90vh] - overflow-y-scroll - */}
            <div className=" bg-[#F6f6f6] min-h-[100vh] lg:pb-6 pb-20 scrollBarSettings" id="content">
              <div className="responsive-layout">
              <Outlet />
              </div>
            </div>
          </div>
          {/* Footer */}
          {/* <div id="footer">
            <Footer />
          </div> */}
        </div>

      </section>
    </div>
  )
}
