import React, { useEffect, useState } from "react";
import failedIcon from "../../../images/failed-trans.svg";
import PendingIcon from "../../../images/pending-trans.svg";
import completeIcon from "../../../images/complete-trans.svg";
import RefundIcon from "../../../images/refund-payout.svg";

import { Link } from "react-router-dom";
import {
  fetchCardTransactions,
} from "../../../features/ClevaCards/ClevaCardsApi";
import { setCardIdentifier } from '../../../features/ClevaCards/ClevaCardsSlice'
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import ViewCardTransfer from "../../Transfers/M2/modals/ViewCardTransfer";
import { convertTime } from "./AccountUtils";

const RecentCardTransaction: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const allTransfer = useSelector(
    (state: RootState) => state.clevaCards.cardTransaction);
  const cards = useSelector((state: RootState) => state.clevaCards.cards);
  const cardIdentifier = useSelector((state: RootState) => state.clevaCards.cardIdentifier);
  const [modal, setModal] = useState(false);
  const [selectedTransfer, setSelectedTransfer] = useState<any>(null);

  const OutgoingModal = (transferId: string) => {
    const transfer = (allTransfer as any)?.find(
      (transfer: any) => transfer?.TransactionID === transferId
    );
    setSelectedTransfer(transfer);
    setModal(true);

  };


  const closeModal = () => {
    setSelectedTransfer(null);
  };

  let recentTransaction: any[] = [];

  if (allTransfer) {
    const sortedTransactions = [...(allTransfer as any)].sort(
      (a, b) => new Date(b.CreatedAt).getTime() - new Date(a.CreatedAt).getTime()
    );
    //recentTransaction = sortedTransactions.slice(0, 6);
    recentTransaction = sortedTransactions
    .filter((transaction) => transaction.Amount != 0)
    .slice(0, 6);
  }

  // useEffect(() => {
  //   //dispatch(fetchCard());
  // }, [dispatch]);

  useEffect(() => {
    // Check if cards and CardIdentifier are available

    if (cards && (cards as any)?.length > 0){
      const activeCard = (cards as any).find((card:any) => ["ACTIVE", "PENDING", "FROZEN"].includes(card.Status));
      if (activeCard) {
        const { CardIdentifier } = activeCard;
        dispatch(setCardIdentifier(CardIdentifier));
        dispatch(fetchCardTransactions(CardIdentifier));
      }
    }

  }, [dispatch, cardIdentifier, cards]);

 

  return (
    <>
      <div className="bg-white shadow-lg rounded-xl p-3 md:py-8 md:px-8 xl:py-10 xl:px-12 mt-2">
        {(allTransfer as any)?.length < 1 ? (
          <div className="flex items-center justify-center gap-4">
            <p className="text-[#979797]">No Recent Transactions</p>
          </div>
        ) : (

          <div>
            <div className="">
              <div className="justify-between items-center gap-[9px] flex">
                <div className="grow h-[0px] border border-neutral-200"></div>
                <div className="grow-0 text-neutral-700 text-[16px] font-medium leading-tight">
                  Recent Transactions
                </div>
                <div className="grow h-[0px] border border-neutral-200"></div>
              </div>
            </div>

            <div className="md:w-2/3 mx-auto">
              <div className="mt-3">
                {recentTransaction?.map((transfer: any, index: number) => (
                  <div
                    data-test="card-tnx"
                    className={`md:gap-8 gap-6 flex justify-between py-3 border-y border-[#CFDBD5] hover:cursor-pointer ${index === 0 ? "border-t-0" : ""
                      } ${index === transfer.length - 1
                        ? "border-y-0"
                        : "border-t-0"
                      }`}
                    key={transfer.TransactionID}
                    onClick={() => OutgoingModal(transfer.TransactionID)}
                  >
                    <div className="justify-center items-center gap-[16px] flex">
                      <div className="">
                        {transfer?.Type === "FUNDING" ?
                          <img src={PendingIcon} alt="Card credit" />
                          :
                          transfer?.Type === "REFUND" || transfer?.Type === "REVERSAL"
                            ?
                            <img src={RefundIcon} alt="Card credit" />
                            :
                            transfer?.Type === "WITHDRAWAL" || transfer?.Type === "AUTHORIZATION"
                              ?
                              <img src={completeIcon} alt="Card Debit" />
                              : 
                                <img src={failedIcon} alt="Failed Transaction" />
                              }

                      </div>
                      <div className="">
                        <p className="text-[#111111] text-sm font-medium leading-none mb-1">
                          {transfer?.Type === "FUNDING" ? " Card funding" : (transfer?.Type === "REVERSAL" || transfer?.Type === "DECLINE" || transfer?.Type === "REFUND" || transfer?.Type === "AUTHORIZATION" ) ? `${transfer?.MerchantName}` : transfer?.Type === "WITHDRAWAL" ? 'Withdrawal' : transfer?.Type === "CARD_TERMINATION" ? 'Card Termination' : transfer?.Type === "DECLINE" ? 'Declined' : transfer?.Description}
                        </p>
                        <p className="text-[#747A80] text-sm font-normal leading-tight">
                          {convertTime(new Date(transfer?.CreatedAt))}
                        </p>
                      </div>
                    </div>
                    <div className="">
                      {transfer?.Amount  
                      
                      ?
                      <p className="text-right text-black text-[14px] font-semibold leading-tight mb-1">
                      {transfer?.Entry === "CREDIT"
                        ? `+$${transfer.NetAmount?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}`
                        : `-$${transfer.Amount?.toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}`}
                    </p>
                    :
                    <p className="text-right text-black text-[14px] font-semibold leading-tight mb-1">
                    {transfer?.Entry === "CREDIT"
                      ? `$${transfer.Amount?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}`
                      : `$${transfer.Amount?.toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}`}
                  </p>
                    }
                      {
                      transfer?.Status === "COMPLETED" && transfer?.Type === "REVERSAL" ?
                      <p className="text-[#B8730A] text-right text-[14px] font-normal leading-tight">
                      Reversed
                    </p>
                    :
                      
                    transfer?.Status === "COMPLETED" && transfer?.Type === "REFUND" ?
                    <p className="text-[#B8730A] text-right text-[14px] font-normal leading-tight">
                    Refunded
                  </p>
                  :
                      transfer?.Status === "COMPLETED" ? (
                        <p className="text-[#278033] text-right text-[14px] font-normal leading-tight">
                          Completed
                        </p>
                      ) : transfer?.Status === "PENDING" ? (
                        <p className="text-[#1892D7] text-right text-[14px] font-normal leading-tight">
                          In Progress
                        </p>
                      ) : transfer?.Status === "FAILED" && transfer?.Type === "DECLINE" ? (
                        <p className="text-[#D31D1D] text-right text-[14px] font-normal leading-tight">
                          Declined
                        </p>
                      ) : 
                      transfer?.Status === "FAILED"  ?
                        <p className="text-[#D31D1D] text-right text-[14px] font-normal leading-tight">
                          Failed
                        </p>
                        :
                      (
                        <p className="text-[#D31D1D] text-right text-[14px] font-normal leading-tight capitalize">
                          {transfer?.Status}
                        </p>
                      )}

                      <div className="flex justify-end">
                        {transfer?.Status === "DAILY_LIMIT_EXCEEDED" ? (
                          <p className="text-[#FA624D] text-right text-[14px] font-normal leading-tight">
                            Limit Exceeded
                          </p>
                        ) : (
                          " "
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                {selectedTransfer && (
                  <>

                    {selectedTransfer && (
                      // Show the Modal for each transaction
                      <ViewCardTransfer
                        props={selectedTransfer}
                        closeModal={closeModal}
                      />
                    )}
                  </>
                )}

                <div className="flex justify-center mt-10">
                  <Link
                    to="/cards/transactions"
                    className="border border-[#0B69AD] text-[#0B69AD] py-3 px-6 rounded-xl"
                  >
                    Show all
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default RecentCardTransaction;
