import  { useState, useEffect, ReactNode } from 'react';

interface ICopyToClipboardAlert {
    isOpen: boolean,
    onClose: () => void;
    children: ReactNode;
    bg: string,
}

const CopyToClipboardAlert = ({ isOpen, onClose, children, bg }: ICopyToClipboardAlert) => {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);

  useEffect(() => {
    setIsModalOpen(isOpen);

    if(isOpen){
      const timer = setTimeout(() => {
        onClose()
      }, 1000)  // 2000 milliseconds = 2 seconds

      return () => {
        clearTimeout(timer);
      };
    }

  
  }, [isOpen, onClose]);

  const closeModal = () => {
    setIsModalOpen(false);
    onClose && onClose();
  };

  return (
    <>
      {isModalOpen && (

        // backdrop-filter bg-black bg-opacity-70
        <div   onClick={closeModal} className="fixed inset-0 z-50 top-[400px] flex items-center justify-center ">
          <div className={`${bg} p-4 rounded-md`}>
            <div className="">{children}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default CopyToClipboardAlert;

