import { DiCssTricks } from "react-icons/di";
import errorImg from '../../../asset/svg/material-symbols_warning.svg';
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { setAdditionalDetails, setBusinesskyc, setKYCState } from "../../../features/Kyc/kycSlice";
import { SaveAndContinue } from "../../../components/Buttons/KycButtons";
import { useEffect, useRef, useState } from "react";
import useGoBack from "../../ClevaCards/utils/handleGoBack";
import InvalidRegistrationModal from "./KYC/Modals/InvalidRegistrationModal";
import MismatchBusiness from "./KYC/Modals/MismatchBusiness";
import FailedBusinessVerification from "./KYC/Modals/FailedBusinessVerification";
import VerifiedBusiness from "./KYC/Modals/VerifiedBusiness";
import { KYCCheckboxData as checkboxes } from '../../../utils';
import { CheckboxComponent } from "../../../components/Auth/Register";
import { businessType, industryType } from "../../../data";
import Loader from "../modals/loader";
import { createKyc } from "../../../api";
import Spinner from "../../../components/PopUps/Spinner";
import { ToastContainer, toast } from "react-toastify";
import MismatchModal from "../modals/mismatchModal";
import BVNInvalidModal from "../modals/BVNInvalidModal";
import InvalidModal from "../modals/invalidModal";
import { AWS_CREDS } from "../../../features/services/AmazonService";
interface ISteps {
  currentStep?: number;
  nextStep?: any;
}

function BusinessDetails(props: ISteps) {
  const [loading, setLoader] = useState(false);
  const { BusinessKyc, KYCState, AdditionalDetails } = useAppSelector((state) => state.kycInfo);
  const user = useAppSelector((state) => state.user)
  const [businessName, setBusinessName] = useState<string>('');
  const [modal, setModal] = useState(false)
  const [bvnModal, setBVNModal] = useState(false)
  const dispatch = useAppDispatch();
  const [invalidRegNumber, setInvalidRegNumber] = useState(false)
  const [invalidPhone, setInvalidPhone] = useState(false)
  const [regNum, setRegNum] = useState('')
  const [mismatchDetails, setMismatchDetails] = useState(false)
  const [showCheckboxError, setShowCheckboxError] = useState(false)
  const [showOtherText, setShowOtherText] = useState<boolean>(false)
  const [isOtherChecked, setIsOtherChecked] = useState<boolean>(false)
  const [otherText, setOtherText] = useState<string>('')
  const [selectedBox, setSelectedBox] = useState<string[]>([])
  const dateInputRef = useRef<HTMLInputElement | null>(null);




  const isButtonDisabled =
    BusinessKyc?.Type === "" ||
    BusinessKyc?.BusinessName === "" ||
    BusinessKyc?.BusinessRegistrationNumber === "" ||
    BusinessKyc?.Industry === "" ||
    BusinessKyc?.DateOfIncorporation === "" ||
    BusinessKyc?.RegisteredAddress?.Country === "" ||
    BusinessKyc?.RegisteredAddress?.City === "" ||
    BusinessKyc?.RegisteredAddress?.StateOrTerritory === "" ||
    BusinessKyc?.RegisteredAddress?.StreetAddress === "" ||
    BusinessKyc?.MonthlyIncome === "" ||
    (BusinessKyc?.FundingSources?.length || 0) < 1;

  

  const handleClose = () => {
    setInvalidRegNumber(false)
    setMismatchDetails(false)
  }

  const handleChange = (event: any) => {
    dispatch(
      setBusinesskyc({
        ...BusinessKyc,
        [event.target.name]: event.target.value.trim(),
      })
    );
  };

  const handleRegNumChange = (event: any) => {
    const num = event.target.value.trim();
    setRegNum(num);


    dispatch(
        setBusinesskyc({
            ...BusinessKyc,
            [event.target.name]: num,
        })
    );
};

  useEffect(() => {
    if (user?.userData?.BusinessName) {
      setBusinessName(user?.userData?.BusinessName)
    }
  }, [user])

  // Handle checkbox value
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowCheckboxError(false)
    const value = e.target.value;
    const id = e.target.id
    const isChecked = e.target.checked;

    setSelectedBox((prevSelected) => {
      const isSelected = prevSelected.includes(value) || prevSelected.includes(id) === isChecked
      if (prevSelected.length !== 2 && value === 'other') {
        setShowOtherText(true)
        setIsOtherChecked(true);
      }

      if (isSelected) {
        if (value === 'other') {
          setShowOtherText(false)
          setIsOtherChecked(false);
          setOtherText("")
        }
        return prevSelected.filter((item) => item !== value && item !== otherText);

      }
      if (prevSelected.length === 2) {
        setShowCheckboxError(true)
        return prevSelected;
      }

      return [...prevSelected, value];
    });

  };

  const handleotherText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOtherText(event.target.value)
    setIsOtherChecked(true);
  }

  const handleContactDetailsChange = (event: any) => {
    const temp = event.target.value
    const ContactDetails: any = {
      ...BusinessKyc?.ContactDetails,
      [event.target.name]: event.target.value,
    };

    const isValid = /^[+\d]*$/.test(temp);
    if (isValid) {
      setInvalidPhone(false)
    } else {
      setInvalidPhone(true)
    }

    dispatch(
      setBusinesskyc({
        ...BusinessKyc,
        ContactDetails,
      })
    );
  };

  const handleAddressChange = (event: any) => {
    const RegisteredAddress: any = {
      ...BusinessKyc?.RegisteredAddress,
      [event.target.name]: event.target.value,
      Country: BusinessKyc.CountryOfIncorporation,
    };
    
    dispatch(
      setBusinesskyc({
        ...BusinessKyc,
        RegisteredAddress,
      })
    );
  };

  useEffect(() => {
    dispatch(setKYCState(""));

    dispatch(
      setBusinesskyc({
        ...BusinessKyc,
        FundingSources: selectedBox,
      })
    );
  }, [selectedBox])

  useEffect(() => {
    dispatch(
      setBusinesskyc({
        ...BusinessKyc,
        BusinessName: businessName,
      })
    );
  }, [businessName])

  useEffect(() => {
    const modifiedSelectedBox = selectedBox.map(item => (item === "other" ? otherText : item));
    setSelectedBox(modifiedSelectedBox);
  }, [otherText])

  const proceed = () => {
    if (props.currentStep) {
      props.nextStep(props?.currentStep + 1);
    }
  }
  const validate = async () => {
    setLoader(true)

    try {
      const response = await createKyc({ BusinessKyc: BusinessKyc });
      if(AWS_CREDS.STAGE === 'qa'){
        console.log(response.data)
      }
      setLoader(false);
      setModal(true);
      if (response?.data?.BusinessKyc) {
        localStorage.setItem("KYCIdentifier", response?.data?.KYCIdentifier);
          dispatch(setKYCState(response?.data?.BusinessKyc.KYCState));
          dispatch(setAdditionalDetails(response?.data?.BusinessKyc.AdditionalDetails));
      }
    } catch (error: any) {
      if(AWS_CREDS.STAGE === 'qa'){
        console.log(error.response)
      }
      setLoader(false);
      if (error?.response?.data?.Code === "NATIONAL_ID_DUPLICATE") {
        setBVNModal(true);
        if(AWS_CREDS.STAGE === 'qa'){
          console.log(error);
        }
      } else {
        toast.error(
          "We're sorry, our service provider is currently down. Please try again in a few hours time."
        );
      }
    }

  };

  const ErrorMismatch: boolean = KYCState === ("BUSINESS_MISMATCH" && AdditionalDetails.NoOfRetriesLeft === 0) || KYCState === "BUSINESS_FAILED";
  const MismatchKYB: boolean =
    KYCState === "BUSINESS_MISMATCH" && AdditionalDetails.NoOfRetriesLeft > 0;

  const handleFocus = (e: any) => {
    if (dateInputRef.current) {
      dateInputRef.current.showPicker();
    }
  };



  if (!user) {
    return <Spinner />
  }

  return (
    <>

      <form className="sm:w-[65%] sm:ml-12">

        <div className="xl:w-[63%] pb-7 pt-8 sm:pt-0">
          <h3 className="font-semibold md:text-2xl text-lg mb-5 ">
            Tell us about your business
          </h3>

          <div className="grid gap-4" >
            {/* field 0 - business type */}
            <div>
              <div className="flex">
                <p className="text-[13px] pb-1 text-black font-normal">
                  Business Type
                </p>
                <p className="text-[6.5px] text-[#D31D1D]">
                  <DiCssTricks />
                </p>
              </div>
              <select className={`text-[13px] text-[#747A80] border mb-1 w-full py-2 pl-2 outline-none rounded-[10px] ${BusinessKyc?.Type === ""
                ? "bg-white"
                : "bg-[#FFF5D9] text-black"
                }`} name="Type" id="Type" value={BusinessKyc?.Type}
                onChange={handleChange} >
                <option value="" disabled>Select Business Type</option>
                {businessType.map((item, index) => (
                  <option key={index} value={item.value}>{item.label}</option>
                ))}
              </select>
            </div>

            {/* Field 1  - business name */}
            <div>
              <div className="flex">
                <p className="text-[13px] pb-1 text-black font-normal">
                  Registered business Name
                </p>
                <p className="text-[6.5px] text-[#D31D1D]">
                  <DiCssTricks />
                </p>
              </div>
              <input
                type="text"
                name="BusinessName"
                id=""
                value={businessName}
                onChange={(e) => setBusinessName(e.target.value)}
                className={`text-[13px] border mb-1 w-full py-2 pl-2 outline-none rounded-[10px] ${BusinessKyc?.BusinessName === "" ? "bg-white" : "bg-[#FFF5D9]"
                  }`}
                placeholder="Business Name"
              />
            </div>

            {/* Field 3 - industry */}
            <div>
              <div className="flex">
                <p className="text-[13px] md:text-[12px] pb-1 text-black font-normal ">
                  Industry
                </p>
                <p className="text-[6.5px] text-[#D31D1D]">
                  <DiCssTricks />
                </p>
              </div>
              <select
                name="Industry"
                id=""
                value={BusinessKyc?.Industry}
                onChange={handleChange}
                className={`text-[13px] text-[#747A80] border mb-1 w-full py-2 pl-2 outline-none rounded-[10px] ${BusinessKyc?.Industry === ""
                  ? "bg-white"
                  : "bg-[#FFF5D9] text-black"
                  }`}
              >
                <option value="" disabled> Select Industry</option>
                {industryType.map((item, index) => (
                  <option key={index} value={item}>{item}</option>
                ))}
              </select>
            </div>

            {/* Field 4 - Country */}
            <div>
              <div className="flex">
                <p className="text-[13px] md:text-[12px] pb-1 text-black font-normal ">
                  Country of business registration
                </p>
                <p className="text-[6.5px] text-[#D31D1D]">
                  <DiCssTricks />
                </p>
              </div>
              <select
                name="CountryOfIncorporation"
                id=""
                value={BusinessKyc?.CountryOfIncorporation}
                onChange={handleChange}
                className={`text-[13px] text-[#747A80] border mb-1 w-full py-2 pl-2 outline-none rounded-[10px] ${BusinessKyc?.CountryOfIncorporation === ""
                  ? "bg-white"
                  : "bg-[#FFF5D9] text-black"
                  }`}
              >
                <option value="Business Address" className="hidden">
                  Select country
                </option>
                <option value="Nigeria" className=" ">
                  Nigeria
                </option>
                {/* <option value="USA" className=" ">
                  United States
                </option>
                <option value="UK" className=" ">
                  UK
                </option> */}
              </select>
            </div>

            {/* field 5 - date */}
            <div>
              <div className="flex">
                <p className="text-[13px] font-normal pb-1 ">Date of registration </p>
                <p className="text-[6.5px] text-[#D31D1D]">
                  <DiCssTricks />
                </p>
              </div>
              <div className="date-input-container">
                <input
                  type="date"
                  name="DateOfIncorporation"
                  id="DateOfIncorporation"
                  ref={dateInputRef}
                  value={BusinessKyc?.DateOfIncorporation}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  className={`text-[13px] border mb-2 w-full py-2 outline-none rounded-[10px] ${BusinessKyc?.DateOfIncorporation === "" ? "text-[#747A80] bg-white" : "bg-[#FFF5D9]"
                    }`}
                  placeholder="MM-DD-YYYY"
                />
              </div>

            </div>

            {/* field 6 - registration number */}
            <div>
              <div className="flex">
                <p className="text-[13px] pb-1 text-black font-normal">
                  Business Registration number (CAC)
                </p>
                <p className="text-[6.5px] text-[#D31D1D]">
                  <DiCssTricks />
                </p>
              </div>
              <input
                type="text"
                name="BusinessRegistrationNumber"
                id=""
                value={regNum}
                onChange={handleRegNumChange}
                className={`text-[13px] border mb-1 w-full py-2 pl-2 outline-none rounded-[10px] ${BusinessKyc?.BusinessRegistrationNumber === "" ? "bg-white" : "bg-[#FFF5D9]"
                  }`}
                placeholder="Registration Number"
              />

              {KYCState === "BUSINESS_INVALID" &&
                <p className="text-[11px] mb-7 text-[#F15757]">
                  Registration number does not exist. Please enter a valid number</p>
              }


            </div>


            {/* Field 6 -  registered business address */}
            <div>
              <div className="flex">
                <p className="text-[13px] md:text-[12px] pb-1 text-black font-normal ">
                  Registered Business Address
                </p>
                <p className="text-[6.5px] text-[#D31D1D]">
                  <DiCssTricks />
                </p>
              </div>
              {/* address line 1 */}
              <input
                type="text"
                name="StreetAddress"
                id=""
                value={BusinessKyc?.RegisteredAddress?.StreetAddress}
                onChange={handleAddressChange}
                className={`text-[13px] border mb-1 w-full py-2 pl-2 outline-none rounded-[10px] ${BusinessKyc?.RegisteredAddress?.StreetAddress === ""
                  ? "bg-white"
                  : "bg-[#FFF5D9]"
                  }`}
                placeholder="Address Line 1"
              />
              {/* adress line 2 */}
              <input
                type="text"
                name="SecondStreetAddress"
                id=""
                value={BusinessKyc?.RegisteredAddress?.SecondStreetAddress}
                onChange={handleAddressChange}
                className={`text-[13px] border mb-2 w-full py-2 pl-2 outline-none rounded-[10px] ${BusinessKyc?.RegisteredAddress?.SecondStreetAddress === ""
                  ? "bg-white"
                  : "bg-[#FFF5D9]"
                  }`}
                placeholder="Address Line 2 (optional)"
              />
               {/* city */}
               <input
                type="text"
                name="City"
                id=""
                value={BusinessKyc?.RegisteredAddress?.City}
                onChange={handleAddressChange}
                className={`text-[13px] border mb-1 w-full py-2 pl-2 outline-none rounded-[10px] ${BusinessKyc?.RegisteredAddress?.City === ""
                  ? "bg-white"
                  : "bg-[#FFF5D9]"
                  }`}
                placeholder="City"
              />
              {/* state */}
              <input
                type="text"
                name="StateOrTerritory"
                id=""
                value={BusinessKyc?.RegisteredAddress?.StateOrTerritory}
                onChange={handleAddressChange}
                className={`text-[13px] border mb-1 w-full py-2 pl-2 outline-none rounded-[10px] ${BusinessKyc?.RegisteredAddress?.StateOrTerritory === ""
                  ? "bg-white"
                  : "bg-[#FFF5D9]"
                  }`}
                placeholder="State"
              />
             
              {/* zip */}
              {/* <input
                type="text"
                name="Zipcode"
                id=""
                value={BusinessKyc?.RegisteredAddress?.Zipcode}
                onChange={handleAddressChange}
                className={`text-[13px] border mb-1 w-full py-2 pl-2 outline-none rounded-[10px] ${BusinessKyc?.RegisteredAddress?.Zipcode === ""
                  ? "bg-white"
                  : "bg-[#FFF5D9]"
                  }`}
                placeholder="Zip"
              /> */}
            </div>

            {/* field 9 - how much do you expect to receive */}
            <section>
              <div className="flex">
                <p className="text-[13px] md:text-[12px] pb-1 text-black font-normal ">
                  How much  do you expect to receive each month?
                </p>
                <p className="text-[6.5px] text-[#D31D1D]">
                  <DiCssTricks />
                </p>
              </div>

              <p className="text-sm font-normal text-[#5B5B5B]">This helps us know what to expect from your account. </p>

              <select
                value={BusinessKyc?.MonthlyIncome}
                onChange={handleChange}
                className={`text-[13px] border mb-1 w-full mt-2 py-2 pl-2 outline-none rounded-[10px] bg-white ${BusinessKyc?.MonthlyIncome === '' ? 'text-[#747A80]' : ''}`}
                name="MonthlyIncome" id="MonthlyIncome">
                <option value="" disabled>Select monthly volume</option>
                <option value="Less than $1000">Less than $1,000</option>
                <option value="$1,000 - $5,000">$1,000 - $5,000</option>
                <option value="$5,000 - $10,000">$5,000 - $10,000</option>
                <option value="$10,000 - $20,000">$10,000 - $20,000</option>
                <option value="$20,000 - $50,000">$20,000 - $50,000</option>
                <option value="More than $50,000">More than $50,000</option>
              </select>
            </section>

            {/*  source of funds */}
            <section>
              <div className="flex">
                <p className="text-[13px] md:text-[12px] pb-1 text-black font-normal ">
                  Source of funds
                </p>
                <p className="text-[6.5px] text-[#D31D1D]">
                  <DiCssTricks />
                </p>
              </div>

              <p className="text-sm font-normal text-[#5B5B5B]">Where do you expect most of your payments into Cleva to come from? Please select max 2 sources. </p>

              <div className="flex mt-2">

                <div className="space-y-1">
                  {checkboxes.map((checkbox) => (
                    <CheckboxComponent
                      key={checkbox.value}
                      id={checkbox.value}
                      label={checkbox.label}
                      value={checkbox.value}
                      checked={selectedBox.includes(checkbox.value)}
                      onChange={handleCheckboxChange}
                    />
                  ))}
                  <div className="relative flex items-start">
                    <div className="flex h-6 items-center">
                      <input
                        id="other"
                        value="other"
                        type="checkbox"
                        checked={isOtherChecked}
                        onChange={handleCheckboxChange}
                        aria-describedby="Enter other sources"
                        name="other"
                        className="h-4 w-4 rounded border-gray-300 text-cleva-gold focus:ring-cleva-gold"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label htmlFor="other" className="text-black-soft">
                        Other
                      </label>
                    </div>
                  </div>
                  <div>
                    {showOtherText &&
                      <input
                        type="text"
                        name="otherText"
                        id="otherText"
                        value={otherText}
                        onChange={handleotherText}
                        className={`text-[13px] border mb-1 w-full mt-2 py-2 pl-2 outline-none rounded-[10px] bg-white`}
                        placeholder="Enter Source"
                      />
                    }
                  </div>
                </div>
              </div>
            </section>

            {/* Field 7 - phone */}
            <div className="">
              <div className="">
                <p className="text-[13px] md:text-[12px] text-black font-normal ">
                  Business Phone Number
                </p>
              </div>

              {/* Phone code */}
              <input
                type="text"
                name="PhoneNumber"
                id=""
                value={BusinessKyc?.ContactDetails?.PhoneNumber}
                onChange={handleContactDetailsChange}
                className={`text-[13px] border mb-1 w-full py-2 pl-2 outline-none rounded-[10px] ${BusinessKyc?.ContactDetails?.PhoneNumber === "" ? "bg-white" : "bg-[#FFF5D9]"
                  }`}
                placeholder="Enter phone number (optional)"
                maxLength={14}
              />
              {invalidPhone &&
                <p className="text-[11px] mb-7 text-[#F15757]">
                  Invalid phone number format</p>
              }

            </div>

            {/* Field 8 - website */}
            <div className="">
              <p className="text-[13px] pb-1 md:text-[12px] text-black font-normal ">
                Website
              </p>
              <input
                type="url"
                name="Website"
                id=""
                value={BusinessKyc?.Website}
                onChange={handleChange}
                className={`text-[13px] border w-full py-2 pl-2 outline-none rounded-[10px] ${BusinessKyc?.Website === "" ? "bg-white" : "bg-[#FFF5D9]"
                  }`}
                placeholder="Enter URL (optional)"
              />
            </div>


            <div className="flex justify-end items-center mt-4">
              {isButtonDisabled &&
                <span className="mt-2  inline-flex text-red-500 text-sm">
                  <img src={errorImg} className="pr-1" alt="error" />
                  Please fill all required fields</span>}
            </div>
            <div className="flex items-center justify-between mt-5">
              <button className="border border-[#5E5E5E] py-3 px-6 text-[13px] rounded-[10px]" onClick={useGoBack}>Back</button>
              <SaveAndContinue
                action={validate}
                isButtonDisabled={isButtonDisabled}
              />
            </div>

          </div>
          <div />
        </div>
      </form>

      {loading && <Loader />}

      {MismatchKYB && (<MismatchModal open={modal} setOpen={setModal} additionalDetails={AdditionalDetails} />)}
      {ErrorMismatch && (
        <FailedBusinessVerification open={modal} setOpen={setModal} />
      )}
      {bvnModal && <BVNInvalidModal open={bvnModal} setOpen={setBVNModal} />}
      {KYCState === "BUSINESS_INVALID" && (
        <InvalidModal open={modal} setOpen={setModal} />
      )}
      {KYCState === "CONTROLLER_PENDING" && <VerifiedBusiness proceed={proceed} />}

      {invalidRegNumber && <InvalidRegistrationModal handleClose={handleClose} />}
    
      {mismatchDetails && <MismatchBusiness handleClose={handleClose} retryCount={1} />}

      <ToastContainer />
    </>
  );
}
export default BusinessDetails;