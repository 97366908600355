import React from 'react'
import { TableColumn } from 'react-data-table-component'

type DataItem = {
  CreatedAt: string
  RecipientName: string
  Description: string
  TransactionType: string
  FromAmount: string
  TotalAmount: string
  TransactionState: string
  id: string
}

const formatAmount = (amount: any) => {
  const parsedAmount = parseFloat(amount) // Parse the amount as a floating-point number
  const formattedAmount = parsedAmount.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }) // Format the amount with two decimal places and comma as a thousands separator

  return formattedAmount
}

export const TransferColumn: TableColumn<DataItem>[] = [
  {
    name: 'Date',
    selector: (row) => row.CreatedAt,
    cell: (row) => <span data-tag="allowRowEvents" className="">{`${row.CreatedAt.slice(0, 10)}`}</span>,
    sortable: true,
    reorder: true,
    omit: false,
  },
  {
    name: 'Recipient',
    selector: (row) => row.RecipientName || row.TransactionType,
    cell: (row) => <span data-tag="allowRowEvents" className="font-medium">{`${(row.TransactionType === "CARD_FUNDING" || row.TransactionType === 'CARD_CREATION') ? "Virtual Card" : row.TransactionState === 'REVERSED' ? 'Sender' : row.RecipientName}`}</span>,
    sortable: true,
    reorder: true,
    omit: false,
    // width:"220px",
  },
  {
    name: 'Description',
    selector: (row) => row.Description || row.TransactionType,
    cell: (row) => <span data-tag="allowRowEvents" className="">{`${row.TransactionType === "CARD_FUNDING" ? "Transfer to Cleva Virtual Card" : row.TransactionType === "CARD_CREATION" ? "Card creation" : row.TransactionState === 'REVERSED' ? 'Reversal from Sender' : row.Description}`}</span>,
    sortable: true,
    reorder: true,
    width: "220px",
    omit: false,
  },
  {
    name: 'Type',
    selector: (row) => row.TransactionType,
    cell: (row) =>
      (row.TransactionType === 'MAKE_PAYMENT' || row.TransactionState === 'REVERSED') ? (
        <div data-tag="allowRowEvents" className="">
          <span data-tag="allowRowEvents" className="">International transfer</span>
        </div>
      ) :
        (row.TransactionType === 'CARD_FUNDING' || row.TransactionType === 'CARD_CREATION') ? (
          <div data-tag="allowRowEvents" className="">
            <span data-tag="allowRowEvents" className="">Card transfer</span>
          </div>
        ) : (row.TransactionType === 'TAG_TRANSFER') ?
          <div data-tag="allowRowEvents" className="">
            <span data-tag="allowRowEvents" className="">Cleva to Cleva</span>
          </div>
          :
          (
            <div data-tag="allowRowEvents" className="">
              <span data-tag="allowRowEvents" className="">Local transfer</span>
            </div>
          ),
    sortable: true,
    center: false,
    reorder: true,
    omit: false,
    width: '200px',
  },
  {
    name: 'Amount',
    selector: (row) => row.TotalAmount,
    cell: (row) => (
      <div data-tag="allowRowEvents" className="">
        <span data-tag="allowRowEvents" className="font-medium text-sm">{`$${formatAmount(row.TotalAmount)}`}</span>
      </div>
    ),
    sortable: true,
    center: false,
    reorder: true,
    width: '140px',
    omit: false,
  },
  {
    name: 'Status',
    selector: (row) => row.TransactionState,
    cell: (row) =>
      row.TransactionState === 'COMPLETED' ? (
        <div data-tag="allowRowEvents" className="py-1">
          <span data-tag="allowRowEvents" className="px-3 py-1 my-2 rounded-lg capitalize bg-[#DEF7EC] text-[#03543F] font-medium flex items-center text-[12px]">
            <span data-tag="allowRowEvents">Completed</span>
          </span>
        </div>
      ) : (row.TransactionState === 'IN_TRANSIT' || row.TransactionState === 'PENDING') && (row.TransactionType === "CARD_CREATION" || row.TransactionType === "CARD_FUNDING") ? (
        <div data-tag="allowRowEvents" className="py-1">
          <span data-tag="allowRowEvents" className="px-3 py-1 my-2 rounded-lg capitalize bg-[#EBFBFE] text-[#1892D7] font-medium flex items-center text-[12px]">
            <span data-tag="allowRowEvents">Pending</span>
          </span>
        </div>
      )
        :
        row.TransactionState === 'IN_TRANSIT' || row.TransactionState === 'PENDING' ? (
          <div data-tag="allowRowEvents" className="py-1">
            <span data-tag="allowRowEvents" className="px-3 py-1 my-2 rounded-lg capitalize bg-[#EBFBFE] text-[#1892D7] font-medium flex items-center text-[12px]">
              <span data-tag="allowRowEvents">In transit</span>
            </span>
          </div>
        )

          : row.TransactionState === 'DELAYED' ? (
            <div data-tag="allowRowEvents" className="py-1">
              <span data-tag="allowRowEvents" className="px-3 py-1 my-2 rounded-lg capitalize bg-[#FDF0E7] text-[#FF6600] font-medium flex items-center text-[12px]">
                <span data-tag="allowRowEvents">Delayed</span>
              </span>
            </div>
          ) : row.TransactionState === 'CANCELLED' ? (
            <div data-tag="allowRowEvents" className="py-1">
              <span data-tag="allowRowEvents" className="px-3 py-1 my-2 rounded-lg capitalize bg-[#FDF0E7] text-[#FF6600] font-medium flex items-center text-[12px]">
                <span data-tag="allowRowEvents">{row.TransactionState}</span>
              </span>
            </div>
          ) :

            //: row.TransactionState === 'REVERSED' ? 'Reversal from Sender'
            row.TransactionState === 'REVERSED' ? (
              <div data-tag="allowRowEvents" className="py-1">
                <span data-tag="allowRowEvents" className="px-3 py-1 my-2 rounded-lg capitalize bg-[#FDE8E8] text-[#9B1C1C] font-medium flex items-center text-[12px]">
                  <span data-tag="allowRowEvents">Reversed</span>
                </span>
              </div>
            ) :
              (
                <div data-tag="allowRowEvents" className="py-1">
                  <span data-tag="allowRowEvents" className="flex items-center px-3 py-1 my-2 font-medium capitalize bg-[#FDE8E8] rounded-lg text-[#9B1C1C] text-[12px]">
                    <span data-tag="allowRowEvents">Failed</span>
                  </span>
                </div>
              ),
    sortable: true,
    reorder: true,
    width: '130px',
    omit: false,
  },
]
