import React, { Fragment, useRef, useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { Dialog, Transition } from "@headlessui/react";


type ModalAProps = {
  closeModal?: () => void;
  children?: any;
  header?: string;
  titlePosition?: string;
  height?: any;
  width?: any;
  handleEditModal?: () => void;
};

const Modal: React.FC<ModalAProps> = ({
  children,
  header,
  titlePosition,
  height,
  width,
  handleEditModal,
  closeModal,
}) => {

  // function handleClose() {
  //   dispatch(setModalState(false))
  // }
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  const mobileStyle = "max-[768px]:top-[50%] max-[768px]:left-1/2  max-[768px]:w-full max-[768px]:h-[400px] max-[768px]:transform max-[768px]:-translate-x-1/2 max-[768px]:-translate-y-1/2"

  return (
    <>
      {
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            initialFocus={cancelButtonRef}
            onClose={setOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className={`fixed inset-0 z-10 overflow-y-auto ${mobileStyle}`}>
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 lg:ml-[256px] xl:ml-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white pb-8 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-[34rem]  w-[30rem]">
                    <header
                      className={`w-full ${titlePosition} bg-[#EBF0F0] py-5 px-8`}
                    >
                      <b>{header}</b>
                      <button
                        type="button"
                        onClick={closeModal}
                        className="text-[25px] float-right cursor-pointer"
                      >
                        <IoCloseOutline />
                      </button>
                    </header>
                    <body>{children}</body>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      }
    </>
  );
};

export default Modal;
