import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
// import { XMarkIcon } from "@heroicons/react/24/outline";
import USIcon from "../../../images/USD.svg";
import AccountDetails from "./AccountDetails";
import { AWS_CREDS } from "../../../features/services/AmazonService";
import { useAppSelector } from "../../../app/hooks";

type ModalProps = {
  closeModal: () => void;
};

const DetailsModal: React.FC<ModalProps> = ({ closeModal }) => {
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);

  const minimumDeposit = AWS_CREDS.MINIMUM_DEPOSIT;
  //const dailyLimit = AWS_CREDS.DAILY_LIMIT;

  const allAccount = useAppSelector((state) => state.virtualAccount.allAccount);
  const dailyLimit = allAccount ? (allAccount as any).Limit?.Total : " ";

 
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={closeModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white md:py-10 md:px-12 p-6 text-center shadow-xl transition-all sm:my-8 sm:w-full md:max-w-xl">
                <header
                  className={`sticky top-0 bg-white z-20  flex justify-between`}
                >
                  <div className="flex">
                    <div className=" mb-4">
                      <img src={USIcon} alt="successful" />
                    </div>
                    <Dialog.Title
                      as="h3"
                      className="mb:text-xl font-semibold ml-2 mb-2 text-left text-lg"
                    >
                      Your account details to receive USD
                    </Dialog.Title>
                  </div>

                  <button
                    type="button"
                    className="rounded-md text-black hover:text-gray-500"
                    onClick={closeModal}
                  >
                    <span className="sr-only">Close</span>
                    {/* <XMarkIcon className="h-4 w-4" aria-hidden="true" /> */}
                  </button>
                </header>

                <div className="text-left">
                  <div className="md:pt-2 pt-2 overflow-y-auto h-[70vh] md:h-[73vh]">
                    <p className="text-[#6F6F6F] md:text-base text-sm">
                      Use these bank details to receive USD via ACH or domestic
                      wire from any U.S. bank. Any money transferred to this
                      account will appear in your account balance.
                    </p>

                    <div className="px-4 py-2 bg-[#F3F3F3] rounded mt-4">
                      <p className="text-[#303030] text-base font-bold">
                        The minimum deposit you can receive is{" "}
                        <span className="text-[#278033]">
                          ${minimumDeposit}
                        </span>
                      </p>
                      <p className="text-sm pt-2 text-[#555656]">
                        Micro deposits (amounts less than $1) used for account
                        confirmation can still be received but they are later
                        returned to the sender.
                      </p>
                    </div>

                    <div className="px-4 py-2 bg-[#F3F3F3] rounded mt-4">
                      <p className="text-[#303030] text-base font-bold">
                        The maximum daily deposit is{" "}
                        <span className="text-[#278033]">${dailyLimit}</span>
                      </p>
                      <p className="text-sm pt-2 text-[#555656]">
                        Amounts above this require additional verification
                        before they can be processed.
                      </p>
                    </div>
                    <AccountDetails onClose={closeModal} />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default DetailsModal;