import { TL, TR, BL, BR } from "../../asset/landing_photos"
import Banner from './Banner'
import Onboarding from './Onboarding'
import Investor from './Investor'


const Reviews = () => {
  const videoClass = ` p-[1.28rem] lg:p-[1.62rem]`

  return (
    <section className="bg-black pt-[5rem] py-[3.62rem] lg:pt-28 lg:py-[4.91rem] mt-[-1rem]">
      <Investor />
      <div className='w-[90%] xl:w-[80%] mx-auto 2xl:w-[1280px]'>
        {/* what our users say */}
        <div className='flex flex-col justify-center items-center'>
          <p className='text-white text-center text-[1.75rem] lg:text-[2.25rem] font-semibold '>What Our Users Say</p>
          <p className='text-[#E8E8E8] text-center text-base font-base pt-[1rem] pb-[3.25rem]'>Our users share how Cleva is transforming banking for them.</p>
        </div>
        <div className='bg-[#151515] rounded-xl relative md:w-[25rem] md:mx-auto  lg:mb-[2.94rem]'>
          <img src={TL} alt="top left" className='absolute left-[-1.5rem] top-4 w-[2.49875rem] lg:w-12' />
          <img src={TR} alt="top right" className='absolute right-[-1rem] top-4 w-8 lg:w-12' />
          <div dangerouslySetInnerHTML={{
            __html: `
        <video
          loop
          muted
          autoplay
   
          playsinline
          src="https://cl-public-docs.s3.eu-west-1.amazonaws.com/cleva-review.MP4"
          class="${videoClass}"
          style=" border-radius: 2.5rem "
        />,
      ` }}></div>
          <img src={BL} alt="bottom left" className='absolute left-[-1.5rem] bottom-[3rem] w-8 lg:w-12' />
          <img src={BR} alt="top right" className='absolute right-[-1rem] bottom-4 w-8 lg:w-12' />
        </div>

        {/* For anyone with global  financial need */}
        <div>
          <Banner />
        </div>

        {/* Onbaording */}
        <div>
          <Onboarding />
        </div>
      </div>
    </section>
  )
}

export default Reviews