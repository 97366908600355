import { DiCssTricks } from "react-icons/di";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { useRef, useState } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import errorImg from '../../../../asset/svg/material-symbols_warning.svg'
import ControllerVerificationFailure from "./Modals/ControllerVerificationFailure";
import VerifiedModal from "../../modals/verifiedModal";
import { setAdditionalDetails, setControllerInformation, setKYCState, setSubKYCState } from "../../../../features/Kyc/kycSlice";
import { useNavigate } from "react-router-dom";
import { controllerIDCheck, createController } from "../../../../api";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../modals/loader";
import BVNInUse from "../../modals/BvnInUse";
import OwnerMismatch from "../../modals/OwnerMismatch";
import OwnerInvalid from "../../modals/OwnerInvalid";
import Spinner from "../../../../components/PopUps/Spinner";
import { isValidEmail } from "../../../../components/Auth/Register";
import { AWS_CREDS } from "../../../../features/services/AmazonService";


interface ISteps {
    currentStep?: number;
    nextStep?: any;
}
const ControllerInformation = (props: ISteps) => {
    const dispatch = useAppDispatch()
    const { Controller, KYCState, AdditionalDetails, SubKYCState } = useAppSelector((state: any) => state.kycInfo);
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(false);
    const [modal, setModal] = useState(false)
    const [bvnModal, setBVNModal] = useState(false)
    const dateInputRef = useRef<HTMLInputElement | null>(null);
    const [bvn, setBvn] = useState("");
    const [email, setEmail] = useState("");
    const [status, setStatus] = useState<any>(null)
    const [isBvnValid, setIsBvnValid] = useState(true)
    const [showEmailError, setShowEmailError] = useState(false)
    const [percentError, setPercentError] = useState({
        invalidValue: false,
        tooLow: false
    })

    const isButtonDisabled = !isBvnValid ||
        (Controller.IsOwnerAndController && (Controller.PercentageOwnership === "" || percentError.invalidValue || percentError.tooLow)) ||
        showEmailError ||
        status === null ||
        Controller.FirstName === ""
        || Controller.LastName === ""
        || Controller.JobTitle === ""
        || Controller.DateOfBirth === ""
        || Controller.CountryOfCitizenship === ""
        || Controller.NationalIdentifier === ""
        || Controller.ProfileLink === ""
        || Controller.Email === ""
        || Controller.Address?.Country === ""
        || Controller.Address?.City === ""
        || Controller.Address?.StateOrTerritory === ""
        || Controller.Address?.StreetAddress === ""



    const handleOwnershipChange = (event: any) => {
        const { name, value } = event.target
        const updatedStatus = value.toLowerCase() === "yes";

        if (value.toLowerCase() === "no") {
            setStatus(false)
        } else {
            setStatus(true)
        }
    
        dispatch(
            setControllerInformation({
                ...Controller,
                [name]: updatedStatus,
            })
        );
    };

    const handleSaveForLater = () => {
        localStorage.setItem("saveForLater", "true")
        navigate('/')
    }

    const handlePercentChange = (event: any) => {
        const percent = event.target.value.trim();

        if (percent < 25) {
            setPercentError(prevState => ({
                ...prevState,
                tooLow: true
            }));
        } else {
            setPercentError(prevState => ({
                ...prevState,
                tooLow: false
            }));
        }

        if (isNaN(Number(percent)) || percent > 100 || percent < 1) {
            setPercentError(prevState => ({
                ...prevState,
                invalidValue: true
            }));

        } else {
            setPercentError(prevState => ({
                ...prevState,
                invalidValue: false
            }));

        }

        dispatch(
            setControllerInformation({
                ...Controller,
                PercentageOwnership: percent,
            })
        );
    };

    const proceed = async () => {
        setLoader(true)
        try {
            const response = await controllerIDCheck({ AllIdChecksComplete: true })
            if(AWS_CREDS.STAGE === 'qa'){
                 console.log(response.data)
              }
            if (response?.data) {
                dispatch(setKYCState(response?.data?.BusinessKycState));
                dispatch(setAdditionalDetails(response?.data?.BusinessKyc?.AdditionalDetails));
                if (response?.data.Message === "All ID checks completed") {
                    if (props.currentStep) {
                        props.nextStep(props?.currentStep + 1);
                        setLoader(false)
                        // window.location.reload()
                    }


                }
            }
        } catch (error) {
            if (AWS_CREDS.STAGE === 'qa') {
                console.log(error);
            }
            setLoader(false)
        }
    };


    const handleFocus = (e: any) => {
        if (dateInputRef.current) {
            dateInputRef.current.showPicker();
        }
    };

    const handleChange = (event: any) => {
        dispatch(
            setControllerInformation({
                ...Controller,
                [event.target.name]: event.target.value.trim(),
            })
        );
    };


    const handleBVNchange = (event: any) => {
        setBVNModal(false);
        const newBvn = event.target.value.trim();
        setBvn(newBvn);

        if (newBvn.length === 11) {
            setIsBvnValid(true);
        } else {
            setIsBvnValid(false);
        }

        dispatch(
            setControllerInformation({
                ...Controller,
                [event.target.name]: newBvn,
            })
        );
    };

    const handleEmailchange = (event: any) => {

        setShowEmailError(!isValidEmail(event.target.value))
        setEmail(event.target.value)


        dispatch(
            setControllerInformation({
                ...Controller,
                [event.target.name]: event.target.value,
            })
        );
    };


    const handleAddressChange = (event: any) => {
        const Address: any = {
            ...Controller?.Address,
            [event.target.name]: event.target.value,
        };

        dispatch(
            setControllerInformation({
                ...Controller,
                Address,
            })
        );
    };

    const validate = async () => {
        setLoading(true)
        try {
            const response = await createController({ Controller: Controller });
            if (AWS_CREDS.STAGE === 'qa') {
                console.log(response)
            }
            setLoading(false);
            setModal(true);
            if (response?.data) {
                localStorage.setItem("CONTROLLER_KYCIdentifier", response?.data?.KYCIdentifier);
                dispatch(setKYCState(response?.data?.BusinessKycState));
                dispatch(setSubKYCState(response?.data?.KYCState))
                dispatch(
                    setAdditionalDetails(
                        response?.data?.Controller.AdditionalDetails
                    )
                );
            }
        } catch (error: any) {
            if (AWS_CREDS.STAGE === 'qa') {
                console.log(error);
            }
            setLoading(false);
            if (error?.response?.data?.Code === "NATIONAL_ID_DUPLICATE") {
                setBVNModal(true);
                if (AWS_CREDS.STAGE === 'qa') {
                    console.log(error);
                }

            } else if (error?.response?.status === 504 || error?.response?.data?.Code === "PROVIDER_DOWN") {
                toast.error("We're sorry, our service provider is currently down. Please try again in a few hours time.");
            } else {
                toast.error("We're sorry, we encountered some unexpected error. Please try again later.");
            }
        }

    };


    const errorMismatch: boolean = (SubKYCState === "MISMATCH" && (AdditionalDetails.NoOfRetriesLeft === 0 || AdditionalDetails.NoOfRetriesLeft < 0)) || SubKYCState === "BVN_FAILED";
    const mismatchBvn = SubKYCState === "MISMATCH" && AdditionalDetails.NoOfRetriesLeft > 0;
    const duplicateBvn = SubKYCState === "DUPLICATE"



    return (
        <>
            {loader ?
                <Spinner />
                :
                <form className="sm:w-[65%] sm:ml-12">
                    <section className="xl:w-[63%] pb-7 pt-8 sm:pt-0">
                        <div className="mb-5">
                            <h3 className="font-semibold md:text-2xl text-lg">
                                Controller Information
                            </h3>

                            <p className="text-[13px] text-[#747A80]">A controller is any person that has significant responsibility to control or manage the business (e.g. CEO, CFO, President).</p>
                        </div>
                        <div className="grid gap-4" >


                            {/* Field 0 - legal name*/}
                            <section>
                                <div className="flex mt-5">
                                    <p className="text-[13px] pb-1 text-black font-normal">
                                        Legal Name
                                    </p>
                                    <p className="text-[6.5px] text-[#D31D1D]">
                                        <DiCssTricks />
                                    </p>
                                </div>
                                {/* first name */}
                                <input
                                    type="text"
                                    name="FirstName"
                                    id=""
                                    value={Controller?.FirstName}
                                    onChange={handleChange}
                                    className={`text-[13px] border mb-1 w-full py-2 pl-2 outline-none rounded-[10px] ${Controller?.FirstName === "" ? "bg-white" : "bg-[#FFF5D9]"
                                        }`}
                                    placeholder="First Name"
                                />
                                {/* middle name: optional */}
                                <input
                                    type="text"
                                    name="MiddleName"
                                    id=""
                                    value={Controller?.MiddleName}
                                    onChange={handleChange}
                                    className={`text-[13px] border mb-1 w-full py-2 mt-2 pl-2 outline-none rounded-[10px] ${Controller?.MiddleName === "" ? "bg-white" : "bg-[#FFF5D9]"
                                        }`}
                                    placeholder="Middle Name (optional)"
                                />
                                {/* last name */}
                                <input
                                    type="text"
                                    name="LastName"
                                    id=""
                                    value={Controller?.LastName}
                                    onChange={handleChange}
                                    className={`text-[13px] border mb-1 w-full py-2 mt-2 pl-2 outline-none rounded-[10px] ${Controller?.LastName === "" ? "bg-white" : "bg-[#FFF5D9]"
                                        }`}
                                    placeholder="Last Name"
                                />
                            </section>


                            {/* field 1 - job title */}
                            <div>
                                <div className="flex">
                                    <p className="text-[13px] pb-1 text-black font-normal">
                                        Job Title
                                    </p>
                                    <p className="text-[6.5px] text-[#D31D1D]">
                                        <DiCssTricks />
                                    </p>
                                </div>
                                <input
                                    type="text"
                                    name="JobTitle"
                                    id=""
                                    value={Controller?.JobTitle}
                                    onChange={handleChange}
                                    className={`text-[13px] border mb-1 w-full py-2 pl-2 outline-none rounded-[10px] ${Controller?.JobTitle === "" ? "bg-white" : "bg-[#FFF5D9]"
                                        }`}
                                    placeholder="Enter Job Title"
                                />
                            </div>


                            {/* field 2 - DOB */}
                            <section>
                                <div className="flex">
                                    <p className="text-[13px] font-normal pb-1 ">Date of birth</p>
                                    <p className="text-[6.5px] text-[#D31D1D]">
                                        <DiCssTricks />
                                    </p>
                                </div>
                                <div className="date-input-container">
                                    <input
                                        type="date"
                                        name="DateOfBirth"
                                        id="DateOfBirth"
                                        ref={dateInputRef}
                                        value={Controller?.DateOfBirth}
                                        onChange={handleChange}
                                        onFocus={handleFocus}
                                        className={`text-[13px] border mb-2 w-full py-2 outline-none rounded-[10px] ${Controller?.DateOfBirth === "" ? "bg-white text-[#747A80] " : "bg-[#FFF5D9]"
                                            }`}
                                        placeholder="MM-DD-YYYY"
                                    />
                                </div>
                            </section>

                            {/* Field 3 - Country of citizenship */}
                            <div>
                                <div className="flex">
                                    <p className="text-[13px] md:text-[12px] pb-1 text-black font-normal ">
                                        Country of citizenship
                                    </p>
                                    <p className="text-[6.5px] text-[#D31D1D]">
                                        <DiCssTricks />
                                    </p>
                                </div>
                                <select
                                    name="CountryOfCitizenship"
                                    id=""
                                    value={Controller?.CountryOfCitizenship}
                                    onChange={handleChange}
                                    className={`text-[13px] text-[#747A80] border mb-1 w-full py-2 pl-2 outline-none rounded-[10px] ${Controller.CountryOfCitizenship === ""
                                        ? "bg-white"
                                        : "bg-[#FFF5D9] text-black"
                                        }`}
                                >
                                    <option value="Business Address" className="hidden">
                                        Select country
                                    </option>
                                    <option value="USA" className=" ">
                                        United States
                                    </option>
                                    <option value="Nigeria" className=" ">
                                        Nigeria
                                    </option>
                                    <option value="UK" className=" ">
                                        UK
                                    </option>
                                </select>
                            </div>

                            {/* Field 4 - BVN */}
                            <div>
                                <div className="flex">
                                    <p className="text-[13px] md:text-[12px] pb-1 text-black font-normal ">
                                        Bank Verification Number (BVN)
                                    </p>
                                    <p className="text-[6.5px] text-[#D31D1D]">
                                        <DiCssTricks />
                                    </p>
                                </div>
                                <input
                                    type="text"
                                    name="NationalIdentifier"
                                    id="bvn"
                                    value={bvn}
                                    onChange={handleBVNchange}
                                    className={`text-[13px] border mb-2 w-full py-2 outline-none rounded-[10px] ${Controller?.NationalIdentifier === "" ? "bg-white" : "bg-[#FFF5D9]"
                                        }`}
                                    placeholder="Enter valid BVN"
                                    maxLength={11}
                                />

                                {!isBvnValid &&
                                    <p className="text-[11px] text-[#F15757]">
                                        BVN should be exactly 11 characters long.
                                    </p>
                                }
                                {KYCState === "CONTROLLER_INVALID" && (
                                    <p className="text-[11px] text-[#F15757]">
                                        BVN does not exist. Please
                                        enter a valid BVN
                                    </p>
                                )}
                                {KYCState === "CONTROLLER_MISMATCH" && (
                                    <p className="text-[11px] text-[#F15757]">
                                        The details you entered don't match the information on your
                                        BVN
                                    </p>
                                )}
                            </div>

                            {/* Field 5 - email address */}
                            <div>
                                <div className="flex">
                                    <p className="text-[13px] md:text-[12px] pb-1 text-black font-normal ">
                                        Email Address
                                    </p>
                                    <p className="text-[6.5px] text-[#D31D1D]">
                                        <DiCssTricks />
                                    </p>
                                </div>
                                <input
                                    type="email"
                                    name="Email"
                                    id="Email"
                                    value={Controller?.Email}
                                    onChange={handleEmailchange}
                                    className={`text-[13px] border mb-2 w-full py-2 outline-none rounded-[10px] ${Controller?.Email === "" ? "bg-white" : "bg-[#FFF5D9]"
                                        }`}
                                    placeholder="Email Address"
                                />
                                {showEmailError &&
                                    <span className="inline-flex text-[11px] text-[#F15757]">
                                        {/* <img src={errorImg} className="pr-2" alt="error" /> */}
                                        Please enter a valid email address</span>

                                }
                            </div>

                            {/* Field 6 -  residential addreess */}
                            <div>
                                <div className="flex">
                                    <p className="text-[13px] md:text-[12px] pb-1 text-black font-normal ">
                                        Residential address
                                    </p>
                                    <p className="text-[6.5px] text-[#D31D1D]">
                                        <DiCssTricks />
                                    </p>
                                </div>
                                <select
                                    name="Country"
                                    id=""
                                    value={Controller?.Address?.Country}
                                    onChange={handleAddressChange}
                                    className={`text-[13px] text-[#747A80] border mb-1 w-full py-2 pl-2 outline-none rounded-[10px] ${Controller?.Address?.Country === ""
                                        ? "bg-white"
                                        : "bg-[#FFF5D9] text-black"
                                        }`}
                                >
                                    <option value="Business Address" className=" ">
                                        Select country
                                    </option>
                                    <option value="USA" className=" ">
                                        United States
                                    </option>
                                    <option value="Nigeria" className=" ">
                                        Nigeria
                                    </option>
                                    <option value="UK" className=" ">
                                        UK
                                    </option>
                                </select>


                                {/* address line 1 */}
                                <input
                                    type="text"
                                    name="StreetAddress"
                                    id=""
                                    value={Controller?.Address?.StreetAddress}
                                    onChange={handleAddressChange}
                                    className={`text-[13px] border mb-1 w-full py-2 pl-2 outline-none rounded-[10px] ${Controller?.Address?.StreetAddress === ""
                                        ? "bg-white"
                                        : "bg-[#FFF5D9]"
                                        }`}
                                    placeholder="Address Line 1"
                                />
                                {/* adress line 2 */}
                                <input
                                    type="text"
                                    name="SecondStreetAddress"
                                    id=""
                                    value={Controller?.Address?.SecondStreetAddress}
                                    onChange={handleAddressChange}
                                    className={`text-[13px] border mb-2 w-full py-2 pl-2 outline-none rounded-[10px] ${Controller?.Address?.SecondStreetAddress === ""
                                        ? "bg-white"
                                        : "bg-[#FFF5D9]"
                                        }`}
                                    placeholder="Address Line 2 (optional)"
                                />
                                {/* city */}
                                <input
                                    type="text"
                                    name="City"
                                    id=""
                                    value={Controller?.Address?.City}
                                    onChange={handleAddressChange}
                                    className={`text-[13px] border mb-1 w-full py-2 pl-2 outline-none rounded-[10px] ${Controller?.Address?.City === ""
                                        ? "bg-white"
                                        : "bg-[#FFF5D9]"
                                        }`}
                                    placeholder="City"
                                />
                                {/* state */}
                                <input
                                    type="text"
                                    name="StateOrTerritory"
                                    id=""
                                    value={Controller?.Address?.StateOrTerritory}
                                    onChange={handleAddressChange}
                                    className={`text-[13px] border mb-1 w-full py-2 pl-2 outline-none rounded-[10px] ${Controller?.Address?.StateOrTerritory === ""
                                        ? "bg-white"
                                        : "bg-[#FFF5D9]"
                                        }`}
                                    placeholder="State"
                                />

                                {/* zip */}
                                {/* <input
                                    type="text"
                                    name="Zipcode"
                                    id=""
                                    value={Controller?.Address?.Zipcode}
                                    onChange={handleAddressChange}
                                    className={`text-[13px] border mb-1 w-full py-2 pl-2 outline-none rounded-[10px] ${Controller?.Address?.Zipcode === ""
                                        ? "bg-white"
                                        : "bg-[#FFF5D9]"
                                        }`}
                                    placeholder="Zip"
                                /> */}
                            </div>


                            {/* field 7 - public profile link  */}
                            <section>
                                <div>
                                    <div className="flex">
                                        <p className="text-[13px] pb-1 text-black font-normal ">
                                            Your public profile link
                                        </p>
                                        <p className="text-[6.5px] text-[#D31D1D]">
                                            <DiCssTricks />
                                        </p>
                                    </div>

                                    <p className="text-sm font-normal text-[#5B5B5B]">e.g Linkedin, Fiverr, X(twitter), other freelancer profiles</p>
                                </div>

                                <input
                                    type="text"
                                    name="ProfileLink"
                                    id=""
                                    value={Controller?.ProfileLink}
                                    onChange={handleChange}
                                    className={`text-[13px] border mb-1 w-full mt-2 py-2 pl-2 outline-none rounded-[10px] bg-white`}
                                    placeholder="Enter URL"
                                />
                            </section>

                            {/* field 8 - ownership */}
                            {/* <input onChange={} checked={Controller?.IsOwnerAndController} type="checkbox" name="IsOwnerAndController" id="" /> */}
                            <section className="">

                                <div className="flex">
                                    <p className="text-[13px] pb-1 text-black font-normal">Do you own 25% or more of the company? </p>

                                    <p className="text-[6.5px] text-[#D31D1D]">
                                        <DiCssTricks />
                                    </p>
                                </div>

                                <select
                                    name="IsOwnerAndController"
                                    id=""
                                    
                                    onChange={handleOwnershipChange}
                                    className={`text-[13px] text-[#747A80] border mb-1 w-full py-2 pl-2 outline-none rounded-[10px] ${status !== null  && "bg-[#FFF5D9] text-black"}`} >
                                    <option value="Select an Option" className="hidden"> Select an Option </option>
                                    <option value="No" className=""> No </option>
                                    <option value="Yes" className="">  Yes </option>
                                </select>
                            </section>

                            {Controller?.IsOwnerAndController &&
                                <section>
                                    <div>
                                        <div className="flex">
                                            <p className="text-[13px] pb-1 text-black font-normal ">
                                                Enter ownership percentage
                                            </p>
                                            <p className="text-[6.5px] text-[#D31D1D]">
                                                <DiCssTricks />
                                            </p>
                                        </div>

                                    </div>
                                    <input
                                        type="text"
                                        name="PercentageOwnership"
                                        id=""
                                        value={Controller?.PercentageOwnership}
                                        onChange={handlePercentChange}
                                        className={`text-[13px] border mb-1 w-full mt-2 py-2 pl-2 outline-none rounded-[10px] bg-white`}
                                        placeholder="%"
                                        maxLength={3}
                                    />

                                    {percentError.invalidValue && (
                                        <p className="text-[11px] mb-4 text-[#F15757]">
                                            Please Enter a valid value
                                        </p>
                                    )}
                                    {percentError.tooLow && (
                                        <p className="text-[11px] text-[#F15757]">
                                            Owners should have atleast 25% ownership
                                        </p>
                                    )}
                                </section>
                            }
                            <div className="flex w-full items-stretch flex-col gap-3 justify-between mt-5">
                                <button
                                    type="button"
                                    disabled={isButtonDisabled}
                                    onClick={validate}
                                    className={`relative text-[13px] flex items-center justify-center  py-3 px-6 rounded-[10px] mt-2 font-medium border-2 ${isButtonDisabled ?
                                        "bg-[#FFF5D9] text-[#5F5D5D]"
                                        : "bg-[#FFBD59]"
                                        }`}>
                                    Save & Continue  <span className="ml-[0.5rem]"> <MdKeyboardArrowRight /></span>
                                </button>

                                <button onClick={handleSaveForLater} className="border border-[#0B69AD] text-[#0B69AD] py-3 px-6 rounded-[10px] mt-2" >Save for later</button>
                            </div>

                        </div>
                        <div />
                    </section>
                </form>
            }


            {loading && <Loader />}
            {SubKYCState === "INVALID" && <OwnerInvalid open={modal} setOpen={setModal} />}
            {(SubKYCState === "DOCUMENT_UPLOAD" || SubKYCState === "VERIFIED" || SubKYCState === "DOCUMENT_IN_REVIEW" || SubKYCState === "DOCUMENT_RETRY") && (
                <VerifiedModal open={modal} setOpen={setModal} action={proceed} />
            )}
            {bvnModal && <BVNInUse open={bvnModal} setOpen={setBVNModal} />}
            {duplicateBvn && <BVNInUse open={modal} setOpen={setModal} />}
            {mismatchBvn &&
                <OwnerMismatch
                    open={modal}
                    setOpen={setModal}
                    additionalDetails={AdditionalDetails}
                />

            }
            {errorMismatch && (
                <ControllerVerificationFailure open={modal} setOpen={setModal} />
            )}
            <ToastContainer />
        </>
    )
}

export default ControllerInformation