import { useEffect, useState } from "react"
import { LuDot } from "react-icons/lu"
import { ISteps } from "../uploadDocuments"
import { useNavigate } from "react-router-dom"
import { IoDocumentText } from "react-icons/io5";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { fetchController, fetchOwners } from "../../../../features/Kyc/KycApi";
import Spinner from "../../../../components/PopUps/Spinner";
import { PeopleUploads, getKyc } from "../../../../api";
import { AWS_CREDS } from "../../../../features/services/AmazonService";
import { toast } from "react-toastify";

const UploadOwnerDocument = (props: ISteps) => {
  const { BeneficialOwners, Controllers } = useAppSelector((state) => state.kycInfo)
  const user = useAppSelector((state) => state.user.userData)
  const [businessInfo, setBusinessInfo] = useState<any>(null)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isDisabled, setIsDisabled] = useState(true)
  const [loader, setLoader] = useState(false)

  const navigateToForm = (id: any) => {
    localStorage.setItem('businessName', businessInfo?.BusinessKyc?.BusinessName)
    localStorage.setItem('incomingPersonType', 'OWNER')
    localStorage.setItem("uploadDoc", id)
    navigate(`/kyb-info/upload/${id}`)
  }

  const navigateController = (id: any) => {
    localStorage.setItem('businessName', businessInfo?.BusinessKyc?.BusinessName)
    localStorage.setItem("uploadDoc", id)
    localStorage.setItem('incomingPersonType', 'CONTROLLER')
    navigate(`/kyb-info/upload/${id}`)
  }

  useEffect(() => {
    const hasNonDocumentInReview = BeneficialOwners?.every(
      (owner: any) => owner?.KYCState === "DOCUMENT_IN_REVIEW" || owner?.KYCState === "VERIFIED"
    );
    const ControllerInReview = Controllers?.every(
      (owner: any) => owner?.KYCState === "DOCUMENT_IN_REVIEW" || owner?.KYCState === "VERIFIED"
    );
    setIsDisabled(hasNonDocumentInReview && ControllerInReview);
  }, [BeneficialOwners, Controllers])


  const submitCompleteDoc = async () => {
    setLoader(true);

    try {
      const response = await PeopleUploads({ AllPeopleUploadsComplete: true });
      if (response?.data?.Message) {
        setLoader(false);
        navigate('/kyb-confirm')
      }

    } catch (error) {
      setLoader(false);
      if(AWS_CREDS.STAGE === 'qa'){
      console.log(error);
      toast.error('Error uploading documents. Please try again in a few minutes.')
      }

    }
  }

  useEffect(() => {
    const identifier = localStorage.getItem("KYCIdentifier")
    const fetchKyc = async () => {
      const resp = await getKyc(identifier)
      setBusinessInfo(resp?.data)
    }
    fetchKyc()
  }, [])


  useEffect(() => {
    dispatch(fetchOwners())
    dispatch(fetchController())
  }, []);

  if(!BeneficialOwners || !Controllers){
    return <Spinner/>
  }

  if (!businessInfo) {
    return <Spinner />
  }

  if (!Controllers) {
    return <Spinner />
  }

  const renderController = () => {

    return (
      Controllers.map((person: any) => (
        <div key={person?.Controller?.KYCIdentifier} className="bg-[#FCFCFC] border p-4 border-[#E3E3E3] rounded-md">

          <div className="flex  gap-4">
            <div className="w-[44px] h-[44px] rounded-full bg-[#EDE5DB] font-medium text-[#703308] text-sm flex items-center justify-center">
              {`${person?.Controller?.FirstName[0]?.toUpperCase()}${person?.Controller?.LastName[0]?.toUpperCase()}`}
            </div>
            <div className="w-full">
              <h2 className="text-[#454545] font-medium text-lg">{person?.Controller?.FirstName}  {person?.Controller?.LastName}</h2>
              <div className="grid md:flex items-center">
                <p className="text-[#454545] font-medium text-sm">{person?.Controller?.IsOwnerAndController ? 'Controller & Owner' : 'Controller'} at {businessInfo?.BusinessKyc?.BusinessName || user?.BusinessName} </p>
                {person?.Controller?.IsOwnerAndController &&
                  <>
                    <p><LuDot /></p>
                    <p className="text-[#B56D00] font-normal text-sm w-1/2">{person?.Controller?.PercentageOwnership}% ownership</p>
                  </>

                }
              </div>
              <div className="">
                {(person?.KYCState === "DOCUMENT_UPLOAD")
                  ?
                  <button onClick={() => navigateController(person?.KYCIdentifier)} className="mt-4 rounded-sm border-[#0B69AD] text-[#0B69AD] text-xs border py-[6px] px-[12px]">Upload Document</button>
                  :
                  (person?.KYCState === "DOCUMENT_RETRY")
                    ?
                    <div className="flex gap-4"> <p className="text-[#bf4d4d] text-sm flex items-center gap-1"><IoDocumentText />Document could not be verified</p> <button className="text-[#0B69AD] text-sm underline" onClick={() => navigateController(person?.KYCIdentifier)}>Re-upload</button> </div>
                    :
                    <div className="flex gap-4"> <p className="text-[#158025] text-sm flex items-center gap-1"><IoDocumentText />Document uploaded</p> <button className="text-[#0B69AD] text-sm underline" onClick={() => navigateController(person?.KYCIdentifier)}>Re-upload</button> </div>
                }
              </div>

            </div>
          </div>
        </div>
      )
      )
    )
  };

  const renderOwners = () => {

    const owners = BeneficialOwners.filter((user: any) => user?.BeneficialOwner?.IsOwnerAndController !== true);
    if (!owners) return null;
    return owners.map((owner: any) => (
      <div key={owner?.KYCIdentifier} className="bg-[#FCFCFC] border p-4 border-[#E3E3E3] rounded-md">

        <div className="flex  gap-4">
          <div className="w-[44px] h-[44px] rounded-full bg-[#EDE5DB] font-medium text-[#703308] text-sm flex items-center justify-center">
            {`${owner?.BeneficialOwner?.FirstName[0]?.toUpperCase()}${owner.BeneficialOwner?.LastName[0]?.toUpperCase()}`}
          </div>
          <div className="w-full">
            <h2 className="text-[#454545] font-medium text-lg">{owner?.BeneficialOwner?.FirstName}  {owner.BeneficialOwner?.LastName}</h2>
            <div className="grid md:flex items-center">
              <p className="text-[#454545] font-medium text-sm">{owner?.BeneficialOwner?.IsOwnerAndController ? 'Controller & Owner' : 'Owner'} at {businessInfo?.BusinessKyc?.BusinessName || user?.BusinessName} </p>
              <p><LuDot /></p>
              <p className="text-[#B56D00] font-normal text-sm w-1/2">{owner?.BeneficialOwner?.PercentageOwnership}% ownership</p>
            </div>
            <div className="">
              {owner?.KYCState === "DOCUMENT_UPLOAD"
                ?
                <button onClick={() => navigateToForm(owner?.KYCIdentifier)} className="mt-4 rounded-sm border-[#0B69AD] text-[#0B69AD] text-xs border py-[6px] px-[12px]">Upload Document</button>
                :
                (owner?.KYCState === "DOCUMENT_RETRY")
                  ?
                  <div className="flex gap-4"> <p className="text-[#bf4d4d] text-sm flex items-center gap-1"><IoDocumentText />Document could not be verified</p> <button className="text-[#0B69AD] text-sm underline" onClick={() => navigateToForm(owner?.KYCIdentifier)}>Re-upload</button> </div>
                  :
                  <div className="flex gap-4"> <p className="text-[#158025] text-sm flex items-center gap-1"><IoDocumentText />Document uploaded</p> <button className="text-[#0B69AD] text-sm underline" onClick={() => navigateToForm(owner?.KYCIdentifier)}>Re-upload</button> </div>
              }
            </div>

          </div>
        </div>
      </div>

    ));
  };


  return (
    <>
      <div className="xl:w-[45%] sm:ml-12">
        <h3 className="text-xl text-[#121212] pb-2 font-semibold">
          Provide Controller & Owner(s) documents
        </h3>
        <p className="text-base text-[#4E4E4E] pb-5 ">
          Please upload ID documents for your management team
        </p>

        <section className="grid gap-3">
          {renderController()}
          {renderOwners()}
        </section>

        <button
          type="button"
          disabled={!isDisabled || loader}
          onClick={submitCompleteDoc}
          className={(!isDisabled || loader ?
            "bg-[#FFF5D9] text-[#5F5D5D]" : "bg-[#FFBD59]") +
            ` text-[15px] font-bold p-3 w-full rounded-lg mt-8 `}>
          {loader ? "Uploading..." : "Submit"}
        </button>
      </div>
    </>
  )
}

export default UploadOwnerDocument