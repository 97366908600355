import {Link} from "react-router-dom"
import { AWS_CREDS } from '../../features/services/AmazonService';

function ReferralBanner(){
    const maxReferralBonus = AWS_CREDS.MAX_REFERRAL_BONUS || 200; 

return (
    <article className="rounded-xl bg-[#FCF8E3] relative top-[8rem] md:top-[12rem] z-40  w-[90%] xl:w-[80%] mx-auto 2xl:w-[1280px]">
        <div className="p-[0.5rem] md:p-4 lg:px-0 text-center">

        <p className="text-black text-base lg:text-xl font-medium">
            <span>Earn up to ${maxReferralBonus} when you refer your friends to Cleva  🎉</span>
            <Link to="/refer-and-earn" className="pl-[.75rem] text-[#1480E3] underline font-semibold cursor-pointer">Learn more here</Link>
            </p>
        </div>
    </article>
)
} 

export default ReferralBanner;