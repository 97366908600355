import React from "react";
import { RootState } from "../../app/store";
import { useSelector } from "react-redux";

interface DetailProps {
  title: string;
  pay?: string;
  send?: string;

}


const DetailsCard: React.FC<DetailProps> = ({title,pay}) => {
  const sendAmount = useSelector((state: RootState) => state.transaction.sendAmount);
  const fee = useSelector((state: RootState) => state.transaction.fee);

  const parseNumber = (value: string): number => {
    const stringValue = String(value);
    const parsedValue = parseFloat(stringValue.replace(/[^0-9.-]+/g, ''));
    return isNaN(parsedValue) ? 0 : parsedValue;
  };
  
  
  const parsedAmount = parseNumber(sendAmount);
  const parsedFee = fee;
  
  const totalAmount = parsedAmount + parsedFee;



  return (
    <>
      <div className="bg-[#FCFCFC] border border-[#F1F1F1] px-4 py-3 rounded-xl mb-3">
        <p className="font-medium">{title}</p>
        <div className="flex justify-between my-3">
          <p className="text-sm text-[#747A80]">Pay from</p>
          <p className="text-sm font-medium">{pay}</p>
        </div>
        <div className="flex justify-between my-3">
          <p className="text-sm text-[#747A80]">You’re sending</p>
          <p className="text-sm font-medium">${sendAmount.toLocaleString()}</p>
        </div>
        <div className="flex justify-between my-3">
          <p className="text-sm text-[#747A80]">Transfer fee</p>
          <p className="text-sm font-medium">${fee.toLocaleString()}</p>
        </div>
        <div className="flex justify-between mt-3">
          <p className="text-sm text-[#747A80]">Total amount</p>
          <p className="text-lg font-semibold">${totalAmount.toLocaleString()}</p>
        </div>
      </div>
    </>
  );
};

export default DetailsCard;
