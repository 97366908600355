import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import UploadBusinessDocument from "./KYC/UploadBusinessDocuments";
import { getKyc } from "../../../api";
import { setKYCState } from "../../../features/Kyc/kycSlice";
import Spinner from "../../../components/PopUps/Spinner";

export interface ISteps {
  currentStep?: number;
  nextStep?: any;
  saveForLater?: any;
  handleSubmit?: any;
  kycState?: any;
}

export function UploadDocuments(props: ISteps) {
  const dispatch = useAppDispatch()
  const { KYCState } = useAppSelector((state) => state.kycInfo)
  const KYCIdentifier = localStorage.getItem("KYCIdentifier");
  const [uploadOwnerDoc, setUploadOwnerDoc] = useState(false)
  const [businessDoc, setBusinessDoc] = useState(true)


  const moveToOwner = () => {
    setBusinessDoc(false)
    setUploadOwnerDoc(true)
  }


  // useEffect(() => {
  //   if (KYCState === "PEOPLE_DOCS") {
  //     setBusinessDoc(false)
  //     setUploadOwnerDoc(true)
  //   }
  // }, [])


  useEffect(() => {
    if (KYCIdentifier) {
      getKyc(KYCIdentifier).then((res) => {
        if (res?.data?.BusinessKyc) {
          dispatch(setKYCState(res?.data?.BusinessKyc?.KYCState!));
        } else {
          dispatch(setKYCState(""));
        }
      }).catch((error) => {
        dispatch(setKYCState(""));
      });
    }
  }, [])

  if (!KYCState) {
    return <Spinner />
  }

 
  return (
    
      <>
        {/* sm:w-65% md:w-[70%] xl:w-[63%] sm:ml-12 bg-[#FFFFFF] p-4 sm:p-8 */}
        <div className="sm:w-65% md:w-[70%] xl:w-[63%]">
          {businessDoc && <UploadBusinessDocument handleBusinessDoc={moveToOwner} />}
          {/* {(uploadOwnerDoc || KYCState === "PEOPLE_DOCS") && <UploadOwnerDocument />} */}
        </div>
      </>
    
  );
}

export default UploadDocuments;
