import { Link, useLocation, useNavigate } from "react-router-dom";
import { Hero, Bank } from "../../../asset"
import logo from "../../../asset/images/logo.svg";
import authImg from '../../../asset/images/login-img.svg'
import { useEffect, useState } from "react";

const RegisterEntry = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [selectedAccountType, setSelectedAccountType] = useState<string>("");

    const handleAccountType = (value: string) => {
        setSelectedAccountType(value);
    };

    const handleContinueRegistration = () => {
        localStorage.setItem('accountType', selectedAccountType)
        navigate('/auth/register/details')
    }

    useEffect(() => {
        const pathSegments = location.pathname.split('/').filter(segment => segment !== '');
        const referralCode = pathSegments[pathSegments.length - 1];

        if (referralCode && referralCode !== 'register' && referralCode !== 'details') {
            localStorage.setItem("referralCode", referralCode)
        }
    }, [location.pathname]);

    return (
        <>
            <div className="md:flex min-h-screen">
                <section className="hidden lg:flex md:flex-col justify-center flex-1 px-4 sm:px-6 lg:flex-none xl:px-20 bg-black text-white ">
                    <div className="mx-auto w-full max-w-[24rem] 2xl:max-w-[28rem] lg:w-[30rem] xl:w-[28rem]">
                        <div className="flex justify-center pt-20 min-h-[100vh] md:items-center md:pt-0">
                            <div className="w-full">
                                <div>
                                    <Link to="/">
                                        <img className="w-auto mt-2" src={logo} alt="logo" />
                                    </Link>
                                    <h1 className="mt-16 text-4xl font-medium text-[#EBF0F0]">USD banking for you & your business</h1>
                                </div>
                                <img src={authImg} className="mt-10" alt="login" />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="relative flex-1 md:flex md:justify-center md:items-center">

                    <div className="w-full xl:w-[40rem] px-4 py-4 md:px-20 lg:px-8 md:pb-16 lg:py-16">
                        <div className="lg:hidden flex justify-center">
                            <img className='py-4 w-[10rem]'
                                src={logo} alt="Cleva banking logo" />
                        </div>
                        <h1 className="flex items-center justify-center text-center text-2xl  md:text-3xl pt-4 pb-8 text-[#292929]">Create an account</h1>
                        <section className="bg-white pt-4 px-[0.8rem] sm:p-6 lg:p-12 rounded-2xl">
                            <h3 className="text-center  text-[#191919] font-semibold text-xl">Tell us who you are</h3>
                            <p className="text-center text-sm md:text-base pt-2 text-[#5F5D5D]">This will determine the type of account you'll get.</p>
                            <div className="py-5 grid gap-4 md:gap-8">
                                <button onClick={() => handleAccountType("IndividualUser")} id="IndividualUser" className={`border border-[#D9D9D9] p-3 md:p-5 rounded-xl ${selectedAccountType === "IndividualUser" ? 'selectedBox' : ''}`}>
                                    <div className="flex justify-between">
                                        <div className="flex gap-2">
                                            <div className="bg-[#111111] p-2 w-[40px] h-[40px] rounded-full flex justify-center items-center">
                                                <img src={Hero} alt="For Freelancers or Contractors" />
                                            </div>
                                            <div className="text-left">
                                                <h1 className="text-base md:text-lg font-semibold text-[#111111]">SoloPreneur</h1>
                                                <p className="text-sm md:text-base text-[#4E4E4E]">For Freelancers, Contractors, e.t.c</p>
                                            </div>
                                        </div>
                                        <label htmlFor="IndividualUser">
                                            <input
                                                className="selectedRadio"
                                                type="radio"
                                                id="IndividualUser"
                                                name="accountType"
                                                value="IndividualUser"
                                                checked={selectedAccountType === "IndividualUser"}
                                                onChange={() => handleAccountType("IndividualUser")}
                                            />
                                        </label>
                                    </div>
                                    <p className="pt-2 text-left text-[#111111]">Account in your name</p>
                                </button>
                                <button onClick={() => handleAccountType("BusinessUser")} id="BusinessUser" className={`border border-[#D9D9D9] p-3 md:p-5 rounded-xl ${selectedAccountType === "BusinessUser" ? 'selectedBox' : ''} `}>
                                    <div className="flex justify-between">
                                        <div className="flex gap-2">
                                            <div className="bg-[#111111] p-[0.6rem] w-[40px] h-[40px] rounded-full flex justify-center items-center">
                                                <img src={Bank} alt="For Registered Business" />
                                            </div>
                                            <div className="text-left">
                                                <h1 className="text-base md:text-lg font-semibold text-[#111111]">Registered Business</h1>
                                                <p className="text-sm md:text-base text-[#4E4E4E]">For LLC, Trust, Corporation, e.t.c</p>
                                            </div>
                                        </div>
                                        <label htmlFor="BusinessUser">
                                            <input
                                                className="selectedRadio"
                                                type="radio"
                                                id="BusinessUser"
                                                name="accountType"
                                                value="BusinessUser"
                                                checked={selectedAccountType === "BusinessUser"}
                                                onChange={() => handleAccountType("BusinessUser")}
                                            />
                                        </label>
                                    </div>
                                    <p className="pt-2 text-left text-[#111111]">Account in your business’ name</p>
                                </button>

                            </div>
                            <button onClick={handleContinueRegistration} className={`bg-[#FFBD59] my-8 rounded-lg w-full py-3 ${selectedAccountType !== "" ? 'opacity-100' : 'opacity-25'}`}>Continue</button>
                        </section>

                    <div className="border-t border-[#E0E0E0] w-full mt-16 mb-4"></div>
                    <p className="pt-4 pb-8 text-center teext-base text-[#000000D9]">Returning user? <Link className="font-semibold underline" to="/auth/login">Login</Link></p>
                    </div>


                </section>
            </div>
        </>
    )
}

export default RegisterEntry