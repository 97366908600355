

interface IProductCard {
    img?: string,
    title?: string,
    content?: string,
    headerBg?: string,
    bottom?: string

}

// interface IProductList {
//     data: IProductCard[];
// }

const ProductCard = (props: IProductCard) => {
    const { img, title, content, headerBg, bottom } = props
    return (
        <div className="rounded-[1.25rem] bg-white border-[#e8e8e8] border-[0.5px] shadow-md hover:drop-shadow-2xl" >
            <header className={`${headerBg} flex flex-col items-center justify-center h-[11rem] lg:h-[13rem] relative rounded-t-[1.25rem]`}>
                {/* transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300  */}
                <img className={`absolute ${bottom} `} src={img} alt={img} />
            </header>
            <div className="px-4 pb-[2rem]">
                <h1 className="text-[#060606] font-semibold text-[1.25rem] lg:text-[1.375rem] pt-[1.91rem] pb-[1rem]">{title}</h1>
                <p className="text-[#4A4A4A] font-normal text-base lg:text-lg">{content}</p>
            </div>



        </div >
    )
}

export default ProductCard;