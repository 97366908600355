import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { setTitle } from "../../../../features/Nav/NavSlice";
import { useNavigate } from "react-router-dom";
import { setStep } from "../../../../features/Kyc/kycSlice";
import { Hero, Bank } from "../../../../asset"
import GoBackComponent from "../../../ClevaCards/utils/GoBackComponent";

const KycEntry = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const { AdditionalDetails, KYCState } = useAppSelector((state: any) => state.kycInfo);
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [IndividualUser, setIndividualUser] = useState(true)
    const user = useAppSelector((state) => state.user.userData);

    useEffect(() => {
        if (user?.BusinessIdentifier) {
            setIndividualUser(false)
        }
    }, [user])

    useEffect(() => {
        dispatch(setTitle("Account Verification"));

        if (KYCState === "VERIFIED") {
            navigate("/");
        } else if (KYCState === "BIOMETRICS") {
            if (AdditionalDetails?.VerificationPending) {
                navigate("/");
            } else {
                dispatch(setStep(2));
            }
        } else if (KYCState === "DOCUMENT_RETRY") {
            dispatch(setStep(2));
        }
        else if (KYCState === "VERIFIED") {
            navigate("/");
        } else if (KYCState === "DENIED") {
            navigate("/");
        } else if (KYCState === "SUSPENDED") {
            navigate("/");
        }
    }, [navigate])

    const handleSwitchAccountType = () => {
        if (IndividualUser) {
            navigate('/kyb')
        } else {
            navigate('/kyc/individual')
        }
    }

    const handleNavigation = () => {
        if (IndividualUser) {
            navigate('/kyc/individual')
        } else {
            navigate('/kyb')
        }
    }

    return (
        <>
            <div className="pt-16 md:w-[32rem] mx-auto xl:w-[40rem]">
                <GoBackComponent text="Back" />
            </div>
            <div className="py-6 lg:py-8 md:w-2/3 xl:w-1/2 mx-auto ">
                <div className="bg-[#FFFFFF] shadow p-3 md:p-6 lg:py-16 w-[100%] rounded-xl text-center flex flex-col justify-center  items-center">

                    <div className="bg-[#111111] p-2 w-[60px] h-[60px] rounded-full flex justify-center items-center">
                        {IndividualUser ?
                            <img src={Hero} alt="For Freelancers or Contractors" />
                            :
                            <img src={Bank} alt="For Business Owners" />
                        }
                    </div>
                    {IndividualUser ?
                        <>
                            <h1 className="text-[#111111] font-bold text-lg md:text-xl py-3 lg:pt-6">You currently have a Solopreneur account</h1>
                            <p className="text-[#111111] font-normal text-sm md:text-base">After KYC, you’ll get a Cleva USD account in your name.</p>
                        </>
                        :
                        <>
                            <h1 className="text-[#111111] font-bold text-lg md:text-xl py-3 lg:pt-6">You currently have a Business account</h1>
                            <p className="text-[#111111] font-normal text-sm md:text-base">You’ll get a Cleva USD account in your business’ name.</p>

                        </>

                    }


                    <div className="pt-4">
                        <p className="text-[#383838] font-normal text-base">Would you like to proceed or change account type?</p>
                        <div className="flex gap-4 justify-center items-center pt-4  lg:pt-8">
                            <button disabled={true} onClick={() => setShowConfirmation(true)} className="border opacity-30 border-[#0B69AD] rounded-lg py-3 px-6 text-sm text-[#0B69AD] font-bold">Change</button>
                            <button onClick={handleNavigation} className="border border-[#FFBD59] bg-[#FFBD59] rounded-lg py-3 px-6 text-sm text-[#111111] font-bold">Continue</button>
                        </div>
                    </div>
                </div>
            </div>

            {showConfirmation &&
                <div className="modal-container">
                    <div className="modal">
                        {IndividualUser ?
                            <>
                                <h1 className="text-lg">Are you sure want to change to a <b>Business Account?</b></h1>
                                <p className="text-[#111111] text-base font-normal pt-8">You’ll get a Cleva USD account in your business’ name</p>
                            </>
                            :
                            <>
                                <h1 className="text-lg">Are you sure want to change to a <b>Solopreneur Account?</b></h1>
                                <p className="text-[#111111] text-base font-normal pt-8">After KYC, you’ll get a Cleva USD account in your name.</p>
                            </>
                        }

                        <div className="flex gap-4 justify-center items-center pt-4  lg:pt-8">
                            <button onClick={() => setShowConfirmation(false)} className="border border-[#0B69AD] rounded-lg py-3 px-12 text-sm text-[#0B69AD] font-bold">No</button>
                            <button onClick={handleSwitchAccountType} className="border border-[#FFBD59] bg-[#FFBD59] rounded-lg py-3 px-12 text-sm text-[#111111] font-bold">Yes</button>
                        </div>
                    </div>
                </div>
            }

        </>
    )
}

export default KycEntry