import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';


function ReferralsRedirect() {
    const navigate = useNavigate();
    const location = useLocation()

    useEffect(() => {
        const pathSegments = location.pathname.split('/').filter(segment => segment !== '');
        const referralCode = pathSegments[pathSegments.length - 1];
   
        navigate(`/auth/register/${referralCode}`);

    }, [navigate, location.pathname, location.search]);

    return null 
}

export default ReferralsRedirect;
