import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import SpinnerTwo from "../../components/PopUps/SpinnerTwo";
import { setTitle } from "../../features/Nav/NavSlice";
import CardModal from "./CardModal";
import Card from "../../asset/cards/card.png";
import Progress from "../../asset/cards/progress-check.svg";
import Pointer from "../../asset/cards/pointer.svg";
import { fetchAccount } from "../../features/VirtualAccount/VirtualAccountApi";
import {
  createCard,
  fetchCard,
} from "../../features/ClevaCards/ClevaCardsApi";
import errIcon from "../../images/error-icon.svg";
import Spinner from "../../components/PopUps/Spinner";
import { getKyc } from "../../api";
import { setAdditionalDetails, setIndividualkyc, setKYCState } from "../../features/Kyc/kycSlice";
import { fetchController } from "../../features/Kyc/KycApi";
import { AWS_CREDS } from "../../features/services/AmazonService";

const FundCard = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [isLoading, setisLoading] = useState(false);

  const [accountFetched, setAccountFetched] = useState(false)
  const [cardFetched, setCardFetched] = useState(false)

  const userNames = useAppSelector((state) => state.clevaCards.userNames)
  const KYCIdentifier = localStorage.getItem("KYCIdentifier");
  const kycUser = useAppSelector((state) => state.kycInfo.IndividualKyc)

  const [isError, setisError] = useState(false);
  const [lowBalance, setLowBalance] = useState(false)
  const [btnError, setBtnError] = useState(false);
  const [cardAlmostReady, setcardAlmostReady] = useState(false);
  const allAccount = useAppSelector((state) => state.virtualAccount.allAccount);
  const [hasAccount, setHasAccount] = useState(true);
  const accountNumber = allAccount ? (allAccount as any).AccountNumber : " ";
  const [formValid, setFormValid] = useState<boolean>(true);
  const cardCreationFee = 2;
  const CardInitialFundingAmount = 1;
  const amountTosend = Number(cardCreationFee) + Number(CardInitialFundingAmount);
  const cards = useAppSelector((state) => state.clevaCards.cards);
  const [selectedBox, setSelectedBox] = useState<string[]>([]);

  const getLastFourDigits = (number: string) => number.slice(-4);
  const getMaskedAccountNumber = (number: string | undefined) =>
    number ? " **** " + getLastFourDigits(number) : "";
  const accBalance = allAccount ? (allAccount as any).Balance?.Money : " ";

  // Handle checkbox value
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setSelectedBox((selectedBox) => {
      if (selectedBox.includes(value)) {
        let selectedItem = selectedBox.filter((item) => item !== value);
      
        return selectedItem;
      } else {
        return [...selectedBox, value];
      }
    });
  };

  
  const handleCardCreation = () => {
    setisLoading(true);

    dispatch(createCard({ Amount: amountTosend}))
      .unwrap()
      .then((result) => {
        if (result.CardID && result.Message && result.Code === "SUCCESS") {
          setTimeout(() => {
            setisLoading(false);
            setcardAlmostReady(true);
            localStorage.setItem('hasCard', 'true')
          }, 2000);
        } else {
          setTimeout(() => {
            setisLoading(false);
            setisError(true);
            setTimeout(() => {
              setisError(false);
            }, 2000);
          }, 2000);
        }
      })
      .catch((error) => {
        if(AWS_CREDS.STAGE === 'qa'){
          console.error(error);
        }
        setTimeout(() => {
          setisLoading(false);
          setisError(true);
          setTimeout(() => {
            setisError(false);
          }, 2000);
        }, 2000);
      });
  };

  //check for low balance
  //const lowBalance = amountTosend > accBalance
  useEffect(() => {
    if (selectedBox.includes("terms")) {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  }, [selectedBox]);

  useEffect(() => {
    if (allAccount?.AccountNumber) {
      setHasAccount(true);
    } else {
      setHasAccount(false);
    }
  }, [allAccount]);

  useEffect(() => {
    if (cards && (cards as any).length > 0) {
      const lastCard = (cards as any)[(cards as any).length - 1];
      if (lastCard.Status === "ACTIVE") {
        navigate("/cards");
      }
    }
  }, [cards, location]);

  useEffect(() => {
    setLowBalance(amountTosend > accBalance);
  }, [amountTosend, accBalance]);

  useEffect(() => {
    dispatch(setTitle("Card"));
    dispatch(fetchAccount())
      .then(() => { setAccountFetched(true) })
      .catch((error) => {
        if(AWS_CREDS.STAGE === 'qa'){
          console.error("Error fetching card:", error);
        }
        setAccountFetched(false);
      });
    dispatch(fetchCard())
      .then(() => { setCardFetched(true) })
      .catch((error) => {
        if(AWS_CREDS.STAGE === 'qa'){
          console.error("Error fetching card:", error);
        }
        setCardFetched(false);
      });
  }, [dispatch]);

  useEffect(() => {
    if (KYCIdentifier) {
      getKyc(KYCIdentifier).then((res) => {
        if (res?.data?.IndividualKyc) {
          dispatch(setIndividualkyc(res?.data?.IndividualKyc))
        }  else
        if (res?.data?.BusinessKyc) {
          dispatch(setKYCState(res?.data?.BusinessKyc?.KYCState!));
          dispatch(setAdditionalDetails(res?.data?.BusinessKyc?.AdditionalDetails));
          dispatch(fetchController())
        } else {
          dispatch(setKYCState(""));
        }
      }).catch((error) => {
        if(AWS_CREDS.STAGE === 'qa'){
          console.log(error);
        }
      });
    }
    
  }, [dispatch, KYCIdentifier]);

  if (!cardFetched || !accountFetched) {
    return <Spinner />
  }

  return (
    <section className="pt-10 xl:ml-20">
      <div className="md:w-[80%] xl:w-[40rem]  mx-auto pb-10">
        <h1 className="text-[#141414] text-xl font-semibold pb-[1.86rem]">
          Cleva Virtual Card
        </h1>
        <img
          className="xl:w-[90%] rounded-[1.25rem]"
          src={Card}
          alt="Cleva virtual  card"
        />
        {cardAlmostReady ? (
          <div className="bg-white xl:w-[90%] mt-8 border border-[#E4E4E4] shadow-md rounded-[1.25rem] p-[1.5rem] md:p-10">
            <div className="flex flex-col items-center justify-center text-center">
              <img src={Progress} alt="" />
              <h1 className="text-[#111] text-base font-semibold py-4">
                Your card is getting ready
              </h1>
              <p className="text-[#494949]  text-sm font-normal">
                You’ll be notified when your card is ready for use.
              </p>

              <div className="w-[60%] mx-auto flex justify-between items-center pt-[3.04rem]">
                <Link
                  to="/"
                  className="rounded-md bg-cleva-gold cursor-pointer py-3 text-lg font-medium w-full d-block"
                >
                  {" "}
                  Go Back Home
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <section className="xl:w-[90%] bg-white border border-[#EFEFEF] shadow-md  rounded-[1.25rem] p-[1.5rem] md:p-10">
            <div className="pb-[1rem]">
              <div className="mb-4">
                <label className="text-sm pb-1 text-left">
                  Source
                  <span className="text-[#D31D1D] font-semibold pl-1">*</span>
                </label>
                <div className="rounded-lg border border-[#111] bg-[#FFFFFA] flex justify-between items-center p-[1.25rem]">
                  <p className="text-[#747A80] font-semibold text-sm">
                    Cleva USD Account {getMaskedAccountNumber(accountNumber)}
                  </p>
                  <p
                    data-test="source-account"
                    className="text-[#111] font-semibold text-sm"
                  >
                    $
                    {accBalance?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </div>

                {lowBalance && (
                  <div className="flex items-center mb-2 mt-1">
                    <img src={errIcon} alt="error" />
                    <p className="text-[#D31D1D] text-sm ml-1">
                      Your balance is low. Top up your account before creating card
                    </p>
                  </div>
                )}
                {!hasAccount && (
                  <div className="flex items-center mb-2 mt-1">
                    <img src={errIcon} alt="error" />
                    <p className="text-[#D31D1D] text-sm ml-1">
                      USD account not found.{" "}
                      <Link
                        className="text-black underline cursor-pointer"
                        to="/accounts"
                      >
                        Create
                      </Link>
                    </p>
                  </div>
                )}
                <div className="flex justify-between gap-2 pt-[0.5rem] items-center ">
                  <div className="flex items-center">
                    <img src={Pointer} alt="" />
                    <p className="text-[#747A80] font-medium text-xs ml-2">
                      Card creation fee
                    </p>
                  </div>

                  <p className="text-[#000] font-bold text-sm text-right">
                    $
                    {cardCreationFee?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </div>
              </div>
              <hr />

              <div className="flex justify-between py-5">
                <div className="w-[12rem] md:w-[18rem]">
                  <p className="text-[#1B1B1B] text-sm mb-1">
                    Amount to add to this card
                  </p>
                  <p className="text-xs text-[#626262]">
                    To activate your card, you’re required to top-up your Cleva USD
                    card
                  </p>
                </div>
                <div>
                  <p className="font-bold text-sm">
                    ${" "}
                    {CardInitialFundingAmount?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </div>
              </div>


              <div className="relative flex items-start mt-7">
                <div className="flex h-6 items-center">
                  <input
                    id="terms"
                    aria-describedby="offers-description"
                    name="terms"
                    type="checkbox"
                    value="terms"
                    checked={selectedBox.includes("terms")}
                    onChange={handleCheckboxChange}
                    className="h-4 w-4 rounded border-[#575757] border-2 text-cleva-gold focus:ring-cleva-gold"
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label htmlFor="terms" className="text-[#363636]">
                    I confirm to pay the one-time fee of $3.00
                  </label>
                </div>
              </div>
            </div>

            <button
              data-test="fund-btn"
              disabled={formValid || btnError || !hasAccount || lowBalance}
              onClick={handleCardCreation}
              className={`rounded-xl bg-[#FFBD59] cursor-pointer py-4 px-[0.75rem] text-xl font-semibold w-full ${formValid || btnError || !hasAccount || lowBalance
                ? "opacity-60"
                : "opacity-100"
                }`}
            >
              Continue
            </button>
          </section>
        )}
      </div>

      {isLoading && (
        <CardModal width="md:w-[33rem]">
          <div className="flex flex-col items-center justify-center p-16 gap-10 lg:gap-20">
            <SpinnerTwo />
            <h1 className="text-[#111] text-center font-semibold text-xl md:text-2xl">
              Creating Card...
            </h1>
          </div>
        </CardModal>
      )}

      {isError && (
      <CardModal width="md:w-[33rem]">
          <div className="flex flex-col items-center justify-center py-5 gap-4 md:p-12">
            <SpinnerTwo/>
            <h1 className="text-[#292929] text-center font-semibold text-lg w-[70%]">
              Card creation failed. Please try again later.
            </h1>
            <button className="w-[50%] rounded-lg bg-[#FFBD59] py-[0.8125rem] px-[2.125rem] font-bold text-[0.875rem] text-black" >Close</button>
          </div>
        </CardModal>
      )}
    </section>
  );
};

export default FundCard;
