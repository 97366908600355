
import { Mac, androidIcon, appleIcon } from "../../asset/landing_photos";
import gsap, { Power3 } from 'gsap';
import { useEffect } from "react";
import { motion } from "framer-motion";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import ReferralBanner from './ReferralBanner';

interface IHero {
  onLinkClick?: () => void;
}
const Hero = (props: IHero) => {

  const navigate = useNavigate();


  useEffect(() => {
    gsap.to('.hero-img', {
      duration: .8,
      opacity: 1,
      y: -20,
      ease: Power3.easeIn,
    })
  })



  return (
    // mt-[-3rem]
    <div className="bg-[#000000] md:mt-[-3rem] relative  ">
      <ReferralBanner />
      <div className="w-[90%] xl:w-[80%] mx-auto 2xl:w-[1280px]">
        <div className="flex flex-col sm:flex-row items-center lg:gap-[3rem] mt-[6rem] pt-[4rem] md:pt-[9.62rem]">
          <div className="md:w-1/2 md:block flex flex-col items-center justify-center text-center md:text-left">
            <h1 className="text-[2rem] xl:text-[3rem] text-[#DFDFDF] lg:text-left font-[700] lg:w-[31.3125rem]">
              Smart USD banking for you and your business
            </h1>
            <p className="text-[#D3D3D3] text-[1.125rem] xl:text-[1.4375rem] font-normal lg:w-[30.4375rem] pt-[1rem] pb-[2rem] md:py-[1rem] lg:pt-[1.69rem] lg:pb-[1.87rem]">
              A Cleva US-based account in your name to receive USD payments, <span className="font-bold">even as a non-US resident.</span>
            </p>
            {/* grid gap-[0.75rem] min-[375px]:flex - custom screen-width */}
            <div className="gap-[0.75rem] flex items-center">
              <a href="https://play.google.com/store/apps/details?id=com.getcleva.cleva_mobile_app" target="_blank" rel="noreferrer" className="">
                <img src={androidIcon} alt="Get it on google play" />
              </a>
              {/* <Button
                text="Get started"
                fn={() => navigate("/auth/register")}
                // px-4rem md:px-[2rem]
                style="px-[1rem] py-[1rem] text-base rounded-[0.75rem]  md:hidden"
              /> */}
              <a href="https://apps.apple.com/app/cleva-app/id6473619732" target="_blank" rel="noreferrer">
                <img src={appleIcon} alt="Get it app store" />

              </a>
            </div>
            {/* <Button
              text="Get started"
              fn={() => navigate("/auth/register")}
              style="px-[4rem] rounded-[0.75rem] md:px-[2rem]"
            /> */}
          </div>
          {/* h-[295px] sm:h-[596px] */}"
          <motion.div transition={{
            repeat: Infinity,
            repeatType: "reverse",
            duration: 2
          }} animate={{ y: 25 }} className="md:w-1/2 hidden md:block">

            <img src={Mac} alt="Laptop" className="hero-img opacity-0" />


          </motion.div>
        </div>
        {/* For anyone with global  financial need */}
        {/* <Banner /> */}
      </div>
    </div>
  );
};

export default Hero;
