import React, { ReactElement } from "react";
import AccountCard from "../../components/Layout/AccountCard";
import { AccountStatus } from "./components/AccountStatus";
import RecentTransaction from "./components/RecentTransaction";


const ViewAccount: React.FC = (): ReactElement => {

  return (
    <>
      <div className="pt-10 mb-20">
        <div className="flex justify-center items-center min-h-[75vh]">
          <div className="md:w-3/4 mx-auto">
            <AccountCard />
            <AccountStatus />
            <RecentTransaction />
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewAccount; 
