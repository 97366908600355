import { useNavigate } from 'react-router-dom'
import Button from '../Button'
import { usdhero } from '../../../asset/landing_photos';


const ClevaUSDAccounts = () => {

  const navigate = useNavigate()
  return (

    <div className='mt-28'>
      <section className='bg-black'>
        <div className="w-[90%] xl:w-[80%] mx-auto 2xl:w-[1280px] pt-8 pb-16">
          <div className="flex space-between lg:gap-16 items-center justify-center">
            <div className='hero-section'>
              <h1 className=' text-white text-4xl font-semibold md:w-[20.51931rem] lg:w-[30.51931rem]'>Your Passport to Borderless Banking</h1>
              <p className=' text-[#F1F1F1] text-xl py-8 font-normal md:w-[27.51931rem]'>Seamless cross-border payments powered by your Cleva USD account</p>
              <div className=''>
                <Button
                  text="Create Account"
                  fn={() => navigate("/auth/register")}
                  style={`px-[2rem] text-[1.125rem] rounded-[0.75rem]`}
                />
              </div>
            </div>
            <div className='hidden md:block'>
              <img loading="lazy" width="100%" height="100%"  src={usdhero} alt="Your Passport to Borderless Banking"/> 

            </div>
          </div>
        </div>
      </section>
    </div>

  )
}

export default ClevaUSDAccounts