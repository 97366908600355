import React, { useEffect, useState } from "react";
import errorImg from "../../asset/svg/material-symbols_warning.svg";
// import Err from "../../asset/cards/error.svg"
import { useAppSelector } from "../../app/hooks";
import { fetchAccount } from "../../features/VirtualAccount/VirtualAccountApi";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../app/store";
import CurrencyInput from "../../components/Layout/CurrencyInput";
import FeeIcon from "../../images/fee-icon.svg";
import USIcon from "../../images/USD.svg";
import infoIcon from "../../asset/svg/infoIcon.svg";
import TopupModal from "./Modals/TopupModal";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import GoBackComponent from "./utils/GoBackComponent";
import ValidateUserPin from "../Security/modals/ValidateUserPin";
import ForgotPin from "../Security/modals/ForgotPin";
import NewPin from "../Security/modals/NewPin";
import UpdatedPin from "../Security/modals/UpdatedPin";
import VerifyUserOTP from "../Security/modals/ExceededPin";
import { toast, ToastContainer } from "react-toastify";
import axios from "../../features/services/AxiosInstance";
import { getUserIdWithAccessToken } from "../../login";
import { ResetPin } from "../../api";
import PinLoader from "../Security/modals/PinLoader";
import { fetchCard, fundCard } from "../../features/ClevaCards/ClevaCardsApi";
import { setTitle } from "../../features/Nav/NavSlice";
import { setCardIdentifier } from '../../features/ClevaCards/ClevaCardsSlice'
import { AWS_CREDS } from '../../features/services/AmazonService';
import CardModal from "./CardModal";
import SpinnerTwo from "../../components/PopUps/SpinnerTwo";

const Topup = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [formValid, setFormValid] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const tooltipContent = "Top up fee is 1%: min of $1, max of $5";
  const allAccount = useAppSelector((state) => state.virtualAccount.allAccount);
  const [sendAmount, setAmountInput] = useState("");
  const [BtnError, setBtnError] = useState(false);
  const [topupErr, setTopupErr] = useState(false)
  const [amtError, setAmtError] = useState("");
  const [openTransactionPin, setTransactionPin] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [openForgotPin, setForgotPin] = useState(false);
  const [openUpdatedPin, setUpdatedPin] = useState(false);
  const [openNewPin, setNewPin] = useState(false);
  const [openOTP, setOTP] = useState(false);
  const cards = useAppSelector((state) => state.clevaCards.cards)
  const amountTosend = Number(sendAmount!);
  const cardIdentifier = useAppSelector((state) => state.clevaCards.cardIdentifier);
  const minFundFee = AWS_CREDS.MIN_FUND_FEE;
  const maxFundFee = AWS_CREDS.MAX_FUND_FEE;


  const [fee, setFee] = useState(minFundFee)
  const [formData, setFormData] = useState({
    sendAmount: "",
    fee: fee,
  });



  const accountNumber = allAccount ? (allAccount as any).AccountNumber : " ";

  const getLastFourDigits = (number: string) => number.slice(-4);
  const getMaskedAccountNumber = (number: string | undefined) =>
    number ? " **** " + getLastFourDigits(number) : "";
  const accBalance = allAccount ? (allAccount as any).Balance?.Money : " ";

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    try {
      setShowModal(true);
      setFormData({
        sendAmount: sendAmount,
        fee: fee,
      });
      setTransactionPin(false);

    } catch (error: any) {
      setLoading(false);
    }
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/,/g, ""); // Remove existing commas
    const amountFloat = parseFloat(value);
    setAmountInput(value);

    const parsedValue = parseFloat(value);
    let inputAmount = Number(value);

    if (isNaN(inputAmount) || !Number.isFinite(inputAmount)) {
      setAmtError("Please enter a valid amount");
      setBtnError(true);
      return;
    }

    if (accBalance < inputAmount + fee) {
      setAmtError("Insufficient account balance");
      setBtnError(true);
      return;
    } else {
      setAmtError("");
      setBtnError(false);
    }
    if (!value) {
      setAmtError("Please enter amount");
    }

    if (amountFloat <= 0) {
      setAmtError("Please enter a valid amount");
      setBtnError(true);
      return;
    }

    if (inputAmount > 1000){
      setAmtError("Maximum top-up allowed is $1000");
      setBtnError(true);
      return;
    }

    if (inputAmount < 1){
      setAmtError("Minimum top-up allowed is $1");
      setBtnError(true);
      return;
    }

    if (!accBalance) {
      setAmtError("Please create an account");
      return;
    }

    const decimalRegex = /^\d+(\.\d{0,2})?$/;
    if (!decimalRegex.test(value)) {
      setAmtError("Please enter a valid amount of maximum two decimal places");
      setBtnError(true);
      return;
    } else {
      setAmtError("");
      setBtnError(false);
    }

    // fund fee calculations 
    const myFee = inputAmount / 100
    setFee(myFee)

    if (myFee <= minFundFee) {
      return setFee(minFundFee)
    }
    if (myFee >= maxFundFee) {
      return setFee(maxFundFee)
    }

    setAmtError("");
  };

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/,/g, "");
    const parsedValue = parseFloat(value);
    const newValue = isNaN(parsedValue) ? 0 : parsedValue;
  };

  const closeModal = () => {
    setShowModal(false);
  };


  const VerifyTransaction = async () => {
    try {
      setLoading(true);
      setVerifying(true);
      const response = await dispatch(fundCard({ card: { Amount: amountTosend }, CardIdentifier: cardIdentifier }))
      if (response.payload) {

        if (response?.payload.Message === "Card funded successfully") {
          //localStorage.setItem('balChange', 'true')
          navigate("/cards");
          window.location.reload();
          setLoading(false);
          setVerifying(false);
        } else {
          toast.error("We could not process your transfer at this time. Please try again later");
        }
      } else {
        setVerifying(false);
        setTopupErr(true)
        setTransactionPin(false)
      }
    } catch (error: any) {
      setLoading(false);
      setVerifying(false);
      //toast.error(error.message);
      delete axios.defaults.headers.common['Otptoken'];
    }
  };

  const sendOTP = async () => {
    const userID = await getUserIdWithAccessToken()
    try {
      await ResetPin(userID);
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (sendAmount) {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  }, [sendAmount]);



  useEffect(() => {
    dispatch(setTitle("Card"));
    dispatch(fetchAccount());
    dispatch(fetchCard())
  }, [dispatch]);

  useEffect(() => {
    // Check if cards and CardIdentifier are available

    if (cards && (cards as any)?.length > 0){
      const activeCard = (cards as any).find((card:any) => ["ACTIVE", "PENDING", "FROZEN"].includes(card.Status));
      if (activeCard) {
        const { CardIdentifier } = activeCard;
        dispatch(setCardIdentifier(CardIdentifier));
      }
    }

  }, [dispatch, cardIdentifier, cards]);


  return (
    <>

      <div className="relative flex-1">
        <div className="flex justify-center pt-10 items-center">
          <div>
            <GoBackComponent text="Top Up" />
            <div className="bg-[#fff] login-card-md w-full md:w-[32rem] xl:w-[32rem] px-8 py-8 md:px-20 md:py-16 shadow rounded-lg mt-10 md:mt-16">
              <div className="">
                {/* error banner */}
                {error !== "" && (
                  <div
                    className="w-full px-5 flex my-[2.81rem] text-[#800205] font-[500]
       rounded-lg
       justify-center text-sm items-center h-[3.75rem] bg-[#FFDCE0] "
                  >
                    <img src={errorImg} className="" alt="error" />
                    <span className="text-[#800205] pl-2  font-normal">
                      {error}
                    </span>
                  </div>
                )}

                {/* form section  */}

                <form onSubmit={handleSubmit} className=" ">
                  <div className="mb-6">
                    <label className="text-sm pb-1 text-left">
                      Source
                      <span className="text-[#D31D1D] font-semibold pl-1">
                        *
                      </span>
                    </label>
                    <div
                      style={{ background: "#FFFFFA" }}
                      className="input-control"
                    >
                      <div className="flex justify-between py-1">
                        <p className="text-[#747A80] text-sm">
                          Cleva USD Account
                          {getMaskedAccountNumber(accountNumber)}
                        </p>
                        <p className="text-[#747A80]">
                          $
                          {accBalance?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </p>
                      </div>
                    </div>
                  </div>

                  <CurrencyInput
                    title="Amount"
                    value={sendAmount?.toLocaleString()}
                    fn={handleAmountChange}
                    onBlur={handleBlur}
                    type="text"
                    err={amtError ? amtError : ""}
                    placeholder="0.00"
                    code="USD"
                    flag={USIcon}
                  />
                  <div className="flex items-center my-1">
                    <div className="flex items-center">
                      <img src={FeeIcon} alt="fee" />

                      <p className="text-xs text-[#747A80] mr-4 ml-2">
                        Top up fee:
                      </p>
                    </div>
                    <p className="text-sm font-bold">
                      $ {fee.toFixed(2)}{" "}
                    </p>

                    <p>
                      <a
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={tooltipContent}
                      >
                        <img src={infoIcon} className="ml-1" alt="" />
                      </a>
                    </p>
                  </div>
                  <div className="mt-10">
                    <button
                      type="submit"
                      disabled={formValid ||
                        !!amtError ||
                        !!BtnError
                      }
                      className={`bg-cleva-gold opacity-${formValid || !!amtError || !!BtnError ? "60" : "100"
                        } flex items-center justify-center mb-3 md:mb-0 font-medium rounded-lg md:py-2.5 py-3 px-4 md:px-8 shadow-sm w-[100%]`}
                    >
                      Add funds
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Tooltip id="my-tooltip" />

      {showModal && <TopupModal formData={formData} closeModal={closeModal} openTransactionPin={() => {
        closeModal();
        setTransactionPin(true);
      }} />}

      {(!verifying && openTransactionPin) &&
        <ValidateUserPin
          VerifyTransaction={VerifyTransaction}
          openForgotPinModal={() => {
            setTransactionPin(false);
            setForgotPin(true)
            sendOTP()
          }}
          sendOTP={() => {
            setTransactionPin(false);
            setOTP(true)
            sendOTP()
          }}
          closeModal={() => setTransactionPin(false)} />}

      {openForgotPin &&
        <ForgotPin
          openNewPinModal={() => {
            setForgotPin(false);
            setNewPin(true);
          }}
          closeModal={() => setForgotPin(false)} />}


      {openOTP &&
        <VerifyUserOTP
          openNewPinModal={() => {
            setOTP(false);
            setNewPin(true);
          }}
          closeModal={() => setOTP(false)} />}


      {openNewPin &&
        <NewPin
          openUpdatedPinModal={() => {
            setNewPin(false);
            setUpdatedPin(true)
          }}
          closeModal={() => setNewPin(false)} />}

      {openUpdatedPin &&
        <UpdatedPin closeModal={() => setUpdatedPin(false)} />}


      {verifying &&
        <PinLoader text="Topping-up Card..." />}

      {topupErr && (
        <CardModal width="md:w-[27rem]" closeModal={() => setTopupErr(false)}>
          <div className="flex flex-col items-center justify-center py-8 gap-4">
            {/* <img src={Err} alt="Error"/> */}
            <SpinnerTwo />
            <h1 className="text-[#292929] text-center font-semibold text-base w-[70%]">
              Card top-up is temporarily unavailable. Please try again in some hours.
            </h1>
            <button className="w-[50%]  rounded-lg bg-[#FFBD59] py-[0.8125rem] px-[2.125rem] font-bold text-[0.875rem] text-black" onClick={() => setTopupErr(false)}>Close</button>
          </div>
        </CardModal>
       )} 

      <ToastContainer />

    </>

  );
};

export default Topup;
