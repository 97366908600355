import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { verifiedIcon } from "../../../Image";

interface ModalProps {
    closeModal: () => void;
}
const UpdatedPin = ({closeModal }:ModalProps) => {
    const cancelButtonRef = useRef(null);
    const [open, setOpen] = useState(true);
  
  const mobileStyle = "max-[768px]:top-[35%] max-[768px]:left-1/2  max-[768px]:w-full max-[768px]:h-[400px] max-[768px]:transform max-[768px]:-translate-x-1/2 max-[768px]:-translate-y-1/2"

  const Continue = () => {
    setOpen(false);
    closeModal();
  };
  
  return (
    <div>
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={Continue}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className={`fixed inset-0 z-10 overflow-y-auto ${mobileStyle}`}>
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white p-10 text-left shadow-xl transition-all md:max-w-lg w-[23rem]">

                <div className="flex justify-center  w-full items-center p-8">
                <center className="">
                   <img className="sm:w-[40px] md:w-[87px]" src={verifiedIcon} alt="" />
                   <h2 className="text-[#000] font-semibold pt-4 w-full md:w-[300px] text-xl">
                        Transaction PIN Updated!
                    </h2>
                     <p className="py-3 text-[16px] md:text-[13px] text-[#444444] font-medium">
                     Your transaction PIN was successfully changed. You can now use your PIN to authorize your transactions.
                    </p>           
                    <button
                        onClick={() => Continue()}
                        className="w-full px-8 text-[15px] font-semibold p-3 rounded-lg mt-8 border bg-[#FFBD59]">
                        Continue
                    </button>   
                </center>
                </div>
              
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    </div>
  );
};

export default UpdatedPin;
