import FAQ from "../../asset/landing_photos/faq.svg";
import Accordion from "./Accordion";
import { faq } from "./data";
import Next from "../../asset/landing_photos/right-angle.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";

interface IFaq {
    innerRef?: React.RefObject<HTMLDivElement>;
}

const FAQs = (props: IFaq) => {
    const { innerRef } = props;
    const navigate = useNavigate();
    const location = useLocation().pathname;
    const isFaqPage = location.includes('faq')

    let data;
    if (!isFaqPage) { data = faq.slice(0, 3) }
    else { data = faq }

    const handleScroll = () => {
        navigate("/faq")
    }

    useEffect(() => {
        if (location === "/faq") {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }
    }, [location]);




    return (
        <section ref={innerRef} id="faq" className="">
            <div className="py-[2rem] md:py-[4.81rem] w-[80%] mx-auto 2xl:w-[1280px]">
                <div className={`grid place-items-center text-center text-black pb-[2rem] md:pb-[4.5rem] ${isFaqPage && 'hidden'}`}>
                    <img className="animate-bounce" src={FAQ} alt={FAQ} />
                    <h1 className="text-[1.375rem] lg:text-[2rem] font-[700] leading-normal">Frequently Asked Questions</h1>
                    <p className="pt-[.5rem] text-[0.875rem] lg:text-[1.25rem] font-normal leading-normal">Get answers to your questions about Cleva</p>
                </div>
                <div className="lg:w-[52.1875rem] lg:mx-auto">
                    <Accordion data={data} />
                </div>
                <div className={`flex items-center justify-center pt-[2.94rem] ${isFaqPage && 'hidden'}`}>
                    <button onClick={handleScroll} className="flex gap-[0.5rem] items-center py-[0.875rem] lg:py-[1.125rem] px-[0.75rem] lg:px-[2rem] rounded-[0.75rem] border-[#B6B6B6] text-[1.25rem] font-[500] border-[1px] text-[1.25rem]transition-all duration-500 hover:text-[#fff] hover:bg-[#000]">
                        <span>See all FAQs</span>
                        <img src={Next} alt={Next} />
                    </button>
                </div>
            </div>
        </section>
    )
}

export default FAQs;