import { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { Link, useNavigate } from "react-router-dom";
import { setTitle } from "../../features/Nav/NavSlice";
import kycIcon from "../../asset/images/kyc.svg"
import KycCard from "./components/KycCard";
import { FiChevronRight } from "react-icons/fi";
import { setStep, setKYCState, setAdditionalDetails } from "../../features/Kyc/kycSlice";
import { getKyc } from "../../api";
import useGoBack from "../ClevaCards/utils/handleGoBack";

function KYC() {
  const dispatch = useAppDispatch();
  const { AdditionalDetails, KYCState } = useAppSelector((state: any) => state.kycInfo);
  const navigate = useNavigate()
  const KYCIdentifier = localStorage.getItem("KYCIdentifier");
  const [loading, setLoader] = useState(true);
  const goBack = useGoBack()

  useEffect(() => {
    if (KYCIdentifier) {
      getKyc(KYCIdentifier).then((res) => {
        if (res?.data?.IndividualKyc) {
          dispatch(setKYCState(res?.data?.IndividualKyc?.KYCState!));
          dispatch(setAdditionalDetails(res?.data?.IndividualKyc?.AdditionalDetails));
        }
        else if (res?.data?.BusinessKyc) {
          dispatch(setKYCState(res?.data?.BusinessKyc?.KYCState!));
          dispatch(setAdditionalDetails(res?.data?.BusinessKyc?.AdditionalDetails));
        }
        else {
          dispatch(setKYCState(""));
        }
        setLoader(false);
      }).catch((error) => {
        setLoader(false);
        dispatch(setKYCState(""));
      });
    } else {
      setLoader(false);
    }
  }, []);

  useEffect(() => {
    dispatch(setTitle("KYC Verification"));

    if (KYCState === "VERIFIED") {
      navigate("/");
    } else if (KYCState === "BIOMETRICS") {
      if (AdditionalDetails?.VerificationPending) {
        navigate("/");
      } else {
        dispatch(setStep(2));
      }
    } else if (KYCState === "DOCUMENT_RETRY") {
      dispatch(setStep(2));
    }
    else if (KYCState === "VERIFIED") {
      navigate("/");
    } else if (KYCState === "DENIED") {
      navigate("/");
    } else if (KYCState === "SUSPENDED") {
      navigate("/");
    }
  }, [navigate])

  return (
    <>
      <div className="py-6 lg:py-16 md:w-10/12 xl:w-2/3 mx-auto">
        <div className="bg-gray-50 shadow p-6 lg:px-20 lg:py-16 w-[100%] rounded-xl">
          <div className="text-center">
            <img src={kycIcon} alt="" srcSet="" className="inline-block" />
            <p className="text-xl font-semibold mt-4">Let’s verify your identity</p>
            <p className="text-xs text-[#545454] mt-1">Completing KYC is a required step to unlock access to our exciting features.</p>
          </div>

          <div>
            <p className="text-center text-[#202020] my-6">3 simple steps to complete KYC</p>
            <KycCard number={1} mainText="Provide your personal information" secText="Your legal name, date of birth, e.t.c" />
            <KycCard number={2} mainText="Upload your ID" secText="Passport, voters card, driver’s license, or national ID (NIN)" />
            <KycCard number={3} mainText="Upload your selfie" secText="Selfie of you holding your ID" />

          </div>

          <div>
            <p className="text-[#747A80] text-sm">Ensure your documents are valid and you’ll be verified within 1 day.</p>

            <div className="flex justify-between items-center mt-10">

              <button onClick={goBack} className="border border-[#5E5E5E]  rounded-lg font-bold py-3 px-4 md:px-6">
                Back
              </button>
              <Link to="/kyc-info" className="bg-cleva-gold text-[#111111] font-medium text-sm py-3 px-4 md:px-6 rounded-lg">
                <div className="flex items-center">
                  <p>Get Started</p>
                  <FiChevronRight className="text-base font-bold ml-3" />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default KYC;
