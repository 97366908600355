import BusinessModal from "./BusinessModal";

const InvalidRegistrationModal = ({ handleClose }: any) => {
  return (
    <BusinessModal handleClose={handleClose} 
     text = "The registration number entered does not exist. Please enter a valid number and try again."
     title = "Invalid Registration Number"
     actionBtn="Enter Registration Number"
     displayRetry={false}
     />
  )
}

export default InvalidRegistrationModal