import WebLayout from "../landing_page/components/WebLayout"
import Img from "../../asset/svg/referral.svg";
import Facebook from "../../asset/blogPost/facebook.svg";
import Twitter from "../../asset/blogPost/twitter.svg";
import Linkedin from "../../asset/blogPost/linkedin.svg";
import { AWS_CREDS } from '../../features/services/AmazonService';
import { FacebookShareButton } from "react-share";
import { Helmet } from 'react-helmet-async';

export default function ReferralsWeb() {

    const maxReferralBonus = AWS_CREDS.MAX_REFERRAL_BONUS ||  200;
    const bonusPerReferredUser = AWS_CREDS.BONUS_PER_REFERRED_USER || 10;
    const maxDepositWaiver = AWS_CREDS.MAX_DEPOSIT_WAIVER || 2;
    const bonusGivenAtDeposit = AWS_CREDS.BONUS_GIVEN_AT_TOTAL_DEPOSIT_AMOUNT || 300;

    const postTitle = `Refer and Earn With Cleva 🎉`;
    const postDescription = `Earn up to $${bonusGivenAtDeposit} by spreading the word about Cleva and our convenient USD banking services using your unique referral link or code.`;
    const postUrl = `https://getcleva.com/refer-and-earn`;


    const handleTwitterShare = () => {
        const url = 'https://twitter.com/intent/tweet?text=' + encodeURIComponent(postTitle) + '&url=' + encodeURIComponent(postUrl);
        window.open(url, "_blank", "width=600,height=400");
    }

    const handleLinkedinShare = () => {
        const linkedInShareLink = `https://www.linkedin.com/sharing/share-offsite/?url=https://getcleva.com/refer-and-earn&summary=${postTitle}`;
        window.open(linkedInShareLink, "_blank", "width=600,height=400");
    }


    return (
        <>
            <Helmet>
                {/* Open Graph Protocol (OGP) metadata for Facebook and LinkedIn */}
                <title>Refer and Earn on Cleva 🎉</title>
                <meta name="title" property="og:title" content="Refer and Earn on Cleva 🎉" />

                <meta name="description" content={postDescription} />
                <meta property="og:title" content={postTitle} />
                <meta property="og:description" content={postDescription} />
                <meta property="og:image" content="https://i.imgur.com/u82tUKB.png" />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="article" />
                {/* Twitter Card metadata for Twitter */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={postTitle} />
                <meta name="twitter:description" content={postDescription} />
                <meta name="twitter:image" content="https://i.imgur.com/u82tUKB.png" />
                <meta name="twitter:url" content={window.location.href} />
                <meta name="twitter:site" content={postUrl} />
            </Helmet>

            <WebLayout>

                <div className="bg-[#FAFAFA]">
                    <main className="w-[90%] md:w-[80%] xl:w-[1024px]  mx-auto pt-40 pb-8">
                        <h1 className="text-[#000000] text-2xl xl:text-4xl lg:leading-[3.75rem] pb-[2.14rem] font-bold xl:w-[55rem]">Refer friends to Cleva and earn up to ${maxReferralBonus} 🎉</h1>

                        <img src={Img} alt="Refer and earn" className=" rounded-lg" />

                        <div className="pt-16">
                            <article className='text-black font-normal text-lg leading-[2.75rem]'>
                                We're excited to introduce Cleva's Referral Program, a fantastic way for you to earn real cash while sharing the benefits of our USD banking services with your friends and family. It's a win-win for everyone involved!
                                <br />
                                <br />
                                <b>You get ${bonusPerReferredUser} for each referral when they receive up to ${bonusGivenAtDeposit} in deposits.</b>  Your cash bonus will be deposited into your balance and available for payout anytime.

                                <h3 className='text-2xl font-bold my-12'>Here is how it works:</h3>

                                <ol className='list-decimal ml-4'>
                                    <li className='font-bold'>Share your referral link:</li>
                                    <ul className='list-disc ml-10'>
                                        <li className='text-lg leading-[2.75rem]'>Log into your Cleva account and click on the “Referrals” tab
                                        </li>
                                        <li className='text-lg leading-[2.75rem]'> Copy your referral link or code
                                        </li>
                                        <li className='text-lg leading-[2.75rem]'> Email, text, or share it on social media
                                        </li>
                                    </ul>
                                    <li className='font-bold mt-4'> You and your friend both win</li>
                                    <ul className='list-disc ml-10'>
                                        <li className='text-lg leading-[2.75rem]'>When your invited friend signs up and receives up to ${bonusGivenAtDeposit} in deposits into their Cleva USD account,  you get ${bonusPerReferredUser}.
                                        </li>
                                        <li className='text-lg leading-[2.75rem]'>Your friend also gets <b>a waiver on their first deposit fee (up to ${maxDepositWaiver} is waived)</b>, making it even more rewarding to get started with Cleva.
                                        </li>
                                    </ul>
                                    <li className='font-bold mt-4'> Repeat and earn up to ${maxReferralBonus}</li>
                                    <ul className='list-disc ml-10'>
                                        <li className='text-lg leading-[2.75rem]'>You can keep inviting more friends and earn up to  ${maxReferralBonus}</li>
                                    </ul>
                                </ol>

                                <div className=' mt-20'>
                                    <span className='font-bold  text-xl'>Cash Bonus Payout:</span>
                                    <ul className='list-disc ml-10'>
                                        <li className='text-lg leading-[2.75rem]'>Your cash bonus will be deposited directly into your Cleva account balance.</li>
                                    </ul>
                                </div>

                                <p className="py-20">Start sharing your referral code today to earn real cash, while introducing your loved ones to hassle-free USD banking. It's an easy and rewarding way to share the benefits of Cleva with your network!</p>
                            </article>
                        </div>
                        <div className="">
                            <p className="pb-4 text-base">Share this post</p>
                            <div className="flex gap-4 cursor-pointer">
                                <FacebookShareButton url={postUrl} quote={postTitle}>
                                    <img className="cursor-pointer" src={Facebook} alt="Share on Facebook" />

                                </FacebookShareButton>
                                <img className="cursor-pointer" onClick={handleTwitterShare} src={Twitter} alt="Share on twitter" />
                                <img className="cursor-pointer" onClick={handleLinkedinShare} src={Linkedin} alt="Share on Linkedin" />
                            </div>
                        </div>



                    </main>
                </div>


            </WebLayout>
        </>
    )
}