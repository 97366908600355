import BusinessModal from "./BusinessModal"

const MismatchBusiness = ({handleClose, retryCount}:any) => {
  return (
    <BusinessModal handleClose={handleClose} 
    text = "The details you entered don’t match the information on your registration number."
    title = "Business details don’t match"
    actionBtn="Enter Valid Registration Number"
    displayRetry={true}
    retryCount={retryCount}
    />
  )
}

export default MismatchBusiness