import React, { useEffect, useState } from 'react'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { useNavigate } from 'react-router'
import SmallModal from '../../../components/PopUps/SmallModal'
import DeleteRecipientModal from './DeleteRecipientModal'
import { useAppDispatch } from '../../../app/hooks'
import {setModalState} from '../../../features/Kyc/kycSlice'

export default function EventPop({ RecipientIdentifier, RecipientName, RecipientTag, closeDropdown}: any) {
  const navigate = useNavigate()
  const [delModal, setDelModal] = useState(false)

  const dispatch = useAppDispatch()

  function handleClose() {
    navigate('/recipients')
    dispatch(setModalState(false))
    closeDropdown()
  }

  useEffect(() => {
  }, [dispatch])

  return (
    <SmallModal closeDropdown={closeDropdown}>
      <ul className="leading-[3em]">
        <li
          onClick={() => {
            setDelModal(!delModal)
          }}
          className="cursor-pointer hover:bg-slate-100 flex items-center gap-3 px-4">
          <span className="text-[14px]">
            <RiDeleteBin6Line />
          </span>
          Delete
        </li>
      </ul>
      {delModal && <DeleteRecipientModal
          RecipientIdentifier={RecipientIdentifier}
          RecipientName={RecipientName}
          RecipientTag={RecipientTag}
          closeModal={handleClose}
      />}
    </SmallModal>
  )
}