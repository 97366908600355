import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchController, fetchKYC, fetchOwner, fetchOwners } from "./KycApi";

export interface ContactDetails {
  PhoneNumber: string;
  Email: string;
}

export interface RegisteredAddress {
  StreetAddress: string;
  SecondStreetAddress?: string;
  City: string;
  Country: string;
  StateOrTerritory: string;
  Zipcode?: string;
  LGA?: string;
}

export interface BusinessDocumentsItems {
  CertificateOfIncorporation: Document;
  StatusReport: Document;
  MemorandumOfAssociation: Document;
}

export interface IdentificationDocument {
  DocumentNumber?: string;
  DocumentType: string;
  IssuingCountry?: string;
  IssueDate?: string;
  ExpirationDate?: string;
}

export interface Controller {
  Address?: RegisteredAddress;
  CountryOfCitizenship: string;
  DateOfBirth: string;
  Email: string;
  FirstName: string;
  LastName: string;
  MiddleName?: string;
  JobTitle: string;
  NationalIdentifier?: string;
  ProfileLink: string;
  KYCIdentifier: string;
  IsOwnerAndController: boolean;
  PercentageOwnership: string;
  DocumentVerificationPending: boolean;
  IdentificationDocument: IdentificationDocument;
  ImageUploadItem: Document;
}
export interface BeneficialOwner {
  Address: RegisteredAddress;
  CountryOfCitizenship: string;
  DateOfBirth: string;
  FirstName: string;
  LastName: string;
  Email: string;
  MiddleName?: string;
  NationalIdentifier?: string;
  KYCIdentifier: string;
  IsOwnerAndController: boolean;
  PercentageOwnership: string;
  DocumentVerificationPending: boolean;
  IdentificationDocument: IdentificationDocument;
  ImageUploadItem: Document;
}

export interface Document {
  DocumentType: string;
  data?: string;
  contentType?: string;
  filename: string;
  status?: string;
  message?: string;
  size?: number;
}

export interface BusinessKyc {
  BusinessName: string;
  BusinessRegistrationNumber: string;
  ContactDetails: ContactDetails;
  CountryOfIncorporation: string;
  DateOfIncorporation: string;
  FundingSources: string[];
  Industry: string;
  MonthlyIncome: string;
  RegisteredAddress: RegisteredAddress;
  Type: string;
  Website?: string;
  AdditionalDetails: any;
  DocumentVerificationPending: boolean | null;
  BusinessDocumentsItems: BusinessDocumentsItems;
  DisclosuresAccepted: boolean;
}

export interface AdditionalDetails {
  SelfieUploadUrl?: {
    url: string;
  };
  IDUploadUrl?: {
    url: string;
  };
}

export interface IndividualKyc {
  FirstName: string;
  MiddleName?: string;
  LastName: string;
  DateOfBirth: string;
  NationalIdentifierType: string;
  Address: RegisteredAddress;
  PhoneNumber: any;
  Email: string;
  NationalIdentifier: string;
  IdentificationDocument: IdentificationDocument;
  AdditionalDetails?: AdditionalDetails;
  ProfileLink: string;
  MonthlyIncome?: string;
  FundingSources: string[];
}

interface Controllers {
  Controller: Controller;
  BusinessIdentifier: string;
  BusinessKycState: string;
  KYCIdentifier: string;
  KYCState: string;
  PersonType: string;
}

interface IKycState {
  modalState: boolean;
  modalSedtDelete: boolean;
  BusinessKyc: BusinessKyc;
  IndividualKyc: IndividualKyc;
  Controller: Controller;
  Controllers: Controllers[];
  BeneficialOwner: BeneficialOwner;
  BeneficialOwners: BeneficialOwner[];
  closeEditModal: boolean;
  KYCState: any;
  SubKYCState?: any;
  accountType: any;
  index: any;
  step: any;
  newOrEdit: boolean;
  body: any;
  incrementalKyc: boolean;
  AdditionalDetails: any;
  completedSteps: any[];
  loading: boolean;
  error: string | null;
}

const initialState: IKycState = {
  IndividualKyc: {
    FirstName: "",
    MiddleName: "",
    LastName: "",
    DateOfBirth: "",
    NationalIdentifierType: "",
    Address: {
      StreetAddress: "",
      City: "",
      Country: "",
      StateOrTerritory: "",
    },
    PhoneNumber: "",
    Email: "",
    ProfileLink: "",
    MonthlyIncome: "",
    FundingSources: [],
    NationalIdentifier: "",
    IdentificationDocument: {
      DocumentNumber: "",
      DocumentType: "",
      IssuingCountry: "",
    },
    AdditionalDetails: {
      SelfieUploadUrl: { url: "" },
      IDUploadUrl: { url: "" },
    },
  },
  BusinessKyc: {
    Type: "",
    BusinessName: "",
    DateOfIncorporation: "",
    MonthlyIncome: "",
    FundingSources: [],
    Industry: "",
    DocumentVerificationPending: null,
    DisclosuresAccepted: false,
    AdditionalDetails: "",
    RegisteredAddress: {
      StreetAddress: "",
      City: "",
      Country: "",
      StateOrTerritory: "",
      Zipcode: "",
    },
    Website: "",
    BusinessRegistrationNumber: "",
    CountryOfIncorporation: "",
    ContactDetails: {
      PhoneNumber: "",
      Email: "",
    },
    BusinessDocumentsItems: {
      CertificateOfIncorporation: {
        DocumentType: "",
        contentType: "",
        filename: "",
        size: 50,
      },
      StatusReport: {
        DocumentType: "",
        contentType: "",
        filename: "",
        size: 50,
      },
      MemorandumOfAssociation: {
        DocumentType: "",
        contentType: "",
        filename: "",
        size: 50,
      },
    },
  },
  Controller: {
    FirstName: "",
    MiddleName: "",
    LastName: "",
    DateOfBirth: "",
    KYCIdentifier: "",
    IsOwnerAndController: false,
    PercentageOwnership: "",
    DocumentVerificationPending: false,
    Address: {
      StreetAddress: "",
      City: "",
      Country: "",
      StateOrTerritory: "",
    },
    CountryOfCitizenship: "",
    Email: "",
    ProfileLink: "",
    JobTitle: "",
    NationalIdentifier: "",
    IdentificationDocument: {
      DocumentNumber: "",
      DocumentType: "",
      IssuingCountry: "",
    },
    ImageUploadItem: {
      DocumentType: "",
      contentType: "",
      filename: "",
      size: 50,
    },
  },
  Controllers: [
    {
      Controller: {
        FirstName: "",
        MiddleName: "",
        LastName: "",
        DateOfBirth: "",
        KYCIdentifier: "",
        IsOwnerAndController: false,
        PercentageOwnership: "",
        DocumentVerificationPending: false,
        Address: {
          StreetAddress: "",
          City: "",
          Country: "",
          StateOrTerritory: "",
        },
        CountryOfCitizenship: "",
        Email: "",
        ProfileLink: "",
        JobTitle: "",
        NationalIdentifier: "",
        IdentificationDocument: {
          DocumentNumber: "",
          DocumentType: "",
          IssuingCountry: "",
        },
        ImageUploadItem: {
          DocumentType: "",
          contentType: "",
          filename: "",
          size: 50,
        },
      },
      BusinessIdentifier: "",
      BusinessKycState: "",
      KYCIdentifier: "",
      KYCState: "",
      PersonType: "",
    },
  ],
  BeneficialOwners: [
    {
      FirstName: "",
      MiddleName: "",
      LastName: "",
      DateOfBirth: "",
      Email: "",
      KYCIdentifier: "",
      IsOwnerAndController: false,
      PercentageOwnership: "",
      DocumentVerificationPending: false,
      Address: {
        StreetAddress: "",
        City: "",
        Country: "",
        StateOrTerritory: "",
      },
      CountryOfCitizenship: "",
      NationalIdentifier: "",
      IdentificationDocument: {
        DocumentNumber: "",
        DocumentType: "",
        IssuingCountry: "",
      },
      ImageUploadItem: {
        DocumentType: "",
        contentType: "",
        filename: "",
        size: 50,
      },
    },
  ],
  BeneficialOwner: {
    FirstName: "",
    MiddleName: "",
    LastName: "",
    DateOfBirth: "",
    KYCIdentifier: "",
    Email: "",
    IsOwnerAndController: false,
    PercentageOwnership: "",
    DocumentVerificationPending: false,
    Address: {
      StreetAddress: "",
      City: "",
      Country: "",
      StateOrTerritory: "",
    },
    CountryOfCitizenship: "",
    NationalIdentifier: "",
    IdentificationDocument: {
      DocumentNumber: "",
      DocumentType: "",
      IssuingCountry: "",
    },
    ImageUploadItem: {
      DocumentType: "",
      contentType: "",
      filename: "",
      size: 50,
    },
  },
  modalState: false,
  modalSedtDelete: false,
  closeEditModal: false,
  accountType: localStorage.getItem("accountType"),
  index: 0,
  step: 1,
  newOrEdit: false,
  body: {},
  incrementalKyc: false,
  KYCState: null,
  completedSteps: [],
  AdditionalDetails: { VerificationPending: false },
  loading: false,
  error: null,
  SubKYCState: null,
};

export const KycSlice = createSlice({
  name: "kycInfo",
  initialState,
  reducers: {
    resetKYBState(state) {
      Object.assign(state, initialState);
    },
    setBusinesskyc(state, action: PayloadAction<BusinessKyc>) {
      state.BusinessKyc = action.payload;
    },
    setIndividualkyc(state, action: PayloadAction<IndividualKyc>) {
      state.IndividualKyc = action.payload;
    },
    setControllerInformation(state, action: PayloadAction<Controller>) {
      state.Controller = action.payload;
    },
    setBeneficialOwner(state, action: PayloadAction<BeneficialOwner>) {
      state.BeneficialOwner = action.payload;
    },
    setIncrementalKyc(state, action: PayloadAction<boolean>) {
      state.incrementalKyc = action.payload;
    },
    // updateBeneficiaryOwner(state, action: PayloadAction<any>) {
    //   const options: any = action.payload;
    //   const owner: any = { ...state.BusinessKyc.BeneficialOwners[options.index] };
    //   state.BusinessKyc.BeneficialOwners[options.index] = { ...owner, ...options.body };
    // },
    // updateBusinessDocument(state, action: PayloadAction<any>) {
    //   const options: any = action.payload;
    //   const document: any = { ...state.BusinessKyc.BusinessDocumentsItems[options.index] };
    //   state.BusinessKyc.BusinessDocuments[options.index] = { ...document, ...options.body };
    // },
    setKYCState(state, action: PayloadAction<any>) {
      state.KYCState = action.payload;
    },
    setSubKYCState(state, action: PayloadAction<any>) {
      state.SubKYCState = action.payload;
    },
    setStep(state, action: PayloadAction<any>) {
      state.step = action.payload;
    },
    setCompletedSteps(state, action: PayloadAction<any>) {
      state.completedSteps = [...state.completedSteps, action.payload];
    },
    setAdditionalDetails(state, action: PayloadAction<any>) {
      state.AdditionalDetails = action.payload;
    },
    setAccountType(state, action: PayloadAction<string>) {
      state.accountType = action.payload;
    },
    setModalState(state, action: PayloadAction<any>) {
      state.modalState = action.payload;
    },
    setIndex(state, action: PayloadAction<any>) {
      state.index = action.payload;
    },
    openForm(state, action: PayloadAction<boolean>) {
      state.newOrEdit = action.payload;
    },
    setModalSedtDelete(state, action: PayloadAction<boolean>) {
      state.modalSedtDelete = action.payload;
    },
    setCloseEditModal(state, action: PayloadAction<boolean>) {
      state.closeEditModal = action.payload;
    },
    updateIDAndSelfieDocuments: (
      state,
      action: PayloadAction<{ IDDocument: Document; selfieDoc: Document }>
    ) => {
      const { IDDocument, selfieDoc } = action.payload;
      state.IndividualKyc.AdditionalDetails = {
        IDUploadUrl: { url: IDDocument.data || "" },
        SelfieUploadUrl: { url: selfieDoc.data || "" },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOwners.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOwners.fulfilled, (state, action) => {
        state.loading = false;
        state.BeneficialOwners = action.payload;
      })
      .addCase(fetchOwners.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Error fetching beneficials";
      });
    builder
      .addCase(fetchOwner.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOwner.fulfilled, (state, action) => {
        state.loading = false;
        state.BeneficialOwner = action.payload;
      })
      .addCase(fetchOwner.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Error fetching owner";
      })
      .addCase(fetchKYC.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchKYC.fulfilled, (state, action) => {
        state.loading = false;
        state.BusinessKyc = action.payload;
      })
      .addCase(fetchKYC.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Error fetching kyc";
      })
      .addCase(fetchController.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchController.fulfilled, (state, action) => {
        state.loading = false;
        state.Controllers = action.payload;
      })
      .addCase(fetchController.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Error fetching kyc";
      });
  },
});

export const {
  resetKYBState,
  setBusinesskyc,
  setIndividualkyc,
  setControllerInformation,
  setBeneficialOwner,
  // updateBeneficiaryOwner,
  // updateBusinessDocument,
  setModalState,
  setModalSedtDelete,
  setCloseEditModal,
  setAccountType,
  setStep,
  setIncrementalKyc,
  setIndex,
  setCompletedSteps,
  setAdditionalDetails,
  setKYCState,
  setSubKYCState,
  openForm,
  updateIDAndSelfieDocuments,
} = KycSlice.actions;
export default KycSlice.reducer;
