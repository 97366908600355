import { useEffect } from "react";
import { Timeline } from "../../components/Features";
import BeneficialOwners from "../beneficiaryOwners";
import BusinessDetails from "../businessDetails";
import UploadDocuments from "../uploadDocuments";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { setStep, setIndex, openForm, setCompletedSteps, setBusinesskyc } from "../../../../features/Kyc/kycSlice";
import { setTitle } from "../../../../features/Nav/NavSlice";
import { ToastContainer, toast } from "react-toastify";
import ControllerInformation from "./ControllerInformation";
import { useNavigate } from "react-router-dom";
import UploadOwnerDocument from "./UploadOwnerDocument";

function BusinessKYC() {
  const navigate = useNavigate()
  const { BusinessKyc, step, index, newOrEdit, completedSteps, KYCState } = useAppSelector((state) => state.kycInfo);

  const dispatch = useAppDispatch();

  const changeStep = (next: number) => {
    if (next > step) {
      dispatch(setCompletedSteps(step));
      dispatch(setStep(next));
    } else {
      setCompletedSteps(completedSteps.filter((completedStep) => completedStep !== step));
      dispatch(setStep(next));
    }
    window.scrollTo(0, 0);
  };


  const setItemIndex = (index: any) => {
    dispatch(setIndex(index));
  };

  const setOpenForm = (action: any) => {
    dispatch(openForm(action));
  };


  const saveForLater = () => {
    toast.success("saved successfully!");
    localStorage.setItem("BusinessKyc", JSON.stringify(BusinessKyc));
  };


  useEffect(() => {
    dispatch(setTitle("KYB Verification"));

    const kycIdentifier = localStorage.getItem('KYCIdentifier')
    if (KYCState === "BUSINESS_RETRY") {
      dispatch(setStep(1));
      dispatch(setCompletedSteps([]));
    }
    else if (KYCState === "CONTROLLER_PENDING" || KYCState === "CONTROLLER_INVALID" || KYCState === "CONTROLLER_RETRY" || KYCState === "CONTROLLER_MISMATCH" || KYCState === "CONTROLLER_DUPLICATE") {
      dispatch(setStep(2));
      dispatch(setCompletedSteps(1));
    } else if (KYCState === "BUSINESS_MISMATCH" || KYCState === "BUSINESS_DUPLICATE" || KYCState === "BUSINESS_PENDING" || KYCState === "BUSINESS_INVALID") {
      dispatch(setStep(1));
    } else if (KYCState === "CONTROLLER_BVN_FAILED" || KYCState === "BUSINESS_DENIED" || KYCState === "BUSINESS_VERIFIED" || KYCState === "CONTROLLER_DENIED" || KYCState === "OWNER_DENIED" || KYCState === "BUSINESS_FAILED" || KYCState === "OWNER_BVN_FAILED") {
      navigate('/')
    } else if (KYCState === "OWNER_PENDING" || KYCState === "OWNER_DUPLICATE" || KYCState === "OWNER_MISMATCH" || KYCState === "OWNER_INVALID" || KYCState === "OWNER_RETRY") {
      dispatch(setStep(3))
      dispatch(setCompletedSteps(2));
      dispatch(setCompletedSteps(1));
    } else if (KYCState === "BUSINESS_DOCUMENT_PENDING" || KYCState === "PEOPLE_DOCS" || KYCState === "PEOPLE_DOCS_RETRY" || KYCState === "BUSINESS_DOCS_RETRY") {
      dispatch(setStep(4))
      dispatch(setCompletedSteps(3));
      dispatch(setCompletedSteps(2));
      dispatch(setCompletedSteps(1));
    }
    else {
      if (kycIdentifier !== 'placeholder') {
        navigate('/')
      }
    }

  }, []);

  useEffect(() => {
    dispatch(
      setBusinesskyc({
        ...BusinessKyc,
        BusinessRegistrationNumber: "",
        FundingSources: []
      })
    );
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])


  return (
    <div className="sm:flex py-5 md:py-20">
      <div className="md:w-[35%] sm:w-[50%] pb-10">
        {<Timeline step={step} completed={completedSteps} />}
      </div>

      {step === 1 && <BusinessDetails currentStep={step} nextStep={changeStep} />}
      {step === 2 && <ControllerInformation currentStep={step} nextStep={changeStep} />}
      {step === 3 && (
        <BeneficialOwners
          opened={newOrEdit}
          openForm={setOpenForm}
          currentStep={step}
          nextStep={changeStep}
          index={index}
          setIndex={setItemIndex}
        />
      )}
      {(step === 4 && (KYCState === 'PEOPLE_DOCS' || KYCState === 'PEOPLE_DOCS_RETRY')) && <UploadOwnerDocument />}
      {(step === 4 && !(KYCState === 'PEOPLE_DOCS' || KYCState === 'PEOPLE_DOCS_RETRY')) && <UploadDocuments kycState={KYCState} saveForLater={saveForLater} currentStep={step} nextStep={changeStep} />}
      <ToastContainer />
    </div>
  );
}

export default BusinessKYC;
