import GetStartedBullets from "./List";
import { getStarted } from "./data";
import AOS from 'aos';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "./Button";

const GetStarted = () => {
    const navigate = useNavigate();

    useEffect(() => {
        AOS.init();
    }, [])

    return (
        <section className="bg-[#000000]">
            <div data-aos="fade-in-up" className="py-[5.25rem] w-[80%] mx-auto 2xl:w-[1280px]">
                <header className="text-white text-center">

                    <h1 className="text-[1.75rem] lg:text-[2rem] font-bold">How to get started</h1>
                    <p className="text-[1.125rem] lg:text-[1.25rem] font-medium pt-[.5rem]">3 simple steps to start receiving USD into your Cleva account</p>
                </header>
                <div>
                    <GetStartedBullets textColor="white" customStyle=" xl:w-[22rem]" data={getStarted} />
                </div>
                <div className="flex items-center justify-center">
                    <Button
                        text="Get started"
                        fn={() => navigate("/auth/register")}
                        style={`px-[2rem]`}
                    />
                </div>
            </div>
        </section>
    )
}

export default GetStarted;