import { cardBenefits as data } from "../data";

const MoreBenefits = () => {
    return (
        <div>
            <div className="w-[90%] xl:w-[80%] mx-auto 2xl:w-[1280px] py-8 md:py-16">
                <section>
                    <h3 className="md:w-[27rem] lg:w-[36rem] mx-auto text-2xl md:text-3xl lg:text-4xl text-black font-bold text-center pb-8 md:pb-16">Say goodbye to traditional banking restrictions</h3>
                </section>
                <section className="grid gap-4 md:grid-cols-3">
                    {
                        data.map((item, id) => (
                            <Box key={id} src={item.img} title={item.title} text={item.text} isFirst={id === 0} />
                        ))
                    }
                </section>
            </div>
        </div>
    )
}

export default MoreBenefits

interface IBox {
    src: string;
    title: string;
    text: string;
    isFirst: boolean
}

export const Box = (props: IBox) => {
    const { src, title, text } = props
    return (
        <article className='gradient-bg-3 py-8 px-5 text-white rounded-[1.25rem]'>
              <div className="flex items-center justify-start pb-4 md:h-[4rem]">
            <img className="w-[2.5rem]" src={src} alt={title} />
              </div>
            <h3 className='text-[1.375rem] md:text-lg lg:text-2xl font-bold pt-8 pb-2'>{title}</h3>
            <p className='text-lg font-base'>{text}</p>
        </article>
    )
}