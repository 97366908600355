/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import { KycStatus } from '../Kyc/components/KycStatus'
import ProfileTabs from './components/profileTab'
import Profile from './pages/Profile'
import Business from './pages/Business'
// import Beneficiary from './pages/BeneficialOwners'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { setTitle } from '../../features/Nav/NavSlice'
import { getKyc, getTag } from '../../api'
import { setKYCState, setAdditionalDetails } from '../../features/Kyc/kycSlice'
import Spinner from '../../components/PopUps/Spinner'
import { getUserIdWithAccessToken } from '../../login'
import { fetchTransactions } from '../../features/Transanctions/transactionApi'
import { setTag } from '../../features/Tags/TagsSlice'

export default function ProfilePage() {
  const [tab, setTab] = useState('profile');
  const { loading } = useAppSelector((state: any) => state.transaction);
  const { BusinessKyc, accountType, KYCState } = useAppSelector((state) => state.kycInfo);
  const user = useAppSelector((state) => state.user.userData);
  const KYCIdentifier = localStorage.getItem("KYCIdentifier");
  const [kyc_loading, setLoader] = useState(true);
  const dispatch = useAppDispatch();

  const handleChangeTab = (tab: string) => {
    setTab(tab)
  }

  const getTagDetails = async () => {
    const TagData = await getTag(user?.ClevaUserID!).catch((err: any)=>null)
    if (TagData?.data){
      dispatch(setTag(TagData.data.Tag!))
    }
  }

  useEffect(() => {
    dispatch(setTitle("Profile"));
    getUserIdWithAccessToken();
    getTagDetails();
    if (KYCIdentifier) {
      getKyc(KYCIdentifier).then((res) => {
        if (res?.data?.IndividualKyc) {
          dispatch(setKYCState(res?.data?.IndividualKyc?.KYCState!));
          dispatch(setAdditionalDetails(res?.data?.IndividualKyc?.AdditionalDetails));
        } else
          if (res?.data?.BusinessKyc) {
            dispatch(setKYCState(res?.data?.BusinessKyc?.KYCState!));
            dispatch(setAdditionalDetails(res?.data?.BusinessKyc?.AdditionalDetails));
          } else {
            dispatch(setKYCState(""));
          }
        setLoader(false);
      }).catch((error) => {
        setLoader(false);
        dispatch(setKYCState(""));
      });
    }
  }, [dispatch, KYCIdentifier]);


  return (
    <>
      {loading || kyc_loading ?
        <Spinner /> :
        <div className="w-full mb-20">
          <header>
            <br />
            <KycStatus />
            {accountType === "BusinessUser" && KYCState === 'VERIFIED' && <ProfileTabs active={tab} selectTab={handleChangeTab} />}
          </header>
          {tab === 'profile' && <Profile user={user} />}
          {tab === 'business' && <Business kyc={BusinessKyc} />}
          {/* {tab === 'beneficiary' && <Beneficiary owners={BusinessKyc.BeneficialOwners} />} */}
        </div>}
    </>
  )
}
