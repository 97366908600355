import {  ReactNode } from 'react';

interface ICopyToClipboardAlert {
    isOpen?: boolean;
    children: ReactNode;
    bg?: string;
    topS?: string;
    leftS?: string;
    shadow?: string;
    width?: string;
    closeModal?: () => void
  openTransactionPin?:  () => void;

}

const CardModal = ({ isOpen, children, bg, topS, leftS, shadow, width, closeModal, openTransactionPin }: ICopyToClipboardAlert) => {

    return (
        <>
            <div onClick={closeModal} className={`fixed inset-0 z-50  flex items-center justify-center backdrop-filter bg-[#3F3F3FB2] bg-opacity-70`}>
                <div className={`bg-white  ${shadow} ${leftS} ${topS} ${width} rounded-xl w-[90%] mx-auto absolute `}>
                    <div className="">{children}</div>
                </div>
            </div>


        </>
    );
};

export default CardModal;
