import errIcon from "../../images/error-icon.svg";

interface Input {
  title: string;
  value: string | number;
  fn: any;
  onBlur?: any;
  type: string;
  err: string;
  readOnly?: boolean;
  placeholder?: string;
  flag: any;
  code: string;
}

export default function CurrencyInput({
  title,
  type,
  fn,
  onBlur,
  value,
  err,
  readOnly,
  placeholder,
  flag,
  code,
}: Input) {
  return (
    <div>
      <header className="text-[14px] pt-5 text-left">
        {title} {<span className="text-[#D31D1D] font-semibold">*</span>}
      </header>
      <div className="relative mt-1 rounded-md shadow-sm">
        <input
          type={type}
          onChange={fn}
          onBlur={onBlur}
          placeholder={placeholder}
          readOnly={readOnly}
          value={value}
          className="input-control"
          aria-describedby="price-currency"
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <p className="text-sm mr-1.5">{code}</p>
          <img src={flag} alt="" srcSet="" />
        </div>
      </div>
      {err ?
      <div className="flex items-center mb-2 mt-1">
        <img src={errIcon} alt="error" />
        <p className="text-[#D31D1D] text-sm ml-1">{err}</p>
      </div>
      :" "
      }
    </div>
  );
}
