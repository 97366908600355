import React from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { sendOTP } from '../../pages/Profile/pages/Security/Index';
import { sendDeleteOTP } from '../../pages/Profile/pages/Security/AccountDeletionOTP';

const ProfileNav = () => {
  const location = useLocation();

  const isProfileActive = (path: any) => {
    return location.pathname === path;
  };


  const isSecurityActive = (path: any) => {
    // Check if the current location matches the "Security" parent or one of its children

    return (
      location.pathname.startsWith(path) ||
      (location.pathname === "/profile/security/verify-otp" && path === "/profile/security/change-pin") ||
      (location.pathname === "profile/security/update-pin" && path === "/profile/security/change-pin") ||
      (location.pathname === "profile/security/confirm-change" && path === "/profile/security/confirm-change")

    );
  };

  const isSecurityChildActive = (paths: any) => {
    // Check if the current location matches any of the children or specific routes
    return (
      paths.some((path: any) => location.pathname === path) ||
      (location.pathname === "/profile/security/verify-otp" &&
        paths.includes("/profile/security/change-pin")) ||
      (location.pathname === "profile/security/update-pin" &&
        paths.includes("/profile/security/change-pin")) ||
      (location.pathname === "profile/security/confirm-change" &&
        paths.includes("/profile/security/confirm-change"))
    );
  };


  const navigation = [
    { id: 1, name: 'Profile', href: '/profile' },
    { id: 2, name: 'Card', href: '/profile/Card' },
    {
      id: 3,
      name: 'Security',
      children: [
        { name: 'Transaction PIN', href: '/profile/security/confirm-change' },
        // { name: 'Password', href: '/profile/security/password' },

      ],
    },
    { id: 4, name: 'Delete Account', href: '/profile/confirm-delete' },

  ];

  return (
    <aside className="md:w-1/5 border-r-2 border-gray-200 pr-8 md:pt-24 pt-12 mt-4 md:mt-0 md:h-screen">
      <nav className="flex flex-1 flex-col">
        <ul className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul className="-mx-2 space-y-1">
              {navigation.map((item) => (
                <li key={item?.name}>
                  {item?.href && !item.children ? (
                    <Link
                    onClick={() => {
                      if (item?.name === "Delete Account") {
                        sendDeleteOTP();
                      }
                    }}
                      to={item.href}
                      className={classNames(
                        'group flex gap-x-3 px-2 py-3 text-base leading-6',
                        {
                          'text-gray-700 bg-[#FFF9EF]  border-r-2 border-black': isProfileActive(item.href),
                          'text-[#111928]': !isProfileActive(item.href),
                          'hover:bg-gray-50': !isProfileActive(item.href),
                        }
                      )}
                    >
                      {item.name}
                    </Link>
                  ) : item?.children ? (
                    <Disclosure as="div">
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            className={classNames(
                              'flex items-center w-full text-left px-2 py-3 gap-x-3 text-base leading-6',
                              {
                                'text-gray-700 bg-[#F6F6F6]': isSecurityActive(item.href) || isSecurityChildActive(item.children.map(subItem => subItem.href)),
                                'text-[#111928]': !isSecurityActive(item.href) && !isSecurityChildActive(item.children.map(subItem => subItem.href)),
                                'hover:bg-gray-50': !isSecurityActive(item.href) && !isSecurityChildActive(item.children.map(subItem => subItem.href)),
                              }
                            )}
                          >
                            {item.name}
                            <ChevronRightIcon
                              className={classNames(
                                open ? 'rotate-90 text-gray-700' : 'text-[#111928]',
                                'ml-auto h-5 w-5 shrink-0'
                              )}
                              aria-hidden="true"
                            />
                          </Disclosure.Button>

                          <Disclosure.Panel as="ul" className="mt-1 pl-2">
                            {item.children.map((subItem) => (
                              <li key={subItem.name} className="my-2">
                                <Link
                                  onClick={() => {
                                    if (subItem.name === "Transaction PIN") {
                                      sendOTP();
                                    }
                                  }}
                                  to={subItem.href}
                                  className={classNames(
                                    'block py-3 pl-2 text-base leading-6',
                                    {
                                      'text-gray-700 bg-[#FFF9EF] border-r-2 border-black': isSecurityActive(subItem.href),
                                      'text-[#111928]': !isSecurityActive(subItem.href),
                                      'hover:bg-gray-50': !isSecurityActive(subItem.href),
                                    }
                                  )}
                                >
                                  {subItem.name}
                                </Link>
                              </li>
                            ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  )
                    : null
                  }
                </li>
              ))}
            </ul>
          </li>
        </ul>
      </nav>
    </aside>
  );
};

export default ProfileNav;
