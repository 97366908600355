import { createAsyncThunk } from "@reduxjs/toolkit";
import { getControllers, getKyc, getOwner, getOwners } from "../../api";
import { AWS_CREDS } from "../services/AmazonService";

export const fetchOwners = createAsyncThunk('kyc/fetchOwners', async () => {
    try {
      const response = await getOwners()
      return response.data?.BeneficialOwnersSummaryList;
    } catch (error) {
      if(AWS_CREDS.STAGE === 'qa'){
     
        console.error(error)
      }
  
    }
  });

export const fetchOwner = createAsyncThunk('kyc/fetchOwner', async (identifier:any) => {
    try {
      const response = await getOwner(identifier)
      return response.data
    } catch (error) {
      if(AWS_CREDS.STAGE === 'qa'){
     
        console.error(error)
      }
  
    }
  });


export const fetchKYC = createAsyncThunk('kyc/fetchKYC', async(identifier:any) =>{
  try {
    const response = await getKyc(identifier)
    return response?.data
  } catch (error) {
    if(AWS_CREDS.STAGE === 'qa'){
     
      console.log(error)
    }
  }
})


export const fetchController = createAsyncThunk('kyc/fetchControllers', async() =>{
  try {
    const response = await getControllers()
    return response?.data?.ControllersSummaryList
  } catch (error) {
    if(AWS_CREDS.STAGE === 'qa'){
     
      console.log(error)
    }
  }
})
