import { useState } from "react"
import { DiCssTricks } from "react-icons/di"
import { SaveForLaterLong } from "../../../../components/Buttons/KycButtons"
import { NewImageUploadInput } from "../../components/NewImageUploadInput"
import { useNavigate } from "react-router-dom"
import { getKyc, updateKyc } from "../../../../api"
import SuccessModal from "../../../../components/PopUps/SuccessModal"
import axios from "axios";
import { useAppDispatch } from "../../../../app/hooks"
import { setKYCState } from "../../../../features/Kyc/kycSlice"
import Loader from "../../../../components/PopUps/MainLoader"
import FailureModal from "../../../../components/PopUps/FailureModal"
import { AWS_CREDS } from "../../../../features/services/AmazonService"

const UploadBusinessDocument = (props: any) => {
  const dispatch = useAppDispatch()
  const { handleBusinessDoc } = props
  const navigate = useNavigate()
  const [fileError, setFileError] = useState(false);
  const [certUpload, setCertUpload] = useState<any>({});
  const [statusUpload, setStatusUpload] = useState<any>({});
  const [isDocumentUploadButtonDisabled, setDocumentUploadButtonDisabled] = useState(true);
  const [isStatReportDisabled, setIsStatReportDisabled] = useState(true);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailureModal, setShowFailureModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSave = () => {
    localStorage.setItem("saveForLater", "true")
    navigate('/')
  };


  function handleStatusReport(field: string, value: any) {
    const updatedData = { ...certUpload, [field]: value };
    const filename = updatedData["uploaded image"]?.file?.name;
    setStatusUpload(updatedData);
    const documentType = updatedData["uploaded image"]?.file?.type
    const allDocumentFieldsUploaded = documentType && filename;
    setIsStatReportDisabled(!allDocumentFieldsUploaded || fileError);
  }

  function handleIncorporationUpload(field: string, value: any) {
    const updatedData = { ...certUpload, [field]: value };
    const filename = updatedData["uploaded image"]?.file?.name;
    setCertUpload(updatedData);
    const documentType = updatedData["uploaded image"]?.file?.type
    const allDocumentFieldsUploaded = documentType && filename;
    setDocumentUploadButtonDisabled(!allDocumentFieldsUploaded || fileError);
  }

  const closeSuccessModal = () => {
    handleBusinessDoc()
  };


  const getContentTypeConfig = (contentType: any) => {
    return {
      headers: {
        "Content-Type": contentType,
      },
    };
  };

  const DocContentType = certUpload["uploaded image"]?.file?.type;
  const SelfieContentType = statusUpload["uploaded image"]?.file?.type;

  const handleSubmit = async () => {
    setLoading(true);
    if (fileError) {
      return;
    }

    try {
      const KYCIdentifier: any = localStorage.getItem("KYCIdentifier");
      const response = await getKyc(KYCIdentifier);
      const responseData = response?.data?.BusinessKyc
   

      const DocConfig = getContentTypeConfig(DocContentType);
      const SelfieConfig = getContentTypeConfig(SelfieContentType);

      const DocUpload = `${responseData?.AdditionalDetails?.COIUploadUrl?.url}`;
      const url = DocUpload;
     
      const file = certUpload["uploaded image"].file;

      await axios.put(url, file, DocConfig);

      const statusUrl = `${responseData?.AdditionalDetails?.SRUploadUrl.url}`;
      const selfieFile = statusUpload["uploaded image"].file;

      await axios.put(statusUrl, selfieFile, SelfieConfig);

      const updatedBusinessDocumentsItems = {
        CertificateOfIncorporation: {
          documentType: "Certificate of Incorporation",
          contentType: certUpload["uploaded image"].file.type,
          filename: certUpload["uploaded image"].file.name,
          size: certUpload["uploaded image"].file.size
        },
        StatusReport: {
          documentType: "Status Report",
          contentType: statusUpload["uploaded image"].file.type,
          filename: statusUpload["uploaded image"].file.name,
          size: statusUpload["uploaded image"].file.size
        }
      };

      if (responseData) {
        responseData.BusinessDocumentsItems = updatedBusinessDocumentsItems
        responseData.DocumentVerificationPending = true
        const response = await updateKyc(KYCIdentifier, { BusinessKyc: responseData });
        if (response?.data?.KYCState === "PEOPLE_DOCS") {
          dispatch(setKYCState(response?.data?.KYCState))
          setLoading(false);
          setShowSuccessModal(true);
        }
      } else {
        if(AWS_CREDS.STAGE === 'qa'){
          console.log('No data found')
        }
      }

    } catch (error) {
      setLoading(false);
      setShowFailureModal(true);
      if(AWS_CREDS.STAGE === 'qa'){
        console.error("Error updating KYB:", error);
      }
    }
  }

  return (
    <>
      {loading ? <Loader text="Uploading documents" /> :
        <section className="xl:w-[65%] sm:ml-12">

          <h3 className="text-xl text-[#121212] pb-2 font-semibold">
            Provide your business documents
          </h3>
          <p className="text-base text-[#4E4E4E] pb-5 ">
            Please upload your business documents for review
          </p>

          <form className="mt-6  grid gap-4">
            <div>
              <div className="flex gap-2">
                <h3 className="text-sm md:text-base font-base ">Certificate of Incorporation </h3>
                <span className="text-[6.5px] text-[#D31D1D]"> <DiCssTricks /> </span>
              </div>
              <NewImageUploadInput
                index="1"
                name="CertificateOfIncorporation"
                show={true}
                document={certUpload}
                fileError={fileError}
                onChange={(field, value, fileError) => {
                  handleIncorporationUpload(field, value);
                  setFileError(fileError);
                }}
              />
            </div>
            <div>
              <div className="flex gap-2">
                <h3 className="text-sm md:text-base font-base">Shareholder & Directors document (Status Report) </h3>
                <span className="text-[6.5px] text-[#D31D1D]"> <DiCssTricks /> </span>
              </div>
              <NewImageUploadInput
                index="1"
                name="StatusReport"
                show={true}
                document={statusUpload}
                fileError={fileError}
                onChange={(field, value, fileError) => {
                  handleStatusReport(field, value);
                  setFileError(fileError);
                }}
              />
            </div>


            <div className="w-full">
              <button
                type="button"
                disabled={isDocumentUploadButtonDisabled || loading || isStatReportDisabled}
                onClick={handleSubmit}
                className={(isDocumentUploadButtonDisabled || loading || isStatReportDisabled ?
                  "bg-[#FFF5D9] text-[#5F5D5D]" : "bg-[#FFBD59]") +
                  ` text-[15px] font-bold p-3 w-full rounded-lg mt-8 `}>
                {loading ? "Uploading..." : "Upload documents"}
              </button>
              <SaveForLaterLong action={handleSave} />
            </div>
          </form>
        </section>
      }

      {showSuccessModal && (
        <SuccessModal
          btnText="Continue"
          text="Documents uploaded successfully!"
          onClose={closeSuccessModal}
        />
      )}

      {showFailureModal && (
        <FailureModal btnText="Please try again"
          text="Error uploading documents." onClose={() => setShowFailureModal(false)} />
      )}

    </>
  )
}

export default UploadBusinessDocument

