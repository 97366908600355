
import { useEffect, useState } from 'react';
import { getKyc } from '../../api';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Spinner from '../../components/PopUps/Spinner';
import { setKYCState, setAdditionalDetails, setBusinesskyc } from '../../features/Kyc/kycSlice';
import Dashboard from './Dashboard';
import { getUserIdWithAccessToken } from '../../login';
import { fetchRates, fetchTransactions } from '../../features/Transanctions/transactionApi';
import { fetchAccount } from '../../features/VirtualAccount/VirtualAccountApi';
import { fetchCard } from '../../features/ClevaCards/ClevaCardsApi';
import { AWS_CREDS } from '../../features/services/AmazonService';

export default function Home() {
  const dispatch = useAppDispatch();
  const KYCIdentifier = localStorage.getItem("KYCIdentifier");
  const { loading } = useAppSelector((state: any) => state.transaction);
  const virtual_loading = useAppSelector((state: any) => state.virtualAccount.loading);
  const card_loading = useAppSelector((state) => state.clevaCards.loading)
  const [kyc_loading, setLoader] = useState(true);
  const [businessName, setBusinessName] = useState('')
  const [cardExist, setCardExist] = useState(false)
  const [isLoading, setisLoading] = useState(true)
  const [dataFetched, setdataFetched] = useState(false)

  const cards = useAppSelector((state) => state.clevaCards.cards)

  const allAccount = useAppSelector(
    (state) => state.virtualAccount?.allAccount
  );

  const hasAccounts = allAccount && Object.keys(allAccount).length > 0;
  const amount = allAccount ? (allAccount as any).Balance?.Money : " ";



  useEffect(() => {
    dispatch(fetchCard())
      .then(() => {
        setdataFetched(true);
      })
      .catch((error) => {
        if(AWS_CREDS.STAGE === 'qa'){
          console.error("Error fetching card:", error);
        }
        setdataFetched(false);
      });
  }, [dispatch])

  // check if user has card
  useEffect(() => {
    setisLoading(true);
    if (cards && (cards as any)?.length > 0) {
      const activeCard = (cards as any).find((card: any) => ["ACTIVE", "PENDING", "FROZEN"].includes(card.Status));
      if (activeCard) {
        setCardExist(true);
        setisLoading(false);
      }
    }

  }, [cards]);


  useEffect(() => {
    getUserIdWithAccessToken();
    dispatch(fetchTransactions());
    dispatch(fetchRates())
    dispatch(fetchAccount());
    if (KYCIdentifier) {
      getKyc(KYCIdentifier).then((res) => {
        if (res?.data?.IndividualKyc) {
          dispatch(setKYCState(res?.data?.IndividualKyc?.KYCState!));
          dispatch(setAdditionalDetails(res?.data?.IndividualKyc?.AdditionalDetails));
        } else
          if (res?.data?.BusinessKyc) {
            setBusinessName(res?.data?.BusinessKyc?.BusinessName)
            dispatch(setKYCState(res?.data?.BusinessKyc?.KYCState!));
            dispatch(setAdditionalDetails(res?.data?.BusinessKyc?.AdditionalDetails));
            dispatch(setBusinesskyc(res?.data?.BusinessKyc))
          } else {
            dispatch(setKYCState(""));
          }
        setLoader(false);
      }).catch((error) => {
        setLoader(false);
        dispatch(setKYCState(""));
      });
    }
  }, [dispatch, KYCIdentifier]);

  return (
    <>
      {(loading || kyc_loading || virtual_loading || card_loading) ? <Spinner /> :

        <Dashboard
          userHasCard={cardExist}
          hasAccounts={hasAccounts}
          amount={amount}
          businessName={businessName}
        />}
    </>)
}
