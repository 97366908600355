import axios from "./AxiosInstance";
import { FormActionTypes, FormData } from "../Transanctions/formType";
import { AWS_CREDS } from "./AmazonService";
import {
  CreateM2Transactions,
  CreateCard,
  FundCard,
  FreezeCard,
} from "../../components/model";
export const submitTransaction = (formData: FormData) => {
  return {
    type: FormActionTypes.SUBMIT_FORM,
    payload: formData,
  };
};

// export const postFormData = (formData: FormData) => {
//   return (dispatch: Dispatch<FormAction>) => {

type CreateTransactions = {
  SenderFirstName: string;
  SenderLastName: string;
  RecipientFirstName: string;
  RecipientLastName: string;
  // country:string;
  RecipientIdentifier: string;
  bankName: string;
  AccountNumber: string;
  // accountType: string;
  receiveAmount: number;
  sendAmount: number;
  exchangeRate: number;
  fee: number;
  description: string;
};

type CreateAccount = {
  FirstName: string;
  LastName: string;
  email: string;
};

interface UpdateTransaction {
  TransactionState: string;
}

/*======================================== 
transfer
===========================================*/
export const createTransaction = async (data: CreateTransactions) => {
  axios.defaults.headers.common["Otptoken"] = localStorage.getItem("OTPToken");
  return await axios
    .post(
      AWS_CREDS.APIGATEWAY_URL + "/transactions",
      {
        TransactionType: "MAKE_PAYMENT",
        TransactionDetail: {
          FromCurrency: "USD",
          ToCurrency: "NGN",
          TransactionDomain: "INTERNATIONAL",
          Sender: {
            FullName: {
              FirstName: data.SenderFirstName,
              LastName: data.SenderLastName,
            },
          },
          Recipient: {
            RecipientIdentifier: data.RecipientIdentifier,
            FullName: {
              FirstName: data.RecipientFirstName,
              LastName: data.RecipientLastName,
            },
            // Country: data.country,
            BankName: data.bankName,
            AccountNumber: data.AccountNumber,
            AccountType: "SAVING",
          },
          PromisedRate: {
            FromCurrency: 1,
            ToCurrency: data.exchangeRate,
          },
          FromAmount: data.sendAmount,
          ToAmount: data.receiveAmount,
          Fee: data.fee,
          Description: data.description,
        },
      }
      //  {
      //   headers: authHeader(),
      // }
    )
    .then((response: any) => {
      localStorage.removeItem("OTPToken");
      delete axios.defaults.headers.common["Otptoken"];
      return response;
    });
};

// for v2
export const postTransaction = async (data: CreateM2Transactions) => {
  axios.defaults.headers.common["Otptoken"] = localStorage.getItem("OTPToken");
  return await axios
    .post(
      AWS_CREDS.APIGATEWAY_URL + "/transactions",
      {
        TransactionType: "MAKE_PAYMENT",
        TransactionDetail: {
          FromCurrency: "USD",
          ToCurrency: "NGN",
          TransactionDomain: "INTERNATIONAL",
          Sender: {
            FullName: {
              FirstName: data.SenderFirstName,
              LastName: data.SenderLastName,
            },
            AccountIdentifier: data.AccountIdentifier,
          },
          Recipient: {
            RecipientIdentifier: data.RecipientIdentifier,
            FullName: {
              FirstName: data.RecipientFirstName,
              LastName: data.RecipientLastName,
            },
            // Country: data.country,
            BankName: data.bankName,
            AccountNumber: data.AccountNumber,
            AccountType: "SAVING",
          },
          PromisedRate: {
            FromCurrency: 1,
            ToCurrency: data.exchangeRate,
          },
          FromAmount: data.sendAmount,
          ToAmount: data.receiveAmount,
          Fee: data.fee,
          Description: data.description,
        },
      }
      //  {
      //   headers: authHeader(),
      // }
    )
    .then((response: any) => {
      localStorage.removeItem("OTPToken");
      delete axios.defaults.headers.common["Otptoken"];
      return response;
    });
};

export const fetchTransfers = () => {
  return axios.get(
    AWS_CREDS.APIGATEWAY_URL + "/transactions"
    // { headers: authHeader() }
  );
};

export const fetchDummyTransfers = (AccountNumber: number) => {
  return axios.get(
    AWS_CREDS.APIGATEWAY_URL + `/transactions?AccountNumber=${AccountNumber}`
    // { headers: authHeader() }
  );
};
export const fetchTransfersByID = (TransactionIdentifier: string) => {
  return axios.get(
    AWS_CREDS.APIGATEWAY_URL + `/transactions/${TransactionIdentifier}`
    //  { headers: authHeader() }
  );
};

export const cancelTransaction = (
  TransactionIdentifier: string,
  data: UpdateTransaction
) => {
  return axios.put(
    AWS_CREDS.APIGATEWAY_URL + `/transactions/${TransactionIdentifier}`,
    {
      TransactionState: "CANCELLED",
    }
  );
};

// recipients
export const fetchRecipients = () => {
  return axios.get(
    AWS_CREDS.APIGATEWAY_URL + "/recipients"
    // { headers: authHeader() }
  );
};

/*======================================== 
//exchange rate amd fee
===========================================*/
export const fetchRate = () => {
  return axios.get(
    AWS_CREDS.APIGATEWAY_URL + "/rates/USD/NGN"
    // { headers: authHeader() }
  );
};

/*======================================== 
account
===========================================*/
export const createAccount = (data: CreateAccount) => {
  const baseURL = AWS_CREDS.APIGATEWAY_URL.split("/v1").length
    ? `${AWS_CREDS.APIGATEWAY_URL.split("/v1")[0]}/v3`
    : "";
  return axios.post(baseURL + "/virtualaccounts", {
    Currency: "USD",
  });
};

export const fetchAccount = () => {
  return axios.get(AWS_CREDS.APIGATEWAY_URL + "/virtualaccounts");
};

export const fetchAccountByID = (VirtualAccountIdentifier: string) => {
  return axios.get(
    AWS_CREDS.APIGATEWAY_URL + `/virtualaccounts/${VirtualAccountIdentifier}`
  );
};

/*======================================== 
Clevacard
===========================================*/
export const createCard = (data: CreateCard) => {
  return axios.post(AWS_CREDS.APIGATEWAY_URL_CARDS + "/cards", {
    NationalIdentifier: data.NationalIdentifier,
    Type: "VIRTUAL",
    Brand: "MASTERCARD",
    Currency: "USD",
    Amount: data.Amount,
  });
};

export const fetchCard = () => {
  return axios.get(AWS_CREDS.APIGATEWAY_URL_CARDS + "/cards");
};

export const fetchCardByID = (CardIdentifier: string) => {
  return axios.get(AWS_CREDS.APIGATEWAY_URL_CARDS + `/cards/${CardIdentifier}`);
};

export const fundCard = (data: FundCard, CardIdentifier: string) => {
  return axios.post(
    AWS_CREDS.APIGATEWAY_URL_CARDS + `/cards/${CardIdentifier}/fund`,
    {
      Amount: data.Amount,
    }
  );
};

export const fetchCardTransactions = (CardIdentifier: string) => {
  return axios.get(
    AWS_CREDS.APIGATEWAY_URL_CARDS + `/cards-transactions/${CardIdentifier}`
  );
};

//transactions .. this is not yet concluded
// export const fetchCardTransactionsByID = (CardIdentifier:string) => {
//   return axios.get(
//     AWS_CREDS.APIGATEWAY_URL_CARDS + `/cards-transactions/${CardIdentifier}`
//   );
// };

export const verifyCardBVN = (identifier: string) => {
  return axios.post(AWS_CREDS.APIGATEWAY_URL_CARDS + `/national-id-compare`, {
    NationalIdentifier: identifier,
  });
};

export const freezeCard = (data: FreezeCard, CardIdentifier: string) => {
  return axios.patch(
    AWS_CREDS.APIGATEWAY_URL_CARDS + `/cards/${CardIdentifier}`,
    {
      RequestType: data.RequestType,
    }
  );
};

export const verifyUserHasBvn = () => {
  return axios.get(AWS_CREDS.APIGATEWAY_URL_CARDS + "/bvn-retrievable");
};

/* =============================================
Validating BVN
==============================================*/
//validation pin is yet to be concluded

/*============================================
C2C tag
============================================*/
export const createTagTransaction = async (data: CreateM2Transactions) => {
  axios.defaults.headers.common["Otptoken"] = localStorage.getItem("OTPToken");
  return await axios
    .post(
      AWS_CREDS.APIGATEWAY_URL + "/transactions",
      {
        TransactionType: "TAG_TRANSFER",
        TransactionDetail: {
          FromCurrency: "USD",
          ToCurrency: "USD",
          TransactionDomain: "CLEVA_TO_CLEVA",
          Sender: {
            FullName: {
              FirstName: data.SenderFirstName,
              LastName: data.SenderLastName,
            },
            AccountIdentifier: data.AccountIdentifier,
            // Tag: data.SenderTag,
          },
          Recipient: {
            Tag: data.RecipientTag,
            RecipientIdentifier: data.RecipientIdentifier,
            FullName: {
              FirstName: data.RecipientFirstName,
              LastName: data.RecipientLastName,
            },
          },
          PromisedRate: {
            FromCurrency: 1,
            ToCurrency: data.exchangeRate,
          },
          FromAmount: data.sendAmount,
          ToAmount: data.receiveAmount,
          Fee: data.fee,
          Description: data.description,
        },
      }
      //  {
      //   headers: authHeader(),
      // }
    )
    .then((response: any) => {
      localStorage.removeItem("OTPToken");
      delete axios.defaults.headers.common["Otptoken"];
      return response;
    });
};

export default {
  submitTransaction,
  createTransaction,
  fetchTransfers,
  fetchRecipients,
  fetchTransfersByID,
  cancelTransaction,
  fetchRate,
  createAccount,
  fetchAccount,
  fetchAccountByID,
  fetchDummyTransfers,
  postTransaction,
  createCard,
  fetchCard,
  fetchCardByID,
  fundCard,
  fetchCardTransactions,
  verifyCardBVN,
  freezeCard,
  verifyUserHasBvn,
  createTagTransaction,
};
