import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import UsIcon from "../../../../images/USD.svg";
import { RootState } from "../../../../app/store";
import { useSelector } from "react-redux";
import Spinner from "../../../../components/PopUps/smallSpinner";
import { convertTime, parseToDateTimeString } from "../../../Accounts/components/AccountUtils";

type ModalAProps = {
  closeModal: () => void;
};

const TagTransferModal: React.FC<ModalAProps> = ({ closeModal }) => {
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  const { singleTransfer, loading } = useSelector(
    (state: RootState) => state.transaction
  );
  const allAccount = useSelector(
    (state: RootState) => state.virtualAccount.allAccount
  );
  const getLastFourDigits = (number: string) => number.slice(-4);
  const getMaskedAccountNumber = (number: string | undefined) =>
    number ? " **** " + getLastFourDigits(number) : "";

  const status = singleTransfer
    ? (singleTransfer as any).TransactionState
    : " ";
  const statusResult =
    status === "COMPLETED" ? (
      <span className="px-3 py-1 my-2 rounded-full capitalize bg-[#DEF7EC] text-[#03543F] font-medium text-xs">
        Completed
      </span>
    ) : status === "IN_TRANSIT" ? (
      <span className="px-3 py-1 my-2 rounded-full capitalize bg-[#EBFBFE] text-[#1892D7] font-medium text-xs">
        In Transit
      </span>
    ) : status === "PENDING" ? (
      <span className="px-3 py-1 my-2 rounded-full capitalize bg-[#EBFBFE] text-[#1892D7] font-medium text-xs">
        In Transit
      </span>
    ) : status === "KYC_PENDING" ? (
      <span className="px-3 py-1 my-2 rounded-full capitalize bg-[#FDF0E7] text-[#FF6600] font-medium text-xs">
        Pending
      </span>
    ) : status === "DAILY_LIMIT_EXCEEDED" ? (
      <span className="px-3 py-1 my-2 rounded-full capitalize bg-[#FDE8E8]  text-[#9B1C1C] font-medium text-xs">
        Limit Exceeded
      </span>
    ) : status === "RETURNED" ? (
      <span className="px-3 py-1 my-2 rounded-full capitalize bg-[#FDE8E8]  text-[#9B1C1C] font-medium text-xs">
        Returned
      </span>
    ) : status === "REJECTED" ? (
      <span className="px-3 py-1 my-2 rounded-full capitalize bg-[#FDE8E8]  text-[#9B1C1C] font-medium text-xs">
        Rejected
      </span>
    ) : status === "IN_REVIEW" ? (
      <span className="px-3 py-1 my-2 rounded-full capitalize bg-[#EBEBEB]  text-[#111111] font-medium text-xs">
        Processing
      </span>
    ) : (
      <span className=" px-3 py-1 my-2 font-medium capitalize bg-[#FDE8E8] rounded-full text-[#9B1C1C] text-[10px]">
        {singleTransfer ? (singleTransfer as any).TransactionState : " "}
      </span>
    );

  const transactionID = singleTransfer?.TransactionIdentifier;

  const charge = singleTransfer
    ? (singleTransfer as any).TransactionDetail.Fee
    : " ";
  const sentAmount = singleTransfer
    ? (singleTransfer as any).TransactionDetail.FromAmount
    : " ";

  const totalAmount = singleTransfer
    ? (singleTransfer as any).TotalAmount
    : " ";

  let createdDate = singleTransfer ? (singleTransfer as any).CreatedAt : "";
  let toBeCompletedBy = singleTransfer ? (singleTransfer as any).ToBeCompletedBy: "";

  const displayDate = convertTime(new Date(createdDate));
  const reviewDate = parseToDateTimeString(new Date(toBeCompletedBy));

  const firstName = singleTransfer
    ? (singleTransfer as any)?.TransactionDetail?.Recipient?.FullName?.FirstName
    : " ";
  const lastName = singleTransfer
    ? (singleTransfer as any)?.TransactionDetail?.Recipient?.FullName?.LastName
    : " ";
  const recipientTag = singleTransfer
  ? (singleTransfer as any)?.TransactionDetail?.Recipient?.Tag
  : null;

  const accountNumber = allAccount ? (allAccount as any).AccountNumber : " ";

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white pb-8 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-lg  w-[30rem]">
                <header
                  className={`w-full bg-[#EFEFEF] py-4 px-8  flex justify-between`}
                >
                  <Dialog.Title
                    as="h3"
                    className="text-base font-bold leading-6 text-gray-900"
                  >
                    Transaction Details
                  </Dialog.Title>
                  <button
                    type="button"
                    className="rounded-md text-black hover:text-gray-500 focus:outline-none focus:ring-2 font-bold"
                    onClick={closeModal}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                  </button>
                </header>
                {loading ? (
                  <Spinner />
                ) : (
                  <div className="">
                    <div className="mt-3">
                      <div className="ml-auto md:w-[80%]">
                        <div className="flex justify-between px-6 py-7 items-center gap-4 md:gap-0">
                          <div className="flex items-center">
                            <img src={UsIcon} alt="icon" />
                            <p className="font-bold ml-2">C2C Transfer</p>
                          </div>
                          <div>
                            <p className="text-sm">{displayDate}</p>
                          </div>
                        </div>
                      </div>

                      <div className="bg-[#F6F6F6]">
                        <div className="text-center py-6">
                          <p className="text-lg">
                          Transfer{" "}
                          <span className="font-bold text-xl">
                            $
                            {sentAmount?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </span>{" "}
                          to{" "}
                          <span className="text-cleva-gold">
                            {firstName} {lastName}
                          </span>
                        </p>

                          <div className="mt-2">
                            {singleTransfer ? statusResult : ""}
                          </div>


                        </div>
                      </div> 
                      {status === "IN_REVIEW" && (
                            <div className="bg-[#FFE0A4] py-2 px-6 md:px-10">
                              <p className="font-medium text-[#111111] text-sm">
                                Your transaction is currently being processed, and
                                it’s expected to complete on {reviewDate} WAT
                              </p>
                            </div>
                          )}

                      <div className="mt-4 px-6 md:px-8">

                        <div className="bg-[#FCFCFC] border border-[#F1F1F1] md:px-4 py-3 px-2 rounded-xl mb-3">
                            <p className="font-medium">Recipient</p>
                            <div className="flex justify-between my-3">
                                <p className="text-sm text-[#747A80]">Name</p>
                                <p className="text-sm font-medium">{firstName} {lastName}</p>
                            </div>
                            {recipientTag &&
                            <div className="flex justify-between my-3">
                                <p className="text-sm text-[#747A80]">Cleva Tag</p>
                                <p className="text-sm font-medium">@{recipientTag}</p>
                            </div>}
                        </div>

                        <div className="bg-[#FCFCFC] border border-[#F1F1F1] px-4 py-3 rounded-xl mb-3">
                            <p className="font-medium">Transaction</p>
                            <div className="flex justify-between my-3">
                            <p className="text-sm text-[#747A80]">Pay from</p>
                            <p className="text-sm font-medium">{getMaskedAccountNumber(accountNumber)}</p>
                            </div>
                            <div className="flex justify-between my-3">
                            <p className="text-sm text-[#747A80]">You’re sending</p>
                            <p className="text-sm font-medium">${sentAmount?.toLocaleString()}</p>
                            </div>
                            <div className="flex justify-between my-3">
                            <p className="text-sm text-[#747A80]">Fee</p>
                            <p className="text-sm font-medium">${charge?.toLocaleString()}</p>
                            </div>
                            <div className="flex justify-between mt-3">
                            <p className="text-sm text-[#747A80]">Total amount you'll pay</p>
                            <p className="text-lg font-semibold">${totalAmount.toLocaleString()}</p>
                            </div>
                        </div>
                      
                        <div className="mt-8 md:mt-8">
                          <div className="md:flex justify-between items-center gap-2">
                            <p className="text-sm text-[#6F6F6F]">
                              Transaction ID
                            </p>
                            <p className="select-text">{transactionID}</p>
                          </div>
                        </div>

                        <footer>
                          <div className="pt-4">
                            <button
                              type="button"
                              className="text-sm 
                        py-3
                          rounded-md mt-4 
                          border border-[#35803F] w-[100%] text-[#35803F]"
                              onClick={closeModal}
                            >
                              Done
                            </button>
                          </div>
                        </footer>

                      </div>

                    </div>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default TagTransferModal;
