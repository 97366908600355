import { useState } from 'react'
import { AiOutlineBell, AiOutlineSearch } from 'react-icons/ai'
import { useAppSelector } from '../../app/hooks'
import Menu from "../../asset/landing_photos/hamburger.svg";
import Down from "../../asset/svg/arrowDown.svg";
import ProfilePop from '../PopUps/Profile_pop';
import { getBusinessNameInitials } from '../../utils';
import { useIsBusinessUser } from '../../utils';
import Spinner from '../PopUps/Spinner';
interface iNav {
  handleClick: () => void,
  isOpen: boolean
}

export default function Nav(props: iNav) {
  const [modal, setModal] = useState(false)
  const user = useAppSelector((state) => state.user.userData)
  const { title } = useAppSelector((state) => state.navbar);
  const { handleClick } = props
  const isBusinessUser = useIsBusinessUser()
  const { KYCState } = useAppSelector((state) => state.kycInfo)

  const handleModal = () => {
    modal ? setModal(false) : setModal(true)
  }

  const closeModal = () => {
    setModal(false)
  }

  //console.log({ isBusinessUser, user })


  if (!user) {
    return <Spinner />
  }

  return (
    <div className={`m-auto `}>
      <section className="flex items-center justify-between">
        <div className="flex items-center justify-evenly gap-4">
          <button onClick={handleClick} className='flex items-center  w-[2rem] h-[2rem] justify-center bg-[#000] rounded-[50%] lg:hidden'>

            <img className='w-[1rem]' src={Menu} alt="Open sidebar Navigation" />

          </button>
          <div>
            <b className=" font-extrabold text-lg">{title}</b>
          </div>
        </div>
        <div className="flex items-center w-[40%] md:w-[12rem] justify-evenly">
          <span className="hidden border-2 items-center gap-1 px-2 rounded-md w-[70%] text-[18px] ">
            <AiOutlineSearch />
            <input
              className="h-9 text-[12px] outline-none border-none "
              type="text"
              placeholder="Type to search ..."
            />
          </span>
          <span className="border-2 p-[10px]  rounded-md ">
            <AiOutlineBell />
          </span>

          {/* <span
            onClick={() => handleModal()}
            className="rounded-full cursor-pointer overflow-hidden w-10 h-10 bg-[#F2F2F2] border-2"
          >
            <img src={user_img} className="w-full h-[100%] object-cover" />
          </span> */}


          <div className='flex items-center'>

            <span
              onClick={() => handleModal()}
              className=" cursor-pointer rounded-full w-10 h-10 bg-[#F2F2F2] border-2 py-[6px] px-2 flex items-center justify-center"
            >
              {
                isBusinessUser !== null ? (
                  isBusinessUser && (user?.BusinessName) ? ( 
                    <b className='flex justify-center items-center'>
                      {getBusinessNameInitials(user?.BusinessName)}
                    </b>
                  ) :
                  
                  isBusinessUser &&(user?.DisplayName) ? (
                    <b className='flex justify-center items-center'>
                    {getBusinessNameInitials(user?.DisplayName)}
                  </b>
                  )
                  :
                  ( // If isBusinessUser is false
                    <b className='flex justify-center items-center'>
                      {user?.FullName?.FirstName?.charAt(0)}
                      {user?.FullName?.LastName?.charAt(0)}
                    </b>
                  )
                ) : null 
              }

            </span>
            <button className='pt-[.5rem]' onClick={() => handleModal()}>
              <img src={Down} alt="View your profile or logout" />
            </button>
          </div>

        </div>
      </section>
      {modal && <ProfilePop handleModal={handleModal} closeModal={closeModal} />}
    </div>
  )
}
