import React from 'react'
import { TableColumn } from 'react-data-table-component'

type DataItem = {
  CreatedAt: string
  MerchantName: string
  Description: string
  Entry: string
  Type: string
  FromAmount: string
  Amount: string
  Status: string
  id: string
  NetAmount:string
}

const formatAmount = (amount: any) => {
  const parsedAmount = parseFloat(amount) // Parse the amount as a floating-point number
  const formattedAmount = parsedAmount.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }) // Format the amount with two decimal places and comma as a thousands separator

  return formattedAmount
}

export const CardIncomingColumn: TableColumn<DataItem>[] = [
  {
    name: 'Date',
    selector: (row) => row.CreatedAt,
    cell: (row) => <span data-tag="allowRowEvents" className="">{`${row.CreatedAt.slice(0, 10)}`}</span>,
    sortable: true,
    reorder: true,
    omit: false,
  },
  {
    name: 'Description',
    selector: (row) => row.Description,
    cell: (row) =>
     row.Type === 'FUNDING' ? (
      <div data-tag="allowRowEvents" className="">
        <span data-tag="allowRowEvents" className="">Card Funding</span>
      </div>
    ) :  (
      <div data-tag="allowRowEvents" className="">
        <span data-tag="allowRowEvents" className="">{row.Description}</span>
      </div>
    ),
    sortable: true,
    reorder: true,
    omit: false,
  },
  {
    name: 'Type',
    selector: (row) => row.Type,
    cell: (row) =>
      row.Type === 'FUNDING' ? (
        <div data-tag="allowRowEvents" className="">
          <span data-tag="allowRowEvents" className="">Funding</span>
        </div>
      ) : row.Type === 'REVERSAL' ? (
        <div data-tag="allowRowEvents" className="">
          <span data-tag="allowRowEvents" className="">Reversal</span>
        </div>
      ) :
        row.Type === 'REFUND' ? (
          <div data-tag="allowRowEvents" className="">
            <span data-tag="allowRowEvents" className="">Refund</span>
          </div>)
          :
          row.Entry === 'DECLINE' ?
          (
            <div data-tag="allowRowEvents" className="">
              <span data-tag="allowRowEvents" className="">Decline</span>
            </div>)
            :
          (
            <div data-tag="allowRowEvents" className="">
              <span data-tag="allowRowEvents" className="">Card Transaction</span>
            </div>
          ),
    sortable: true,
    center: false,
    reorder: true,
    omit: false,
    // width: '200px',
  },
  {
    name: 'Amount',
    selector: (row) => row.NetAmount,
    cell: (row) => (
      <div data-tag="allowRowEvents" className="">
        <span data-tag="allowRowEvents" className="font-medium text-sm">{`$${formatAmount(row.NetAmount)}`}</span>
      </div>
    ),
    sortable: true,
    center: false,
    reorder: true,
    omit: false,
  },
  {
    name: 'Status',
    selector: (row) => row.Status,
    cell: (row) =>
      row.Status === 'COMPLETED' ? (
        <div data-tag="allowRowEvents" className="py-1">
          <span data-tag="allowRowEvents" className="px-3 py-1 my-2 rounded-lg capitalize bg-[#DEF7EC] text-[#03543F] font-medium flex items-center text-[12px]">
            <span data-tag="allowRowEvents">Completed</span>
          </span>
        </div>
      ) : row.Status === 'PENDING' ? (
        <div data-tag="allowRowEvents" className="py-1">
          <span data-tag="allowRowEvents" className="px-3 py-1 my-2 rounded-lg capitalize bg-[#EBFBFE] text-[#1892D7] font-medium flex items-center text-[12px]">
            <span data-tag="allowRowEvents">In Progress</span>
          </span>
        </div>
      ) : row.Status === 'DELAYED' ? (
        <div data-tag="allowRowEvents" className="py-1">
          <span data-tag="allowRowEvents" className="px-3 py-1 my-2 rounded-lg capitalize bg-[#FDF0E7] text-[#FF6600] font-medium flex items-center text-[12px]">
            <span data-tag="allowRowEvents">Delayed</span>
          </span>
        </div>
      ) : row.Status === 'CANCELLED' ? (
        <div data-tag="allowRowEvents" className="py-1">
          <span data-tag="allowRowEvents" className="px-3 py-1 my-2 rounded-lg capitalize bg-[#FDF0E7] text-[#FF6600] font-medium flex items-center text-[12px]">
            <span data-tag="allowRowEvents">{row.Status}</span>
          </span>
        </div>
      ) : (
        <div data-tag="allowRowEvents" className="py-1">
          <span data-tag="allowRowEvents" className="flex items-center px-3 py-1 my-2 font-medium capitalize bg-[#FDE8E8] rounded-lg text-[#9B1C1C] text-[12px]">
            <span data-tag="allowRowEvents">Failed</span>
          </span>
        </div>
      ),
    sortable: true,
    reorder: true,
    omit: false,
  },
]

