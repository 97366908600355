import React from "react";
import { Link } from "react-router-dom";
interface CardProps {
  active?: boolean;
  title: string;
  body?: string;
  text?: string;
  icon?: string;
  link?: string;
  disabled?: boolean;
  cardClass?: boolean;
  width?:string;
  additionalStyle?:string
}

const DashboardCard: React.FC<CardProps> = ({
  active,
  title,
  body,
  text,
  icon,
  link,
  disabled,
  width,
  additionalStyle
}) => {
  const cardClasses = active ? "card-active" : "card-inactive";

  if (disabled) {
    return (
      <div className={`card`}>
      <div className="flex rounded-xl items-center justify-between bg-[#111111] opacity-80  h-[153px] py-2 pl-5">
        <div className="w-[80%] md:w-[58%]">
          <div className="mb-0">
            <button
            disabled={true}
              className={
                text
                  ? "text-[8px] leading-4 opacity-80  text-[#EBF0F0] font-semibold  mb-3 flex items-center rounded-full bg-zinc-700 px-2 py-1 "
                  : "hidden"
              }
            >
      
              {text}
            </button>
            <div style={{width}}  className={`${additionalStyle} ${cardClasses}`}>
              {title}
            </div>
            <p className="text-[17px] opacity-60 text-gray-200 pt-[8px]">
              {body}
            </p>
          </div>
        </div>

        <div className="w-[40%]right-0">
          <img src={icon} alt="logo" />
        </div>
      </div>
    </div>
    )
  }

  return (
    <div className={`card`}>
      <Link to={`${link}`}
      >
      <div className="flex rounded-xl items-center justify-between bg-[#111111]  h-[153px] py-2 pl-5">
        <div className="w-[80%] md:w-[58%]">
          <div className="mb-0">
            <button
              className={
                text
                  ? "border border-[#F8B755] bg-white text-[#FF6D59] text-[8px] mb-3 leading-4 py-[.2rem] px-[1.5rem] font-semibold  flex items-center rounded-full "
                  : "hidden"
              }
            >
      
              {text}
            </button>
            <div style={{width}} className={`${additionalStyle} ${cardClasses}`}>
              {title}
            </div>
            <p className="text-[17px] text-white pt-[8px]">
              {body}
            </p>
          </div>
        </div>

        <div className="w-[40%]right-0">
          <img src={icon} alt="logo" />
        </div>
      </div>
      </Link>
    </div>
  );
};

export default DashboardCard;
