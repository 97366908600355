import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setTitle } from "../../features/Nav/NavSlice";
import { getKyc } from "../../api";
import {
  setAdditionalDetails,
  setIndividualkyc,
  setKYCState,
} from "../../features/Kyc/kycSlice";
import {
  fetchCard,
  verifyUserHasBvn,
} from "../../features/ClevaCards/ClevaCardsApi";
import CardState from "./CardState";
import NewCard from "./NewCard";
import Spinner from "../../components/PopUps/Spinner";
import { setUserNames } from "../../features/ClevaCards/ClevaCardsSlice";
import { fetchController } from "../../features/Kyc/KycApi";
import { useIsBusinessUser } from "../../utils";
import { AWS_CREDS } from "../../features/services/AmazonService";
import Announcement from "../../components/Announcement/Announcement";
export interface ICards {
  cards: any;
}

const ClevaCard = () => {
  const isBusiness = useIsBusinessUser();
  const [cardExist, setCardExist] = useState(false);
  const dispatch = useAppDispatch();
  const [dataFetched, setdataFetched] = useState(false);
  const cards = useAppSelector((state) => state.clevaCards.cards);
  const [loading, setLoading] = useState(true);
  const KYCIdentifier = localStorage.getItem("KYCIdentifier");
  const kycUser = useAppSelector((state) => state.kycInfo.IndividualKyc);
  const { Controllers } = useAppSelector((state) => state.kycInfo);

  useEffect(() => {
    dispatch(setTitle("Card"));
    if (!isBusiness) {
      dispatch(verifyUserHasBvn());
    }
    dispatch(fetchCard())
      .then(() => {
        setdataFetched(true);
      })
      .catch((error: any) => {
        if (AWS_CREDS.STAGE === "qa") {
          console.error("Error fetching card:", error);
        }
        setdataFetched(false);
      });
  }, [dispatch]);

  useEffect(() => {
    if (KYCIdentifier) {
      getKyc(KYCIdentifier)
        .then((res) => {
          if (res?.data?.IndividualKyc) {
            dispatch(setIndividualkyc(res?.data?.IndividualKyc));
          } else if (res?.data?.BusinessKyc) {
            dispatch(setKYCState(res?.data?.BusinessKyc?.KYCState!));
            dispatch(
              setAdditionalDetails(res?.data?.BusinessKyc?.AdditionalDetails)
            );
            dispatch(fetchController());
          } else {
            dispatch(setKYCState(""));
          }
        })
        .catch((error) => {
          dispatch(setKYCState(""));
        });
    }
  }, [dispatch, KYCIdentifier]);

  useEffect(() => {
    const processNames = (names: (string | undefined)[]) => {
      return names.filter(Boolean).flatMap((name) =>
        name
          ?.split(" ")
          .map(
            (part) =>
              part?.charAt(0)?.toUpperCase() + part?.slice(1)?.toLowerCase()
          )
          .filter(Boolean)
      );
    };

    if (isBusiness) {
      const { FirstName, MiddleName, LastName } =
        Controllers[0]?.Controller ?? {};
      const allNames = processNames([FirstName, MiddleName, LastName]);
      dispatch(setUserNames(allNames));
    } else if (kycUser) {
      const { FirstName, MiddleName, LastName } = kycUser;
      const allNames = processNames([FirstName, MiddleName, LastName]);
      dispatch(setUserNames(allNames));
    }
  }, [kycUser, dispatch, isBusiness, Controllers]);

  useEffect(() => {
    setLoading(true);

    if (cards && (cards as any)?.length > 0) {
      let cardExistFlag = false;
      const activeCard = (cards as any).find((card: any) =>
        ["ACTIVE", "PENDING", "FROZEN"].includes(card.Status)
      );
      if (activeCard) {
        cardExistFlag = true;
      }

      setCardExist(cardExistFlag);
      setLoading(false);
    }
  }, [cards, dispatch]);

  if (loading && !dataFetched) {
    return <Spinner />;
  }

  return (
    <>
      <div className="pt-4">
        <Announcement Service="Cards" />
      </div>

      {cardExist ? <CardState cards={cards} /> : <NewCard />}
    </>
  );
}

export default ClevaCard;
