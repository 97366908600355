import BackBtn from "../../asset/referral/backBtn.svg"
import Spinner from "../../components/PopUps/Spinner";
import formatDateString from "../../utils/FormatDateString";
import { IReferredUsers } from "../../features/Referrals/ReferralSlice";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { fetchReferral } from "../../features/Referrals/ReferralAPI";
import { AppDispatch } from "../../app/store";
import { AWS_CREDS } from "../../features/services/AmazonService";
import { Tooltip } from 'react-tooltip'
import SpinnerTwo from "../../components/PopUps/SpinnerTwo";


const Statistics = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const { loading, error, data } = useSelector((state: any) => state.referral)
  const bonusPerReferredUser = Number(AWS_CREDS.BONUS_PER_REFERRED_USER || 3);
  const potentialEarning = data?.ReferredUsers ? data?.ReferredUsers.length * bonusPerReferredUser : 0

  const [state, setState] = useState({
    invited: true,
    pending: false,
    completed: false
  })

  const youCanStillEarn = Number(data?.MaximumEarnableAmount) - Number(data?.TotalAmountEarned)
  const maxReferralBonus = AWS_CREDS.MAX_REFERRAL_BONUS
  const successfulRefers = data?.NumberOfUsersEarnedFrom;

  const usersWithFalseBonus = data?.ReferredUsers?.filter((user: any) => user.BonusAwarded === false);
  const numberOfUsersWithFalseBonus = usersWithFalseBonus?.length;
  const potentialEarningNoBonus = numberOfUsersWithFalseBonus * bonusPerReferredUser

  //g({potentialEarningNoBonus, numberOfUsersWithFalseBonus, bonusPerReferredUser})

  const usersWithBonus = data?.ReferredUsers?.filter((user: any) => user.BonusAwarded === true && user.BonusPaid === false);
  const numberOfUsersWithBonus = usersWithBonus?.length;
  const potentialEarningBonus = numberOfUsersWithBonus * bonusPerReferredUser


  const tooltipContent = `Referrals who have deposited up to $${AWS_CREDS.BONUS_GIVEN_AT_TOTAL_DEPOSIT_AMOUNT}`

  let amountEarned = data?.TotalAmountEarned || 0
  let progress, rawProgress;
  let [minAmount, maxAmount] = [bonusPerReferredUser, Number(data?.MaximumEarnableAmount)]
  if (amountEarned === minAmount) {
    progress = 1;
  } else {
    rawProgress = ((amountEarned - minAmount) / (maxAmount - minAmount))
    progress = Math.min(Math.max(rawProgress, 0), 1) * 100;
  }

  const returnToReferral = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    navigate('/referrals')
  }

  useEffect(()=>{
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  },[])
  const handleClick = (type: string) => {
    setState({
      invited: type === 'invited',
      pending: type === 'pending',
      completed: type === 'completed'
    });
  };

  useEffect(() => {
    dispatch(fetchReferral())
  }, [dispatch])

  useEffect(() => {
    if (AWS_CREDS.STAGE === 'qa') {
      console.log('qa env', { data })
    }
   
  }, [])
  return (
    <section className="">
      <div className="py-9">
        <div className="flex gap-2 items-center cursor-pointer pb-8 font-medium text-[#0D0D0D]" onClick={returnToReferral}>
          <img src={BackBtn} alt="Referrals Statistics" />
          <button>Referrals Statistics</button>
        </div>
        {error ?

          <div className="bg-white p-8 md-[38rem]">
            <div className="flex flex-col items-center justify-center">
              <SpinnerTwo />
              <p className="pt-4 font-medium text-base text-red">Loading your statistics...</p>
            </div>
          </div>

          :

          <>
            {loading ? <Spinner /> :
              <div>
                <section className="rounded-xl shadow-lg bg-white border border-solid border-[#EAEAEA]">
                  <div className="flex flex-col items-center py-16">
                    <p className="text-7xl text-[#1D1D1D] font-bold">${data?.TotalAmountEarned}</p>
                    <p className="text-[#FD810E] text-lg font-medium">
                      Total cash bonus earned
                    </p>
                    <p className="text-[#379256] text-sm text-center md:text-base w-[90%] md:w-[29rem] mx-auto">
                      <span className="text-black font-medium"> Note:</span> The bonus takes <span className="font-bold">5 business days</span> to be added to your balance after your invited friend meets the deposit criteria.
                    </p>
                    <p className="py-8 flex items-center">
                      <button className="flex items-center justify-center rounded-full w-6 h-6 text-white text-xs bg-[#027429] p-2 mr-1">
                        {successfulRefers}
                      </button>
                      <span className="text-base">
                        <span className="cursor-pointer underline font-bold mr-[0.2rem]">  <a href="#" data-tooltip-id="my-tooltip" data-tooltip-content={tooltipContent}>
                          Earned
                        </a></span>
                        Referrals</span>

                    </p>
                    <p className="text-sm text-[#2B2B2B] pb-1"> Your Progress</p>
                    <div className="w-[90%] md:w-[19.28763rem]">
                      {/* progress bar earnnings */}

                      <section className="relative pt-1">
                        <div className="overflow-hidden h-[1.25rem] mb-4 text-xs flex rounded-lg bg-[#D9D9D9]">
                          <div
                            style={{ width: `${progress}%` }}
                            className="shadow-none flex flex-col text-center whitespace-nowrap text-[#3E3E3E] 
                   justify-center bg-[#FFBD59] relative"
                          >
                            <span className="absolute inset-y-0 m-auto left-[40px] right-0 flex items-center justify-center">{`$${amountEarned}`}</span>
                          </div>
                        </div>
                      </section>
                    </div>

                    <p className="pt-7 text-base text-[#1B1B1B] text-center">
                      Keep referring more people to earn <b>${youCanStillEarn || maxReferralBonus}.</b>
                    </p>

                  </div>
                </section>
                <section className="mt-2 shadow-lg rounded-xl bg-white border border-solid border-[#EAEAEA]">
                  <div className="w-[90%] md:w-1/2 mx-auto py-8 md:py-16">
                    <div className="flex xl:justify-center items-center gap-10 border-b border-b-[#D8D8D8] overflow-scroll md:overflow-auto">
                      <button onClick={() => handleClick('invited')} className={`${state.invited ? 'border-b-[#1C6CB5]' : ''} flex gap-[0.5rem] font-xs font-medium border-b `}>Invited <span className="flex items-center justify-center rounded-full w-6 h-6 text-white text-xs bg-[#1C6CB5] p-2 mr-1">{numberOfUsersWithFalseBonus || 0}</span></button>
                      <button onClick={() => handleClick('pending')} className={`${state.pending ? ' border-b-[#278033]' : ''} flex gap-[0.5rem] font-xs font-medium border-b`}>Pending <span className="flex items-center justify-center rounded-full w-6 h-6 text-white text-xs bg-[#278033] p-2 mr-1">{numberOfUsersWithBonus || 0}</span></button>
                      <button onClick={() => handleClick('completed')} className={`${state.completed ? ' border-b-[#278033]' : ''} flex gap-[0.5rem] font-xs font-medium border-b`}>Completed <span className="flex items-center justify-center rounded-full w-6 h-6 text-white text-xs bg-[#278033] p-2 mr-1">{data?.NumberOfUsersEarnedFrom}</span></button>
                    </div>

                    {(state.invited) &&
                      <EarningsList
                        supportingText=" List of people that have joined Cleva using your referral code but
                     have not yet met the criteria to unlock your bonus."
                        tagline="Potential earning"
                        potentialEarning={potentialEarningNoBonus || 0}
                        date="Date Joined"
                        textColor="text-[#1C6CB5]">

                        <article className="pt-5 ">
                          {data?.ReferredUsers
                            ? [...data?.ReferredUsers].filter(item => item.BonusAwarded === false).sort(
                              (a: any, b: any) =>
                                new Date(a.JoinedDate).getTime() - new Date(b.JoinedDate).getTime()
                            ).map((item: IReferredUsers, index: number) => (
                              <div className="flex justify-between pb-5" key={index}>
                                <p className="text-sm xl:text-base text-[#1C6CB5]">{item.FullName}</p>
                                <p className="text-sm xl:text-base text-[#414141]">
                                  {formatDateString(item.JoinedDate)}
                                </p>
                              </div>
                            ))
                            : null}
                        </article>
                      </EarningsList>
                    }
                    {(state.pending) &&
                      <>
                        <EarningsList
                          supportingText="List of people who have met the deposit criteria and unlocked your bonus. The bonus takes 5 business days to be added to your balance."
                          potentialEarning={potentialEarningBonus || 0}
                          date="Bonus date"
                          tagline=" Total pending bonus"
                          textColor="text-[#278033]"
                        >

                          <article className="pt-5 ">
                            {data?.ReferredUsers
                              ? [...data?.ReferredUsers].filter(item => (item.BonusAwarded === true && item.BonusPaid === false)).sort(
                                (a: any, b: any) =>
                                  new Date(a.JoinedDate).getTime() - new Date(b.JoinedDate).getTime()
                              ).map((item: IReferredUsers, index: number) => (
                                <div className="flex justify-between pb-5" key={index}>
                                  <p className="text-sm xl:text-base text-[#278033]">{item.FullName}</p>
                                  <p className="text-sm xl:text-base text-[#414141]">
                                    <span className="font-bold"> ${bonusPerReferredUser} </span>on {formatDateString(item?.BonusCompletionDate)}
                                  </p>
                                </div>
                              ))
                              :
                              null
                            }
                          </article>
                        </EarningsList>
                      </>
                    }
                    {(state.completed) &&
                      <>
                        <EarningsList
                          supportingText="List of people who have met the deposit criteria and unlocked your bonus."
                          potentialEarning={amountEarned}
                          tagline=" Total bonus"
                          textColor="text-[#278033]"
                        >

                          <article className="pt-5 ">
                            {data?.ReferredUsers
                              ? [...data?.ReferredUsers].filter(item => item.BonusPaid === true).sort(
                                (a: any, b: any) =>
                                  new Date(a.JoinedDate).getTime() - new Date(b.JoinedDate).getTime()
                              ).map((item: IReferredUsers, index: number) => (
                                <div className="flex justify-between pb-5" key={index}>
                                  <p className="text-sm xl:text-base text-[#278033]">{item.FullName}</p>
                                  <p className="hidden text-sm xl:text-base text-[#414141]">
                                    {formatDateString(item.JoinedDate)}
                                  </p>
                                </div>
                              ))
                              : null}
                          </article>
                        </EarningsList>
                      </>
                    }

                  </div>
                </section>
              </div>
            }
          </>
        }
      </div>
      <Tooltip id="my-tooltip" />
    </section>
  );
};

export default Statistics;

interface iEarningsList {
  tagline: string;
  supportingText: string;
  potentialEarning: number;
  date?: any;
  children: any;
  textColor: string;
}

export const EarningsList = ({ tagline, supportingText, potentialEarning, date, children, textColor }: iEarningsList) => {
  return (
    <div className="pt-4">
      <p className="pt-1 pb-8 text-[#818181] text-sm">
        {supportingText}
      </p>
      <p className="pb-[1.38rem] text-[#5C5C5C] text-base">
        {tagline}:
        <span className={`${textColor} text-lg`}>${potentialEarning}</span>
      </p>
      <div className="bg-[#FCFCFC] border border-solid border-[#DADADA] rounded-xl shadow-md p-5">
        <div className="flex justify-between border-[#EBEBEB] border-b border-solid pb-5">
          <p>Name</p>
          <p>{date}</p>
        </div>
        {children}
      </div>
    </div>

  )
}