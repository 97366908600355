import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { VerifyOTP } from "../../../api";
import { getUser, getUserIdWithAccessToken } from "../../../login";
import { toast } from "react-toastify";
import errorImg from "../../../asset/svg/material-symbols_warning.svg";
import OtpField from "react-otp-field";
import { Auth } from "aws-amplify";
import { useAppDispatch } from "../../../app/hooks";
import { setUser } from "../../../features/User/UserSlice";

interface ModalProps {
  closeModal: () => void;
  openNewPinModal: () => void;
}

const ResetPin:React.FC<ModalProps> = ({closeModal,openNewPinModal}) => {
  const [otp, setOTP] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const cancelButtonRef = useRef(null);
  const AppDispatch = useAppDispatch();

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    const userID = await getUserIdWithAccessToken()
    const session = await Auth.currentSession()
    try {
      await VerifyOTP(userID, otp).then(async ()=>{
          setLoading(false);
          const userDetails = await getUser(userID, session.getIdToken().getJwtToken());
          AppDispatch(setUser(userDetails))
          openNewPinModal();
      }).catch((err: any)=>{
          if (err?.response?.status === 403){
            setError(true);  
          } else {
            toast.error(err.message);
          }
        setLoading(false);
      })
    } catch (err: any) {
      setLoading(false);
      toast.error(err.message);
    }
  };

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={closeModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full w-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white pb-8 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-xl  w-[60rem]">
                <header
                  className={`w-full text-left bg-[#EFEFEF] py-4 px-8  flex justify-between`}
                >
                  <Dialog.Title
                    as="h3"
                    className="text-base font-bold leading-6 text-gray-900"
                  >
                    Transaction PIN
                  </Dialog.Title>
                  <button
                    type="button"
                    className="rounded-md text-black hover:text-gray-500 focus:outline-none focus:ring-2 font-bold"
                    onClick={() => closeModal()}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                  </button>
                </header>



                <div className="flex justify-center  w-full items-center p-3 ">
                <div className="sm:w-[80%] md:w-[80%] pb-14 sm:pb-8 rounded-lg ">
                  <div className="p-3">
                  <h2 className="text-[#000] font-semibold pt-4 text-xl mt-2">
                      Forgot PIN?
                    </h2>
                    <p className="py-6 text-sm text-[#444444]">
                    No worries!  We have sent a reset code to your email address. Please enter the 6-digit code sent to your email address.
                    </p>
                  </div>
                  
                    <form>
                    <div className="w-full mb-10 mt-6 justify-center">
                    <OtpField
                      value={otp}
                      onChange={(e:any)=>{setOTP(e);setError(false)}}
                      numInputs={6}
                      onChangeRegex={/^([0-9]{0,})$/}
                      autoFocus
                      separator={<span> </span>}
                      isTypeNumber={false}
                      inputProps={{
                        className: `otp-field__input ${error ? 'error-box' : ''}`,
                        disabled: false,
                        maxLength: 1,
                        pattern: "[0-9]*",
                        inputMode: "numeric"
                      }}
                    />
                    </div>

                    
                    {error && 
                      <div
                        className="w-full px-5 flex font-[500] mb-8
                        rounded-lg
                        justify-center text-sm items-center h-[3.75rem] bg-[#FFEAED] ">
                        <img src={errorImg} className="" alt="error" />
                        <span className="text-[#800205] pl-2  font-normal">
                        OTP code not valid
                        </span>
                      </div>}


                    <button
                        onClick={(e) =>handleSubmit(e)}
                        disabled={loading || otp.length < 6}
                        className={
                          (loading || otp.length < 6) ? " login-disabled":"login-active"
                        }>
                     {loading ? "Loading ..." : "Submit"}
                    </button>

                    </form>
                </div>
                </div>

                <footer>
                </footer>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ResetPin;
