import { useNavigate } from "react-router-dom"
import Button from "../Button"
import GetStartedBullets from "../List"

interface IGetStartedTwo {
  dataArr: any[]
  cta: string
}

const GetStartedTwo = (props: IGetStartedTwo) => {
  const { dataArr, cta } = props
  const navigate = useNavigate()
  return (
    <div className="bg-black">
      <div className="w-[90%] xl:w-[80%] mx-auto 2xl:w-[1280px] py-8 md:py-16">
        <section>
          <div data-aos="fade-in-up" className="">
            <header className="text-white text-center">
              <h1 className="text-[1.75rem] lg:text-[2rem] font-bold">How to get started</h1>
              <p className="text-[1.125rem] lg:text-[1.25rem] font-medium pt-[.5rem]">3 simple steps to start receiving USD into your Cleva account</p>
            </header>
            <div>
              <GetStartedBullets textColor="white" customStyle=" xl:w-[22rem]" data={dataArr} />
            </div>

          </div>

        </section>
        <section style={{
          backgroundImage: `url(${process.env.PUBLIC_URL + '/bg-image.png'})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
          className="bg-white rounded-2xl [min-1280px]:w-[58rem] lg:mt-[3rem] mx-auto">
          <div className="flex justify-center items-center text-center flex-col p-4 py-14">
            <h3 className="text-2xl font-semibold">Ready to get started?</h3>
            <p className="text-base py-3 lg:pb-[2.44rem]">{cta}</p>
            <Button
              text="Get Started"
              fn={() => navigate("/auth/register")}
              style={`rounded-[0.75rem] px-[2rem]`}
            />
          </div>
        </section>
      </div>
    </div>
  )
}

export default GetStartedTwo