import { Alarm , ClevaLogo, CardSymbol, scratchCard, Copy, Swirl} from "../../asset/cards";
import { useEffect, useState } from "react";
import CopyToClipboardAlert from "../../components/PopUps/CopyToClipboard";
import { useNavigate } from "react-router-dom";
import frozenIcon from "../../asset/cards/frozen.svg";
import RecentCardTransaction from "../Accounts/components/RecentCardTransactions";
import {  useDispatch } from "react-redux";
import { AppDispatch } from "../../app/store";
import { setTitle } from "../../features/Nav/NavSlice";
import freezeIcon from "../../asset/cards/freeze.svg";
import CardModal from "./CardModal";
import { IoCloseOutline } from "react-icons/io5";
import { useAppSelector } from "../../app/hooks";
import {fetchCard,fetchCardById,freezeCard,} from "../../features/ClevaCards/ClevaCardsApi";
import { setCardIdentifier } from '../../features/ClevaCards/ClevaCardsSlice'
import ValidateUserPin from "../Security/modals/ValidateUserPin";
import VerifyUserOTP from "../Security/modals/ExceededPin";
import NewPin from "../Security/modals/NewPin";
import UpdatedPin from "../Security/modals/UpdatedPin";
import ForgotPin from "../Security/modals/ForgotPin";
import PinLoader from "../Security/modals/PinLoader";
import { toast, ToastContainer } from "react-toastify";
import { getUserIdWithAccessToken } from "../../login";
import { ResetPin } from "../../api";
import Spinner from "../../components/PopUps/Spinner";
import { copyAddress } from "./utils/utils";
import SpinnerTwo from "../../components/PopUps/SpinnerTwo";
import { AWS_CREDS } from "../../features/services/AmazonService"

const ExistingCard = () => {
  const dispatch = useDispatch<AppDispatch>();
 const MAX_DECLINE = AWS_CREDS.MAX_CARD_DECLINE;
  const [showCardDetails, setshowCardDetails] = useState(false);
  const [copyState, setcopyState] = useState(false);
  const [declinedTransaction, setdeclinedTransaction] = useState(false);
  const [lowBalance, setlowBalance] = useState(false);
  const [freezeModal, setFreezeModal] = useState(false);
  const [UnFreezeModal, setUnFreezeModal] = useState(false);
  const cards = useAppSelector((state) => state.clevaCards.cards)
  const card = useAppSelector((state) => state.clevaCards.card)
  const cardIdentifier = useAppSelector((state) => state.clevaCards.cardIdentifier)
  const [freezingCard, setFreezingCard] = useState(false)
  const [unfreezingCard, setUnfreezingCard] = useState(false)
  const [freezeCardErr, setFreezeCardErr] = useState(false)
  const [unfreezeCardErr, setUnfreezeCardErr] = useState(false)
  const navigate = useNavigate();

  //card pin and otp
  const [openTransactionPin, setTransactionPin] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [openForgotPin, setForgotPin] = useState(false);
  const [openUpdatedPin, setUpdatedPin] = useState(false);
  const [openNewPin, setNewPin] = useState(false);
  const [openOTP, setOTP] = useState(false);
  const [isloading, setisLoading] = useState<boolean>(false); //might be needed

  const [frozenCard, setFrozenCard] = useState<boolean>(false);
  const [cardStatus, setCardStatus] = useState<string>('ACTIVE')

  const [deleteCard, setDeleteCard] = useState(
    localStorage.getItem("deleteCard") === "true" || false
  );

  const CARD_ADDRESS = card?.Address;
  const CARD_BALANCE = card?.Balance;
  const CARD_EXPIRY = card?.Expiry;
  const CARD_MASKEDPAN = card?.MaskedPan;
  const CARD_NAME = card?.Name;
  const CARD_STATUS = card?.Status;
  const CARD_NUMBER = card?.CardNumber;
  const CARD_CVV = card?.CVV;
  const CARD_DECLINES = card?.NoOfDeclines;

  const { City = "", Country = "", StateOrTerritory = "", StreetAddress = "", ZipCode = "" } = CARD_ADDRESS || {};



  useEffect(() => {
    if (card?.Balance !== undefined) {
        setlowBalance(card.Balance < 5);
    }

    if (card?.NoOfDeclines !== undefined){
      setdeclinedTransaction(Number(card?.NoOfDeclines) >= 1)
    }
}, [card]);



  const displayCardDetails = () => {
    setshowCardDetails(!showCardDetails);
  };

  const copyCardNumber = async () => {
    try {
      await navigator.clipboard.writeText(CARD_NUMBER || "");
      setcopyState(true);
    } catch (error) {
      if(AWS_CREDS.STAGE === 'qa'){
        console.error("Copy failed:", error);
      }
    }
  };

  const handleCopyAddress = () => {
    if (CARD_ADDRESS) {
      copyAddress(  StreetAddress,   City,StateOrTerritory, Country,  ZipCode, CARD_ADDRESS, setcopyState);
    } else {
      if(AWS_CREDS.STAGE === 'qa'){
     
        console.error("CARD_ADDRESS is undefined");
      }
    }
  };


  const handleFreezeModal = () => {
    setFreezeModal(true);
  };
  const handleUnFreezeModal = () => {
    setUnFreezeModal(true);
  };

  const handleFreezeCard = async () => {
    setFreezeModal(false);
    setFreezingCard(true);

    try {
      const response = await dispatch(
        freezeCard({
          card: { RequestType: "FREEZE" },
          CardIdentifier: cardIdentifier,
        })
      );
      if (response?.payload) {
        setFreezingCard(false);
        setFrozenCard(true);
        setCardStatus("FROZEN");
        setshowCardDetails(false);
      } else {
        setFreezingCard(false);
        setFreezeCardErr(true)
        toast.error("Please try again later");
      }
    } catch (error: any) {
      setFreezingCard(false);
    }
  };


  const handleUnFreezeCard = () => {
    setisLoading(true);
    try {
      setUnFreezeModal(false);
      setTransactionPin(true);
    } catch (error: any) {
      setisLoading(false);
    }
  };

  const cardStatusColor = frozenCard || deleteCard ? "#D86512" : "#0B851C";


  useEffect(() => {
    dispatch(setTitle("Card"));
    dispatch(fetchCard())
  }, [dispatch]);

  useEffect(() => {
    // Check if cards and CardIdentifier are available
    if ((cards as any)?.length > 0) {
      const card = (cards as any)[(cards as any).length - 1];
      const { CardIdentifier } = card;
      dispatch(setCardIdentifier(CardIdentifier));
      dispatch(fetchCardById(CardIdentifier));
    }

  }, [dispatch, cards, cardIdentifier, navigate, CARD_STATUS]);



  //card pin logics

  const sendOTP = async () => {
    const userID = await getUserIdWithAccessToken();
    try {
      await ResetPin(userID);
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const VerifyTransaction = async () => {
    try {
      setUnfreezingCard(true);
      setVerifying(true);
      const response = await dispatch(
        freezeCard({
          card: { RequestType: "UNFREEZE" },
          CardIdentifier: cardIdentifier,
        })
      );

      if (response?.payload) {
        setFrozenCard(false);
        setVerifying(false);
        setTransactionPin(false);
        setCardStatus("ACTIVE");
        setUnfreezingCard(false);
      } else {
        setVerifying(false);
        setTransactionPin(false);
        setisLoading(false);
        setUnfreezingCard(false);
        setUnfreezeCardErr(true)
        toast.error("Please try again later");
      }
    } catch (error: any) {
      setisLoading(false);
      toast.error(error.message);
    }
  };

  if (!cards || !card) {
    return <Spinner />
  }

  return (
    <>
      <div className="pt-10 pb-20">
        <div className="flex justify-center items-center min-h-[75vh]">
          {/* md:w-3/4  */}
          <div className="w-[40rem] mx-auto">
            {/* card balance */}
            <section className=" rounded-xl bg-white shadow-sm flex items-center justify-center p-8 text-center">
              <div className="">
                <h1 className="text-[#393939] text-sm font-normal pb-[0.75rem]">
                  Card Balance
                </h1>
                <p
                  data-test="card-balance"
                  className="text-[#000] font-extrabold text-[2.75rem] leading-normal"
                >
                  $
                  {CARD_BALANCE?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}

                </p>

                  <>
                    {frozenCard ? (
                      <button
                        data-test="unfreeze-card"
                        onClick={handleUnFreezeModal}
                        className="mt-[1.44rem] px-3 py-2.5 md:w-[18rem] rounded-lg border border-[#FFBD59] bg-[#FFBD59] hover:brightness-90 hover:border-[#FFBD59] transition-transform duration-300 ease-in  text-base font-normal text-[#111]"
                      >
                        Unfreeze Card
                      </button>
                    ) : (
                      <div className="flex items-center justify-center flex-row pt-[1.44rem] gap-4">
                        <button
                          data-test="topup-card"
                          onClick={() => navigate("/cards/topup")}
                          className=" px-3 lg:px-[1.25rem]  py-2.5 w-[7rem] md:w-[11rem] rounded-lg border border-[#FFBD59] bg-[#FFBD59] hover:brightness-90 text-base font-normal text-[#111]"
                        >
                          Topup
                        </button>
                        <div className="relative">
                          <span className="absolute top-[-0.8rem] z-10 right-[0.8rem] md:right-[2rem] bg-[#68A17C] rounded-[62.4375rem] px-2 py-1 text-white font-semibold text-[.625rem]">Coming soon</span>
                          {/* hover:brightness-90 */}
                          <button disabled onClick={() => navigate('/cards/withdraw')} className=' px-3 lg:px-[1.25rem]  py-2.5 w-[8rem] md:w-[11rem] rounded-lg border border-[#8A8A8A] bg-[#FFF] opacity-[0.3]  text-base font-semibold text-[#111]'>Withdraw</button>
                        </div>
                      </div>
                    )}
                  </>

              </div>
            </section>
            {/* cooling period for delete card */}
            {deleteCard && (
              <section className="rounded-xl bg-white  shadow-sm flex items-center justify-center mt-3 p-4">
                <div className="flex md:w-[28rem] items-center justify-center gap-4">
                  <img src={frozenIcon} alt="" />
                  <p className="text-[#1E1E1E] text-sm font-normal">
                    In a cooling down period, to be deleted on  Decemeber 31, 2023
                  </p>
                </div>
              </section>
            )}
            {/* low card balance alert */}
            {lowBalance && (
              <section className="rounded-xl bg-white  shadow-sm flex items-center justify-center mt-3 p-8">
                <div className="flex md:w-[28rem] items-center justify-center gap-4">
                  <img src={Alarm} alt="" />
                  <p className="text-[#363636] text-sm font-normal">
                    Your card balance is low. Fund your card to avoid being
                    charged for declined transactions.{" "}
                    <button
                      onClick={() => navigate("/cards/deactivation")}
                      className="underline text-[#2F7BB2] font-medium"
                    >
                      Learn more
                    </button>
                  </p>
                </div>
              </section>
             )}
            {/* declined transactions */}
            {declinedTransaction && (
              <section className="rounded-xl border-[#F5F5F5] bg-[#FFFDF6] shadow-sm flex items-center justify-center mt-3 p-4">
                <div className=" md:w-[26rem]  flex items-center justify-center gap-4">
                  <img src={Alarm} alt="" />
                  <div>
                  <p className="text-[#262626] text-lg font-medium">
                    Declined transactions:{" "}
                    <span className="text-xl text-[#000] font-extrabold">
                      {CARD_DECLINES}/<span className="font-medium">{MAX_DECLINE}</span>
                    </span>
                  </p>
                  <p className="text-[#383838] text-sm">
                  To avoid card deactivation, please top up your card.{" "}
                  <button
                      onClick={() => navigate("/cards/deactivation")}
                      className="underline text-[#2F7BB2] font-medium"
                    >
                      Learn more
                    </button>
                  </p>
                  </div>
                </div>
              </section>
            )}
              <section className="rounded-xl border border-[#EAD4B2] bg-[#FFFAF1] shadow-sm flex items-center justify-center mt-3 p-4">
                <div className=" md:w-[26rem]  flex items-center justify-center gap-4">       
                  <p className="text-[#111111] text-sm font-medium">
                  Card declines incur a <span className="font-bold">$0.30</span> fee per decline. Please ensure your card balance is always sufficient.
                  </p>
                </div>
              </section> 
            {/* card details */}
            <section className="rounded-xl bg-white shadow-sm flex flex-col items-center justify-center mt-3  p-4 md:p-8">
              <div className="md:w-[28rem] w-full">
                <div className="flex items-center justify-center gap-4 w-full">
                  <div
                    className={`bg-[#ECB166] relative rounded-3xl w-full grid gap-8 py-4 px-8 ${frozenCard || deleteCard ? "opacity-60" : ""
                      }`}
                  >
                    <img
                      className="absolute w-full h-full "
                      src={Swirl}
                      alt=""
                    />

                    {showCardDetails ? (
                      <div className="z-10 grid md:gap-4 relative gap-3">
                        <div className="flex justify-between">
                          <img src={ClevaLogo} alt="" />
                          <button
                            disabled={frozenCard || deleteCard}
                            style={{ color: cardStatusColor }}
                            className="bg-[#F6FFEF] rounded-[62.4375rem] text-xs font-bold md:py-2 p-4 h-[25px] flex items-center self-center sm:h-auto md:px-5 "
                          >
                            {CARD_STATUS}
                          </button>
                        </div>

                        <div>
                          <img src={scratchCard} alt="" />
                        </div>
                        <div className="flex justify-between">
                          <div>
                            <p data-test="card-name" className="text-[#1D1D1D] text-sm font-semibold">
                              {CARD_NAME}
                            </p>
                            <div className="flex">
                              <p data-test="card-number" className="text-[#1D1D1D] mr-1">
                                {CARD_NUMBER}
                              </p>
                              <img
                                onClick={copyCardNumber}
                                className="cursor-pointer w-4"
                                src={Copy}
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="min-[350px]:flex">
                            <div className="flex">
                              <p className="opacity-75 text-sm mr-2 font-normal">
                                Exp
                              </p>
                              <p data-test="card-expiry" className="text-sm font-medium text-[#1D1D1D]">
                                {CARD_EXPIRY}
                              </p>
                            </div>
                            <div className="flex min-[350px]:ml-4">
                              <p className="opacity-75 text-sm mr-2 font-normal">
                                CVV
                              </p>
                              <p data-test="card-cvv" className="text-sm font-medium text-[#1D1D1D]">
                                {CARD_CVV}
                              </p>
                            </div>
                          </div>

                          <img src={CardSymbol} alt="" className="w-[3rem]" />
                        </div>
                      </div>
                    ) : (
                      <div className="z-10 grid md:gap-4 relative gap-3">
                        <div className="flex justify-between">
                          <img src={ClevaLogo} alt="" />
                          <button
                            data-test="card-status"
                            disabled={frozenCard || deleteCard}
                            style={{ color: cardStatusColor }}
                            className="bg-[#F6FFEF] rounded-[62.4375rem] text-xs font-bold md:py-2 p-4 h-[25px] flex items-center self-center sm:h-auto md:px-5 "
                          >
                            {cardStatus}
                          </button>
                        </div>

                        <div>
                          <img src={scratchCard} alt="" />
                        </div>
                        <div>
                          <p className="text-[#1D1D1D] text-sm font-semibold">
                            {CARD_NAME}
                          </p>
                          <p className="text-[#664119]">{CARD_MASKEDPAN}</p>
                        </div>

                        <div className="flex justify-between items-center">
                          <div className="min-[350px]:flex">
                            <div className="flex">
                              <p className="opacity-75 text-sm mr-2 font-normal">
                                Exp
                              </p>
                              <p className="text-sm font-medium text-[#1D1D1D]">
                                **/**
                              </p>
                            </div>
                            <div className="flex min-[350px]:ml-4">
                              <p className="opacity-75 text-sm mr-2 font-normal">
                                CVV
                              </p>
                              <p className="text-sm font-medium text-[#1D1D1D]">
                                ***
                              </p>
                            </div>
                          </div>

                          <img src={CardSymbol} alt="" className="w-[3rem]" />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {!frozenCard && (
                  <div className="flex pt-4 justify-between min-[350px]:px-4 items-center w-full">
                    <button
                      data-test="show-card"
                      onClick={displayCardDetails}
                      className="text-[#0D5DA5] text-base font-medium cursor-pointer"
                    >
                      {showCardDetails
                        ? "Hide card details"
                        : "Show card details"}
                    </button>

                    <button
                      onClick={handleFreezeModal}
                      className="flex items-center border border-[#8A8A8A] shadow-sm rounded pl-1 pr-2"
                    >
                      <img src={freezeIcon} alt="" />
                      <p data-test="freeze-button" className="text-[#292929] text-sm">
                        {frozenCard ? "Unfreeze" : "Freeze"}{" "}
                      </p>
                    </button>
                  </div>
                )}
                {showCardDetails && (
                  <div className="flex flex-col  self-start pt-4 pl-4 ">
                    <p className="text-[#0C0C0C] font-medium tex-sm">
                      Billing Address
                    </p>
                    <div className="flex items-center pt-3">
                      <p data-test="billing-address" className="text-[#0A0A0A] font-normal text-sm md:text-base">
                        <span className="block">
                          {" "}
                          {StreetAddress}, {City},
                        </span>
                        <span className="block">
                          {" "}
                          {StateOrTerritory}, {Country},
                        </span>
                        <span className="block">{ZipCode}</span>
                      </p>
                      <img
                        onClick={handleCopyAddress}
                        className="cursor-pointer w-4"
                        src={Copy}
                        alt=""
                      />
                    </div>
                  </div>
                )}
              </div>
            </section>

            {/* recent transactions */}
            <RecentCardTransaction />
          </div>
        </div>
      </div>
      <CopyToClipboardAlert
        isOpen={copyState}
        onClose={() => setcopyState(false)}
        bg="bg-black"
      >
        <div className="flex flex-col items-center justify-center gap-2">
          <p className="text-sm font-light text-white">Copied to clipboard</p>
        </div>
      </CopyToClipboardAlert>

      {freezeModal && (
        <CardModal width="md:w-[33rem]" shadow="rounded-[1.25rem]">
          <div className="">
            <div className="rounded-t-[1.25rem] bg-[#EBF0F0] flex items-center justify-between px-[1.8rem] py-4">
              <h1 className="text-black font-medium text-base">Freeze Card</h1>
              <IoCloseOutline
                onClick={() => setFreezeModal(false)}
                className="text-xl cursor-pointer"
              />
            </div>
            <div className="px-[1.8rem] py-4">
              <div className="">
                <p className="text-[#404040] text-base font-normal">
                  Freezing your card will automatically decline all future
                  transactions made on the card.
                </p>
                <p className="text-[#232323] text-base font-normal pt-8">
                  Are you sure you want to continue?
                </p>
              </div>
              <div className="flex justify-between items-center pt-[3.04rem]">
                <button
                  className="bg-[#F4C952] py-[0.63rem] px-8 md:px-[2.87rem] rounded-lg hover:brightness-90 text-black font-bold  text-sm"
                  onClick={() => setFreezeModal(false)}
                >
                  Cancel
                </button>
                <button
                  data-test="freeze-card"
                  className="bg-[#FFBD59] py-[0.63rem] px-8 md:px-[2.87rem] rounded-lg hover:brightness-90 text-black font-bold  text-sm"
                  onClick={handleFreezeCard}
                >
                  Freeze
                </button>
              </div>
            </div>
          </div>
        </CardModal>
      )}

      {UnFreezeModal && (
        <CardModal width="md:w-[33rem]" shadow="rounded-[1.25rem]">
          <div className="">
            <div className="rounded-t-[1.25rem] bg-[#EBF0F0] flex items-center justify-between px-[1.8rem] py-4">
              <h1 className="text-black font-medium text-base">
                Unfreeze Card
              </h1>
              <IoCloseOutline
                onClick={() => setUnFreezeModal(false)}
                className="text-xl cursor-pointer"
              />
            </div>
            <div className="px-[1.8rem] py-4">
              <div className="">
                <p className="text-[#404040] text-base font-normal">
                  Unfreezing your card will automatically ensure you can use
                  this card for all transactions
                </p>
                <p className="text-[#232323] text-base font-normal pt-8">
                  Are you sure you want to continue?
                </p>
              </div>
              <div className="flex justify-between items-center pt-[3.04rem]">
                <button
                  className="bg-[#F4C952] py-[0.63rem] px-8 md:px-[2.87rem] rounded-lg hover:brightness-90 text-black font-bold  text-sm"
                  onClick={() => setUnFreezeModal(false)}
                >
                  Cancel
                </button>
                <button
                  data-test="unfreeze-modal"
                  className="bg-[#FFBD59] py-[0.63rem] px-8 md:px-[2.87rem] rounded-lg hover:brightness-90 text-black font-bold  text-sm"
                  onClick={handleUnFreezeCard}
                >
                  Unfreeze
                </button>
              </div>
            </div>
          </div>
        </CardModal>
      )}

      {!verifying && openTransactionPin && (
        <ValidateUserPin
          VerifyTransaction={VerifyTransaction}
          openForgotPinModal={() => {
            setTransactionPin(false);
            setForgotPin(true);
            sendOTP();
          }}
          sendOTP={() => {
            setTransactionPin(false);
            setOTP(true);
            sendOTP();
          }}
          closeModal={() => setTransactionPin(false)}
        />
      )}

      {openForgotPin && (
        <ForgotPin
          openNewPinModal={() => {
            setForgotPin(false);
            setNewPin(true);
          }}
          closeModal={() => setForgotPin(false)}
        />
      )}

      {openOTP && (
        <VerifyUserOTP
          openNewPinModal={() => {
            setOTP(false);
            setNewPin(true);
          }}
          closeModal={() => setOTP(false)}
        />
      )}

      {openNewPin && (
        <NewPin
          openUpdatedPinModal={() => {
            setNewPin(false);
            setUpdatedPin(true);
          }}
          closeModal={() => setNewPin(false)}
        />
      )}

      {openUpdatedPin && <UpdatedPin closeModal={() => setUpdatedPin(false)} />}

      {verifying && <PinLoader text='Loading...' />}

      {freezingCard && <PinLoader text='Freezing Card...' />}
      {unfreezingCard && <PinLoader text='Unfreezing Card...' />}

      {freezeCardErr && (
        <CardModal width="md:w-[27rem]" closeModal={() => setFreezeCardErr(false)}>
          <div className="flex flex-col items-center justify-center py-8 gap-[0.5rem]">
            {/* <img src={Err} alt="Error"/> */}
            <SpinnerTwo />
            <h1 className="text-[#292929] text-center font-semibold text-base w-[70%]">
              Freeze card is temporarily unavailable. Please try again later.
            </h1>
            <button className="w-[50%] mt-5 rounded-lg bg-[#FFBD59] py-[0.8125rem] px-[2.125rem] font-bold text-[0.875rem] text-black" onClick={() => setFreezeCardErr(false)}>Close</button>
          </div>
        </CardModal>
      )}
      {unfreezeCardErr && (
        <CardModal width="md:w-[27rem]" closeModal={() => setUnFreezeModal(false)}>
          <div className="flex flex-col items-center justify-center py-8 gap-[0.5rem]">
            {/* <img src={Err} alt="Error"/> */}
            <SpinnerTwo />
            <h1 className="text-[#292929] text-center font-semibold text-base w-[70%]">
              UnFreeze card is temporarily unavailable. Please try again later.
            </h1>
            <button className="w-[50%] mt-5 rounded-lg bg-[#FFBD59] py-[0.8125rem] px-[2.125rem] font-bold text-[0.875rem] text-black" onClick={() => setUnfreezeCardErr(false)}>Close</button>
          </div>
        </CardModal>
      )}
      <ToastContainer />
    </>
  );
};

export default ExistingCard;
