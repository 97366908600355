import React from "react";
import USIcon from "../../../images/USD.svg";
import { useAppSelector } from "../../../app/hooks";
import Spinner from "../../PopUps/smallSpinner";

// interface AccountNumberProps {
//   accountNumber: string | undefined;
//   status: string;
// }

export const getLastFourDigits = (number: string) => number.slice(-4);

export const getMaskedAccountNumber = (number: string | undefined) =>
  number ? "**** **** **** " + getLastFourDigits(number) : "";

const ClevaAccount = () => {
  const allAccount = useAppSelector((state) => state.virtualAccount.allAccount);
  const accountNumber = allAccount ? (allAccount as any).AccountNumber : " ";

  const status = allAccount ? (allAccount as any).VirtualAccountState : " ";
  const formattedStatus = status?.charAt(0) + status?.slice(1).toLowerCase();
  const statusResult =
    status === "ACTIVE" ? (
      <div className=" px-4 py-2 bg-[#F6FFEF] rounded-full ml-3 justify-start items-start gap-2 inline-flex">
        <span className="text-right text-[#0B851C] capitalize text-[12px] font-bold leading-none">
          {formattedStatus}
        </span>
      </div>
    ) : (
      <div className=" px-4 py-2 bg-[#F7FCEC] rounded-full ml-3 justify-start items-start gap-2 inline-flex">
        <span className="text-right text-[#D86512] capitalize text-[12px] font-bold leading-none">
          {formattedStatus}
        </span>
      </div>
    );

  const amount = allAccount ? (allAccount as any).Balance?.Money : " ";



  return (
    <>
      {accountNumber ? (
        <div className="md:w-[90%]">
          <div className="flex justify-center md:pr-4">
            <div className="flex md:pl-20 items-center">
              <div>
                <img src={USIcon} alt="" srcSet="" />
              </div>
              <div className="ml-4">
                <p className="text-sm text-[#111111] font-bold">USD Account</p>
                <p className="text-sm text-[#393939]">
                  {getMaskedAccountNumber(accountNumber)}
                </p>
              </div>
              <div>{statusResult}</div>
            </div>
          </div>
          <p className="text-black text-[44px] text-center font-extrabold my-2">
            {
              Number(amount) < 0 ?
                `-$
                ${Math.abs(Number(amount)).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}` : `$ ${amount.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`
            }
          </p>
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default ClevaAccount;
