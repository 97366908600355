import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../asset/images/logo.svg";
import errorImg from "../../asset/svg/material-symbols_warning.svg";
import authImg from "../../asset/images/login-img.svg";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import { Link, useNavigate } from "react-router-dom";
import {
  getUser,
  getUserIdWithAccessToken,
  removeAuthTokens,
} from "../../login";
import { useAppDispatch } from "../../app/hooks";
import AuthService from "../../features/services/AuthServices";
import { setUser } from "../../features/User/UserSlice";
import { updateTagModalStatus, setTag } from "../../features/Tags/TagsSlice";
import { EMAIL_SENT } from "./VerifyEmail";
import AuthServices from "../../features/services/AuthServices";
import Logo from "../../asset/logo.svg";
import { getTag } from "../../api";
import { AWS_CREDS } from "../../features/services/AmazonService";

const Login = () => {
  const AppDispatch = useAppDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [formValid, setFormValid] = useState<boolean>(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const TIMEOUT_MILLI_SECS = 2000;
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    removeAuthTokens();
    setLoading(true);
    try {
      const user = await AuthService.login(email.toLowerCase(), password);
      if (user) {
        toast.success("Login successful");
        const userId = await getUserIdWithAccessToken();
        // remove field when login is successful login
        sessionStorage.removeItem(EMAIL_SENT);
        const currentUser = await getUser(
          userId,
          user.signInUserSession.idToken.jwtToken
        );
        AppDispatch(setUser(currentUser));
        const TagData = await getTag(userId).catch(() => null);
        if (TagData?.data) {
          AppDispatch(updateTagModalStatus(false));
          AppDispatch(setTag(TagData?.data?.Tag || ""));
        } else {
          AppDispatch(updateTagModalStatus(true));
          AppDispatch(setTag(""));
        }
      }
    } catch (error: any) {
      setLoading(false);
      if (AWS_CREDS.STAGE === "qa") {
        console.log(error);
      }

      if (error.toString().includes("NotAuthorizedException")) {
        setError(error.message);
      } else if (error.toString().includes("UserNotFoundException")) {
        setError("Invalid Username or password");
      } else if (error.toString().includes("UserNotConfirmedException")) {
        setError(error.message);
        await AuthServices.resendOTP(email!);
        setTimeout(() => navigate("/auth/verify-email"), TIMEOUT_MILLI_SECS);
      } else {
        setError(error.message);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (email && password) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [email, password]);

  useEffect(() => {}, []);

  return (
    <>
      <div className="md:flex min-h-full">
        <div className="hidden lg:flex md:flex-col justify-center flex-1 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-20 bg-black text-white ">
          <div className="mx-auto w-full max-w-[24rem] 2xl:max-w-[28rem] lg:w-[30rem] xl:w-[28rem]">
            <div className="flex justify-center pt-20 min-h-[100vh] md:items-center md:pt-0">
              <div className="w-full">
                <div>
                  <Link to="/">
                    {/* <img className="w-auto mt-2" src={logo} alt="logo" /> */}
                    <img className="w-auto mt-2" src={logo} alt="logo" />
                  </Link>
                  <h1 className="mt-16 text-4xl font-medium text-white">
                    Welcome to Cleva
                  </h1>
                  <p className="text-[#EBF0F0] mt-6">
                    Login to access your account
                  </p>
                </div>

                <img src={authImg} className="mt-10" alt="login" />
              </div>
            </div>
          </div>
        </div>
        <div className="relative flex-1 bg-[#FBFBFB]">
          <div className="flex justify-center pt-8 min-h-[100vh] items-center md:pt-0">
            <div className="login-card-md w-full md:w-[30rem] xl:w-[36rem] px-8 py-8 md:px-20 md:py-16">
              <div className="">
                <div className="flex items-center justify-center lg:hidden">
                  {/* <img loading='lazy' className='pb-4 w-[10rem]' src={Logo} alt="Cleva logo" /> */}
                  <img className="w-[10rem] mb-2" src={logo} alt="logo" />
                </div>
                <h2 className="text-center lg:text-left text-2xl text-black-soft">
                  Login to your account
                </h2>
                {/* banner */}
                {error !== "" && (
                  <div
                    className="w-full px-5 flex my-[2.81rem] text-[#800205] font-[500]
                 rounded-lg
                 justify-center text-sm items-center h-[3.75rem] bg-[#FFDCE0] "
                  >
                    <img src={errorImg} className="" alt="error" />
                    <span className="text-[#800205] pl-2  font-normal">
                      {error}
                    </span>
                  </div>
                )}

                {/* form section  */}

                <form onSubmit={handleSubmit} className=" mt-10">
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-black-soft"
                    >
                      Email Address
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        placeholder="Email Address"
                        value={email}
                        onChange={(event) => {
                          setError("");
                          setEmail(event.target.value);
                        }}
                        required
                        className="input-control"
                      />
                    </div>
                  </div>

                  <div className="mt-6">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-black-soft mb-2"
                    >
                      Password
                    </label>

                    <div className="flex mt-2">
                      <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        value={password}
                        onChange={(event) => {
                          setError("");
                          setPassword(event.target.value);
                        }}
                        placeholder="(min of 8 characters)"
                        autoComplete="current-password"
                        required
                        className="password-control"
                      />
                      <button
                        type="button"
                        onClick={toggleShowPassword}
                        className="password-button"
                      >
                        {showPassword ? (
                          <EyeSlashIcon className="h-5 w-5 text-gray-700" />
                        ) : (
                          <EyeIcon className="h-5 w-5 text-gray-700" />
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="mt-2">
                    <Link
                      to={`/auth/forgot-password`}
                      className="text-[#323232d9] text-sm underline underline-offset-2"
                    >
                      Reset Password?
                    </Link>
                  </div>

                  <div className="mt-7">
                    <button
                      type="submit"
                      disabled={loading || !formValid}
                      className={
                        !loading && formValid
                          ? "login-active"
                          : "login-disabled"
                      }
                    >
                      {loading ? "Loading ..." : "Log in"}
                    </button>
                  </div>

                  <div className="mt-9 text-center">
                    <p className="text-black-soft text-sm ">
                      New user?{" "}
                      <Link
                        to="/auth/register"
                        className="underline underline-offset-2"
                      >
                        Create an account
                      </Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <ToastContainer />
      </div>
    </>
  );
};

export default Login;
