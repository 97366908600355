import completeIcon from "../../../images/complete-trans.svg";
import transitIcon from "../../../images/transit-trans.svg";
import failedIcon from "../../../images/failed-trans.svg";
import PendingIcon from "../../../images/pending-trans.svg";
import RefundIcon from "../../../images/refund-payout.svg";
import ReviewIcon from "../../../images/reviewIcon.svg";
import dayjs from "./../../../utils/dayjs";

const getTransactionIcon = (transfer: any) => {
  const { TransactionType, TransactionState } = transfer;

  if (
    (TransactionType === "MAKE_PAYMENT" ||
      TransactionType === "CARD_FUNDING" ||
      TransactionType === "TAG_TRANSFER" ||
      TransactionType === "CARD_CREATION") &&
    TransactionState === "COMPLETED"
  ) {
    return <img src={completeIcon} alt="complete" />;
  } else if (
    TransactionState === "IN_TRANSIT" ||
    TransactionState === "PENDING"
  ) {
    return <img src={transitIcon} alt="transit" />;
  } else if (
    (TransactionType === "USD_CREDIT" || TransactionType === "USD_CREDIT_REVERSAL_REFUND" || TransactionType === "TAG_CREDIT")  &&
    (TransactionState === "COMPLETED" ||
      TransactionState === "DAILY_LIMIT_EXCEEDED" || TransactionState === "REVERSAL_REFUNDED")
  ) {
    return <img src={PendingIcon} alt="money received" />;
  } else if (
    TransactionType === "REFERRAL_BONUS" &&
    TransactionState === "COMPLETED"
  ) {
    return <img src={PendingIcon} alt="money received" />;
  } else if (
    TransactionType === "MAKE_PAYMENT_REFUND" ||   TransactionType === "TAG_DEBIT_REVERSAL" ||
    TransactionState === "REFUNDED" 
  ) {
    return <img src={RefundIcon} alt="money refunded" />;
  } else if (
    TransactionType === "USD_CREDIT" &&
    (TransactionState === "IN_REVIEW" || TransactionState === "UNDER_REVIEW")
  ) {
    return <img src={ReviewIcon} alt="review deposit" />;
  } else if (
    TransactionType === "TAG_TRANSFER" &&
    TransactionState === "PENDING"
  ) {
    return <img src={transitIcon} alt="transit" />;
  } else if (
    TransactionType === "TAG_TRANSFER" &&
    TransactionState === "COMPLETED"
  ) {
    return <img src={completeIcon} alt="complete" />;
  } else if (
    TransactionType === "TAG_CREDIT" &&
    TransactionState === "COMPLETED"
  ) {
    return <img src={PendingIcon} alt="money received" />;
  } else {
    return <img src={failedIcon} alt="failed" />;
  }
};

const parseToDateTimeString = (date: Date) => {
  return dayjs(date).utcOffset(1).format("MMMM D, YYYY h:mm A");
};

const convertTime = (myDate: any, includeTime: boolean = true) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const day = myDate.getDate();
  const month = months[myDate.getMonth()];
  const year = myDate.getFullYear();

  if (!includeTime) {
    return `${month} ${day}, ${year}`;
  }

  let hour = myDate.getHours();
  let minutes = myDate.getMinutes();
  let period = "am";

  if (hour >= 12) {
    hour -= 12;
    period = "pm";
  }

  if (hour === 0) {
    hour = 12;
  }

  if (minutes < 10) {
    minutes = "0" + minutes;
  }

  const datestring = `${month} ${day}, ${year}  ${hour}:${minutes}${period}`;
  return datestring;
};

const getTransferStatus = (transfer: any) => {
  const { TransactionState, TransactionType } = transfer;
  let status;
  let color;

  switch (TransactionState) {
    case "COMPLETED":
      status = "Completed";
      color = "#278033";
      break;
    case "IN_TRANSIT":
    case "PENDING":
      status =
        TransactionType === "CARD_CREATION" ||
        TransactionType === "CARD_FUNDING"
          ? "In Progress"
          : "In Transit";
      color = "#1892D7";
      break;
    case "KYC_PENDING":
    case "DAILY_LIMIT_EXCEEDED":
      status = "Pending";
      color = "#303030";
      break;
    case "REJECTED":
    case "RETURNED":
      status = "Rejected";
      color = "#FA624D";
      break;
    case "REVERSED":
      status = "Reversed";
      color = "#D31D1D";
      break;
    case "DELAYED":
      status = "Delayed";
      color = "#D31D1D";
      break;
    case "REFUNDED":
      status = "Refunded";
      color = "#B8730A";
      break;
    case "REVERSAL_REFUNDED":
      status = "Reversal Refund";
      color = "#B8730A";
      break;
    case "IN_REVIEW":
      status = "Processing";
      color = "#111111";
      break;
    case "UNDER_REVIEW":
      status = "Under Review";
      color = "#9B1C1C";
      break;
    default:
      status = TransactionState;
      color = "#D31D1D";
  }

  return (
    <p
      className={`text-right text-[14px] font-normal leading-tight text-[${color}]`}
    >
      {status}
    </p>
  );
};

const formatAmount = (amount: number | undefined): string => {
  if (amount === undefined) return '';
  
  const formattedAmount = amount.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return amount < 0 ? `- $${Math.abs(amount).toLocaleString()}` : `+ $${formattedAmount}`;
};

const getTransactionType = (
  transactionType: string | undefined,
  transactionState: string | undefined,
  FromAmount: number | undefined,
  TotalAmount: number | undefined
): string => {
  if (!transactionType) return '';

  switch (transactionType) {
    case "USD_CREDIT":
    case "REFERRAL_BONUS":
    case "MAKE_PAYMENT_REFUND":
    case "CARD_FUNDING_REFUND":
    case "CARD_CREATION_REFUND":
    case "CARD_TERMINATION_REFUND":
      return formatAmount(FromAmount);
    case "REFERRAL_BONUS":
      return transactionState !== "REVERSED" ? formatAmount(FromAmount) : '';
    default:
      return formatAmount(TotalAmount);
  }
};


export { getTransactionIcon, convertTime, getTransferStatus, parseToDateTimeString, formatAmount, getTransactionType};
