import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { kycError } from "../../../Image";
import { useIsBusinessUser } from "../../../utils";

interface ModalProps {
  open:  boolean;
  setOpen: any;
}
const BVNInvalidModal = ({setOpen, open}:ModalProps) => {
  const cancelButtonRef = useRef(null);
const isBusinessUser = useIsBusinessUser()
  
  const close = () => {
    setOpen(false);
  };
  return (
    <div>
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={close}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white pb-8 text-left shadow-xl transition-all sm:my-8 md:max-w-lg  w-[28rem]">
                <header
                  className={`w-full text-left bg-[#FFFFFF] py-4 px-8  flex justify-between`}
                >
                  <Dialog.Title
                    as="h3"
                    className="text-base font-bold leading-6 text-gray-900"
                  >
                    {" "}
                  </Dialog.Title>
                  <button
                    type="button"
                    className="rounded-md text-black float-right justify-end hover:text-gray-500 focus:outline-none focus:ring-2 font-bold"
                    onClick={() => close()}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                  </button>
                </header>

                <div className="flex justify-center  w-full items-center ">
                <div className="p-2 sm:w-[80%] md:w-[80%] pb-14 sm:pb-8 rounded-lg ">
                <center className="">
                    <img className="sm:w-[80px] md:w-[120px]" src={kycError} alt="" />
                    <h2 className="text-[#000] font-semibold pt-4 text-xl">
                      {
                        isBusinessUser
                        ? 
                        'Business registration number already in use'
                        :
                        'BVN already in use'
                      }
                
                    </h2>
                    <p className="py-3 text-sm text-[#444444]">
                      {
                        isBusinessUser?
                        "The business ID you entered has already been associated with another user's account."
                        :
                        "The Bank Verification Number (BVN) you entered has already been associated with another user's account."
                      }
                    </p>
                    
                    <button
                        onClick={() => close()}
                        className="w-[70%] mx-auto text-[15px] font-semibold p-3 rounded-lg mt-4 border bg-[#FFBD59]">
                        Try again
                    </button>
                
                </center>
                </div>
                </div>
              
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  </div>
  )
}

export default BVNInvalidModal