import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import logo from "../../asset/images/logo.svg";
import errorImg from '../../asset/svg/material-symbols_warning.svg';
import authImg from "../../asset/images/login-img.svg";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import { IoCloseOutline } from "react-icons/io5";
import { BiErrorCircle } from "react-icons/bi";
import "react-phone-number-input/style.css";
import "../Country/styles/style.css"
import PhoneInput from "react-phone-number-input";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthServices, { UserTypes } from "../../features/services/AuthServices";
import { removeAuthTokens } from "../../login";
import CountrySelect from "../Country/CountrySelect";
import ErrorModal from "../PopUps/ErrorModal";
import { isValidPhoneNumber, E164Number } from "libphonenumber-js";
import { RegisterCheckboxData as checkboxes } from "./utils"
import { AWS_CREDS } from "../../features/services/AmazonService";
import axios from "../../features/services/AxiosInstance";

const defaultCountry = {
  "id": 161,
  "name": "Nigeria",
  "iso3": "NGA",
  "iso2": "NG",
  "numeric_code": "566",
  "phone_code": 234,
  "capital": "Abuja",
  "currency": "NGN",
  "currency_name": "Nigerian naira",
  "currency_symbol": "₦",
  "tld": ".ng",
  "native": "Nigeria",
  "region": "Africa",
  "subregion": "Western Africa",
  "latitude": "10.00000000",
  "longitude": "8.00000000",
  "emoji": "🇳🇬",
  "emojiU": "🇳🇬"

}

export const isValidPassword = (password: string) => {
  const minLength = 8;
  const symbolAndUpperCaseRegex = /^(?=.*[A-Z])(?=.*[!@#$&*.[\]{}()?!"!@#$%^&*\/\\,><':;|_~`=+-])(?=.*[0-9])(?=.*[a-z]).{8,99}$/;


  const hasMinLength = password.length >= minLength;
  const hasSymbol = symbolAndUpperCaseRegex.test(password);

  return hasMinLength && hasSymbol;
}

export const hasSpace = (password: string) => {
  return password.includes(' ');
}

export const isValidEmail = (email: string) => {
  let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');

  const isTrue = regex.test(email)

  return isTrue;
}

const Register = () => {
  const location = useLocation();
  const navigate = useNavigate();


  const [firstName, setFirstName] = useState<string>("");
  const [showPasswordError, setShowPasswordError] = useState<boolean>(false);
  const [pwdHasSpace, setPwdHasSpace] = useState<boolean>(false);
  const [showEmailError, setShowEmailError] = useState<boolean>(false);
  const [showCheckboxError, setShowCheckboxError] = useState<boolean>(false);
  const [lastName, setLastName] = useState<string>("");

  const [businessName, setBusinessName] = useState<string>("");//for business users
  const [userType, setUserType] = useState<UserTypes>("IndividualUser");//use for dynamic setting of user type
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [country, setCountry] = useState<string>("Nigeria");
  const [gender, setGender] = useState<"MALE" | "FEMALE" | "OTHER">("OTHER");
  const [referralCode, setReferralCode] = useState<string>();
  const [phone_number, setPhoneValue] = useState<string>();
  const [selectedBox, setSelectedBox] = useState<string[]>([]);
  const [termsofService, setTermsofService] = useState<boolean>(false);
  const [formValid, setFormValid] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [handleAPIError, setHandleAPIError] = useState<string>('An error occured, please try again later');
  const [APIErrorOccured, setAPIErrorOccured] = useState<boolean>(false);
  const [genericError, setGenericError] = useState<boolean>(false);
  const [phoneError, setPhoneError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [clevaMails, setClevaMails] = useState(false)
  const invalidReferralError = "Invalid Referral Code"
  const invalidRequestBody = "Validation error, please try again"
  const userAlreadyExist = "User already exists"
  const anErrorOccurred = "An error occured, please try again later"

  useEffect(() => {
    const referralCode = localStorage.getItem('referralCode');
    if (referralCode && referralCode !== 'register' && referralCode && referralCode !== 'details') {
      setReferralCode(referralCode)
    }

    const accountType = localStorage.getItem("accountType")
    if (accountType && accountType === "BusinessUser") {
      setUserType('BusinessUser')
    } else {
      setUserType('IndividualUser')
    }

  }, [location, navigate]);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordFocus = () => {
    setShowPasswordError(!isValidPassword(password))
    setPwdHasSpace(hasSpace(password))
  };

  const handlePasswordBlur = () => {
    setShowPasswordError(!isValidPassword(password))
    setPwdHasSpace(hasSpace(password))
  };

  const handlePasswordChange = (e: any) => {
    setShowPasswordError(!isValidPassword(e.target.value))
    setPwdHasSpace(hasSpace(e.target.value))
    setPassword(e.target.value)
  }

  const handleEmailChange = (e: any) => {
    setShowEmailError(!isValidEmail(e.target.value))
    setEmail(e.target.value)
  }

  // Handle checkbox value
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowCheckboxError(false)
    const value = e.target.value;

    setSelectedBox((prevSelected) => {
      const isSelected = prevSelected.includes(value);

      if (isSelected) {
        return prevSelected.filter((item) => item !== value);
      }
      if (prevSelected.length === 3) {
        setShowCheckboxError(true)
        return prevSelected;
      }
      return [...prevSelected, value];
    });
  };

  // Handle terms of service 
  const handleTerms = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTermsofService(e.target.checked)
  };


  // Handle generic error
  const handleError = (inputField: string, text: string) => {
    // alert(inputField)
    if (!inputField) {
      setGenericError(true)
      setError(text)
    }
    else {
      setGenericError(false)
      setError("")
    }
  }

  function isCorrectNumberFormat(phoneNumber: string): boolean {
    const regex = /^\+\d+$/;
    return regex.test(phoneNumber);
  }

  function isValidPhoneNumberLength(phoneNumber: string): boolean {
    return phoneNumber.length === 14;
  }

  //handle phone validation
  const handlePhoneNumberChange = (value: any) => {
    setPhoneValue(value);
   
    if (isCorrectNumberFormat(value) && isValidPhoneNumberLength(value)) {
      setPhoneError(false);
    } else {
      setPhoneError(true)
    }
    
  
  };


  // const handleFocus = (value: any) => {
  //   let phoneValue = value.target.value.replace(/\s/g, '')
  //   if (isCorrectNumberFormat(value) && isValidPhoneNumber(phoneValue)) {
  //     setPhoneError(false)
  //   }
  //   else {
  //     setPhoneError(true)
  //   }
  // };

  // const handleBlur = (value: any) => {
  //   let phoneValue = value.target.value.replace(/\s/g, '')
  //   if (isCorrectNumberFormat(value) && isValidPhoneNumber(phoneValue)) {
  //     setPhoneError(false);
  //   } else {
  //     setPhoneError(true);
  //   }
  // };

  const data = {
    firstName, lastName, email: email.toLowerCase(), referralCode, businessName, password, country, phone_number, selectedBox, userType, gender
  }

  // handle form submit and send params to amanzon cognito
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    removeAuthTokens();
    e.preventDefault();
    setLoading(true);

    try {
      await AuthServices.createUser(data);
      const cognitoUser = data;
      const registeredEmail = cognitoUser.email;

      localStorage.setItem("registeredEmail", registeredEmail);
      toast.success("Account created successfully!");
      setLoading(false);


      // Wait for toast message to display before navigating
      setTimeout(() => {
        navigate("/auth/verify-email/pending");
      }, 2000);
    } catch (error: any) {
      setLoading(false);
      setAPIErrorOccured(true)
      if(AWS_CREDS.STAGE === 'qa'){
        console.log(error);
      }

      let errorDetails;
      if (!error.response) {
        errorDetails = JSON.stringify(error.message).toString()
      } else {
        errorDetails = JSON.stringify(error.response.data).toString();
      }

      let errorMsg = errorDetails.toLowerCase()
      if(AWS_CREDS.STAGE === 'qa'){

        console.log('error data:', errorMsg);
      }

      if (errorMsg.includes('invalid referral code')) {
        setHandleAPIError(invalidReferralError)
      } else if (errorMsg.includes('invalid request body')) {
        setHandleAPIError(invalidRequestBody)
      } else if (errorMsg.includes('user already exists')) {
        setHandleAPIError(userAlreadyExist)
      } else {
        setHandleAPIError(anErrorOccurred)
        // toast.error('an error occured, please try again later')
      }
    }
  };

  const prevent = (e: React.ChangeEvent<any>) => {
    e.preventDefault()
  }


  useEffect(() => {
    if (
      firstName && lastName && isValidEmail(email) && businessName !== " " && country !== "" && phone_number && !phoneError
      && isValidPassword(password) && !hasSpace(password) && selectedBox.length > 0 && termsofService === true) {

      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [firstName, lastName, email, password, country, phone_number, selectedBox, termsofService, phoneError]);


  const select = localStorage.getItem("select")

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);


  return (
    <>
      {APIErrorOccured &&

        <ErrorModal onClose={() => setAPIErrorOccured(false)} isOpen={APIErrorOccured} shadow="shadow-2xl" bg='bg-[#fff]' topS='top-[-300px]' leftS='lg:left-[550px]'>
          <div className="flex flex-col items-center text-black gap-2 justify-center">
            <IoCloseOutline className='text-[1.5rem] text-[#747474] flex self-end' />
            <BiErrorCircle className="text-[#FFBD59]  text-[3rem]" />
            <p className='text-xl text-black py-8'>
              {handleAPIError}
            </p>
          </div>
        </ErrorModal>
      }
      <div className="md:flex min-h-full ">
        <section className="hidden lg:flex md:flex-col justify-center flex-1 px-4 sm:px-6 lg:flex-none xl:px-20 bg-black text-white">

          <div className="mx-auto w-full max-w-[24rem] 2xl:max-w-[28rem] lg:w-[30rem] xl:w-[28rem]">
            <div className="flex justify-center pt-20 min-h-[100vh] md:items-center md:pt-0">
              <div className="w-full">
                <div>
                  <Link to="/">
                    {/* <img className="w-auto mt-2" src={logo} alt="logo" /> */}
                    <img className="w-auto mt-2" src={logo} alt="logo" />
                  </Link>
                  <h1 className="mt-16 text-[2rem] 2xl:text-4xl leading-loose font-medium text-white">

                    USD banking for you & your business

                  </h1>
                </div>

                <img src={authImg} className="mt-10" alt="login" />
              </div>
            </div>
          </div>
        </section>
        <section className="relative flex-1 md:flex md:justify-center md:items-centers">
          <div className="flex justify-center min-h-[100vh] md:items-center ">
            <div className="login-card w-full xl:w-[40rem] py-8 px-8 md:py-12 md:px-16 md:max-h-[90vh] md:overflow-y-auto md:overscroll-auto;">
              <div className="">
                <div className="lg:hidden flex justify-center">
                  <img className='pb-4 w-[10rem]'
                    src={logo} alt="Cleva banking logo" />
                </div>
                <h2 className="text-center text-2xl text-black-soft">Create an account</h2>

                {/* form section  */}
                <form onSubmit={handleSubmit} className=" mt-6">
                  <div className="grid grid-cols-1 gap-x-6 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >

                        First name <span className="text-red-600 ">*</span>

                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="first-name"
                          id="first-name"
                          value={firstName}

                          required
                          onBlur={(e) => handleError(e.target.value, "first")}

                          // onFocus={(e) => handleError(e.target.value, "first")}
                          onChange={(e) => {
                            setFirstName(e.target.value)
                            handleError(e.target.value, "first")

                          }}

                          autoComplete="given-name"
                          placeholder="First Name"
                          className="input-control border peer-required:border-red-700"
                        />
                        {/* {firstName.length} */}
                        {error == "first" && genericError &&
                          <span className="mt-2  inline-flex text-red-500 text-sm">
                            <img src={errorImg} className="pr-1" alt="error" />
                            First name is required</span>

                        }

                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >

                        Last name <span className="text-red-600 ">*</span>

                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          value={lastName}
                          required
                          onBlur={(e) => handleError(e.target.value, "last")}
                          // onFocus={() => handleError(lastName , "last")}
                          onChange={(e) => {
                            handleError(e.target.value, "last")
                            setLastName(e.target.value)
                          }}

                          name="last-name"
                          id="last-name"
                          autoComplete="family-name"
                          placeholder="Last Name"
                          className="input-control"
                        />
                        {error == "last" && genericError &&
                          <span className="mt-2  inline-flex text-red-500 text-sm">
                            <img src={errorImg} className="pr-1" alt="error" />
                            Last name is required</span>

                        }
                      </div>
                    </div>
                  </div>

                  <div className="mt-5">
                    {userType === "BusinessUser" ?
                    <>
                    <label
                      htmlFor="country"
                      className="block text-sm font-medium text-black-soft"
                    >
                      Country of business registration <span className="text-red-600 pl-1">*</span>
                    </label>
                  <p className="text-[#464646] text-sm">We can only accept businesses registered in the following countries. We'll add more countries soon.</p>  
                    </>
                : 
                <> 
                <label
                htmlFor="country"
                className="block text-sm font-medium text-black-soft"
              >
                Government-issued ID <span className="text-red-600 pl-1">*</span>
              </label>
                    <p className="text-[#464646] text-sm">
                      We can only accept Identity Documents (IDs) from countries on this list. We'll add more countries soon.
                      </p>
                </>
                }
                    <div className="mt-2">
                      <CountrySelect
                        defaultValue={defaultCountry}
                        containerClassName="form-group"
                        inputClassName=""
                        onChange={(e) => {
                          setCountry(e.name)
                        }}
                        onTextChange={(e) => {
          
                        }}
                        placeHolder="Select Country"
                      />
                    </div>

                  </div>

                  {/* for business users */}
                  {userType === "BusinessUser" &&


                    <div className="mt-5">
                      <label
                        htmlFor="businessName"
                        className="block text-sm font-medium text-black-soft"
                      >
                        Business Name<span className="text-red-600 ">*</span>
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="businessName"
                          id="businessname"
                          value={businessName}
                          onBlur={(e) => handleError(e.target.value, "business")}
                          onChange={(e) => {
                            handleError(e.target.value, "business")
                            setBusinessName(e.target.value)
                          }}
                          autoComplete="family-name"
                          className="input-control"
                          placeholder="Your business Name"
                          required
                        />
                        {error == "business" &&
                          <span className="mt-2  inline-flex text-red-500 text-sm">
                            <img src={errorImg} className="pr-1" alt="error" />
                            Business name is required</span>

                        }
                      </div>
                    </div>

                  }

                  <div className="mt-5">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-black-soft"
                    >
                      {userType === "BusinessUser" ?
                        "Business Email Address" : "Email Address"
                      }
                      <span className="text-red-600 ">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        placeholder="Email Address"
                        value={email}
                        onChange={handleEmailChange}
                        required
                        className="input-control peer"
                      />
                      {showEmailError &&
                        <span className="mt-2 ml-3 inline-flex text-red-500 text-sm">
                          <img src={errorImg} className="pr-2" alt="error" />
                          Please enter a valid email address</span>

                      }
                    </div>
                  </div>

                  <div className="mt-5">
                    <label
                      htmlFor="businessName"
                      className="block text-sm font-medium text-black-soft"
                    >
                      Phone Number<span className="text-red-600 pl-1">*</span>
                    </label>
                    <div className="relative mt-2 rounded-md shadow-sm">
                      <PhoneInput
                        international
                        countryCallingCodeEditable={false}
                        placeholder="Enter phone number"
                        maxLength={17}
                        value={phone_number as E164Number}
                        required
                        // onBlur={handleBlur}
                        // onFocus={handleFocus}
                        onChange={handlePhoneNumberChange}
                        defaultCountry="NG"
                        country="NG"
                      />

                    </div>
                    {phoneError &&
                      <span className="mt-4 inline-flex text-red-500 text-sm">
                        <img src={errorImg} className="pr-2" alt="error" />
                        Please enter a valid phone number</span>

                    }
                  </div>

                  <div className="mt-5">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-black-soft mb-2"
                    >
                      Password <span className="text-red-600 ">*</span>

                    </label>

                    <div className="flex mt-2">
                      <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        value={password}
                        onFocus={handlePasswordFocus}
                        onBlur={handlePasswordBlur}

                        onChange={handlePasswordChange}

                        placeholder="Password (min of 8 characters)"
                        autoComplete="current-password"
                        required
                        className="password-control"
                      />
                      <button
                        type="button"
                        onClick={toggleShowPassword}
                        className="password-button"
                      >
                        {showPassword ? (
                          <EyeSlashIcon className="h-5 w-5 text-gray-700" />
                        ) : (
                          <EyeIcon className="h-5 w-5 text-gray-700" />
                        )}
                      </button>
                    </div>

                    {showPasswordError ?
                      <span className="mt-4 inline-flex text-red-500 text-base text-[.9rem]">
                        <img src={errorImg} className="pr-3" alt="error" />
                        Must be at least 8 characters and use at least 1 uppercase, number, and symbol
                      </span>
                      : null
                    }

                    {pwdHasSpace ?
                      <span className="mt-4 inline-flex text-red-500 text-base text-[.9rem]">
                        <img src={errorImg} className="pr-3" alt="error" />
                        Space not allowed in password
                      </span>
                      : null
                    }

                  </div>

                  <div className="mt-5">
                    <label htmlFor="referralCode" className="block text-sm font-medium text-black-soft"> Referral code </label>
                    <div className="relative mt-2 rounded-md shadow-sm">
                      <input
                        id="referralCode"
                        name="referralCode"
                        type="text"
                        autoComplete="off"
                        placeholder="(optional)"
                        value={referralCode}
                        className="input-control"
                        onChange={(e) => setReferralCode(e.target.value.trim())}
                      />
                    </div>
                  </div>

                  <div className="mt-5">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-black-soft "
                    >
                      How did you hear about Cleva? <span className="text-red-600 pl-1 ">*</span>
                    </label>
                    <span className="text-[#5B5B5B] text-sm mb-2">Maximum of 3 can be selected</span>
                    <div className="flex mt-2">
                      <fieldset>
                        <div className="space-y-1">
                          {checkboxes.map((checkbox) => (
                            <CheckboxComponent
                              key={checkbox.value}
                              id={checkbox.value}
                              label={checkbox.label}
                              value={checkbox.value}
                              checked={selectedBox.includes(checkbox.value)}
                              onChange={handleCheckboxChange}
                            />
                          ))}
                        </div>

                        {/* {showCheckboxError &&
                          <span className="mt-2  inline-flex text-red-500 text-sm">
                            <img src={errorImg} className="pr-1" alt="error" />
                            Maximum of 3 sources can be selected
                          </span>
                        } */}
                        <hr className="my-5" />

                        <div className="relative flex items-start mb-4">
                          <div className="flex h-6 items-center">
                            <input
                              id="clevaMails"
                              aria-describedby="clevaMails"
                              name="clevaMails"
                              type="checkbox"
                              value="clevaMails"
                              checked={clevaMails}
                              onChange={() => setClevaMails(!clevaMails)}
                              className="h-4 w-4 rounded border-gray-300 text-cleva-gold focus:ring-cleva-gold"
                            />
                          </div>
                          <div className="ml-3 text-sm leading-6">
                            <label htmlFor="clevaMails" className="text-[#111928]">
                              Keep me in the loop! I consent to receiving emails about Cleva, including product updates, promotions, and industry insights
                            </label>
                          </div>
                        </div>

                        <div className="relative flex items-start">
                          <div className="flex h-6 items-center">
                            <input
                              id="terms"
                              aria-describedby="offers-description"
                              name="terms"
                              type="checkbox"
                              value="terms"
                              checked={termsofService}
                              onChange={handleTerms}
                              className="h-4 w-4 rounded border-gray-300 text-cleva-gold focus:ring-cleva-gold"
                            />
                          </div>
                          <div className="ml-3 text-sm leading-6">
                            <label htmlFor="terms" className="text-[#111928]">

                              I have read and agree to Cleva’s
                              <Link
                                to="/terms-of-service"
                                className="text-sm underline underline-offset-2"
                              >
                                <span className="pl-2 pr-1">Terms of Service </span>
                              </Link>
                              and&nbsp;
                              <Link
                                to="/privacy-policy"
                                className="text-sm underline underline-offset-2"
                              >
                                <span className=""> Privacy Policy.</span>

                              </Link>
                            </label>
                            <span className="text-red-600 ">*</span>
                          </div>
                        </div>
                       
                      </fieldset>
                    </div>
                  </div>
                  {/*<div className="mt-5">*/}
                  {/*  <RecaptchaV3 action="signup" onVerify={handleVerify} />*/}

                  {/*  /!*<input type="hidden" name="recaptcha_response" id="recaptchaResponse"/>*!/*/}
                  {/*  /!*<AWSWAFCaptchaModal/>*!/*/}
                  {/*</div>*/}
                  <div className="mt-7">
                    {!formValid &&
                        <span className="mt-2  inline-flex text-red-500 text-sm">
                        <img src={errorImg} className="pr-1" alt="error" />
                        Please fill all required fields</span>}
                    <button
                      type="submit"
                      disabled={!formValid}
                      className={formValid ? "login-active" : "login-disabled"}
                    >
                      {loading ? "Loading ..." : "Create Account"}
                    </button>
                  </div>
                  <div className="mt-9 text-center">
                    <p className="text-black-soft text-sm ">
                      Returning user?{" "}
                      <Link
                        to="/auth/login"
                        className="underline underline-offset-2"
                      >
                        Login
                      </Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <ToastContainer />
      </div>
    </>
  );
};

export default Register;

// types of id, label, checked and onChange
export const CheckboxComponent = ({ id, label, checked, onChange }: any) => {
  return (
    <div className="relative flex items-start">
      <div className="flex h-6 items-center">
        <input
          id={id}
          value={id}
          type="checkbox"
          checked={checked}
          onChange={onChange}
          aria-describedby={label}
          name={id}
          className="h-4 w-4 rounded border-gray-300 text-cleva-gold focus:ring-cleva-gold"
        />
      </div>
      <div className="ml-3 text-sm leading-6">
        <label htmlFor={id} className="text-black-soft">
          {label}
        </label>
      </div>

    </div>
  )
}