import React, { useEffect, useState, useCallback } from "react";
import { useAppDispatch } from "../../app/hooks";
import { setTitle } from "../../features/Nav/NavSlice";
import backIcon from "../../asset/svg/backIcon.svg";
import { useNavigate } from "react-router-dom";
import loadingIcon from "../../asset/svg/loading.svg";
import { debounce } from "lodash";
import { checkTag, createTag, searchTag } from "../../api";
import { setTag } from "../../features/Tags/TagsSlice";
import { toast, ToastContainer } from "react-toastify";
import BackButton from "../../components/Buttons/BackButton";

export default function CreateTag() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [error, setError] = useState("");
  const [Username, setUsername] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [btnLoading, setButtonLoader] = useState<boolean>(false);
  const [success, setSuccess] = useState("");
  const btnDisabled =
    error !== "" || Username === "" || loading || !success || btnLoading;

  const debouncedSave = useCallback(
    debounce(async (nextValue: string) => {
      setLoading(true);
      try {
        const res = await checkTag({ Tag: nextValue });
        setLoading(false);
        if (res?.data.Available) {
          setSuccess("Tag is available");
        } else {
          setError("Tag already in use by another user");
        }
      } catch (error: any) {
        setLoading(false);
      }
    }, 500),
    []
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let tagName = e.target.value.toLowerCase();

    tagName = tagName.replace(/[^a-z0-9_]/g, "");
    setUsername(tagName);
    setLoading(false);
    setSuccess("");
    setError("");
    if (tagName.length > 12) {
      setError("Tag cannot be more than 12 characters");
    } else if (tagName.length < 4) {
      setError("Tag cannot be less than 4 characters");
    } else {
      setError("");
      setSuccess("");
      if (e.target.value !== "") {
        debouncedSave(tagName);
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setButtonLoader(true);
    try {
      const res = await createTag({ Tag: Username });
      setButtonLoader(false);
      if (res.status === 400) {
        setError("Tag is not available");
      } else {
        dispatch(setTag(Username));
        navigate("/tag-complete");
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    dispatch(setTitle("Create Tag"));
    setUsername("");
  }, []);

  const returnToProfile = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    window.history.back();
  };

  return (
    <>
      <ToastContainer />
      <div className="relative flex-1">
        <div className="flex items-center pt-6">
          <BackButton />
          <p className="text-lg font-bold ml-3">Create Tag</p>
        </div>

        <div className="flex justify-center flex-col md:h-[60vh] text-center">
          <div className="top-4 mt-4 left-0 right-0 bottom-0 bg-cleva-white flex items-center justify-center">
            <div className="login-card-md w-full md:w-[32rem] xl:w-[32rem] px-6 py-10 md:px-10 md:py-16 md:shadow rounded-lg">
              <div className="text-center mb-6 md:w-[70%] m-auto">
                Create a @ClevaTag to receive USD from other Cleva users.
                <p className="text-red-300 italic text-xs mt-3">
                  We allow lowercase and alphanumeric character
                </p>
              </div>
              <div className="">
                <form onSubmit={handleSubmit} autoComplete="off" className=" ">
                  <div className="flex mt-2">
                    <button
                      type="button"
                      className="username-icon bg-[#F4F4F4]"
                    >
                      <p className="text-[20px]">@</p>
                    </button>
                    <input
                      type="text"
                      id="Tag"
                      name="Tag"
                      autoComplete="off"
                      value={Username}
                      onChange={handleInputChange}
                      placeholder="yourTag"
                      required
                      className="username-control"
                      aria-label="yourTag"
                    />
                  </div>

                  <div className="mt-2 text-left">
                    {loading && (
                      <div className="text-[#454545] text-sm mb-2 flex">
                        <img src={loadingIcon} width={20} alt="loading" />{" "}
                        &nbsp; Searching
                      </div>
                    )}

                    {!loading && error && (
                      <div className="text-red-500 text-sm mb-2">{error}</div>
                    )}

                    {!loading && !error && success && (
                      <div className="text-[#158025] text-sm mb-2">
                        {success}
                      </div>
                    )}
                  </div>
                  <div className="mt-8">
                    <button
                      type="submit"
                      disabled={Username === "" || btnDisabled}
                      className={`bg-cleva-gold opacity-${
                        !Username || btnDisabled ? "60" : "100"
                      } flex items-center justify-center mb-3 md:mb-0 font-medium rounded-lg md:py-2.5 py-3 px-4 md:px-8 shadow-sm w-[100%]`}
                    >
                      {!btnLoading ? "Submit" : "Please wait"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
