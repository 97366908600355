interface TagsListProps {
    tags: any[];
    onTagClick: (tag: string) => void;
}
const TagsList = ({tags, onTagClick}:TagsListProps) => {
    return (
        <div className="flex flex-wrap">
            <h2 className="text-[14px] text-[#787878] text-left font-semibold mt-4 mb-8 w-full">Cleva users</h2>
            {tags.map((tag, index) => (
                <div
                    key={index}
                    className="flex w-full mb-8 cursor-pointer" onClick={() => onTagClick(tag)}>
                         <div className="bg-cleva-gold rounded-full h-[45px] w-[45px] flex items-center justify-center mr-4">
                            <span className="text-gray-700 text-lg font-semibold">{tag?.FullName?.FirstName[0]}</span>
                        </div>
                        <div className="text-left">
                            <h2 className="text-[15px] font-semibold">{tag?.FullName?.FirstName} {tag?.FullName?.MiddleName} {tag?.FullName?.LastName}</h2>
                            <p className="text-gray-500 text-[15px]">@{tag?.Tag}</p>
                        </div>
                </div>
            ))}
        </div>
    )

}

export default TagsList;