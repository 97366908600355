import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from '@headlessui/react';
import { verifiedIcon } from '../../Image';
import {useNavigate} from 'react-router-dom';


const ConfirmSelfie = () => {
  const cancelButtonRef = useRef(null);
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  const mobileStyle = 'max-[768px]:top-[50%] max-[768px]:left-1/2  max-[768px]:w-full max-[768px]:h-[400px] max-[768px]:transform max-[768px]:-translate-x-1/2 max-[768px]:-translate-y-1/2';
  const handleNext = () => {
    navigate("/");

  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={handleNext}
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

        <div className={`fixed inset-0 z-10 overflow-y-auto ${mobileStyle}`}>
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all md:max-w-lg w-[23rem]">
                <header className={`w-full text-left bg-[#FFFFFF] flex justify-between`}>
                  <Dialog.Title as="h3" className="text-base font-bold leading-6 text-gray-900">
                    {' '}
                  </Dialog.Title>
                </header>

                <div className="flex justify-center w-full items-center py-4 px-4">
                  <div className="sm:w-[80%] md:w-[80%] pl-7 pb-4 rounded-lg pt-2">
                    <center className="">
                      <img className="sm:w-[40px] md:w-[47px]" src={verifiedIcon} alt="" />
                      <p className="py-3 text-[16px] md:text-[13px] text-[#444444] font-medium">
                        Thank you for submitting your documents. We’ll email you soon with the result of your verification
                      </p>
                      <button
                        onClick={handleNext}
                        className="w-auto px-8 text-[15px] font-semibold p-3 rounded-lg mt-2 border bg-[#FFBD59]"
                      >
                        Done
                      </button>
                    </center>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default ConfirmSelfie