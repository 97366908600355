import React from 'react'
import BackIcon from "../../images/leftArrow.svg"
import { useNavigate } from 'react-router-dom';
import {
  setDescription, setReceiveAmount,
  setRecipientFirstName,
  setRecipientLastName,
  setSendAmount
} from "../../features/Transanctions/TransanctionSlice";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../app/store";
const BackArrow = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>()


  const goBack = () => {
    dispatch(setDescription(''))
    dispatch(setSendAmount(0))
    dispatch(setRecipientFirstName(''))
    dispatch(setRecipientLastName(''))
    dispatch(setReceiveAmount(0))
    navigate(-1)
  }

  return (
    <button className='bg-white border border-[#CFDBD5B2] py-3 px-3.5 rounded-lg' onClick={goBack}>
      <img src={BackIcon} alt="back" />
    </button>
  )
}

export default BackArrow
