import NigeriaFlag from "../../asset/landing_photos/flag-nigeria.svg";

interface IOnboarding { }

const Onboarding = (props: IOnboarding) => {
    const text = "text-black text-base font-normal"
    return (
        <div className="mt-[2rem]  xl:w-[80%] mx-auto 2xl:w-[1280px]">
            <div className="rounded-[1.25rem] bg-[#FCF8E3] text-center py-[1.5rem] px-[1.5rem]">
                <p className={`${text} `}>
                    Currently onboarding citizens of:
                    <span className="font-bold"> Nigeria <img className="inline" src={NigeriaFlag} alt={NigeriaFlag} /></span>
                </p>
                <p className={`${text} pt-[1rem]`}>Email us at <a className="text-[#4672CD] underline cursor-pointer" href="mailto:expansion@getcleva.com" target="_blank" rel="noreferrer">expansion@getcleva.com</a> with a vote for the nationalities you’d like to see Cleva onboard</p>
            </div>
        </div>
    )
}

export default Onboarding;