import { useState, useEffect } from "react";
import TabButtons from "../../../components/Tabs/TabButton";
import TabContent from "../../../components/Tabs/TabContent";
import Table from "../../../components/Table/Index";
import { TransferColumn } from "../../../components/Table/TransferColumn";
import { IncomingTransferColumn } from "../../../components/Table/IncomingTransColumn";
import TransferIcon from "../../../images/make-transfer.svg";
import { Link, useNavigate } from "react-router-dom";
import ViewIncomingTransfer from "./modals/ViewIncomingTransfer";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchTransactions,
  fetchTransactionById,
  fetchDummyTransactions,
} from "../../../features/Transanctions/transactionApi";
import { fetchAccount } from "../../../features/VirtualAccount/VirtualAccountApi";
import { AppDispatch } from "../../../app/store";
import { ToastContainer } from "react-toastify";
import Spinner from "../../../components/PopUps/Spinner";
import { setTitle } from "../../../features/Nav/NavSlice";
import ViewOutgoingTransfer from "./modals/ViewOutgoingTransfer";
import RefundModal from "../../Transfers/M2/modals/ViewPayoutRefund";
import ReversalModal from "../../Transfers/M2/modals/ViewReversal";
import ReversalRefundModal from "../../Transfers/M2/modals/ViewReversalRefund";
import CardFundingModal from "../../Transfers/M2/modals/CardFundingModal";
import C2CModal from "./modals/C2CModal";
import TagTransferModal from "./modals/TagTransferModal";
import TagCreditModal from "./modals/TagCreditModal";

export default function Transfers() {
  const allAccount = useSelector(
    (state: any) => state.virtualAccount.allAccount
  );
  const { allTransfer, loading, error } = useSelector(
    (state: any) => state.transaction
  );
  const [activeTab, setActiveTab] = useState<string>("all");
  const [modal, setModal] = useState(false);
  const [openOption, setOpenOption] = useState(false);
  const [transactionType, setTransactionType] = useState("");
  const [transactionState, setTransactionState] = useState("");
  const [statementModal, setStatementModal] = useState(false);
  const [_, setSelectedTransfer] = useState<any>(null);
  const dispatch = useDispatch<AppDispatch>();
  const accountNumber = allAccount ? (allAccount as any).AccountNumber : " ";

  useEffect(() => {
    dispatch(setTitle("Transfers"));
    dispatch(fetchTransactions());
    dispatch(fetchDummyTransactions(accountNumber));
    dispatch(fetchAccount());
  }, [dispatch]);

  const navigate = useNavigate();

  function OutgoingModal(row: any) {
    setTransactionType(row.TransactionType);
    setTransactionState(row.TransactionState);
    dispatch(fetchTransactionById(row?.TransactionIdentifier));
    setModal(!modal);
  }
  function handleStatementModal() {
    setStatementModal(!statementModal);
  }


  const IncomingModal = (row: any) => {
    setTransactionType(row.TransactionType);
    setTransactionState(row.TransactionState);

    const transfer = allTransfer.find(
      (transfer: any) =>
        transfer?.TransactionIdentifier === row.TransactionIdentifier
    );
    setSelectedTransfer(transfer);
    dispatch(fetchTransactionById(transfer?.TransactionIdentifier));
    setModal(!modal);
  };

  const closeModal = () => {
    setSelectedTransfer(null);
    setModal(false);
  };

  const OutgoingTrans = allTransfer.filter((transfer: any) => {
    return (
      transfer.TransactionType === "MAKE_PAYMENT" ||
      transfer.TransactionType === "CARD_FUNDING" ||
      transfer.TransactionType === "CARD_CREATION" ||
      transfer.TransactionType === "TAG_TRANSFER" ||
      transfer.TransactionState === "REVERSED"
    );
  });
  const IncomingTrans = allTransfer.filter((transfer: any) => {
    //console.log(allTransfer.filter((transfer:any) => transfer.TransactionType === 'USD_CREDIT'))
    //))
    return (
      (transfer.TransactionType === "USD_CREDIT" &&
        transfer.TransactionState !== "REVERSED") ||
      transfer.TransactionType === "TAG_CREDIT" ||
      transfer.TransactionType === "USD_CREDIT_REVERSAL_REFUND" ||
      transfer.TransactionType === "REFERRAL_BONUS" ||
      transfer.TransactionType === "MAKE_PAYMENT_REFUND" ||
      transfer.TransactionType === "CARD_TERMINATION_REFUND" ||
      transfer.TransactionType === "CARD_CREATION_REFUND" ||
      transfer.TransactionType === "TAG_CREDIT" ||
      transfer.TransactionType === "CARD_FUNDING_REFUND" ||
      transfer.TransactionType === "TAG_DEBIT_REVERSAL" 
    );
  });


  useEffect(() => {
      dispatch(fetchTransactions());
  },[dispatch]);

  return (
    <>

        <div className="pt-10 mb-10 Tables">
          <div className="md:flex items-center justify-end xs:flex-wrap md:flex-nowrap md:float-right">
            <div className="md:w-auto order-1 md:order-3 w-100 mb-3 md:mb-0">
              <div className="flex md:justify-end right">
                <Link
                  to="/transfers/create"
                  className="btn flex bg-cleva-gold items-center border-[0.5px] border-[#8A8A8A] py-2 px-4 rounded-lg">
                  <img src={TransferIcon} alt="" className="mr-1" />
                  Make transfer
                </Link>
            </div>
          </div>
          </div>

          <div className=" w-full md:mb-8 md:w-1/2 order-0 md:order-0 pt-5 mb-5">
          <ul className="grid grid-cols-2 gap-4 bg-[#EBF0F0] rounded-[10px] px-2 py-1.5">
              <TabButtons
                name="Outgoing"
                id="all"
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
              <TabButtons
                name="Incoming"
                id="successfulTab"
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </ul>
          </div>
          </div>
        <div className="mt-4">
          <TabContent id="all" activeTab={activeTab}>
            {loading ? (
              <Spinner /> // Show the spinner when loading is true
            ) : (
              <Table
                data={OutgoingTrans}
                TableColumns={TransferColumn}
                title="Recent outgoing transfers"
                searchPlaceholder="Search transfers"
                onClickTable={OutgoingModal}
                onclickStatementButton={() => {
                  handleStatementModal();
                }}
              />
            )}
          </TabContent>
          <TabContent id="successfulTab" activeTab={activeTab}>
            {loading ? (
              <Spinner /> // Show the spinner when loading is true
            ) : (
              <Table
                data={IncomingTrans}
                TableColumns={IncomingTransferColumn}
                title="Recent Incoming transfers"
                searchPlaceholder="Search transfers"
                onClickTable={IncomingModal}
                onclickStatementButton={handleStatementModal}
              />
            )}
          </TabContent>
        </div>
        {modal && (
          <>
            {activeTab === "all" && transactionType === "MAKE_PAYMENT" ? (
              <ViewOutgoingTransfer closeModal={closeModal} />
            ) : (transactionType === "MAKE_PAYMENT_REFUND" ||transactionType === "TAG_DEBIT_REVERSAL" ) ? (
              <RefundModal closeModal={closeModal} />
            ) : transactionState === "REVERSED" ? (
              <ReversalModal closeModal={closeModal} />
            ) : transactionState === "REVERSAL_REFUNDED" ? (
              <ReversalRefundModal closeModal={closeModal} />
            ) : transactionType === "CARD_FUNDING" ? (
              <CardFundingModal closeModal={closeModal} />
            ) : (transactionType === "CARD_CREATION" || transactionType === "CARD_TERMINATION_REFUND" || transactionType === "CARD_CREATION_REFUND" || transactionType === "CARD_FUNDING_REFUND") ? (
              <CardFundingModal closeModal={closeModal} />
            ) : (transactionType === "TAG_CREDIT" || transactionType === "TAG_TRANSFER") ?
              <C2CModal closeModal={closeModal} />
              : (
                <ViewIncomingTransfer closeModal={closeModal} />
              )}
          </>
        )}
      <ToastContainer />
    </>
  );
}
