import React, { useEffect, useState } from "react";
import ClevaAccount from "./extras/ClevaAccount";
import transferIcon from "../../images/make-transfer.svg";
import infoIcon from "../../images/info-icon.svg";
import { Link, useNavigate } from "react-router-dom";
import DetailsModal from "../../pages/Accounts/components/DetailsModal";
import { useAppSelector } from "../../app/hooks";
import Spinner from "../PopUps/smallSpinner";
import { getKyc } from "../../api";
import { setAdditionalDetails, setKYCState } from "../../features/Kyc/kycSlice";
import { AppDispatch } from "../../app/store";
import { useDispatch } from "react-redux";
import { AiOutlineFile, AiOutlineRight } from "react-icons/ai";

const AccountCard: React.FC = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const allAccount = useAppSelector((state) => state.virtualAccount.allAccount);

  const status = allAccount ? (allAccount as any).VirtualAccountState : "";

  const { allTransfer } = useAppSelector((state) => state.transaction);
  const { KYCState } = useAppSelector((state) => state.kycInfo);
  const KYCIdentifier = localStorage.getItem("KYCIdentifier");
  const [loading, setLoader] = useState(true);

  const kycPendingTransfers = allTransfer.filter(
    (transfer: any) => transfer?.TransactionState === "KYC_PENDING"
  );



  const isButtonDisabled =
    kycPendingTransfers.length > 0 || allTransfer.length < 1;
  const BVNVerified = KYCState !== "VERIFIED" && KYCState !== 'BUSINESS_VERIFIED';
  const disabledBtn = status === "DEACTIVATED" || BVNVerified;

  const dispatch = useDispatch<AppDispatch>();

  const handleDetails = () => {
    setShowModal(true);
    // setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const navigateTransfer = () => {
    navigate("/transfers/create");
  };

  useEffect(() => {
    if (KYCIdentifier) {
      getKyc(KYCIdentifier)
        .then((res) => {
          if (res?.data?.IndividualKyc) {
            dispatch(setKYCState(res?.data?.IndividualKyc?.KYCState!));
            dispatch(
              setAdditionalDetails(res?.data?.IndividualKyc?.AdditionalDetails)
            );
          } else if (res?.data?.BusinessKyc) {
            dispatch(setKYCState(res?.data?.BusinessKyc?.KYCState!));
            dispatch(
              setAdditionalDetails(res?.data?.BusinessKyc?.AdditionalDetails)
            );
          } else {
            dispatch(setKYCState(""));
          }
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
          dispatch(setKYCState(""));
        });
    } else {
      setLoader(false);
    }
  }, []);

  return (
    <>
      {allAccount ? (
        <>
          <div className="bg-white rounded-2xl p-5 md:p-10">
            <div className="flex-col gap-4 flex  xl:w-[60%] 2xl:w-[50%] mx-auto">
              <ClevaAccount />

              <div className="justify-center w-full md:items-start gap-4 md:inline-flex flex-col md:flex-row items-center">
                <div>
                  <button
                    className={`bg-cleva-gold opacity-${
                      isButtonDisabled || disabledBtn ? "60" : "100"
                    } flex items-center justify-center mb-3 md:mb-0 font-medium rounded-lg md:py-2.5 py-3 px-4 md:px-8 shadow-sm w-[100%]`}
                    disabled={isButtonDisabled || disabledBtn}
                    onClick={navigateTransfer}
                    // reloadDocument
                  >
                    <img src={transferIcon} alt="" className="mr-2" />
                    Transfer
                  </button>
                </div>

                {/* buttons  */}
                <div>
                  {/* desktop button  */}
                  <button
                    onClick={() => handleDetails()}
                    disabled={disabledBtn}
                    className={`opacity-${
                      disabledBtn ? "60" : "100"
                    } text-[#111111] text-[16px] justify-center font-medium border border-[#8A8A8A] md:flex rounded-lg p-3  md:px-4 md:py-2.5 shadow-sm w-[100%] hidden`}
                  >
                    <img src={infoIcon} alt="" className="mr-2" />
                    Account details
                  </button>

                  {/* mobile button  */}
                  <Link
                    to="/accounts/account-details"
                    className="text-[#111111] text-[16px] justify-center font-medium border border-[#8A8A8A] flex rounded-lg p-3  md:px-4 md:py-2.5 shadow-sm w-[100%] md:hidden"
                  >
                    <img src={infoIcon} alt="" className="mr-2" />
                    Account details
                  </Link>
                </div>
              </div>
            </div>
            {showModal && <DetailsModal closeModal={handleCloseModal} />}
          </div>

          <section className="py-4 h-12  md:h-20 cursor-pointer rounded-lg px-4 my-5 text-sm md:text-base bg-gray-50 border border-[#E9E9E9] hover:bg-slate-100">
            <div
              onClick={() => navigate("/accounts/statement")}
              className="flex h-full mx-auto items-center justify-between md:justify-center md:w-[60%] gap-3 "
            >
              <div className="flex items-center gap-3 ">
                <span className="text-[20px]">
                  <AiOutlineFile />
                </span>
                Generate account statement
              </div>
              <span>
                <AiOutlineRight />
              </span>
            </div>
          </section>
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default React.memo(AccountCard);
