import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import logo from "../../asset/images/logo.svg";
import authImg from "../../asset/images/login-img.svg";
import errorImg from '../../asset/svg/material-symbols_warning.svg';
import { ToastContainer, toast } from "react-toastify";
import { AWS_CREDS } from '../../features/services/AmazonService';
import Logo from "../../asset/logo.svg";
import AuthService from '../../features/services/AuthServices'
import { formatTime } from "./AwaitingEmailVerification";

const ForgotPassword = () => {
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("")
  const [countdown, setCountdown] = useState(300); 
  const [formattedTime, setFormattedTime] = useState(formatTime(countdown));
  const [resendEmail, setResendEmail] = useState<boolean>(true)


  const emailHandler = (e: any) => {
    setError("")
    setEmail(e.target.value)
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    try {
      const params = {
        ClientId: AWS_CREDS.CACHED_BUSINESS_USER_POOL_CLIENT_ID,
        Username: email.toLowerCase(),
      };

      await AuthService.forgotPassword(email.toLowerCase())

      setLoading(false);
      toast.success("Email sent. Click the link from the email");
      setResendEmail(false)
      setCountdown(300)

    } catch (error: any) {
      toast.success("An error occurred. Please try again.");
      if(AWS_CREDS.STAGE === 'qa'){
     console.log(error)
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (countdown <= 0) {
        clearInterval(interval);
        setResendEmail(true)
        return;
      }
      setCountdown(countdown - 1);
      setFormattedTime(formatTime(countdown - 1));
    }, 1000);

    return () => {
      clearInterval(interval); 
    };
  }, [countdown]);

  return (
    <>
      <div className="md:flex min-h-full">
        <div className="hidden md:flex md:flex-col justify-center flex-1 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-20 bg-black text-white ">
          <div className="mx-auto w-full max-w-[24rem] 2xl:max-w-[28rem] lg:w-[30rem] xl:w-[28rem]">
            <div className="flex justify-center pt-20 min-h-[100vh] md:items-center md:pt-0">
              <div className="w-full">
                <div>
                  <Link to="/">
                    <img className="w-auto mt-2" src={logo} alt="logo" />
                  </Link>
                  <h1 className="mt-12 text-4xl font-medium text-white">
                    Forgot Password
                  </h1>
                </div>

                <img src={authImg} className="mt-20" alt="forgot password" />
              </div>
            </div>
          </div>
        </div>
        <div className="relative flex-1 bg-[#FBFBFB]">
          <div className="flex justify-center min-h-[100vh] md:items-center pt-8 md:pt-0">
            <div className="login-card w-full lg:w-[32rem] xl:w-[36rem] py-8 px-8 md:py-16 md:px-20">
              <div className="">
                <div className="md:hidden flex items-center justify-center ">
                  <img className='pb-4 w-[10rem]' src={Logo} alt="Cleva banking logo" />
                </div>
                <h2 className="text-center md:text-left text-2xl text-black-soft">Forgot Password?</h2>
                <p className="text-center md:text-left text-[#5F5D5D] md:w-[20rem] text-sm mt-4">
                  No worries, we’ll send reset instructions to your registered
                  email address
                </p>
                {/* form section  */}
                <form onSubmit={handleSubmit} className=" mt-10">
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-black-soft"
                    >
                      Email Address
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        placeholder="Email Address"
                        value={email}
                        onChange={emailHandler}
                        required
                        className="input-control"
                      />
                      {error !== "" ?
                        <span className='inline-flex py-2 '> <img src={errorImg} className="" alt="error" />
                          <span className='text-red-800 pl-2 text-sm font-normal'>
                            {error}</span></span> : null}
                    </div>
                  </div>

                  {resendEmail ?
                    <div className="mt-7">
                    <button type="submit" className="login-active">
                      {loading ? "Loading ..." : "Reset Password"}
                    </button>
                  </div>
               // <p className="text-center text-[#404040d9] text-base">Didn’t receive an email? <a className='underline cursor-pointer hover:text-black' onClick={handleResendEmail}>Resend</a></p>
                :
                <p className="text-center text-[#404040d9] pt-4 text-base">Didn’t receive an email? <span className="text-black">Resend in {formattedTime}</span> secs</p>
              }
                

                  <div className="mt-9 text-center">
                    <Link
                      to="/auth/login"
                      className="underline underline-offset-2 text-black-soft text-sm "
                    >
                      Return to Login Page
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />

      </div>
    </>
  );
}

export default ForgotPassword
