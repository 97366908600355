import React, {  useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Products from "./Products";
import NewProducts from "./NewProducts";
import GetStarted from "./GetStarted";
import Secure from "./Secure";
import FAQs from "./FAQs";
import Reviews from './Reviews';



import Hero from './Hero';
import Navbar from './Navbar';
import Footer from './Footer';
import DownloadPrompt from './DownloadPrompt';




function Page() {
  const productsRef = useRef<HTMLDivElement>(null);
  const faqRef = useRef<HTMLDivElement>(null);
  const divRef = useRef<HTMLDivElement>(null);
  const location  = useLocation()
  const [isMobile, setIsMobile] = useState(false)
  const handleMobileNav = () => setIsMobile(!isMobile)
  const navigate = useNavigate()
  const [displayProducts, setDisplayProducts] = useState(false)
  const [productsMobile, setProductsMobile] = useState(false)

  const scrollToProducts = () => {
    if (productsRef?.current) {
      productsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToFAQ = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    navigate('/faq')
  };

  const scrollToBlog = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    window.open('https://medium.com/@clevabanking', '_blank');
  };
  const scrollToRefer = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    navigate('/refer-and-earn')
  };

  useEffect(() => {
    const handleScrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    window.addEventListener('popstate', handleScrollToTop);

    return () => {
      window.removeEventListener('popstate', handleScrollToTop);
    };
  }, []);
  
  useEffect(() => {
    const scrollToTtop = () => {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, 0)

    }
    scrollToTtop()

  }, [location.pathname]);

  const showProductsMobile = (event:any) => {
    event.stopPropagation()
    setProductsMobile(!productsMobile)
  }


const showProducts = (event:any) => {

    setDisplayProducts(!displayProducts)}

  const closeProducts = () => {
   
   setDisplayProducts(false)
  }



  return (
    <div ref={divRef} className='font-montserrat'>
      <Navbar productsMobile={productsMobile} closeProducts={closeProducts} displayProducts={displayProducts} showProductsMobile={showProductsMobile} showProducts={showProducts}  isMobile={isMobile} handleMobileNav={handleMobileNav} scrollToProducts={scrollToProducts} scrollToFAQ={scrollToFAQ} scrollToBlog={scrollToBlog} scrollToRefer={scrollToRefer} />
      <Hero />
        <Reviews />
        <Products innerRef={productsRef} />
        <NewProducts />
        <GetStarted />
        <Secure />
        <FAQs innerRef={faqRef} />
        <DownloadPrompt/>
      <Footer />
    </div>
  )
}

const LandingPage = Page
export default LandingPage;
