import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setTitle } from "../../features/Nav/NavSlice";
import backIcon from "../../asset/svg/backIcon.svg";
import { useNavigate } from "react-router-dom";
import successIcon from "../../images/checkmark.svg";

export default function TagComplete() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch();
    const userTag = useAppSelector((state) => state.tags.userTag);

    useEffect(() => {
        dispatch(setTitle("Create Tag"));
    }, [dispatch]);
    
    
    const returnToProfile = () => {
     navigate("/profile");
    }
      

    return (
        <>
            <div className="relative flex-1">

                <div className="flex items-center pt-6">
                    <img src={backIcon} alt="Create Tag" />
                  <p className="text-lg font-bold ml-3">Create Tag</p>
                </div>

               
                <div className="flex justify-center flex-col md:h-[60vh] text-center">
                    <div className="top-4 md:mt-4 left-0 right-0 bottom-0 bg-cleva-white flex items-center justify-center">
                        <div className="login-card-md w-full md:w-[32rem] xl:w-[32rem] px-6 py-10 md:px-10 md:py-16 md:shadow rounded-lg">
                            <div className="flex items-center justify-center">
                                <img
                                loading="lazy"
                                className="m-4 mt-8 w-[4rem]"
                                src={successIcon}
                                alt="success"
                                />
                            </div>
                            <div className="mt-1 text-center items-center">
                                <p className="text-5md font-semibold text-[#158025]">
                                Tag created successfully
                                </p>
                                <p className="text-md m-auto text-[#484848] py-2 md:w-[80%]">
                                Share your Cleva tag <b>(@{userTag})</b> to receive instant USD from other Cleva users
                                </p>
                            </div>
                            <div className="mt-8 items-center">
                                <button
                                    onClick={returnToProfile}
                                    className="m-auto login-active sm:w-full md:w-auto md:px-12 py-4 text-sm font-medium text-white bg-[#0772D5] rounded-lg">
                                    Done
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}