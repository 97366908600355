import React from 'react'
import { IoPersonOutline } from 'react-icons/io5'
import { TbArrowBarRight } from 'react-icons/tb'
import { GrShieldSecurity, GrSettingsOption } from 'react-icons/gr'
import { removeAuthTokens } from '../../login'
import { useNavigate } from 'react-router'
import { useAppDispatch } from '../../app/hooks'
import { setUser } from '../../features/User/UserSlice'
import { AuthService } from '../../features/services/AuthServices'
import { resetKYBState, setCompletedSteps, setModalState, setStep } from "../../features/Kyc/kycSlice";
import { resetBvnVerified } from '../../features/ClevaCards/ClevaCardsSlice'
import { sendOTP } from '../../pages/Profile/pages/Security/Index'
//import { setUser } from "../../features/Accounts/AccountSlice";


export default function ProfilePop({ handleModal, closeModal }: any) {
  const navigate = useNavigate()

  const dispatch = useAppDispatch();
  function handleClose() {
    closeModal()
    dispatch(setModalState(false));
  }

  const handleSecurity = () => {
    sendOTP()
    navigate('/profile/security/confirm-change')
    handleModal()
  }

  return (
    <div onClick={handleClose} className="fixed inset-0 z-20 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
      <div className="fixed right-0 mr-[5%] mt-[3%] top-6 py-5 border w-52 shadow-lg rounded-md bg-white">
        <ul className="leading-[3em]">
          <li
            onClick={() => {

              navigate('/profile')
              handleModal()
            }}
            className=" cursor-pointer hover:bg-slate-100 flex items-center gap-3 px-8"
          >
            <span className="text-[20px]">
              <IoPersonOutline />
            </span>{' '}
            Profile
          </li>
          <li onClick={() => {
            navigate('/profile')
            handleModal()
          }}
          className=" cursor-pointer hover:bg-slate-100 flex items-center gap-3 px-8 pb-2">
            <span className='text-[20px]'>
              <GrSettingsOption />
            </span>
             Settings
          </li>
          <li
            onClick={() => {
              handleSecurity()
            }}
            className=" cursor-pointer hover:bg-slate-100 flex items-center gap-3 px-8 pb-2"
          >
            {' '}
            <span className="text-[20px]">
              <GrShieldSecurity />
            </span>{' '}
            Security
          </li>
          <li
            className=" cursor-pointer hover:bg-slate-100 flex items-center gap-3 border-t pt-2 px-8 "
            onClick={() => {
              dispatch(resetKYBState())
              localStorage.removeItem('modalDisplayed');
              removeAuthTokens()
              AuthService.signOut()
              dispatch(setCompletedSteps([]))
              dispatch(setStep(1));
              dispatch(setUser(null))
              dispatch(resetBvnVerified())
              navigate('/')
            }}
          >
            {' '}
            <span className="text-[20px]">
              <TbArrowBarRight />
            </span>{' '}
            Logout
          </li>
        </ul>
      </div>
    </div>
  )
}
