import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
// import { Link, useNavigate } from "react-router-dom";
import {
  XMarkIcon,
} from "@heroicons/react/24/outline";
import TransferFlag from "../../../../components/TransferFlag";
import TabButtons from "../../../../components/Tabs/LineButton";
import TabContent from "../../../../components/Tabs/TabContent";
import M2Timeline from "../../../../components/Layout/extras/StatusLine";
import RecipientCard from "../../../../components/Layout/TransferRecipientCard";
import DetailsCard from "../../../../components/Layout/M2IndividualDetails";
import { RootState } from "../../../../app/store";
import { useSelector } from "react-redux";
import Spinner from "../../../../components/PopUps/Spinner";
import { convertTime } from "../../../Accounts/components/AccountUtils";

type ModalProps = {
  closeModal: () => void;
};

const ViewOutgoingTransfer: React.FC<ModalProps> = ({ closeModal }) => {
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  const [activeTab, setActiveTab] = useState<string>("status");
 
  const { singleTransfer, loading } = useSelector( 
    (state: RootState) => state.transaction
  );

  const transactionID = singleTransfer
    ? (singleTransfer as any).TransactionIdentifier
    : " ";

  const status = singleTransfer
    ? (singleTransfer as any).TransactionState
    : " ";


  const statusResult =
    status === "COMPLETED" ? (
      <span className="px-3 py-1 my-2 rounded-lg capitalize bg-[#DEF7EC] text-[#03543F] font-medium text-xs">
        Completed
      </span>
    ) : status === "IN_TRANSIT" ? (
      <span className="px-3 py-1 my-2 rounded-lg capitalize bg-[#EBFBFE] text-[#1892D7] font-medium text-xs">
        In Transit
      </span>
    ) : status === "PENDING" ? (
      <span className="px-3 py-1 my-2 rounded-lg capitalize bg-[#EBFBFE] text-[#1892D7] font-medium text-xs">
        In Transit
      </span>
    ) : status === "KYC_PENDING" ? (
      <span className="px-3 py-1 my-2 rounded-lg capitalize bg-[#FDF0E7] text-[#FF6600] font-medium text-xs">
        Pending
      </span>
    ) : (
      <span className=" px-3 py-1 my-2 font-medium capitalize bg-[#FDE8E8] rounded-lg text-[#9B1C1C] text-[10px]">
        {singleTransfer ? (singleTransfer as any).TransactionState : " "}
      </span>
    );

  const amount = singleTransfer
    ? (singleTransfer as any).TransactionDetail.FromAmount
    : " ";
    const fee = singleTransfer
    ? (singleTransfer as any).TransactionDetail.Fee
    : " ";

    const totalAmount = amount + fee;

  let createdDate = singleTransfer ? (singleTransfer as any).CreatedAt : "";
  const displayDate = convertTime(new Date(createdDate));

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={closeModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white pb-8 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-[34rem]  w-[30rem]">
                <header
                  className={`w-full bg-[#EFEFEF] py-4 px-8  flex justify-between`}
                >
                  <Dialog.Title
                    as="h3"
                    className="text-base font-bold leading-6 text-gray-900"
                  >
                    Transaction Details
                  </Dialog.Title>
                  <button
                    type="button"
                    className="rounded-md text-black hover:text-gray-500 focus:outline-none focus:ring-2 font-bold"
                    onClick={closeModal}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                  </button>
                </header>
                {loading ? (
                  <Spinner />
                ) : (
                  <div className="">
                    <div className="mt-3">
                      <div className="flex justify-between px-6 py-9 items-center">
                        <div>
                          <p className="text-sm font-bold">
                            International Transfer
                          </p>
                        </div>
                        <div>
                          <TransferFlag />
                        </div>
                        <div>
                          <p className="pl-10 md:pl-0 text-xs">
                            {displayDate}
                          </p>
                        </div>
                      </div>

                      <div className="bg-[#F6F6F6]">
                        <div className="text-center py-6">
                          <p className="text-lg">
                            Transfer of {" "}
                            <span className="font-bold text-xl">
                              $
                              {totalAmount?.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </span>{" "}
                            {/* to{" "}
                            <span className="text-[#B16F0B]">{recipient}</span> */}
                          </p>

                          <div className="mt-2">
                            {singleTransfer ? statusResult : ""}
                          </div>
                        </div>
                      </div>
                      <div className=" w-full mb-4 md:mb-0 mt-8">
                        <ul className="grid grid-cols-2 gap-4 border-b-2 border-[#C1C1C1]">
                          <TabButtons
                            name="Status"
                            id="status"
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                          />
                          <TabButtons
                            name="Transfer Summary"
                            id="transfer"
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                          />
                        </ul>
                      </div>

                      <div className="mt-4 px-6 md:px-8">
                        <TabContent id="status" activeTab={activeTab}>
                          <div className="container mt-8">
                            <div className="md:w-[24rem] mx-auto my-12">
                              <M2Timeline />
                            </div>
                          </div>
                          <div className="mt-12 md:mt-16">
                            <div className="md:flex justify-between">
                              <p className="text-sm text-[#6F6F6F]">
                                Transaction ID
                              </p>
                              <p className="select-text">{transactionID}</p>
                            </div>
                          </div>
                          <footer>
                            <div className="pt-4 mb-8">
                              <button
                                type="button"
                                className="text-sm 
                          py-3
                            rounded-md mt-4 
                            border border-[#35803F] w-[100%] text-[#35803F] bg-[#F9FFFA]"
                                onClick={closeModal}
                              >
                                Done
                              </button>
                            </div>
                          </footer>
                        </TabContent>

                        <TabContent id="transfer" activeTab={activeTab}>
                          <div>
                            <RecipientCard title="" />
                            <div className="border-dashed border-t border-[#BDBDBD] my-3"></div>
                            <DetailsCard />
                          </div>
                          <div className="mt-8 md:mt-8">
                            <div className="md:flex justify-between items-center gap-2">
                              <p className="text-sm text-[#6F6F6F]">
                                Transaction ID
                              </p>
                              <p className="select-text">{transactionID}</p>
                            </div>
                          </div>
                          <footer>
                            <div className="pt-4">
                              <button
                                type="button"
                                className="text-sm 
                          py-3
                            rounded-md mt-4 
                            border border-[#35803F] w-[100%] text-[#35803F] bg-[#F9FFFA]"
                                onClick={closeModal}
                              >
                                Done
                              </button>
                            </div>
                          </footer>
                        </TabContent>
                      </div>
                    </div>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ViewOutgoingTransfer;
