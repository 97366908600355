import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import { UpdatePin } from "../../../api";
import { getUserIdWithAccessToken, getUser } from "../../../login";
import errorImg from "../../../asset/svg/material-symbols_warning.svg";
import errIcon from "../../../images/error-icon.svg";
import { useAppDispatch } from "../../../app/hooks";
import { setUser } from "../../../features/User/UserSlice";
import { AWS_CREDS } from "../../../features/services/AmazonService";

interface ModalProps {
  closeModal: () => void;
  openUpdatedPinModal: () => void;
}
const NewPin:React.FC<ModalProps> = ({closeModal , openUpdatedPinModal}) => {

  const cancelButtonRef = useRef(null);
  const [pin, setPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [pinError, setPinError] = useState("");
  const [confirmPinError, setConfirmPinError] = useState("");
  const [formValid, setFormValid] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showPin, setShowPin] = useState(false);
  const [showConfirmPin, setShowConfirmPin] = useState(false);
  const [isPinValid, setIsPinValid] = useState(true);
  const AppDispatch = useAppDispatch();

  

  const toggleShowPin = () => {
    setShowPin(!showPin);
  };

  const toggleShowConfirmPin = () => {
    setShowConfirmPin(!showConfirmPin);
  };

  const validatePin = (value: string) => {
    if (value.length !== 4) {
      setPinError("PIN must be 4 digits");
      setIsPinValid(false);
    } else if (!/^[0-9]+$/.test(value)) {
      setPinError("Please enter a valid PIN");
      setIsPinValid(false);
    } else {
      setPinError("");
      setIsPinValid(true);
    }
  };
  

  const validateConfirmPin = (value: string) => {
    if (value !== pin) {
      setConfirmPinError("PIN and Confirm PIN do not match.");
    } else {
      setConfirmPinError("");
    }
  };

  const handlePinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPin(value);
    validatePin(value);
  };

  const handleConfirmPinChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setConfirmPin(value);
    validateConfirmPin(value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const userID = await getUserIdWithAccessToken()
    const session = await Auth.currentSession()
    try {
       await UpdatePin(userID,pin).then(async ()=>{
          const userDetails = await getUser(userID, session.getIdToken().getJwtToken());
          AppDispatch(setUser(userDetails))
          openUpdatedPinModal();
       })
    } catch (error: any) {
      setLoading(false);
      if(AWS_CREDS.STAGE === 'qa'){
        console.log(error);
      }
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (pin && confirmPin) {
      setFormValid(!pinError && !confirmPinError);
    } else {
      setFormValid(false);
    }
  }, [pin, confirmPin, pinError, confirmPinError]);

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={closeModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white pb-8 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-lg  w-[30rem]">
                <header
                  className={`w-full text-left bg-[#EFEFEF] py-4 px-8  flex justify-between`}
                >
                  <Dialog.Title
                    as="h3"
                    className="text-base font-bold leading-6 text-gray-900"
                  >
                    Transaction PIN
                  </Dialog.Title>
                  <button
                    type="button"
                    className="rounded-md text-black hover:text-gray-500 focus:outline-none focus:ring-2 font-bold"
                    onClick={() => closeModal()}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                  </button>
                </header>



                <div className="flex justify-center  w-full items-center p-3 ">
                <div className="sm:w-[80%] md:w-[80%] pb-14 sm:pb-8 rounded-lg ">
                  <h2 className="lg:text-left font-bold text-[20px] text-black mt-4 mb-2">
                    Reset Transaction PIN
                  </h2>
                  <p className="text-black text-opacity-90 mt-3">A 4-digit PIN to authorize your transactions on Cleva</p>

                  {/* form section  */}

                  <form onSubmit={handleSubmit} className="mt-10">
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-black-soft"
                      >
                        Enter 4-digit PIN
                      </label>
                      <div className="flex mt-2">
                        <input
                          id="pin"
                          name="pin"
                          type={showPin ? "text" : "password"}
                          placeholder="4-digit PIN"
                          value={pin}
                          onChange={handlePinChange}
                          required
                          className={`${
                            !isPinValid ? "border-[#F15757]" : ""
                          } password-control`}
                          aria-label="4-digit PIN"
                          pattern="[0-9]*" // Only accept numeric values
                          inputMode="numeric"
                        />
                        <button
                          type="button"
                          onClick={toggleShowPin}
                          className="password-button"
                        >
                          {showPin ? <p>Hide</p> : <p>Show</p>}
                        </button>
                      </div>
                      {pinError && (
                        <div className="flex items-center mb-2 mt-1">
                          <img src={errIcon} alt="error" />
                          <p className="text-[#D31D1D] text-sm ml-1">
                            {pinError}
                          </p>
                        </div>
                      )}
                    </div>

                    <div className="mt-6">
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-black-soft mb-2"
                      >
                        Confirm PIN
                      </label>
                      <div className="flex mt-2">
                        <input
                          type={showConfirmPin ? "text" : "password"}
                          id="confirmPin"
                          name="confirmPin"
                          value={confirmPin}
                          onChange={handleConfirmPinChange}
                          placeholder="Confirm PIN"
                          required
                          className="password-control"
                          aria-label="4-digit PIN"
                          pattern="[0-9]*" // Only accept numeric values
                          inputMode="numeric"
                        />
                        <button
                          type="button"
                          onClick={toggleShowConfirmPin}
                          className="password-button"
                        >
                          {showConfirmPin ? <p>Hide</p> : <p>Show</p>}
                        </button>
                      </div>

                      {/* banner */}
                      {confirmPinError !== "" && 
                      (<div
                          className="w-full px-5 flex my-[2.81rem] text-[#800205] font-[500]
                          rounded-lg
                          justify-center text-sm items-center h-[3.75rem] bg-[#FFDCE0] ">
                          <img src={errorImg} className="" alt="error" />
                          <span className="text-[#800205] pl-2  font-normal">
                            {confirmPinError}
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="mt-9">
                      <button
                        type="submit"
                        disabled={!formValid}
                        className={
                          formValid ? "login-active" : " login-disabled "
                        }
                      >
                        {loading ? "Loading ..." : "Submit"}
                      </button>
                    </div>
                  </form>
                </div>
                </div>

                <footer>
                </footer>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default NewPin;
