import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface INavState {
  title: string;
}

const initialState: INavState = {
  title: "Home"
};

export const NavSlice = createSlice({
  name: "NavInfo",
  initialState,
  reducers: {
    setTitle(state, action: PayloadAction<any>) {
      state.title = action.payload;
    }
  },
});

export const {
  setTitle
} = NavSlice.actions;
export default NavSlice.reducer;
