import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { AWS_CREDS } from "../../../features/services/AmazonService";

const M2Timeline: React.FC = () => {
  const delay = AWS_CREDS.DELAYED_TRANSACTION;
  const { singleTransfer, loading, error } = useSelector(
    (state: RootState) => state.transaction
  );

  const timeline = singleTransfer
    ? (singleTransfer as any)?.AdditionalDetails.TransactionStatus
    : " ";


  const recipient = singleTransfer
    ? (singleTransfer as any).TransactionDetail.Recipient.FullName.FirstName +
      " " +
      (singleTransfer as any).TransactionDetail.Recipient.FullName.LastName
    : " ";

  let transitTime =
    timeline && timeline.In_Transit && timeline.In_Transit.DateTime;

  let completeTime =
    timeline && timeline.Completed && timeline.Completed.DateTime;

    //backend is calling delayed instead of Delayed.. so this is changed
  let delayedTime = timeline && timeline.delayed && timeline.delayed.DateTime;

  let pendingTime = singleTransfer ? (singleTransfer as any).CreatedAt : "";

  let cancelledTime =
    timeline && timeline.Cancelled && timeline.Cancelled.DateTime;
  let failedTime = timeline && timeline.Failed && timeline.Failed.DateTime;

  const transactionOverAnHour = (dateTime: string) => {
    if (dateTime) {
      const currentTime = new Date();
      const trfTransitTime = new Date(dateTime);
      const timeDifferenceInMilliseconds =
        Number(currentTime) - Number(trfTransitTime);
      const oneHourInMilliseconds = Number(delay) * 60 * 1000;
      return timeDifferenceInMilliseconds > oneHourInMilliseconds;
    }
    return false;
  };


  let isDelayedTransaction =
    (transactionOverAnHour(pendingTime) || delayedTime) &&
    !completeTime &&
    !cancelledTime;

  let transitDescription = "The NGN is on its way";

  let pretransitDescription = "You’ve set up your transfer";

  let cancelTime = singleTransfer ? (singleTransfer as any).UpdatedAt : "";

  let cancelDescription = "You cancelled this transfer";
  let failedDescription = "Failed";

  let delayedDescription =
    "Your transfer is taking longer than usual. We're already working on it with our provider. Please bear with us.";

  let completeDescription = `${recipient} has received your NGN`;

  const convertTime = (myDate: any) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const day = myDate.getDate(); 
    const month = months[myDate.getMonth()];
    const year = myDate.getFullYear();

    let hour = myDate.getHours();
    let minutes = myDate.getMinutes();
    let period = "am";

    if (hour >= 12) {
      hour -= 12;
      period = "pm";
    }

    if (hour === 0) {
      hour = 12;
    }

    if (minutes < 10) {
      minutes = "0" + minutes;
    }

    const datestring = `${month} ${day} at ${hour}:${minutes}${period}`;
    return datestring;
  };

  const myTransitTime = convertTime(new Date(transitTime));
  const myPendingTime = convertTime(new Date(pendingTime));
  const myCompleteTime = convertTime(new Date(completeTime));
  const myCancelledTime = convertTime(new Date(cancelTime));
  const myFailedTime = convertTime(new Date(failedTime));

  return (
    <>
      <ol className="relative text-gray-500 border-l-[3px] border-[#CACACA] ml-24 md:ml-[10rem]">
        <li className="mb-8 ml-6 md:ml-10 flex items-center">
          <p className="w-[5rem] md:w-auto leading-tight -left-[7rem] md:-left-48 flex absolute text-xs md:text-sm">
            {" "}
            {myPendingTime}
          </p>
          <span className="absolute flex items-center justify-center w-3 h-3 bg-[#35803F] rounded-full -left-[7.5px] mt-0.5"></span>
          <p className="text-sm text-[#35803F]">You’ve set up your transfer</p>
        </li>
        {/* {transitTime ? (
          <li className="mb-8 ml-6 flex items-center">
            <p className="leading-tight -left-[10rem] flex absolute text-sm">
              {" "}
              {myTransitTime}
            </p>

            <span className="absolute flex items-center justify-center w-3 h-3 bg-[#35803F] rounded-full -left-[7.5px] mt-0.5"></span>
            <p className="text-sm text-[#35803F]">{pretransitDescription}</p>
          </li>
        ) : (
          <li className="mb-8 ml-6 flex items-center">
            <p className="leading-tight -left-[10rem] flex absolute text-sm"> </p>

            <span className="absolute flex items-center justify-center w-3 h-3 bg-gray-200 rounded-full -left-[7.5px] mt-0.5"></span>
            <p className="text-sm">{pretransitDescription}</p>
          </li>
        )} */} 
        {pendingTime ? (
          <li className="mb-8 ml-6 md:ml-10 flex items-center">
            <p className="w-[5rem] md:w-auto leading-tight -left-[7rem] md:-left-48 flex absolute text-xs md:text-sm">
              {" "}
              {myPendingTime}
            </p>

            <span className="absolute flex items-center justify-center w-3 h-3 bg-[#35803F] rounded-full -left-[7.5px] mt-0.5"></span>
            <p>
              <span className="text-sm text-[#35803F]">
                {transitDescription}
              </span>
              {isDelayedTransaction && (
                <span className="text-xs block text-[#000] p-[0.5rem] mt-[0.5rem] rounded-[0.25rem] bg-[#FFE0A4] font-medium">
                  {delayedDescription}{" "}
                </span>
              )}
            </p>
          </li>
        ) : (
          <li className="mb-8 ml-6 md:ml-10 flex items-center">
            <p className="leading-tight -left-[10rem] flex absolute text-xs md:text-sm">
              {" "}
            </p>

            <span className="absolute flex items-center justify-center w-3 h-3 bg-gray-200 rounded-full -left-[7.5px] mt-0.5"></span>
            <p className="text-sm">
              {isDelayedTransaction && (
                <span className="text-xs block text-[#000] mb-[0.5rem] p-[0.5rem] rounded-[0.25rem] bg-[#FFE0A4] font-medium">
                  {delayedDescription}
                </span>
              )}
              <span> {transitDescription} </span>
            </p>
          </li>
        )}
        {cancelledTime ? (
          <li className="mb-8 ml-6 md:ml-10 flex items-center">
            <p className="w-[5rem] md:w-auto leading-tight -left-[7rem] md:-left-48 flex absolute text-xs md:text-sm">
              {" "}
              {myCancelledTime}
            </p>

            <span className="absolute flex items-center justify-center w-3 h-3 bg-[red] rounded-full -left-[7.5px] mt-0.5"></span>
            <p className="text-sm text-[red]">{cancelDescription}</p>
          </li>
        ) : (
          " "
        )}
        {failedTime ? (
          <li className="mb-8 ml-6 md:ml-10 flex items-center">
            <p className="w-[5rem] md:w-auto leading-tight -left-[7rem] md:-left-48 flex absolute text-xs md:text-sm">
              {" "}
              {myFailedTime}
            </p>

            <span className="absolute flex items-center justify-center w-3 h-3 bg-[red] rounded-full -left-[7.5px] mt-0.5"></span>
            <p className="text-sm text-[red]">{failedDescription}</p>
          </li> 
        ) : (
          " "
        )}
        {completeTime ? (
          <li className="mb-8 ml-6 md:ml-10 flex items-center">
            <p className="w-[5rem] md:w-auto leading-tight -left-[7rem] md:-left-48 flex absolute text-xs md:text-sm">
              {" "}
              {myCompleteTime}
            </p>

            <span className="absolute flex items-center justify-center w-3 h-3 bg-[#35803F] rounded-full -left-[7.5px] mt-0.5"></span>
            <p className="text-sm text-[#35803F]">{completeDescription}</p>
          </li>
        ) : (
          <li className="mb-8 ml-6 md:ml-10 flex items-center">
            <p className="leading-tight -left-[10rem]  md:-left-48 flex absolute text-xs md:text-sm">
              {" "}
            </p>

            <span className="absolute flex items-center justify-center w-3 h-3 bg-gray-200 rounded-full -left-[7.5px] mt-0.5"></span>
            <p className="text-sm">{completeDescription}</p>
          </li>
        )}
      </ol>
    </>
  );
};

export default M2Timeline;
