import React, { ReactNode } from 'react'
import logo from "../../asset/images/logo.svg";
import { IoIosAnalytics, IoMdTrendingUp } from 'react-icons/io'
import { MdOutlineGroupAdd } from "react-icons/md";
import { TbArrowBarRight } from 'react-icons/tb'
import { IoCardSharp, IoReaderOutline, IoFileTrayOutline, IoPersonOutline } from 'react-icons/io5'
import { NavLink, useNavigate } from 'react-router-dom'
import { removeAuthTokens } from '../../login'
import { AuthService } from '../../features/services/AuthServices'
import { setUser } from '../../features/User/UserSlice'
import Close from "../../asset/landing_photos/close-menu.svg";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { resetBvnVerified } from '../../features/ClevaCards/ClevaCardsSlice';
import { resetKYBState } from '../../features/Kyc/kycSlice';

interface ITab {
  id: number
  tabName: string
  tabIcon: ReactNode
  disable?: Boolean
  path: string
  onClick: (e: any) => void
  disableIfDenied: Boolean
  newFeature?: Boolean
  selectedTester?: Boolean
}

interface iSideBar {
  isOpen: Boolean,
  handleClick: () => void,
  KYCState?: string,
}

export default function SideBar(props: iSideBar) {
  const AppDispatch = useAppDispatch()
  const { isOpen, handleClick } = props
  const navigate = useNavigate()
  const { KYCState } = useAppSelector((state: any) => state.kycInfo);
  // const refreshPage = () => window.location.reload();

  const Tabs: ITab[] = [
    {
      id: 1,
      tabName: 'Home',
      tabIcon: <IoIosAnalytics />,
      onClick: () => {
        navigate('/')
        // refreshPage();
      },
      path: '/',
      disableIfDenied: true,
    },
    {
      id: 2,
      tabName: 'Accounts',
      tabIcon: <IoFileTrayOutline />,
      onClick: () => navigate('/accounts'),
      path: '/accounts',
      disableIfDenied: true,
    },
    // {
    //   id: 3,
    //   tabName: 'Transactions',
    //   tabIcon: <TbArrowsRightLeft />,
    //   disable: true,
    //   onClick: () => navigate('/Transanctions'),
    //   path: '/Transanctions',
    // },
    {
      id: 4,
      tabName: 'Transfers',
      tabIcon: <IoMdTrendingUp />,
      onClick: () => navigate('/transfers'),
      path: '/transfers',
      disableIfDenied: true,
  },
    {
      id: 5,
      tabName: 'Recipients',
      tabIcon: <IoPersonOutline />,
      onClick: () => navigate('/recipients'),
      path: '/recipients',
      disableIfDenied: true,
    },
    {
      id: 8,
      tabName: 'Referrals',
      tabIcon: <MdOutlineGroupAdd />,
      onClick: () => navigate('/referrals'),
      path: '/referrals',
      disableIfDenied: true,
      newFeature: false,
    },
    {
      id: 6,
      tabName: 'Cards',
      tabIcon: <IoCardSharp />,
      onClick: () => navigate('/cards'),
      path: '/cards',
      disableIfDenied: true,
      newFeature: true,
      disable: false,
    },
    {
      id: 7,
      tabName: 'Invoices',
      tabIcon: <IoReaderOutline />,
      disable: true,
      onClick: () => navigate('/Invoices'),
      path: 'Invoices',
      disableIfDenied: true,
    },
  ]

  const handleLogout = () => {
    AppDispatch(resetKYBState())
    removeAuthTokens()
    AuthService.signOut()
    AppDispatch(setUser(null))
    AppDispatch(resetBvnVerified())
    navigate('/')
  }

  return (
    <section onClick={handleClick} id="sideBar" className={`w-[256px] sm:w-[288px]  z-10 lg:sticky bg-black h-screen fixed top-0 left-0 transform delay-0 ${isOpen ? 'translate-x-0' : '-translate-x-[300%] lg:translate-x-0'} transition-transform duration-300 ease-in`}>
      <div className='flex items-center justify-between'>
        <div className="p-4 cursor-pointer" onClick={() => navigate('/')}>
          <img src={logo} alt="logo" />
          
        </div>
        <button className='pr-[1.66rem] lg:hidden'>
          <img src={Close} alt="Close side navigation" />
        </button>
      </div>
      <nav className="text-[14px] text-[#EBF0F0] leading-[4em] font-bold px-9 md:mt-12 ">
        {Tabs.map((tab) => (
          <div className='relative' key={tab.id}>
            <NavLink
            data-test="sidenav"
              onClick={handleClick}
              to={tab.path}
              // reloadDocument
              style={tab.disable || ((tab.disableIfDenied && KYCState === 'DENIED') || (KYCState === 'SUSPENDED')) ? { pointerEvents: 'none', color: '#A9A9A9' } : undefined}
              className={({ isActive }) => (isActive ? 'navbar-link active' : 'navbar-link')}
            >
              <span className="text-[20px]">{tab.tabIcon}</span>
              <span className=" cursor-pointer">{tab.tabName}</span>
              {tab.disable &&
                <div className='absolute right-[-1rem] sm:right-[3rem] lg:right-[-1rem] top-0'>
                  <span data-test="coming-soon" className=" bg-green-600 text-white rounded-lg px-[0.5rem] py-[0.2rem] text-[.5rem]">Coming Soon</span>
                </div>
              }
              {tab.newFeature &&
                <div className='absolute right-[2rem] sm:right-[4rem] lg:right-0 xl:right-[1rem] top-0'>
                  <span className="border border-[#F8B755] bg-white text-[#FF6D59] font-medium rounded-[62rem] px-[0.5rem] py-[0.2rem] text-[.75rem]">New</span>
                </div>
              }
            </NavLink>
          </div>
        ))}
        <button className="flex lg:hidden items-center gap-4 pt-16" onClick={handleLogout}>
          <span className='text-[20px]'> <TbArrowBarRight /> </span>
          <span>Logout</span>
        </button>
      </nav>
    </section>
  )
}
