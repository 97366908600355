import React, { useEffect } from "react";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { LuDot } from "react-icons/lu";
import { setAdditionalDetails, setKYCState } from "../../../features/Kyc/kycSlice";
import { FaPlus } from "react-icons/fa6";
import BeneficiaryOwnerForm from "./beneficiaryOwnerForm";
import { completeIDCheck, deleteOwner, getKyc } from "../../../api";
import { RiDeleteBin6Line, RiEditLine } from "react-icons/ri";
import { fetchOwners } from "../../../features/Kyc/KycApi";
import CardModal from "../../ClevaCards/CardModal";
import { IoCloseOutline } from "react-icons/io5";
import Spinner from "../../../components/PopUps/Spinner";
import VerifiedOwners from "../modals/VerifiedOwners";
import { AWS_CREDS } from "../../../features/services/AmazonService";
interface ISteps {
  currentStep?: number;
  nextStep?: any;
  openForm?: any;
  setIndex?: any;
  index?: any;
  opened: boolean;
}

export interface RegisteredAddress {
  StreetAddress: string;
  SecondStreetAddress?: string;
  City: string;
  Country: string;
  StateOrTerritory: string;
  Zipcode?: string;
  LGA?: string;
}

export interface IdentificationDocument {
  DocumentNumber?: string;
  DocumentType: string;
  IssuingCountry?: string;
  IssueDate?: string;
  ExpirationDate?: string;
}




function BeneficialOwners(props: ISteps) {
  const dispatch = useAppDispatch();
  const { BusinessKyc, BeneficialOwners, loading } = useAppSelector((state: any) => state.kycInfo);
  const [businessInfo, setBusinessInfo] = useState<any>(null)
  const user = useAppSelector((state) => state.user.userData)
  const [ownerForm, setOwnerForm] = useState(false)
  const [ownerCount, setOwnerCount] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [modal, setModal] = useState(false)
  const [percentError, setPercentError] = useState({
    lessThan: false,
    greaterThan: false
  })
  const [names, setNames] = useState({
    firstName: '',
    lastName: '',
    kycIdentifier: '',
  })


  const closeDeleteModal = () => {
    setDeleteModal(false)
    setNames((prev) => ({
      firstName: "",
      lastName: "",
      kycIdentifier: ""
    }))
  }

  const deletePrompt = (fName: string, lName: string, kyc: string) => {
    setNames((prev) => ({
      ...prev,
      firstName: fName,
      lastName: lName,
      kycIdentifier: kyc
    }));
    setDeleteModal(true)
  }
  const handleOwnerDetail = () => {
    setOwnerForm(true)
  }

  const closeOwnerDetail = () => {
    dispatch(fetchOwners())
    setOwnerForm(false)
  }

  const editOwner = (id: string) => {
    localStorage.setItem("editOwner", id)
    setOwnerForm(true)
    // setEditOwnerForm(true)
  }

  const handleDeleteOwner = async () => {
    if (names.kycIdentifier) {
      try {
        const response = await deleteOwner(names.kycIdentifier);
        if (response?.data) {
          closeDeleteModal();
          dispatch(fetchOwners())
        }
      } catch (error) {
        if(AWS_CREDS.STAGE === 'qa'){
          console.error("Error deleting owner:", error);
        }
      }
    }

  }

  const proceed = () => {
    if (props.currentStep) {
      props.nextStep(props?.currentStep + 1);
    }
  }
  const validate = async () => {

    try {
      const response = await completeIDCheck({ AllIdChecksComplete: true })
      if (response?.data) {
        dispatch(setKYCState(response?.data?.BusinessKycState));
        dispatch(setAdditionalDetails(response?.data?.BusinessKyc?.AdditionalDetails));
        if (response?.data.Message === "All ID checks completed") {
          setModal(true)
        }
      }
    } catch (error) {
      if(AWS_CREDS.STAGE === 'qa'){
        console.log(error);
      }
    }

  };

  useEffect(() => {
    setOwnerCount(BeneficialOwners?.length > 0)
  }, [BeneficialOwners])

  useEffect(() => {
    const isOwnerInReview = BeneficialOwners?.every((owner: any) => owner.KYCState === 'DOCUMENT_IN_REVIEW' || owner.KYCState === 'DOCUMENT_UPLOAD' || owner.KYCState === 'DOCUMENT_RETRY' || owner.KYCState === 'VERIFIED')
    let totalOwnership;
    if (BeneficialOwners) {
      totalOwnership = BeneficialOwners?.reduce(
        (acc: any, curr: any) => acc + Number(curr?.BeneficialOwner?.PercentageOwnership || 0),
        0
      );
    }

    if (totalOwnership < 25) {
      setPercentError({
        lessThan: true,
        greaterThan: false
      })
    } else if (totalOwnership > 100) {
      setPercentError({
        lessThan: false,
        greaterThan: true
      })
    } else {
      setPercentError({
        greaterThan: false,
        lessThan: false
      })
    }
    setButtonDisabled(!isOwnerInReview || totalOwnership < 25 || totalOwnership > 100)
  }, [BeneficialOwners]);


  useEffect(() => {
    dispatch(fetchOwners())
  }, [dispatch]);

  useEffect(() => {
    const identifier = localStorage.getItem("KYCIdentifier")
    const fetchKyc = async () => {
      const resp = await getKyc(identifier)
      setBusinessInfo(resp?.data)
    }
    fetchKyc()

  }, [])

  const ownerState = (state: string) => {
    switch (state) {
      case 'RETRY':
      case 'INVALID':
      case 'MISMATCH':
      case 'BVN_FAILED':
        return 'Owner could not be verified. Please retry.'
      case 'DUPLICATE':
        return 'Duplicate BVN. Please retry with accurate details.'
      case 'DOCUMENT_IN_REVIEW':
      case 'DOCUMENT_UPLOAD':
      case 'DOCUMENT_RETRY':
      case 'VERIFIED':
        return 'Owner verified successfully.'
      default:
        return 'Error verifying owner. Please retry.';
    }
  }


  if (!businessInfo) {
    return <Spinner />
  }

  // if (!BeneficialOwners) {
  //   return <Spinner />
  // }

  if (loading) {
    return <Spinner />
  }

  const renderOwners = () => {
    const owners = BeneficialOwners;
    if (!owners) return null;

    return owners.map((owner: any) => (
      <article className="mb-3" key={owner?.KYCIdentifier}>
        {/* <p>{owner?.KYCState}</p> */}
        <div  className="bg-[#FCFCFC] border p-4 border-[#E3E3E3] rounded-md">
          <div className="flex gap-4 justify-between">
            <div className="flex gap-4">
              <div className="w-[44px] h-[44px] rounded-full bg-[#EDE5DB] font-medium text-[#703308] text-sm flex items-center justify-center">
                {`${owner?.BeneficialOwner?.FirstName[0]?.toUpperCase()}${owner.BeneficialOwner?.LastName[0]?.toUpperCase()}`}
              </div>
              <div>
                <h2 className="text-[#454545] font-medium text-lg"> {owner?.BeneficialOwner?.FirstName}  {owner.BeneficialOwner?.LastName}</h2>
                <div className="flex justify-between lg:gap-24 items-center">
                  {/*  */}
                  <div className="grid xl:flex  justify-center items-center">

                    <p className="text-[#454545] font-medium text-sm">{owner?.BeneficialOwner?.IsOwnerAndController ? 'Controller & Owner' : 'Owner'} at  {businessInfo?.BusinessKyc?.BusinessName || user?.BusinessName} </p>
                    <p><LuDot /></p>
                    <p className="text-[#B56D00] font-normal text-sm">{owner?.BeneficialOwner?.PercentageOwnership}% ownership</p>
                  </div>
                </div>
                <p className={`mt-3 w-max text-xs  ${(owner?.KYCState === "RETRY" || owner?.KYCState === "INVALID" || owner?.KYCState === "MISMATCH" || owner?.KYCState === "DUPLICATE") ? 'text-[#E01E1E]' : (owner?.KYCState === "DOCUMENT_IN_REVIEW" || owner?.KYCState === "DOCUMENT_UPLOAD" || owner?.KYCState === "VERIFIED"  ? ' text-[#158025]' : 'text-[#703308]')}`}>{ownerState(owner?.KYCState)}</p>

              </div>
            </div>

            <button
              type="button"
              onClick={(e) =>
                editOwner(
                  owner?.KYCIdentifier
                )
              }

              className={`flex center gap-[0.4rem] items-end ${owner?.KYCState === "DOCUMENT_IN_REVIEW" || owner?.KYCState === "DOCUMENT_REVIEW" || owner?.KYCState === "DOCUMENT_UPLOAD" || owner?.KYCState === "DOCUMENT_RETRY" || owner?.KYCState === "VERIFIED" ? 'hidden' : ''}`}
            >
              <RiEditLine className="text-[#B56D00]" />
            </button>
            <button
              type="button"
              onClick={(e) =>
                deletePrompt(
                  owner?.BeneficialOwner?.FirstName || "",
                  owner?.BeneficialOwner?.LastName || "",
                  owner?.KYCIdentifier
                )
              }

              //className={`flex center gap-[0.4rem] items-end ${owner?.KYCState === "DOCUMENT_IN_REVIEW" || owner?.KYCState === "DOCUMENT_REVIEW" || owner?.KYCState === "DOCUMENT_UPLOAD" || owner?.KYCState === "DOCUMENT_RETRY" || owner?.KYCState === "VERIFIED" ? 'hidden' : ''}`}
              className={`flex center gap-[0.4rem] items-end ${owner?.BeneficialOwner?.IsOwnerAndController ? 'hidden' : ''}`}
            >
              <RiDeleteBin6Line className="text-[#E01E1E]" />
            </button>

          </div>


        </div>
      </article>
    ));
  };

  return (
    <React.Fragment>
      {ownerForm ?

        <div className="sm:w-[65%] md:w-[70%] sm:ml-12">
          <BeneficiaryOwnerForm businessName={businessInfo?.BusinessKyc?.BusinessName} closeDetail={closeOwnerDetail} />
        </div>
        :
        (
          <form className=" bg-[#FFFFFF] rounded-2xl p-2 sm:p-8 sm:w-[60%] md:w-[70%] xl:w-[53%] h-auto">
            <div>
              <h3 className="font-semibold text-[20px] pb-3 ">
                Beneficial Owners
              </h3>

              <p className="text-[13px] mb-7 text-[#747A80]">
                Due to regulatory guidelines, we’re required to collect information on anyone who has significant ownership of your business
              </p>

              <section className="grid">
                {renderOwners()}
              </section>


              {ownerCount ?
                <div className="pt-8 grid gap-4">
                  <button onClick={handleOwnerDetail} className={`flex items-center justify-center gap-3 w-full lg:w-[20rem] xl:w-[26rem] mx-auto  text-base font-medium p-3 rounded-lg mt-2 border border-[#747A80] text-[#111111]`}> <FaPlus /> Add Beneficial Owner</button>
                  <button disabled={buttonDisabled} type="button" onClick={validate} className={`flex items-center justify-center gap-3 w-full lg:w-[20rem] xl:w-[26rem] mx-auto  text-base font-bold p-3 rounded-lg mt-2 border  text-[#111111] ${buttonDisabled ? "bg-[#FFF5D9] opacity-50 text-[#5F5D5D]" : "bg-[#FFBD59]"}`}> Continue </button>
                  <div className="text-center">
                    {percentError.greaterThan && (
                      <p className="text-[11px] text-[#F15757]">
                        Total ownership cannot be greater than 100%
                      </p>
                    )}
                    {percentError.lessThan && (
                      <p className="text-[11px] text-[#F15757]">
                        Total ownership cannot be less than 25%
                      </p>
                    )}
                  </div>
                </div>
                :
                <button onClick={handleOwnerDetail} className={`flex items-center justify-center gap-3 w-full lg:w-[17rem] text-sm font-semibold p-3 rounded-lg mt-2 border bg-[#FFBD59] text-[#111111]`}> <FaPlus /> Add Beneficial Owner</button>
              }


            </div>

          </form>
        )
      }

      {deleteModal && (
        <CardModal width="md:w-[33rem]" shadow="rounded-[1.25rem]">
          <div className="">
            <div className="rounded-t-[1.25rem] bg-[#EBF0F0] flex items-center justify-between px-[1.8rem] py-4">
              <h1 className="text-black font-medium text-base">Delete Owner</h1>
              <IoCloseOutline
                onClick={closeDeleteModal}
                className="text-xl cursor-pointer"
              />
            </div>
            <div className="px-[1.8rem] py-4">
              <div className="">
                <p className="text-[#404040] text-base font-normal">
                  Are you sure you want to delete {names.firstName} {names.lastName} as a owner?
                </p>

              </div>
              <div className="flex justify-between items-center pt-[3.04rem]">
                <button
                  className="bg-[#F4C952] py-[0.63rem] px-8 md:px-[2.87rem] rounded-lg hover:brightness-90 text-black font-bold  text-sm"
                  onClick={closeDeleteModal}
                >
                  Cancel
                </button>
                <button
                  className="bg-[#FFBD59] py-[0.63rem] px-8 md:px-[2.87rem] rounded-lg hover:brightness-90 text-black font-bold  text-sm"
                  onClick={handleDeleteOwner}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </CardModal>
      )}

      <VerifiedOwners open={modal} setOpen={setModal} action={proceed} />

    </React.Fragment>
  )

}
export default BeneficialOwners;
