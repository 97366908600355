
interface IGetStartedBullets {
    img?: string,
    title?: string,
    content?: string,
}

interface IGetStartedList {
    data: IGetStartedBullets[],
    textColor: string,
    customStyle?:string,
    ctnWidth?:string,
}

const GetStartedBullets = ({ data, textColor, customStyle, ctnWidth }: IGetStartedList) => {
    return (
        <div className="grid lg:grid-cols-3 gap-[1.75rem] lg:gap-[0.75rem] py-[4.28rem]">
            {data.map((item: IGetStartedBullets, index: number) => (
                <div className={`${customStyle} flex flex-col items-center  mx-auto gap-[2rem]}`} key={index}>
                    <header className={` `}>
                        <img className={``} src={item.img} alt={item.img} />
                    </header>
                    <div className={`text-${textColor} text-center`}>
                        <h1 className="font-semibold py-[1rem] text-[1.25rem]">{item.title}</h1>
                        <p className=" font-normal text-base lg:text-lg">{item.content}</p>
                    </div>
                </div>
            ))}

        </div>
    )
}

export default GetStartedBullets;