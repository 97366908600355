import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import StatusIcon from "../../../images/Account-status.svg"
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/store";
import { RootState } from "../../../app/store";
import Spinner from "../../../components/PopUps/smallSpinner";
import { getKyc } from "../../../api";
import { setAdditionalDetails, setKYCState } from "../../../features/Kyc/kycSlice";
import { KycStatus } from "../../Kyc/components/KycStatus";


// import allTransfer from "../../../components/data/TransferData";
import {
  fetchTransactions,
} from "../../../features/Transanctions/transactionApi";
export const AccountStatus = () => {
  const {  KYCState } = useSelector((state: RootState) => state.kycInfo);
  const KYCIdentifier = localStorage.getItem("KYCIdentifier");
  const [loading, setLoader] = useState(true);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(fetchTransactions());
  }, [dispatch]);
 
  useEffect(()=>{
    if (KYCIdentifier){
        getKyc(KYCIdentifier).then((res)=>{ 
          if (res?.data?.IndividualKyc){
            dispatch(setKYCState(res?.data?.IndividualKyc?.KYCState!));
                dispatch(setAdditionalDetails(res?.data?.IndividualKyc?.AdditionalDetails));
             } 
          else if (res?.data?.BusinessKyc){
              dispatch(setKYCState(res?.data?.BusinessKyc?.KYCState!));
              dispatch(setAdditionalDetails(res?.data?.BusinessKyc?.AdditionalDetails));
          }
          else {
              dispatch(setKYCState(""));
          }
          setLoader(false);
        }).catch((error)=>{
          setLoader(false);
          dispatch(setKYCState(""));
        });
    }else{
      setLoader(false);
    }
  },[]);

  const { allTransfer, error } = useSelector(
    (state: RootState) => state.transaction
  );

  const BVNVerified = KYCState !== "VERIFIED" && KYCState !== "BUSINESS_VERIFIED" && KYCState !== "DOCUMENT_IN_REVIEW" && KYCState !== "SUSPENDED" && KYCState !== "PENDING"
  
  if(!KYCState){
    return <Spinner/>
  }
  if ( BVNVerified) {
    return <PreStatus />;
  }
   else {
    return <KycStatus/>
  }

}

function PreStatus() {
  const navigate = useNavigate();
  return (
    <>
      <div className=" p-3 md:py-5 md:px-12 mt-2 bg-white rounded-2xl border border-[#D6D6D6] justify-center gap-2 flex">
       <div className="w-12 md:w-24 md:flex justify-end">
        <img alt="you need to complete KYC" src={StatusIcon}/>
       </div>
    <div className="w-7/8">
        <div className=" text-black text-base font-normal leading-tight">
        For your pending transactions to be available for transfer, you need to complete KYC by
        <span
            className="underline text-[#111] font-bold cursor-pointer pl-2"
            onClick={() => navigate("/kyc")}
          >
            Click here to complete KYC now
          </span>. 
          </div>
    </div>
</div> 
    </>
  );
}

function PendingStatus() {
  return (
    <>
      <div className=" p-3 md:py-5 md:px-12 mt-2 bg-[#FFF9E9] rounded-2xl border border-[#D6D6D6] justify-center gap-2 flex">
       <div className="w-12 md:w-24 md:flex justify-end">
        <img className="" alt="KYC Verification pending" src={StatusIcon}/>
       </div>
    <div className="w-7/8">
        <div className=" text-black text-base font-normal leading-tight">
        <p>KYC Verification pending, please check back soon</p>

          </div>
    </div>
</div>
    </>
  );
}

// function LoadingState() {
//   return <div>
//     <Spinner/>
//   </div>;
// }